package Structs

import Utils.WrappedName
import myName
import kotlin.contracts.Returns

@myName("AmountsDataHolder")
data class AmountsDataHolder(
    var value: Float = 0f,
    var returns: Float = 0f,
    var taxPaid: Float = 0f,
    var money: Float = 0f,
    var wrappedMoney: Float = 0f,
    var wrappedTaxPaid: Float = 0f,
    var wrappedValue: Float = 0f,
    var wrappedReturn: Float = 0f,
    var wrappedUnit: WrappedName = WrappedName.NONE
) {
}