package Structs

import Structs.Api.Api
import Structs.Api.ApiNote
import Utils.DocType
import myName

@myName("InformationBase")
abstract class InformationBase {
    var fulled: Boolean = false

    @myName("apis")
    var apis: MutableList<ApiNote> = mutableListOf()
    abstract fun getConnectedDate(): String
    abstract fun getConnectedId(): Int
    abstract fun getConnectedEntId(): Int
    abstract fun getConnectedValue(): Float
    abstract fun getConnectedName(): String
    @myName("getBussinessId")
    open fun getBusinessId(): String {
        return ""
    }

    @myName("getConnectedDateTime")
    open fun getConnectedDateTime(): String {
        return getConnectedDate()
    }

    @myName("getConnectedAgent")
    open fun getConnectedAgent(): String {
        return ""
    }

    @myName("getConnectedDocName")
    fun getConnectedDocName(): String {
        return DocType.TAX_NOTE_CANCEL.convert(getConnectedDocType()).DOC_NAME()
    }
    @myName("getAmounts")
    open fun getAmounts(): Float {
        return 0f
    }
    @myName("getReturnAmounts")
    open fun getReturnAmounts(): Float {
        return 0f
    }
    @myName("getConnectedTaxValueReal")
    open fun getConnectedTaxValueReal(): Float {
        return 0f
    }
    @myName("getBeforeValueReal")
    open fun getBeforeValueReal(): Float {
        return 0f
    }
    @myName("getConnectedValueReal")
    open fun getConnectedValueReal(): Float {
        return getConnectedValue()
    }

    @myName("getConnectedPayment")
    open fun getConnectedPayment(): Float {
        return 0f
    }
    @myName("getSign")
    open fun getSign(): Int {
        return 1
    }
    @myName("getConnectedDocNameShort")
    fun getConnectedDocNameShort(): String {
        return DocType.TAX_NOTE_CANCEL.convert(getConnectedDocType()).DOC_NAME_SHORT()
    }

    @myName("getConnectedDocType")
    open fun getConnectedDocType(): Int {
        return -1
    }

    @myName("getConnectedDocTypeStruct")
    open fun getConnectedDocTypeStruct(): DocType {
        return DocType.DELIVERY_NOTES.convert(getConnectedDocType())
    }


    @myName("isUsed")
    open fun isUsed(): Boolean {
        return true
    }

    @myName("isActive")
    open fun isActive(): Boolean {
        return true
    }

    @myName("getStoreNext")
    fun getStoreNext(): ApiNote? {
        return apis.firstOrNull { it.method == Api.STORENEXT }
    }

    @myName("getOne")
    fun getOne(): ApiNote? {
        return apis.firstOrNull { it.method == Api.ONE }
    }

    @myName("getManager")
    fun getManager(): ApiNote? {
        return apis.firstOrNull { it.method == Api.MANAGER }
    }
    @myName("getHashavshevet")
    fun getHashavshevet(): ApiNote? {
        return apis.firstOrNull { it.method == Api.HASHAVSHEVET }
    }

    @myName("signData")
    open fun signData(): NoteSignHolder? = null

    @myName("digiSign")
    open fun digiSign(): Boolean = false

    @myName("digiPhoto")
    open fun digiPhoto(): Boolean = false

    @myName("getComments")
    open fun getComments(): String {
        return ""
    }


    @myName("getConnectedProductsIds")
    open fun getConnectedProductsIds():List<Int> {
        return listOf()
    }
}