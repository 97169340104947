import DataBase.UINKDBInterface
import Network.NetworkOutput
import Network.Tasks
import Structs.Respone
import com.example.nihulkavapp.Backend.Network.JsCoroutinesAsyncTask

@JsName("ApiChainWrapper")
class ApiChainWrapper(
    val callback: (result: Any?) -> Unit,
    val tasksName: List<String>
) :
    JsCoroutinesAsyncTask<List<Any?>?>() {

    val params: HashMap<String, Any> = HashMap()


    @JsName("putParam")
    fun putParam(name: String, value: Any): ApiChainWrapper {
        params[name] = value
        return this
    }

    init {

    }

    override fun onPreExecute() {
        console.log("on pre")
        super.onPreExecute()
    }


    override suspend fun doInBackground(): List<Any?>? {
        try {
//            var a: List<Agent>
//            a = SharedApi().getAgents()
            var result: MutableList<Any?> = mutableListOf()

            tasksName.forEach {
                val taskId = Tasks.valueOf(it)
                val cur_result = taskId.applyTask(params)
                result.add(cur_result)
                when (true) {
                    cur_result is Respone<*, *> -> {
                        val msg = (cur_result as Respone<*, *>).second
                        if (msg is NetworkOutput)
                            if (msg != NetworkOutput.SUCCESS && msg != NetworkOutput.ERROR_EMPTY_QUERY) {
                                return null
                            }
                    }
                    cur_result is NetworkOutput -> {
                        if (cur_result != NetworkOutput.SUCCESS && cur_result != NetworkOutput.ERROR_EMPTY_QUERY) {
                            return null
                        }
                    }
                }
            }

            return result

        } catch (e: Exception) {
            console.log(e.toString())
            console.log(e.stackTraceToString())
        }
        return null
    }


    override fun onPostExecute(result: List<Any?>?) {
        super.onPostExecute(result)
        console.log("on post")
        callback(result)

    }

}