package Utils

object NameOptimizer {
    fun dirAdjust(s: String): String {
        var new_s = s.trim()
        new_s = new_s.replace("\"", "")
        new_s = new_s.replace("\'", "")
        new_s = new_s.replace("\\'", "")
        new_s = new_s.replace("/", "")
        new_s = new_s.replace("-", "")
        new_s = new_s.replace(" ", "_")
        return new_s
    }

    fun companyNameAdjust(s: String): String {
        var new_s = s.trim()
        return new_s.toLowerCase()
    }

    fun splitName(name: String, th: Int): Pair<String, String> {
        var first = name
        var second = ""
        if (first.length > th) {
            val slice = splitNameToEqSize(first).first()
            second = first.substring(slice).trimStart()
            first = first.substring(0, slice)
        }
        return Pair(first, second)
    }
    fun getPhone(first: String, second: String, firstC: String, secondC: String): Pair<String, String> {

        return when {
            (first.isNotEmpty() || firstC.isNotEmpty()) && (second.isNotEmpty() || secondC.isNotEmpty()) -> {
                Pair("$first,$second", "$firstC,$secondC")
            }

            (first.isNotEmpty() || firstC.isNotEmpty()) && (second.isEmpty() && secondC.isEmpty()) -> {
                Pair(first, firstC)
            }

            (first.isEmpty() && firstC.isEmpty()) && (second.isNotEmpty() || secondC.isNotEmpty()) -> {
                Pair(second, secondC)
            }

            else -> Pair("", "")
        }
    }
    fun getPhones(phone1:String,name1:String,phone2: String,name2:String,phone3: String,name3:String):Pair<String,String>{
        val phones = mutableListOf<String>()
        val names = mutableListOf<Pair<Int,String>>()
        val p1 = fixNumber(phone1)
        val p2 = fixNumber(phone2)
        val p3 = fixNumber(phone3)

        if(p1.isNotEmpty()){
            phones.add(phone1)
            names.add(Pair(phones.size-1,name1))
        }
        if(p2.isNotEmpty()){
            phones.add(phone2)
            names.add(Pair(phones.size-1,name2))
        }
        if(p3.isNotEmpty()){
            phones.add(phone3)
            names.add(Pair(phones.size-1,name2))
        }
        names.sortByDescending { phones[it.first].length }
        phones.sortByDescending { it.length }


        return getPhone(phones.getOrNull(0) ?: "", phones.getOrNull(1) ?: "", names.getOrNull(0)?.second ?: "", names.getOrNull(1)?.second ?: "")
    }
    fun fixNumber(number: String): String {
        if (number.isEmpty())
            return ""
        val has972 = number.startsWith("972")
        var new = (if(has972) number.replace("972", "") else number).replace("-", "").replace("+", "").trim()
        if (has972 || !new.startsWith("0"))
            new = "0$new"

        return new
    }
}