package Structs

import DataBase.UINKDBInterface
import Utils.DatesManipulator
import Utils.WrappedName
import Utils.fixJson
import Utils.fixJsonInternal
import kotlinx.serialization.Serializable
import myName

@myName("Product")
@Serializable
data class Product(
    var name: String,
    val id: Int,
    val barcode: String,
    val default_price: Float,
    var position: Int,
    var active: Int,
    val date: String,
    val connected_product: Int = -1,
    val no_tax_product: Int = 0,
    val taklit_id: Int = -1,
    val taklit_type: Int = -1,
    val external_id: String = "-1",
    val unit: Int = 0,
    val unit_amount: Float = 0f,
    val category: String = "",
    val cost_price: Float = 0f,
    val cost_include_returns: Int = 1,
    val split_category: Int = 0,
    val base_price: Float = 0f,
    val category2: String = "",

) : Named {
    var isClient: Boolean = true
    var productDated: ProductSequence = ProductSequence()
    var tempPosition: Int = 0

    init {
        name = fixJsonInternal(name)
    }

    @myName("getUnitName")
    fun getUnitName(): String {

        return WrappedName.NONE.convert(unit).MethodName()
    }

    override fun getConnectedName(): String {
        return getName()
    }

    fun getUnitWrapped(): WrappedName {
        return WrappedName.NONE.convert(unit)
    }

    override fun getConnectedSubName(): String? {
        val barcode = getBarcode()
        if (barcode == "0000000000000")
            return null
        return barcode
    }

    override fun getConnectedId(): Int {
        return id
    }

    @myName("getCostDated")
    fun getCostDated(date:String=DatesManipulator.dateNowNoHour()):Float?{
        val pp = UINKDBInterface.activeDB.getProductCost(id)?.get(date)
        pp?.let {cost->
            return (cost.first ) * (1- (cost.second)/100)
        }
        return null
    }
    override fun getActiveState(): Int {
        return active
    }

    fun getTaklitType(): String? {
        if (taklit_type == -1) {
            return null
        }
        return if (taklit_type == 0)
            "סוג א"
        else "מובחר"
    }

    override fun toString(): String {
        return name.toString()
    }

    fun clone(): Product {
        val c =  this.copy()
        c.isClient = this.isClient
        return c
    }

    @myName("getOrderProduct")
    fun getOrderProduct(): OrderProduct? {
        return if(isClient) UINKDBInterface.activeDB.getOrderProduct(id)?.first()  else  getConnectedProduct().firstOrNull()?.getOrderProduct()
    }
    @myName("getImageId")
    fun getImageId(): Int? {
        return if (!isClient) {
            if (connected_product != -1)
                UINKDBInterface.activeDB.getClientProduct(connected_product).first?.id
            else {
               null
            }
        } else {
            id
        }

    }
    @myName("getConnectedProduct")
    fun getConnectedProduct(): List<Product> {
        return if (!isClient) {
            if (connected_product != -1)
                listOf(UINKDBInterface.activeDB.getClientProduct(connected_product).first!!)
            else {
                listOf()
            }
        } else {
            UINKDBInterface.activeDB.getAllSupplierProduct(true).first.filter { it.connected_product == id }
        }

    }

    @myName("getCostIncludeReturns")
    fun getCostIncludeReturns(curDate: String = DatesManipulator.dateNow()): Int {
        return productDated.get(curDate).cost_include_returns
    }

    @myName("getCostPrice")
    fun getCostPrice(curDate: String = DatesManipulator.dateNow()): Float {
        return productDated.get(curDate).cost_price
    }

    @myName("getNoTaxProduct")
    fun getNoTaxProduct(curDate: String = DatesManipulator.dateNow()): Int {
        return productDated.get(curDate).no_tax_product
    }

    @myName("getConnectedEntity")
    fun getConnectedEntity(): List<Entity> {
        return if (!isClient) {
            val suppliers = UINKDBInterface.activeDB.getAllSupplier(true).first
            suppliers.filter { it.getActiveProducts().any { p -> p.id == id } }

        } else {
            val clients = UINKDBInterface.activeDB.getAllClient(true).first
            clients.filter { it.getActiveProducts().any { p -> p.id == id } }
        }
    }

    @myName("getDefaultPrice")
    open fun getDefaultPrice(curDate: String = DatesManipulator.dateNow()): Float {
        return productDated.get(curDate).default_price
    }

    @myName("getBasePrice")
    open fun getBasePrice(curDate: String = DatesManipulator.dateNow()): Float {
        return productDated.get(curDate).base_price
    }

    @myName("getActive")
    open fun getActive(curDate: String = DatesManipulator.dateNow()): Boolean {
        return productDated.get(curDate).active == 1
    }

    @myName("getName")
    open fun getName(curDate: String = DatesManipulator.dateNow()): String {
        return productDated.get(curDate).name
    }

    @myName("getPosition")
    open fun getPosition(curDate: String = DatesManipulator.dateNow()): Int {
        return productDated.get(curDate).position
    }

    @myName("getBarcode")
    open fun getBarcode(curDate: String = DatesManipulator.dateNow()): String {
        return productDated.get(curDate).barcode
    }

    @myName("getExternalId")
    open fun getExternalId(curDate: String = DatesManipulator.dateNow()): String {
        return productDated.get(curDate).external_id
    }

    @myName("getUnit")
    open fun getUnit(curDate: String = DatesManipulator.dateNow()): Int {
        return productDated.get(curDate).unit
    }

    @myName("getUnitAmmount")
    open fun getUnitAmmount(curDate: String = DatesManipulator.dateNow()): Float {
        return productDated.get(curDate).unit_amount
    }

    @myName("getCategory")
    open fun getCategory(curDate: String = DatesManipulator.dateNow()): String {
        return productDated.get(curDate).category
    }
    @myName("getCategory2")
    open fun getCategory2(curDate: String = DatesManipulator.dateNow()): String {
        return productDated.get(curDate).category2
    }
    @myName("getSplitCategory")
    open fun getSplitCategory(curDate: String = DatesManipulator.dateNow()): Int {
        return productDated.get(curDate).split_category
    }
}


