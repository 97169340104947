import DataBase.NKAdapter
import Network.Tasks
import Structs.ClientTaxNote
import Structs.ProductDelivery
import Structs.User
import Utils.DatesManipulator
import Utils.NameOptimizer
import commonder.Agent
import commonder.Project
import commonder.SharedApi
import commonder.commonTester
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import mainTester.testYotam

@ExperimentalMultiplatform
fun main() {

    GlobalScope.launch {

    }
    js("process.env.NODE_TLS_REJECT_UNAUTHORIZED = \"0\";")
    NameOptimizer.dirAdjust("asd")
    JsDB.setActiveDB()
    ProductDelivery
    Tasks.CALCULATE_AMOUNTS_DATA
    ClientTaxNote
    JsDB.getDatesManipulator().getNextDay("2020-01-01",1)
    JsDB.getClientX(1)
    ClientTaxNote
    NKTypeConvertor.toKotlinInt(3)
    ApiCallWrapper("FETCH_CLIENT_DATA", { console.log("fetching data") }).putParam("id",1)
    ApiChainWrapper( { console.log("fetching data") }, listOf("FETCH_CLIENT_DATA")).putParam("id",1)
    JsDB.getClientOrderNotes()
    DatesManipulator.minDay("2020-01-01")
    DatesManipulator.dateNow()

}

fun greet() = "world asdasdasdasd"