package Structs

import Utils.TaxNoteType
import kotlinx.serialization.Serializable
import myName
import kotlin.math.abs


val epsilon = 2.5
val small_epsilon = 1

@Serializable
@myName("DebtWall")
data class DebtWall(val client: Int) {
    lateinit var year: String
    var taxNotes: List<ClientLightTaxNote> = listOf()
    var payNotes: List<ClientLightPay> = listOf()
    var taxPayNotes: List<ClientLightTaxNote> = listOf()
    var taxCancelNotes: List<ClientLightTaxNote> = listOf()
    var debts: List<ClientCarteset> = listOf()

    @myName("moneyOut")
    val moneyOut: Float
        get() {
            var s = taxNotes.sumByDouble { it.total_value.toDouble() }
            s += taxPayNotes.sumByDouble { it.total_value.toDouble() }
            s -= taxCancelNotes.sumByDouble { it.total_value.toDouble() }
            s += debts.sumByDouble { it.positive.toDouble() }
            return s.toFloat()
        }

    @myName("moneyIn")
    val moneyIn: Float
        get() {
            var s = payNotes.sumByDouble { it.value.toDouble() }
            s += taxPayNotes.sumByDouble {
                it.total_value.toDouble()
            }
            s += debts.sumByDouble { it.negative.toDouble() }

            return s.toFloat()
        }
    val taxPaid: Float
        get() {
            var s = taxNotes.sumByDouble { it.getTaxPaid().toDouble() }
            s += taxPayNotes.sumByDouble { it.getTaxPaid().toDouble() }
            s -= taxCancelNotes.sumByDouble { it.getTaxPaid().toDouble() }
            return s.toFloat()
        }

    fun totalDebt(): Float {
        return moneyOut - moneyIn
    }

    @myName("prepareCartesetList")
    fun prepareCartesetList(
        withSep: Boolean = false,
        onlyTaxSide: Boolean = false,
        reverse: Boolean = false
    ): List<Pair<String, InformationBase>> {
        val map: MutableMap<String, MutableList<InformationBase>> = HashMap()
        taxNotes.forEach {
            var doc = map[it.document_date]
            if (doc == null) {
                map[it.document_date] = mutableListOf()
                doc = map[it.document_date]
            }
            doc!!.add(it)

        }
        taxCancelNotes.forEach {
            var doc = map[it.document_date]
            if (doc == null) {
                map[it.document_date] = mutableListOf()
                doc = map[it.document_date]
            }
            doc!!.add(it)

        }
        taxPayNotes.forEach {
            var doc = map[it.document_date]
            if (doc == null) {
                map[it.document_date] = mutableListOf()
                doc = map[it.document_date]
            }
            doc!!.add(it)

        }
        if (!onlyTaxSide) {
            payNotes.forEach {
                var doc = map[it.document_date]
                if (doc == null) {
                    map[it.document_date] = mutableListOf()
                    doc = map[it.document_date]
                }
                doc!!.add(it)

            }
        }
        debts.forEach {
            if (onlyTaxSide && it.state == 1) {
                return@forEach
            }
            var doc = map[it.date]
            if (doc == null) {
                map[it.date] = mutableListOf()
                doc = map[it.date]
            }
            doc!!.add(it)
        }


        val ll = map.map {
            it.value.map { p ->
                Pair(it.key, p)
            }
        }.flatten().sortedByDescending {  it.second.getConnectedDocType() }
        val l = if (reverse) ll.reversed()
            .sortedBy { it.first }
            .toMutableList()
        else
            ll
                .sortedByDescending { it.first }
                .toMutableList()

        if (withSep) {
            var sum: Float = 0f
            val retL: MutableList<Pair<String, InformationBase>> = mutableListOf()
            l.reversed().forEach {
                when (val doc = it.second) {
                    is ClientLightTaxNote -> {
                        when (doc.taxNoteType) {
                            TaxNoteType.WITH_PAYMENT -> {

                            }

                            TaxNoteType.CANCEL -> {
                                sum -= doc.getConnectedValue()
                            }

                            else -> {
                                sum += doc.getConnectedValue()
                            }
                        }
                    }

                    is ClientLightPay -> {

                        sum -= doc.getConnectedValue()
                    }

                    is ClientCarteset -> {
                        sum += doc.positive - doc.negative

                    }
                }
                retL.add(it)
                if (abs(sum) < epsilon) {
                    retL.add(Pair("0", EmptyDoc(id = -2)))
                }
            }
            retL.reverse()

            retL.add(0, Pair("", EmptyDoc(id = -1)))
            return retL
        } else {
            l.add(0, Pair("", EmptyDoc(id = -1)))
            return l
        }


    }
}
