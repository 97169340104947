package Structs


import DataBase.UINKDBInterface
import kotlinx.serialization.Serializable
import myName

@Serializable
class Price(val product_id: Int, val ent_type: String,val cost:Boolean=false) : DatedObject<Pair<Float, Float>>() {
    // pair of price,discount
    val product: Product
        get() {
            return if (ent_type.compareTo("client") == 0)
                UINKDBInterface.activeDB.getClientProduct(product_id).first!!
            else
                UINKDBInterface.activeDB.getSupplierProduct(product_id).first!!
        }


    override fun isActive(curDate: String): Boolean {
        return get(curDate).first != 0f
    }


    fun add(rp: RawPrice,runOver: Boolean=false) {
        add(Pair(rp.price, rp.discount), rp.date,runOver)
    }

    override fun copy(): Price {
        val new_p: Price = Price(product_id, ent_type)
        new_p.objects = objects.toMutableList()
        return new_p
    }

    override fun toString(): String {
        return get().first.toString()
    }

    @myName("getWithTax")
    fun getWithTaxDesc(entity:Entity,curDate:String): Triple<Float,Float, Float> {
        val tax = UINKDBInterface.activeDB.tax.get(curDate)
        val isInclude = entity.getIncludeTax(curDate) ==1
        val isProductNoTax =product.getNoTaxProduct(curDate) ==1 || entity.getNoTaxClient(curDate)==1
        return if (isInclude  || isProductNoTax) {
            val p = get(curDate)
            Triple(p.first/tax,p.first, p.second)
        } else {
            val price = get(curDate).first
            val discount = get(curDate).second
            val priceNoTax = price * tax
            val discountNoTax = discount
            Triple(price,priceNoTax, discountNoTax)
        }
    }



    override fun getDefault(curDate: String): Pair<Float, Float> {
        return Pair(if(cost) 0f else product.getDefaultPrice(curDate), 0f)
    }


}