package Utils


import Structs.DatedObject
import myName
import kotlin.math.roundToInt


@myName("DatedTaxFactor")
class DatedTaxFactor() : DatedObject<Float>() {

    override fun isActive(curDate: String): Boolean {
        return get(curDate) != 0f
    }

    fun add(th: TaxHolder) {
        add(th.percent / 100 + 1, th.date)
    }



    fun getPercent(date: String = DatesManipulator.dateNow()): Float {
        return (get(date) - 1) * 100
    }

    @myName("getPercentDivided")
    fun getPercentDivided(date: String = DatesManipulator.dateNow()): Float {
        return (get(date) - 1)
    }
    @myName("getTaxToTotal")
    fun getTaxToTotal(date: String = DatesManipulator.dateNow()): Float {
        return get(date)/(get(date) - 1)
    }
    @myName("getTaxToTotalInverse")
    fun getTaxToTotalInverse(date: String = DatesManipulator.dateNow()): Float {
        return (get(date) - 1)/get(date)
    }
    @myName("getAsString")
    fun getAsString(date: String = DatesManipulator.dateNow()): String {
        val percent = getPercent(date)
//        return DecimalFormat("##.##").format(percent)
        return roundToDecimals(percent,2).toString()
    }

    override fun copy(): DatedTaxFactor {
        val new_p: DatedTaxFactor = DatedTaxFactor()
        new_p.objects = objects.toMutableList()
        return new_p
    }

    override fun toString(): String {
        return get().toString()
    }

    override fun getDefault(curDate: String): Float {
        return 1.17f
    }


}