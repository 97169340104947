package Structs

import Utils.DatesManipulator
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import myName

@myName("ClientProductData")
@Serializable
data class ClientProductData(val client_id: Int, val product_id: Int, val barcode: String? = null) {
    fun toJson(): JsonObject {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()

        map["client_id"] = JsonPrimitive(client_id.toString())
        map["product_id"] = JsonPrimitive(product_id.toString())
        if (barcode != null)
            map["barcode"] = JsonPrimitive(barcode.toString())
        return JsonObject(map)
    }
}