package Structs.Api

import Structs.ClientTaxNoteData
import Utils.FillTaxNoteType
import Utils.getJsonWithConfig
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import myName

@myName("ApiConfig")
@Serializable
data class ApiConfig(
    val storeNextNote: Int,
    val storeNextTax: Int,
    val managerOrder: Int,
    val managerNote: Int,
    val managerTax: Int,
    val oneNote: Int,
    val oneOrder: Int,
    val oneTax: Int,
    val hashavshevetNote: Int,
    val hashavshevetTax: Int,
    val read_products: Int,
    val read_customers: Int,
    val read_prices: Int, // 0 - no read and send, 1 - read and send, 2 - dont read dont send, 3 - read and dont send
    val mailOrder: Int = 0,
    val mailTaxNote: Int = 0,
    val mailNote: Int = 0,
    val read_orders: String = "[]",
    val whatsapp:String = "{}",
    val last_counter:String = "{}",

    ) {
    val readOrderJson: List<ReadOrderJson> = ReadOrderJson.createFromJson(read_orders)
    val whatsappJson = getJsonWithConfig().parseToJsonElement(whatsapp)
    val lastCounterJson = getJsonWithConfig().parseToJsonElement(last_counter)
    @myName("hasManager")
    fun hasManager(): Boolean {
        return managerNote == 1 || managerTax == 1 || managerOrder == 1
    }

    @myName("hasOne")
    fun hasOne(): Boolean {
        return oneNote == 1 || oneTax == 1 || oneOrder == 1
    }
    @myName("hasHishuvit")
    fun hasHishuvit(): Boolean {
        return oneNote == 2 || oneTax == 2 || oneOrder == 2
    }
    @myName("hasPriority")
    fun hasPriority(): Boolean {
        return oneNote == 3 || oneTax == 3 || oneOrder == 3
    }
    @myName("hasLesser")
    fun hasLesser(): Boolean {
        return oneNote == 4 || oneTax == 4 || oneOrder == 4
    }

    @myName("hasStoreNext")
    fun hasStoreNext(): Boolean {
        return storeNextTax == 1 || storeNextNote == 1
    }

    @myName("hasHashavshevet")
    fun hasHashavshevet(): Boolean {
        return hashavshevetNote >= 1 || hashavshevetTax == 1
    }

    @myName("hasMail")
    fun hasMail(): Boolean {
        return mailOrder == 1 || mailTaxNote == 1 || mailNote == 1
    }

    fun getZester(): ReadOrderJson? {
        return readOrderJson.firstOrNull { it.method == "zester" }
    }
}

@Serializable
data class ReadOrderJson(
    val type: String,
    val method: String,
    val username: String? = null,
    val password: String? = null,
    val token: String? = null,
    val idType:Int=0,
) {
    val realPassword = password?.replace("@", "'")?.replace("##", "@")
    fun toJson(): JsonObject {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
        map["type"] = JsonPrimitive(type.toString())
        map["method"] = JsonPrimitive(method.toString())
        username?.let {
            map["username"] = JsonPrimitive(it.toString())
        }
        password?.let {
            map["password"] = JsonPrimitive(it.toString())
        }
        token?.let {
            map["token"] = JsonPrimitive(it.toString())
        }
        return JsonObject(map)
    }

    companion object {
        fun createFromJson(js: String): List<ReadOrderJson> {
            return (getJsonWithConfig().parseToJsonElement((js).replace("\\'", "'")) as JsonArray).map {
                getJsonWithConfig().decodeFromJsonElement(ReadOrderJson.serializer(), it)
            }
        }

        fun toJsonArrayString(noteData: List<ReadOrderJson>): String {
            return if (noteData.isEmpty()) {
                "[]"
            } else {
                val payload = noteData.map {
                    getJsonWithConfig().encodeToJsonElement(JsonObject.serializer(), it.toJson()).toString()
                }.joinToString(",")
                return "[$payload]"
            }

        }
    }
}