package Structs

import DataBase.UINKDBInterface
import kotlinx.serialization.Serializable
import myName

@myName("GlulStruct")
@Serializable
data class GlulStruct(

    val client_id: Int,
    val branch:Int,
    var  totalValueSum: Float,
    val taxNotes:MutableList<ClientTaxNote> = mutableListOf(),
    var numOfOpen: Float,

    var valueSum1: Float?=null,
    var openAmount1: Int?=null,
    var valueSum2: Float?=null,
    var openAmount2: Int?=null,
    var valueSum3: Float?=null,
    var openAmount3: Int?=null,
    var valueSum4: Float?=null,
    var openAmount4: Int?=null,
    var restTotalValueSum: Float?=null,
    var restNumOpen: Int?=null,

    ) {
    init {

        if(restTotalValueSum==null){
            restTotalValueSum = totalValueSum - (valueSum1?:0f) - (valueSum2?:0f) - (valueSum3?:0f) - (valueSum4?:0f)
        }
    }
    companion object{
        fun mergeGiuls(id:Int,l:List<GlulStruct>):GlulStruct{
            val first = l.first().copy(client_id = id)

            l.drop(1).forEach {
                first.totalValueSum += it.totalValueSum
                first.numOfOpen += it.numOfOpen
                first.taxNotes.addAll(it.taxNotes)
                first.valueSum1 = (first.valueSum1?:0f) + (it.valueSum1?:0f)
                first.openAmount1 = (first.openAmount1?:0) + (it.openAmount1?:0)
                first.valueSum2 = (first.valueSum2?:0f) + (it.valueSum2?:0f)
                first.openAmount2 = (first.openAmount2?:0) + (it.openAmount2?:0)
                first.valueSum3 = (first.valueSum3?:0f) + (it.valueSum3?:0f)
                first.openAmount3 = (first.openAmount3?:0) + (it.openAmount3?:0)
                first.valueSum4 = (first.valueSum4?:0f) + (it.valueSum4?:0f)
                first.openAmount4 = (first.openAmount4?:0) + (it.openAmount4?:0)
                first.restTotalValueSum = (first.restTotalValueSum?:0f) + (it.restTotalValueSum?:0f)
                first.restNumOpen = (first.restNumOpen?:0) + (it.restNumOpen?:0)




            }
            return first
        }
        fun shrinkTo(g:GlulStruct,num:Int):GlulStruct{
            val giul = g.copy()
            when (num) {
                1 -> {

                    giul.valueSum2 = null
                    giul.openAmount2 = null
                    giul.valueSum3 = null
                    giul.openAmount3 = null
                    giul.valueSum4 = null
                    giul.openAmount4 = null
                    giul.restTotalValueSum = (giul.restTotalValueSum?:0f) + (giul.valueSum2?:0f) + (giul.valueSum3?:0f) + (giul.valueSum4?:0f)
                    giul.restNumOpen = (giul.restNumOpen?:0)+ (giul.openAmount2?:0) + (giul.openAmount3?:0) + (giul.openAmount4?:0)
                }
                2 -> {
                    giul.valueSum3 = null
                    giul.openAmount3 = null
                    giul.valueSum4 = null
                    giul.openAmount4 = null
                    giul.restTotalValueSum = (giul.restTotalValueSum?:0f) + (giul.valueSum3?:0f) + (giul.valueSum4?:0f)
                    giul.restNumOpen = (giul.restNumOpen?:0)+ (giul.openAmount3?:0) + (giul.openAmount4?:0)

                }
                3 -> {
                    giul.valueSum4 = null
                    giul.openAmount4 = null
                    giul.restTotalValueSum = (giul.restTotalValueSum?:0f) + (giul.valueSum4?:0f)
                    giul.restNumOpen = (giul.restNumOpen?:0)+ (giul.openAmount4?:0)

                }

            }
            return giul
        }
    }

}

