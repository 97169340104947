import DataBase.DBQuery
import DataBase.UINKDBInterface
import Network.Tasks
import Structs.*
import Utils.*
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.async
import kotlin.math.roundToInt
import kotlin.math.roundToLong


@JsName("JsDB")
object JsDB : UINKDBInterface() {
    var currentId: Int = -1
    var currentType: Boolean = true
    var currentTaxType: Int = 0
    var currentAgent: String = ""
    var curUser:Agent?=null

    fun sayHello(): String {
        return "hey JSDB"

    }

    @JsName("getToken")
    fun getToken(): String {
        return adapter.getToken()
    }

    fun myT(): Tasks {
        return Tasks.valueOf("FETCH_SUPPLIER_DATA")
    }

    @JsName("round")
    fun round(f: Float, decimals: Int): Float {
        return roundToDecimals(f, decimals)
    }

    @JsName("saygoodhet")
    fun saygoodhet(tf: () -> Unit): String {
        tf()
        return "hiush"
    }

    fun base64toByteArr(base64: String) {

    }

    @JsName("JsBuildClients")
    suspend fun JsBuildClients(): Respone<List<Client>, DBQuery> {
        return buildClients()

    }

    @JsName("getClientsIds")
    fun getClientsIds(): List<Int> {
        return getAllClient(true).first.map { it.id }

    }

    @JsName("kartonEstimate")
    fun kartonEstimate(pds: List<ProductDelivery>): Float {
        return ProductDelivery.kartonEstimate(pds)

    }


    @JsName("createProductDelivery")
    fun createProductDelivery(productId: Int): ProductDelivery {
        return ProductDelivery(productId, price = 0f)

    }

    @JsName("isProductLocked")
    fun isProductLocked(productId: Int, date: String? = null, applyTime: Boolean = true, available: Int = 1): Boolean {
        return available == 0 || (getClientProduct(productId).first?.getOrderProduct()
            ?.isLocked(date?.let { DatesManipulator.dateIsrael(it) }, applyTime = applyTime) ?: false)
    }

    @JsName("createDailyData")
    fun createDailyData(
        date: String,
        id: Int,
        driver_name: String,
        collector_name: String,
        position: Int,
        position_collector: Int,
        group_id: Int,
        day: Int,
        car: String = "",
    ): ClientDailyData {
        val agents = getAgents().first
        val driver = agents.firstOrNull { it.isDriver() && it.user_name == driver_name }?.id ?: -1
        val collector = agents.firstOrNull { it.isCollector() && it.user_name == collector_name }?.id ?: -1
        val tDate = if (day != -1) {
            baseOrderDaysToData[day] ?: date
        } else date
        return ClientDailyData(tDate, id, driver, collector, position, position_collector, group_id, car, 1)

    }

    @JsName("getBaseDaysRange")
    fun getBaseDaysRange(): List<String> {
        return inverseBaseOrderDaysToData.keys.toList()
    }

    @JsName("productDeliveryToAmount")
    fun productDeliveryToAmount(
        pds: List<ProductDelivery>,
        id: Int,
        date: String,
        isClient: Boolean = true
    ): Triple<Float, Float, Float> {
        val calcMap = calcClientProductDeliverySumMap(pds, id, date, externalPrices = true, isClient = isClient)
        val res = calcMap.first[-1]
        return Triple(res!!.totalValue - res.taxPaid, res.taxPaid, res.totalValue)
    }

    @JsName("getUnits")
    fun getUnits(): List<String> {
        return WRAPPED_NAME
    }

    @JsName("getUnclosedOrderDate")
    fun getUnclosedOrderDate(): String {
        return unclosed_date
    }



    @JsName("getOrderStatuses")
    fun getOrderStatuses(): List<OrderStatus> {
        return order_status_object
    }

    @JsName("createChangeNoteHelper")
    fun createChangeNoteHelper(
        docId: Int,
        docType: Int,
        p_id: Int,
        amount: Float,
        amountSecond: Float,
        conversion_rate: Float,
        returns: Float,
        price: Float,
        discount: Float,
        driver: String? = null,
        date:String?=null,
        cost: Float? = null
    ): ChangeNoteHelper {
        return ChangeNoteHelper(
            docId,
            docType,
            p_id,
            amount,
            amountSecond,
            conversion_rate,
            returns,
            price,
            discount,
            driver,
            date,
            cost
        )
    }

    @JsName("taxNotePDeliveryToObject")
    fun taxNotePDeliveryToObject(pds: List<ProductDelivery>, id: Int, date: String): Pair<Float, Float> {
        val calcMap = calcClientProductDeliverySumMap(pds, id, date, externalPrices = true)
        val client = getClient(id).first!!
        pds.forEach {
            it.price = calcMap.first[it.productId]!!.price //include tax
            it.discount = calcMap.first[it.productId]!!.discount
            it.use_price = ProductDelivery.createUsedState(
                it.getProduct().getNoTaxProduct(date),
                client.getNoTaxClient(date),
                client.getIncludeTax(date),
                true
            )
        }

        val totalVal = calcMap.first[-1]!!
        val productTax = totalVal.taxPaid
        return Pair(totalVal.totalValue, productTax)
    }

    @JsName("createClientStaticData")
    fun createClientStaticData(
        id: Int, clientCompany: String, BussId: String, phone: String, city: String, street: String, streetNum: String
    ): ClientStaticData {
        return ClientStaticData(
            id, clientCompany, BussId, "${city},${street},${streetNum}", phone = phone
        )
    }

    @JsName("getBranchTaxNoteInput")
    fun getBranchTaxNoteInput(branchId: Int, start: String, end: String): Pair<List<Int>, DatesHolder> {
        return Pair(getBranchMembers(branchId).first.map { it.id }, DatesHolder("$start..$end"))
    }

    @JsName("getDeliveryNotesForCalculation")
    fun getDeliveryNotesForCalculation(
        client: Boolean, dateStart: String, dateEnd: String, id: Int
    ): Pair<String, String>? {
        val deliveryNotesRaw =
            if (client) getClientDeliveryNotes(client_id = id).first.filter { it.date in dateStart..dateEnd && it.isActive() }
            else getSupplierDeliveryNotes(supplier_id = id).first.filter { it.date in dateStart..dateEnd && it.isActive() }
        val deliveryNotes = deliveryNotesRaw
        if (deliveryNotes.isEmpty()) return null
        val dh = DatesHolder(deliveryNotes.map { it.date }.distinct())
        val note_data = deliveryNotes.map {
            "${it.delivery_id}"
        }

            .joinToString(",") // סכוםכולל  מע''מ
        return Pair(dh.toString(), note_data)
    }

    @JsName("getPaymentMethods")
    fun getPaymentMethods(): List<String> {
        return banks
    }


    @JsName("createTaxNoteDataFromNotesNew")
    fun createTaxNoteDataFromNotesNew(
        id: Int, ids: List<Int>,sup:Boolean=false,date:String
    ): Triple<ClientTaxNoteData, List<DeliveryNote>, Triple<String, Float,ClientTaxNoteData?>>? {
        val idsSet = ids.toSet()
        val deliveryNotes = (if(sup) getSupplierDeliveryNotes(delivery_ids = ids).first else
            getClientDeliveryNotes(delivery_ids = ids).first).filter { deliveryNote -> deliveryNote.id in idsSet && !deliveryNote.isUsed() && deliveryNote.isActive() }
        if (deliveryNotes.isEmpty()) return null
        return createTaxNoteDataFromNotesCalculation(id, deliveryNotes,sup=sup,date=date)

    }


    @JsName("createTaxNoteDataFromNotes")
    fun createTaxNoteDataFromNotes(
        dateStart: String, dateEnd: String, id: Int,sup: Boolean = false,date:String
    ): Triple<ClientTaxNoteData, List<DeliveryNote>, Triple<String, Float,ClientTaxNoteData?>>? {
        val c = getClient(id).first!!
        val deliveryNotesRaw = if (c.hasBranch()) {
            getBranchMembers(c.branch).first.map {
                getClientDeliveryNotes(client_id = it.id).first.filter { deliveryNote -> deliveryNote.date in dateStart..dateEnd && !deliveryNote.isUsed() && deliveryNote.isActive() }
            }.flatten()
        } else {
            getClientDeliveryNotes(client_id = id).first.filter { it.date in dateStart..dateEnd && !it.isUsed() && it.isActive() }
        }

        val deliveryNotes = deliveryNotesRaw
        if (deliveryNotes.isEmpty()) return null
        return createTaxNoteDataFromNotesCalculation(id, deliveryNotes,sup=sup,date=date)

    }


    @JsName("createTaxNoteDataFromProducts")
    fun createTaxNoteDataFromProducts(value: Float, pds: List<ProductDelivery>): ClientTaxNoteData {
        return ClientTaxNoteData(
            value, 1, ProductDelivery.toJsonArrayString(pds),
        )

    }

    @myName("inverseName")
    fun inverseName(name: String): OrderStatus {
        return OrderStatus.REGULAR.inverseName(name)
    }

    @JsName("createTaxNoteDataFromRound")
    fun createTaxNoteDataFromRound(value: Float, tax: Float): ClientTaxNoteData {
        val addStr = if (value < 0) "הנחת עיגול" else "עיגול סכום"
        return ClientTaxNoteData(
            roundToDecimals(value, 4), FillTaxNoteType.FREE.state, addStr, roundToDecimals(tax, 4)
        )
    }

    @JsName("createPayNoteDataFromNotes")
    fun createPayNoteDataFromNotes(dateStart: String, dateEnd: String, id: Int): Pair<String, Float>? {
        val c = getClient(id).first!!
        val taxNotes = if (c.hasBranch()) {
            getBranchMembers(c.branch).first.map {
                getClientTaxNote(
                    client_id = it.id, type = 0
                ).first.filter { clientTaxNote -> clientTaxNote.document_date in dateStart..dateEnd && !clientTaxNote.isUsed() }
            }.flatten()
        } else {
            getClientTaxNote(
                client_id = id, type = 0
            ).first.filter { it.document_date in dateStart..dateEnd && !it.isUsed() }
        }
        if (taxNotes.isEmpty()) return null
        val sumValue = taxNotes.sumByDouble { it.total_value.toDouble() }
        val ids = taxNotes.map { it.id }.joinToString(",")
        return Pair(
            ids, sumValue.toFloat()
        )
    }

    @JsName("calcNoteForTax")
    fun calcNoteForTax(t: ClientTaxNote): Triple<Int, String, String> {
        val notes = getClientDeliveryNotes(t.getNotes()).first
        val dates = if (notes.isEmpty()) {
            t.document_date
        } else {
            DatesHolder(notes.map { it.date }).toString()
        }
        return Triple(t.client_id, t.getNotes().joinToString(",") { it.toString() }, dates)

    }

    @JsName("getRealOrderMatcher")
    fun getRealOrderMatcher(
        ordered: List<Pair<Named, AmountsDataHolder>>, real: List<Pair<Named, AmountsDataHolder>>
    ): MutableList<Pair<Named, oVrHolder>> {
        return oVrHolder.buildOrdered(ordered, real)
    }

    @JsName("companyNameAdjust")
    fun companyNameAdjust(s: String): String {
        return NameOptimizer.companyNameAdjust(s)
    }

    @JsName("trim")
    fun trim(s: String): String {
        return (s).trim()
    }

    @JsName("createPayNoteDataFromData")
    fun createPayNoteDataFromData(
        value: Float,
        pay_method: Int = 0,
        bank_id: String = "",
        bank_branch: String = "",
        bank_number: String = "",
        check_number: String = "",
        check_date: String = ""
    ): PaymentData {
        val splitted = bank_id.split("-")
        val bank = if (splitted.size == 2) {
            getBankId(splitted[0])?.toString() ?: ""
        } else {
            getBankId(bank_id)?.toString() ?: ""
        }

        return PaymentData(
            value,
            pay_method,
            bank,
            bank_branch.toString(),
            bank_number.toString(),
            check_number.toString(),
            check_date.toString()
        )

    }

    @JsName("createTaxNoteDataFromHandValue")
    fun createTaxNoteDataFromHandValue(
        value: Float, withoutMaam: Boolean = false, date: String = DatesManipulator.dateNowNoHour()
    ): ClientTaxNoteData {
        return if (withoutMaam) ClientTaxNoteData(value, 2, "", taxPaid = 0f)
        else ClientTaxNoteData(
            roundToDecimals(value, 4),
            2,
            "",
            taxPaid = roundToDecimals(value - value / tax.get(date), 4)
        )
    }

    @JsName("getHebrewMonthFromNumber")
    fun getHebrewMonthFromNumber(month: Int): String {
        return number_to_month[month]!!
    }

    @JsName("getSpecificSupplierProducts")
    fun getSpecificSupplierProducts(id: Int): List<Product> {
        return getSupplier(id).first!!.getActiveProducts()
    }

    @JsName("createRawPrice")
    fun createRawPrice(
        date: String,
        id: Int,
        productId: Int,
        price: Float,
        discount: Float,
        master: Int? = null
    ): RawPrice {
        return RawPrice(id, productId, price, date, discount, master = master)
    }

    @JsName("getClientPayNotesForCalendar")
    fun getClientPayNotesForCalendar(id: Int, date: String): List<ClientPay> {

        val client = getClient(id).first!!
        val notes = if (client.hasBranch()) {
            getBranchMembers(client.branch).first.map {
                this.getClientPay(client_id = it.id).first
            }.flatten()
        } else {
            this.getClientPay(client_id = id).first
        }
        return notes.filter { it.document_date.compareTo(date) == 0 }

    }

    @JsName("getClientPayNotesForWeb")
    fun getClientPayNotesForWeb(id: Int, startDate: String, endDate: String): List<ClientLightPay> {
        return getClientLightPay(client_id = id, fromDate = startDate, toDate = endDate).first

    }

    @JsName("getClientTaxNotesForCalendar")
    fun getClientTaxNotesForCalendar(id: Int, date: String, type: Int = 0): List<ClientTaxNote> {
        val client = getClient(id).first!!
        val notes = if (client.hasBranch()) {
            getBranchMembers(client.branch).first.map {
                this.getClientTaxNote(client_id = it.id, type = type).first
            }.flatten()
        } else {
            this.getClientTaxNote(client_id = id, type = type).first
        }
        return notes.filter { it.document_date.compareTo(date) == 0 }

    }

    @JsName("getClientTaxNotesForWeb")
    fun getClientTaxNotesForWeb(id: Int, startDate: String, endDate: String, type: Int = 0): List<ClientLightTaxNote> {
        return getClientLightTaxNotes(
            client_id = id, fromDate = startDate, toDate = endDate
        ).first.filter { it.type == type }

    }

    @JsName("getClientNotesForCalendar")
    fun getClientNotesForCalendar(id: Int, date: String): List<Note> {
        val client = getClient(id).first!!
        val notes = if (client.hasBranch()) {
            getBranchMembers(client.branch).first.map {
                this.getClientDeliveryNotes(client_id = it.id).first
            }.flatten()
        } else {
            this.getClientDeliveryNotes(client_id = id).first
        }
        return notes.filter { it.date.compareTo(date) == 0 }.sortedBy { it.active }

    }

    @JsName("getSupplierNotesForCalendar")
    fun getSupplierNotesForCalendar(id: Int, date: String): List<Note> {
        val notes = this.getSupplierDeliveryNotes(supplier_id = id).first
        return notes.filter { it.date.compareTo(date) == 0 }.sortedBy { it.active }

    }

    @JsName("getClientMonthlyCycleForList")
    fun getClientMonthlyCycleForList(id: Int): List<MonthlyCycle> {
        return getClientMonthlyCycle(id = id).first

    }

    @JsName("testPrinter")
    fun testPrinter() {
        console.log("test printer")
    }

    fun getDatesManipulator(): DatesManipulator {
        return DatesManipulator
    }

    @JsName("isLoaded")
    fun isLoaded(): Boolean {
        return fetched
    }

    @JsName("toKotlinInt")
    fun toKotlinInt(id: Int): Int {
        return id.toLong().toInt()
    }

    @JsName("getClientX")
    fun getClientX(id: Int): Respone<Client?, DBQuery> {

        console.log("inside", id)

        console.log(jsTypeOf(id))
        console.log(idToClient)
        console.log(id in idToClient)
        console.log(idToClient[id])
        return if (id in idToClient) {
            Respone(idToClient[id]!!.get() as Client, DBQuery.SUCCESS)
        } else {
            Respone(null, DBQuery.ID_ISSUE)
        }
    }

    @JsName("newClientTaxShowNote")
    fun newClientTaxShowNote(
        date: String,
        document_date: String,
        client_id: Int,
        value: Float,
        total_value: Float,
        details: ClientStaticData,
        type: Int,
        cover_dates: String,
        discount: Float,
        value_data_raw: List<ClientTaxNoteData>
    ): ClientTaxNote {
        console.log("hey from new client tax note")
        return ClientTaxNote(
            date = date,
            document_date = document_date,
            client_id = client_id,
            id = -1,
            value = value,
            total_value = total_value,
            details = ClientStaticData.toJsonArrayString(
                (details)
            ),
            type = type,
            cover_dates = cover_dates,
            discount_percent = discount,
            value_data_raw = ClientTaxNoteData.toJsonArrayString(value_data_raw),
            payment_data_raw = "[]",
            cover_date_start = "",
            cover_date_end = ""
        )
    }

    @JsName("getBanks")
    fun getBanks(): Map<String, Int> {
        return Banks
    }

    @JsName("getBankId")
    fun getBankId(bank: String): Int? {
        return Banks[bank]

    }

    @JsName("getWrappedUnitFromString")
    fun getWrappedUnitFromString(unit: String): Int {
        return WrappedName.convertFrom(unit)?.state ?: 0
    }

    @JsName("prepareAmountData")
    fun prepareAmountData(lst: List<Pair<Named, AmountsDataHolder>>): List<Pair<Named, AmountsDataHolder>> {
        return NotesAmountsQuery.toWrappedDescribe(lst)
    }

    @JsName("preparePhone")
    fun preparePhone(
        first: String, second: String,
        firstC: String, secondC: String, third: String, thirdC: String
    ): Pair<String, String> {
        return Pair("$first,$second,$third", "$firstC,$secondC,$thirdC")

        return when {
            (first.isNotEmpty() || firstC.isNotEmpty()) &&
                    (second.isNotEmpty() || secondC.isNotEmpty()) &&
                    (third.isNotEmpty() || thirdC.isNotEmpty()) -> {
                Pair("$first,$second,$third", "$firstC,$secondC,$thirdC")
            }

            (first.isNotEmpty() || firstC.isNotEmpty()) &&
                    (second.isNotEmpty() || secondC.isNotEmpty()) &&
                    (third.isEmpty() && thirdC.isEmpty()) -> {
                Pair("$first,$second", "$firstC,$secondC")
            }

            (first.isNotEmpty() || firstC.isNotEmpty()) &&
                    (second.isEmpty() && secondC.isEmpty()) &&
                    (third.isNotEmpty() || thirdC.isNotEmpty()) -> {
                Pair("$first,$third", "$firstC,$thirdC")
            }

            (first.isNotEmpty() || firstC.isNotEmpty()) &&
                    (second.isEmpty() && secondC.isEmpty()) &&
                    (third.isEmpty() && thirdC.isEmpty()) -> {
                Pair(first, firstC)
            }

            (first.isEmpty() && firstC.isEmpty()) &&
                    (second.isNotEmpty() || secondC.isNotEmpty()) &&
                    (third.isNotEmpty() || thirdC.isNotEmpty()) -> {
                Pair("$second,$third", "$secondC,$thirdC")
            }

            (first.isEmpty() && firstC.isEmpty()) &&
                    (second.isNotEmpty() || secondC.isNotEmpty()) &&
                    (third.isEmpty() && thirdC.isEmpty()) -> {
                Pair(second, secondC)
            }

            (first.isEmpty() && firstC.isEmpty()) &&
                    (second.isEmpty() && secondC.isEmpty()) &&
                    (third.isNotEmpty() || thirdC.isNotEmpty()) -> {
                Pair(third, thirdC)
            }

            else -> Pair("", "")
        }
    }


    @myName("getKartonAutoCollection")
    fun getKartonAutoCollection(pds: List<ProductDelivery>): List<CollectionHolder> {
        val collectors = agents.filter { it.is_collector == 5 }
        val ids = collectors.map { it.id }.toSet()
        var lst: MutableList<CollectionHolder> = mutableListOf()
        if (ids.isNotEmpty()) {

            pds.filter {
                val col = it.getProduct().getOrderProduct()?.collector
                if (col != null) {
                    ids.contains(col)
                } else
                    false
            }.groupBy { it.getProduct().category2 }.forEach {
                val karton = ProductDelivery.kartonEstimate(it.value)
                if (karton > 0) {
                    lst.add(CollectionHolder(it.key, karton))
                }
            }
        }
        return lst
    }

    @myName("getKartonCategories")
    fun getKartonCategories(): List<String> {
        val collectors = agents.filter { it.is_collector == 5 }.map { it.id }.toSet()
        if (collectors.size == 0) {
            return listOf()
        }
        val product = getAllClientProduct(true).first.filter {
            if (it.getOrderProduct()?.collector != null)
                collectors.contains(it.getOrderProduct()?.collector)
            else
                false


        }.map { it.category2 }.toSet().toList()
        return product
    }

    @myName("createNewCollector")
    fun createNewCollector(id: Int, karton: Float): CollectionObject {
        return CollectionObject(id, karton)
    }

    @JsName("doAsyncCall")
    fun doAsyncCall(call: () -> Unit) {
        GlobalScope.async {
            call()
        }
    }

    @myName("createBaseData")
    fun createBaseData(c_id: Int, p_id: Int, amount: Float, day: Int? = null): baseChangeHolder {
        return baseChangeHolder(c_id, p_id, amount, day)
    }

    @myName("getCurrentAgent")
    fun getCurrentAgent(): Agent? {
        return agents.firstOrNull { it.user_name == currentAgent }
    }

    @JsName("doAsync")
    fun doAsync(taskName: String, callback: (Any?) -> Unit) {
        activeDB = this
        GlobalScope.async {
            console.log("doAsync here")
            JsDB.setCompany("test")
            activeDB.setCompanyId("7")
            console.log(activeDB)
            var res: Any? = fetch()
            console.log("succses here")
            callback(res)

        }
    }
    override fun logout(){
        currentId = -1
        currentType = true
        currentTaxType = 0
        currentAgent = ""
        curUser=null
        clearDB()
    }
}