package Utils


enum class TaxNoteType(val state: Int = 0) {
    REGULAR(0),
    WITH_PAYMENT(1),
    CANCEL(2);


    fun convert(ps: Int): TaxNoteType {
        return when (ps) {
            0 -> {
                REGULAR
            }
            1 -> {
                WITH_PAYMENT
            }
            2 -> {
                CANCEL
            }

            else -> {
                REGULAR
            }
        }
    }
}
data class TaxNoteSum(
    val id: Int,
    val name: String,
    val amount: Float,
    val returns: Float,
    val price: Float,
    val discount: Float,
    val total: Float,
    val priceMin: Float,
    val priceMax: Float,
    val priceBefore: Float,
    val priceBeforeMin: Float,
    val priceBeforeMax: Float,
    val totalBefore: Float
)

enum class FillTaxNoteType(val state: Int = 0) {
    NOTES(0),
    PRODUCTS(1),
    HAND(2),
    TAX_NOTES(3),
    TAX_PAY_NOTES(4),
    FREE(5),
    TAX_CANCEL_NOTES(6),
    FREE_STYLE(7);


    fun convert(ps: Int): FillTaxNoteType {
        return when (ps) {
            0 -> {
                NOTES
            }
            2 -> {
                HAND
            }
            1 -> {
                PRODUCTS
            }
            3 -> {
                TAX_NOTES
            }
            4 -> {
                TAX_PAY_NOTES
            }
            5 -> {
                FREE
            }
            6 -> {
                TAX_CANCEL_NOTES
            }
            7-> {
                FREE_STYLE
            }
            else -> {
                FREE
            }
        }
    }

}

enum class ActiveStateTaxNoteType(val state: Int = 0) {
    NOTACTIVE(0),
    ACTIVE(1),
    PAID(2),
    PAID_PART(3);


    fun convert(ps: Int): ActiveStateTaxNoteType {
        return when (ps) {
            0 -> {
                NOTACTIVE
            }
            2 -> {
                PAID
            }
            3 -> {
                PAID_PART
            }
            1 -> {
                ACTIVE
            }

            else -> {
                NOTACTIVE
            }
        }
    }

}