package DataBase

import DataBase.Interface.AdapterInterface
import DataBase.Interface.FixJsonType
import Network.NetworkOutput
import Network.httpWorker
import Structs.*
import Structs.Api.ApiConfig
import Structs.Api.ApiNote
import Utils.*
import kotlinx.serialization.json.*
import printPlat


class NKAdapter : AdapterInterface {
    var company: String = ""
    private var company_id: String = ""
    private var local: Boolean = false
    private var web: Boolean = false

    constructor(local: Boolean = false,web: Boolean=false) {
        this.company = ""
        this.local = local
        this.web = web
        httpWorker.companyName = ""
        httpWorker.companyId = ""
        httpWorker.local = local
        httpWorker.setHTTPClient()
    }


    constructor(company: String, local: Boolean = false,web:Boolean=false) {
        this.company = (company)
        this.local = local
        this.web = web
        httpWorker.companyName = (this.company)
        httpWorker.local = (this.local)
        httpWorker.setHTTPClient()
    }

    override fun setConcreteCompanyId(company_id: String) {
        this.company_id = company_id
        httpWorker.companyId = company_id
    }

    override suspend fun getTaxData(): Respone<List<TaxHolder>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        val url = NewDBAction.GET_tax_holder.getURL(local,web)
        val response = (httpWorker.post(url, params))

        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<TaxHolder> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(TaxHolder.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun isLocal(): Boolean {
        return local
    }

    override suspend fun getCompanyId(): String {
        return company_id
    }

    override fun getToken(): String {
        return httpWorker.curToken
    }

    override suspend fun setCompanyId(company_id: String?) {
        this.company_id = if (company_id == null) {
            val date = DatesManipulator.dateNowNoHour()
            val q = getCompanyInfo(company = company, fromDate = date, toDate = date)
            q.first!!.company_id!!.toString()
        } else {
            company_id
        }
        httpWorker.companyId = this.company_id
    }

    override suspend fun getClientsCreationData(

    ): Respone<List<ClientCreation>, NetworkOutput> {


        val params = mutableMapOf<String, String>()


        val url = NewDBAction.GET_clients_creation.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientCreation> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientCreation.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getClients(
        id: String?, active: String?, fromDate: String, toDate: String, ids: String?, branch: String?, branches: String?
    ): Respone<List<Client>, NetworkOutput> {
        //val params = hashMapOf<String, String>("client" to client, "fromDate" to start_date, "toDate" to end_date)

        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        ids?.let { params["ids"] = ids }
        active?.let { params["active"] = active }
        fromDate.let { params["fromDate"] = fromDate }
        toDate.let { params["toDate"] = toDate }
        branch?.let { params["branch"] = branch }
        branches?.let { params["branches"] = branches }

        val url = NewDBAction.GET_clients.getURL(local,web)
        val response = (httpWorker.post(url, params, timeout = 50000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Client> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Client.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newClient(
        agent: String,
        name: String,
        date: String,
        include_tax: String?,
        business_name: String?,
        business_id: String?,
        address: String?,
        print_state: String?,
        id: String?,
        phone: String?,
        branch: String?,
        master: String?,
        phone_contact: String?,
        discount: String?,
        email: String?,
        external_id: String?,
        day: String?,
        notes: String?,
        no_tax_client: String?,
        position: String?,
        comments: String?,
        min_order: String?,
        location: String?,
        category: String?,
        obligo: String?,
        price_control: String?,
        payment_notes: String?,
        category2: String?,
        min_order_sum: String?,
        tax_note_type: String?,
        net_split: String?,
        days_to_pay: String? ,
        notes2: String? ,
        notes3: String? ,
        notes4: String? ,
        date_contact: String? ,
    ): Respone<List<Client>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        agent.let { params["agent"] = agent }
        name.let { params["name"] = name }
        include_tax?.let { params["include_tax"] = include_tax }
        business_name?.let { params["business_name"] = business_name }
        business_id?.let { params["business_id"] = business_id }
        address?.let { params["address"] = address }
        print_state?.let { params["print_state"] = print_state }
        id?.let { params["id"] = id }
        phone?.let { params["phone"] = phone }
        branch?.let { params["branch"] = branch }
        master?.let { params["master"] = master }
        date?.let { params["date"] = date }
        phone_contact?.let { params["phone_contact"] = phone_contact }
        discount?.let { params["discount"] = discount }
        email?.let { params["email"] = email }
        external_id?.let { params["external_id"] = external_id }
        day?.let { params["day"] = day }
        notes?.let { params["notes"] = notes }
        no_tax_client?.let { params["no_tax_client"] = no_tax_client }
        position?.let { params["position"] = position }
        comments?.let { params["comments"] = comments }
        min_order?.let { params["min_order"] = min_order }
        location?.let { params["location"] = location }
        category?.let { params["category"] = category }
        obligo?.let { params["obligo"] = obligo }
        price_control?.let { params["price_control"] = price_control }
        payment_notes?.let { params["payment_notes"] = payment_notes }
        category2?.let { params["category2"] = category2 }
        min_order_sum?.let { params["min_order_sum"] = min_order_sum }
        tax_note_type?.let { params["tax_note_type"] = tax_note_type }
        net_split?.let { params["net_split"] = net_split }
        days_to_pay?.let { params["days_to_pay"] = days_to_pay }
        notes2?.let { params["notes2"] = notes2 }
        notes3?.let { params["notes3"] = notes3 }
        notes4?.let { params["notes4"] = notes4 }
        date_contact?.let { params["date_contact"] = date_contact }

        val url = NewDBAction.NEW_clients.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Client> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Client.serializer(), it)
        }
        return Respone(res, networkOutput)
    }


    suspend fun _updateClientStatic(
        id: String, print_state: String?, position: String?, active: String?
    ): Respone<List<Client>, NetworkOutput> {
        //static
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        print_state?.let { params["print_state"] = print_state }
//        active?.let { params["active"] = active }
        position?.let { params["position"] = position }
        val url = NewDBAction.UPDATE_clients_static.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Client> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Client.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun updateClient(
        id: String,
        date: String,
        agent: String?,
        name: String?,
        include_tax: String?,
        business_name: String?,
        business_id: String?,
        address: String?,
        print_state: String?,
        position: String?,
        active: String?,
        phone: String?,
        branch: String?,
        master: String?,
        phone_contact: String?,
        discount: String?,
        email: String?,
        external_id: String?,
        day: String?,
        notes: String?,
        no_tax_client: String?,
        comments: String?,
        min_order: String?,
        location: String?,
        category: String?,
        obligo: String?,
        price_control: String?,
        payment_notes: String?,
        category2: String?,
        min_order_sum: String?,
        tax_note_type: String?,
        net_split: String?,
        days_to_pay: String? ,
        notes2: String? ,
        notes3: String? ,
        notes4: String? ,
        date_contact: String? ,
    ): Respone<List<Client>, NetworkOutput> {

        val params = mutableMapOf<String, String>()
        //dynamic
        id.let { params["id"] = id }
        date.let { params["date"] = date }
        agent?.let { params["agent"] = agent }
        name?.let { params["name"] = name }
        include_tax?.let { params["include_tax"] = include_tax }
        business_name?.let { params["business_name"] = business_name }
        business_id?.let { params["business_id"] = business_id }
        address?.let { params["address"] = address }
        phone?.let { params["phone"] = phone }
        branch?.let { params["branch"] = branch }
        master?.let { params["master"] = master }
        position?.let { params["position"] = position }
        print_state?.let { params["print_state"] = print_state }
        active?.let { params["active"] = active }
        phone_contact?.let { params["phone_contact"] = phone_contact }
        discount?.let { params["discount"] = discount }
        email?.let { params["email"] = email }
        external_id?.let { params["external_id"] = external_id }
        day?.let { params["day"] = day }
        notes?.let { params["notes"] = notes }
        no_tax_client?.let { params["no_tax_client"] = no_tax_client }
        comments?.let { params["comments"] = comments }
        min_order?.let { params["min_order"] = min_order }
        location?.let { params["location"] = location }
        category?.let { params["category"] = category }
        obligo?.let { params["obligo"] = obligo }
        price_control?.let { params["price_control"] = price_control }
        payment_notes?.let { params["payment_notes"] = payment_notes }
        category2?.let { params["category2"] = category2 }
        min_order_sum?.let { params["min_order_sum"] = min_order_sum }
        tax_note_type?.let { params["tax_note_type"] = tax_note_type }
        net_split?.let { params["net_split"] = net_split }
        days_to_pay?.let { params["days_to_pay"] = days_to_pay }
        notes2?.let { params["notes2"] = notes2 }
        notes3?.let { params["notes3"] = notes3 }
        notes4?.let { params["notes4"] = notes4 }
        date_contact?.let { params["date_contact"] = date_contact }

        val url = NewDBAction.UPDATE_clients.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Client> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Client.serializer(), it)
        }
        return Respone(res, networkOutput)

    }

    override suspend fun updateClientsAll(date: String, data: String): Respone<List<Client>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        data.let { params["data"] = data }

        val url = NewDBAction.UPDATE_clients_all.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Client> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Client.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getClientsDailyData(
        id: String?, date: String, driver_id: String?, collector_id: String?, ids: String?
    ): Respone<List<ClientDailyData>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        ids?.let { params["ids"] = ids }
        date?.let { params["date"] = date }
        driver_id?.let { params["driver_id"] = driver_id }
        collector_id?.let { params["collector_id"] = collector_id }

        val url = NewDBAction.GET_clients_daily.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientDailyData> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientDailyData.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newClientDailyData(
        id: String, date: String, driver_id: String, collector_id: String, position: String, car: String
    ): Respone<List<ClientDailyData>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        date.let { params["date"] = date }
        driver_id.let { params["driver_id"] = driver_id }
        collector_id.let { params["collector_id"] = collector_id }
        position.let { params["position"] = position }
        car.let { params["car"] = car }

        val url = NewDBAction.NEW_clients_daily.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientDailyData> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientDailyData.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newClientAllDailyData(
        date: String, data: String
    ): Respone<List<ClientDailyData>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        data.let { params["data"] = data }

        val url = NewDBAction.NEW_clients_all_daily.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientDailyData> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientDailyData.serializer(), it)
        }
        return Respone(res, networkOutput)
    }


    override suspend fun getClientProducts(
        id: String?, active: String?, fromDate: String, toDate: String, ids: String?
    ): Respone<List<Product>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        active?.let { params["active"] = active }
        fromDate.let { params["fromDate"] = fromDate }
        toDate.let { params["toDate"] = toDate }
        ids?.let { params["ids"] = ids }
        val url = NewDBAction.GET_clients_products.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Product> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Product.serializer(), it)
        }

        return Respone(res, networkOutput)
    }

    override suspend fun newClientProduct(
        name: String,
        date: String,
        barcode: String?,
        default_price: String?,
        id: String?,
        no_tax_product: String?,
        external_id: String?,
        unit: String?,
        unit_amount: String?,
        category: String?,
        cost_price: String?,
        split_category: String?,
        position: String?,
        base_price: String?,
        cost_include_returns: String?,
        category2: String?,
        taklit_id: String?,
        taklit_type: String?,

    ): Respone<Product?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        name?.let { params["name"] = name }
        barcode?.let { params["barcode"] = barcode }
        default_price?.let { params["default_price"] = default_price }
        id?.let { params["id"] = id }
        date?.let { params["date"] = date }
        no_tax_product?.let { params["no_tax_product"] = no_tax_product }
        external_id?.let { params["external_id"] = external_id }
        unit?.let { params["unit"] = unit }
        unit_amount?.let { params["unit_amount"] = unit_amount }
        category?.let { params["category"] = category }
        cost_price?.let { params["cost_price"] = cost_price }
        split_category?.let { params["split_category"] = split_category }
        position?.let { params["position"] = position }
        base_price?.let { params["base_price"] = base_price }
        cost_include_returns?.let { params["cost_include_returns"] = cost_include_returns }
        category2?.let { params["category2"] = category2 }
        taklit_id?.let { params["taklit_id"] = taklit_id }
        taklit_type?.let { params["taklit_type"] = taklit_type }
        val url = NewDBAction.NEW_clients_products.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Product> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Product.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }


    override suspend fun updateClientProduct(
        id: String,
        date: String,
        name: String?,
        barcode: String?,
        default_price: String?,
        position: String?,
        active: String?,
        no_tax_product: String?,
        taklit_id: String?,
        taklit_type: String?,
        external_id: String?,
        unit: String?,
        unit_amount: String?,
        category: String?,
        cost_price: String?,
        split_category: String?,
        base_price: String?,
        cost_include_returns: String?,
        category2: String?
    ): Respone<List<Product>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        date.let { params["date"] = date }
        position?.let { params["position"] = position }
        name?.let { params["name"] = name }
        barcode?.let { params["barcode"] = barcode }
        default_price?.let { params["default_price"] = default_price }
        active?.let { params["active"] = active }
        no_tax_product?.let { params["no_tax_product"] = no_tax_product }
        taklit_id?.let { params["taklit_id"] = taklit_id }
        taklit_type?.let { params["taklit_type"] = taklit_type }
        external_id?.let { params["external_id"] = external_id }
        unit?.let { params["unit"] = unit }
        unit_amount?.let { params["unit_amount"] = unit_amount }
        category?.let { params["category"] = category }
        cost_price?.let { params["cost_price"] = cost_price }
        split_category?.let { params["split_category"] = split_category }
        base_price?.let { params["base_price"] = base_price }
        cost_include_returns?.let { params["cost_include_returns"] = cost_include_returns }
        category2?.let { params["category2"] = category2 }
        val url = NewDBAction.UPDATE_clients_products.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Product> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Product.serializer(), it)
        }
        return Respone(res, networkOutput)


    }

    override suspend fun updateClientAllProduct(

        date: String, data: String
    ): Respone<List<Product>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        data.let { params["data"] = data }
        val url = NewDBAction.UPDATE_clients_all_products.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Product> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Product.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun updateClientAllProductFull(

        date: String, data: String
    ): Respone<List<Product>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        data.let { params["data"] = data }
        val url = NewDBAction.UPDATE_clients_all_products_full.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Product> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Product.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun updateSupplierAllProduct(

        date: String, data: String
    ): Respone<List<Product>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        data.let { params["data"] = data }
        val url = NewDBAction.UPDATE_supplier_all_products.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Product> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Product.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getClientVisitNote(
        fromDate: String?, toDate: String?, ids: String?, ent_ids: String?, states: String?,has_order: String?
    ): Respone<List<VisitNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        ids?.let { params["ids"] = ids }
        ent_ids?.let { params["ent_ids"] = ent_ids }
        states?.let { params["states"] = states }
        has_order?.let { params["has_order"] = has_order }
        true.let { params["full"] = "true" }
        val url = NewDBAction.GET_clients_visit_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.simple))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<VisitNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(VisitNote.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newClientVisitNote(
        client_id: String,
        agent: String,
        document_date: String,
        date_issued: String,
        lon_lat: String,
        states: String,
        comment: String,
        f: ByteArray?,
        order_id: String?,
        payment: String?,
        valueForDebt: String?

    ): Respone<VisitNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        client_id.let { params["client_id"] = client_id }
        agent.let { params["agent"] = agent }
        document_date.let { params["document_date"] = document_date }
        date_issued.let { params["date_issued"] = date_issued }
        lon_lat.let { params["lon_lat"] = lon_lat }
        states.let { params["states"] = states }
        comment.let { params["comment"] = comment }
        f?.let {
            params["company"] = company
        }
        order_id?.let {
            params["order_id"] = order_id
        }
        payment?.let {
            params["payment"] = payment
        }
        valueForDebt?.let {
            params["valueForDebt"] = valueForDebt
        }

        val url = NewDBAction.NEW_clients_visit_notes.getURL(local,web)
        val response = (httpWorker.upload_file(url, params, f, fixJsonType = FixJsonType.simple))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<VisitNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(VisitNote.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }
    override suspend fun getClientGiul(
        date: String,
        toDate: String
    ): Respone<List<GlulStruct>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        toDate.let { params["toDate"] = toDate }
        val url = NewDBAction.GET_CLIENT_GIUL.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!.replace("None", "0.0")) as JsonArray
        val res: List<GlulStruct> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(GlulStruct.serializer(), it)
        }
        return Respone(res, networkOutput)
    }
    override suspend fun updateClientVisitNote(
        id: String?,
        states: String?,
        comment: String?,
        active: String?,
        payment: String?,
        valueForDebt: String?,
        order_id: String?,
        date:String?
    ): Respone<VisitNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        states?.let { params["states"] = states }
        comment?.let { params["comment"] = comment }
        active?.let { params["active"] = active }
        payment?.let { params["payment"] = payment }
        valueForDebt?.let { params["valueForDebt"] = valueForDebt }
        order_id?.let { params["order_id"] = order_id }
        date?.let { params["date"] = date }


        val url = NewDBAction.UPDATE_clients_visit_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<VisitNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(VisitNote.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getClientNotes(
        ent_id: String?,
        delivery_id: String?,
        fromDate: String?,
        toDate: String?,
        delivery_ids: String?,
        open: String?,
        order_ids: String?,
        historyQ: String?
    ): Respone<List<DeliveryNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ent_id?.let { params["ent_id"] = ent_id }
        delivery_id?.let { params["delivery_id"] = delivery_id }
        delivery_ids?.let { params["delivery_ids"] = delivery_ids }
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        open?.let { params["open"] = open }
        order_ids?.let { params["order_ids"] = order_ids }
        true.let { params["full"] = "true" }
        historyQ?.let { params["historyQ"] = historyQ }

        val url = NewDBAction.GET_clients_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light, timeout = 30000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<DeliveryNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(DeliveryNote.serializer(), it)
        }
        return Respone(res, networkOutput)
    }
    override suspend fun readMimshak(
        toDate: String?,
    ): NetworkOutput {
        val params = mutableMapOf<String, String>()
        toDate?.let { params["to_date"] = toDate }
        params["company"] = company
        val url = NewDBAction.READ_MIMSHAK.getURL(local,web)
        val response = (httpWorker.post(url, params,true, fixJsonType = FixJsonType.light, timeout = 60000))
        return response.second
    }
    override suspend fun getClientLastNotes(ent_id: String): Respone<List<DeliveryNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ent_id.let { params["ent_id"] = ent_id }
        true.let { params["full"] = "true" }
        val url = NewDBAction.GET_last_clients_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<DeliveryNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(DeliveryNote.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newClientNote(
        agent: String,
        ent_id: String,
        date: String,
        date_issued: String,
        delivery_value: String,
        delivery_id: String?,
        order_id: String?,
        connected_id: String?,
        notes: String?,
        increase_id: String?,
        notes2: String?,
        notes3: String?,
        notes4  : String?,
        notes5  : String?,
        agent_id: String?,
        active:String?
    ): Respone<DeliveryNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        agent?.let { params["agent"] = agent }
        ent_id?.let { params["ent_id"] = ent_id }
        date?.let { params["date"] = date }
        date_issued?.let { params["date_issued"] = date_issued }
        delivery_value?.let { params["delivery_value"] = delivery_value }
        delivery_id?.let { params["delivery_id"] = delivery_id }
        order_id?.let { params["order_id"] = order_id }
        connected_id?.let { params["connected_id"] = connected_id }
        notes?.let { params["notes"] = notes }
        increase_id?.let { params["increase_id"] = increase_id }
        notes2?.let { params["notes2"] = notes2 }
        notes3?.let { params["notes3"] = notes3 }
        notes4?.let { params["notes4"] = notes4 }
        notes5?.let { params["notes5"] = notes5 }
        agent_id?.let { params["agent_id"] = agent_id }
        active?.let { params["active"] = active }
        val url = NewDBAction.NEW_clients_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<DeliveryNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(DeliveryNote.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun updateClientNote(
        delivery_id: String,
        paid: String?,
        active: String?,
        connected_id: String?,
        delivery_value: String?,
        order_id: String?,
        notes: String?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
        agent_id: String?,
    ): Respone<DeliveryNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        active?.let { params["active"] = active }
        delivery_id.let { params["delivery_id"] = delivery_id }
        paid?.let { params["paid"] = paid }
        connected_id?.let { params["connected_id"] = connected_id }
        delivery_value?.let { params["delivery_value"] = delivery_value }
        order_id?.let { params["order_id"] = order_id }
        notes?.let { params["notes"] = notes }
        notes2?.let { params["notes2"] = notes2 }
        notes3?.let { params["notes3"] = notes3 }
        notes4?.let { params["notes4"] = notes4 }
        notes5?.let { params["notes5"] = notes5 }
        agent_id?.let { params["agent_id"] = agent_id }


        val url = NewDBAction.UPDATE_clients_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<DeliveryNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(DeliveryNote.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getClientBaseOrder(ent_id: String?): Respone<List<OrderNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ent_id?.let { params["ent_id"] = ent_id }
        val url = NewDBAction.GET_clients_base_order_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderNote.serializer(), it)
        }
        res.forEach {
            it.sup_delivery_value.forEach { p ->
                p.isClient = false
            }
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newClientBaseOrderNote(
        order_id: String?,
        agent: String,
        ent_id: String,
        date_issued: String,
        delivery_value: String,
        sup_delivery_info: String,
    ): Respone<OrderNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        order_id?.let { params["order_id"] = order_id }
        agent.let { params["agent"] = agent }
        ent_id.let { params["ent_id"] = ent_id }
        date_issued.let { params["date_issued"] = date_issued }
        delivery_value.let { params["delivery_value"] = delivery_value }
        sup_delivery_info.let { params["sup_delivery_info"] = sup_delivery_info }
        val url = NewDBAction.NEW_clients_base_order_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderNote.serializer(), it)
        }
        res.forEach {
            it.sup_delivery_value.forEach { p ->
                p.isClient = false
            }
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun calcClientDeliveryNotePdf(
        client_id: String, date_range: String?, specific_notes: String?, tax_id_merge: String?, order: String?,force_sum_type:String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        client_id.let { params["client_id"] = client_id }
        date_range?.let { params["date_range"] = date_range }
        specific_notes?.let { params["specific_notes"] = specific_notes }
        tax_id_merge?.let { params["tax_id_merge"] = tax_id_merge }
        order?.let { params["order"] = order }
        force_sum_type?.let { params["force_sum_type"] = force_sum_type }
        val url = NewDBAction.CALCULATE_client_delivery.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun calcClientCartesetPdf(
        client_id: String, date_range: String?, onlyTaxSide: String?,open:String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        client_id.let { params["id"] = client_id }
        date_range?.let { params["date_range"] = date_range }
        onlyTaxSide?.let { params["onlyTaxSide"] = onlyTaxSide }
        open?.let { params["open"] = open }

        val url = NewDBAction.CALCULATE_client_carteset_pdf.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun calcClientEdiNote(
        note_id: String,
    ): NetworkOutput {
        val params = mutableMapOf<String, String>()
        note_id.let { params["note_id"] = note_id }
        params["company"] = company
        val url = NewDBAction.CALCULATE_client_edi_note.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        if (response.second == NetworkOutput.SUCCESS) {
            return NetworkOutput.SUCCESS
        }
        return response.second
    }

    override suspend fun getEdiNote(
        note_id: String?, note_ids: String?, connected_id: String?, fromDate: String?, toDate: String?, send: String?
    ): Respone<List<EdiNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        note_id?.let { params["note_id"] = note_id }
        note_ids?.let { params["note_ids"] = note_ids }
        connected_id?.let { params["connected_id"] = connected_id }
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        send?.let { params["send"] = send }
        val url = NewDBAction.GET_client_edi_note.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<EdiNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(EdiNote.serializer(), it)
        }
        return Respone(res, networkOutput)

    }

    override suspend fun newEdiNote(
        note_id: String, date: String, info: String, reason: String?, connected_id: String?, send: String?
    ): Respone<EdiNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        note_id.let { params["note_id"] = note_id }
        connected_id?.let { params["connected_id"] = connected_id }
        date.let { params["date"] = date }
        info.let { params["info"] = info }
        reason?.let { params["reason"] = reason }
        send?.let { params["send"] = send }
        val url = NewDBAction.NEW_client_edi_note.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<EdiNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(EdiNote.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun updateEdiNote(
        note_id: String, date: String, info: String?, reason: String?, connected_id: String?, send: String?
    ): Respone<EdiNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        note_id.let { params["note_id"] = note_id }
        date.let { params["date"] = date }
        connected_id?.let { params["connected_id"] = connected_id }
        info?.let { params["info"] = info }
        reason?.let { params["reason"] = reason }
        send?.let { params["send"] = send }
        val url = NewDBAction.UPDATE_client_edi_note.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<EdiNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(EdiNote.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getTaklitProducts(id: String?, date: String?): Respone<List<TaklitProduct>, NetworkOutput> {

        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        date?.let { params["date"] = date }
        val url = NewDBAction.GET_taklit_products.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<TaklitProduct> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(TaklitProduct.serializer(), it)
        }
        return Respone(res, networkOutput)

    }

    override suspend fun getOrderProducts(
        id: String?, product_id: String?, available: String?, active: String?, product_ids: String?
    ): Respone<List<OrderProduct>, NetworkOutput> {

        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        product_id?.let { params["product_id"] = product_id }
        available?.let { params["available"] = available }
        active?.let { params["active"] = active }
        product_ids?.let { params["product_ids"] = product_ids }
        val url = NewDBAction.GET_order_products.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderProduct> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderProduct.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newOrderProducts(
        id: String,
        product_id: String,
        extra_unit: String,
        conversion_rate: String,
        available: String,
        active: String,
        weight: String?,
        englishName: String?,
        orderName: String?,
        position: String?,
        volume: String?,
        sales: String?,
        forceLock: String?,
        hourLock: String?,
        dayLock: String?,
        step: String?,
        collector: String?,
        min_order: String?,
        unit_preferece: String?
    ): Respone<OrderProduct?, NetworkOutput> {

        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        product_id.let { params["product_id"] = product_id }
        available.let { params["available"] = available }
        active.let { params["active"] = active }
        extra_unit.let { params["extra_unit"] = extra_unit }
        conversion_rate.let { params["conversion_rate"] = conversion_rate }
        weight?.let { params["weight"] = weight }
        englishName?.let { params["englishName"] = englishName }
        orderName?.let { params["orderName"] = orderName }
        position?.let { params["position"] = position }
        volume?.let { params["volume"] = volume }
        sales?.let { params["sales"] = sales }
        forceLock?.let { params["forceLock"] = forceLock }
        hourLock?.let { params["hourLock"] = hourLock }
        dayLock?.let { params["dayLock"] = dayLock }
        step?.let { params["step"] = step }
        collector?.let { params["collector"] = collector }
        min_order?.let { params["min_order"] = min_order }
        unit_preferece?.let { params["unit_preferece"] = unit_preferece }

        val url = NewDBAction.NEW_order_products.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderProduct> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderProduct.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun updateAllOrderProduct(date: String, data: String): Respone<List<OrderProduct>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        data.let { params["data"] = data }

        val url = NewDBAction.UPDATE_order_products_all.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderProduct> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderProduct.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun calcTaklit(): NetworkOutput {
        val params = mutableMapOf<String, String>()

        val url = NewDBAction.CALCULATE_taklit.getURL(local,web)
        val x = (httpWorker.post(url, params))
        return x.second
    }


    override suspend fun calcClientPricesPdf(
        client_id: String, date_range: String?, p_ids: String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        client_id.let { params["id"] = client_id }
        date_range?.let { params["date_range"] = date_range }
        p_ids?.let { params["p_ids"] = p_ids }
        val url = NewDBAction.CALCULATE_client_prices_pdf.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun getLastUpdateOrders(
        date: String,
        action_time: String,
    ): Respone<List<OrderNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()

        date.let { params["date"] = date }
        action_time.let { params["action_time"] = action_time }

        val url = NewDBAction.GET_last_updated_clients_order_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderNote.serializer(), it)
        }
        res.forEach {
            it.sup_delivery_value.forEach { p ->
                p.isClient = false
            }
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getLastUpdateEnt(
        action_time: String
    ): Respone<List<Int>, NetworkOutput> {
        val params = mutableMapOf<String, String>()

        action_time.let { params["action_time"] = action_time }

        val url = NewDBAction.GET_last_updated_clients.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray

        val res: List<Int> = arr.map {
            ((it as JsonObject)["id"] as JsonPrimitive).content.toIntOrNull() ?: 0
        }

        return Respone(res, networkOutput)
    }

    override suspend fun getClientOrderNotes(
        ent_id: String?,
        order_id: String?,
        fromDate: String?,
        toDate: String?,
        ids: String?,
        order_ids: String?,
        status: String?,
        inverse_status: String?,
        ref_id: String?,
        agent_id: String?,
        historyQ:String?
    ): Respone<List<OrderNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ent_id?.let { params["ent_id"] = ent_id }
        order_id?.let { params["order_id"] = order_id }
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        ids?.let { params["ids"] = ids }
        order_ids?.let { params["order_ids"] = order_ids }
        status?.let { params["status"] = status }
        inverse_status?.let { params["inverse_status"] = inverse_status }
        ref_id?.let { params["ref_id"] = ref_id }
        agent_id?.let { params["agent_id"] = agent_id }
        true.let { params["full"] = "true" }
        historyQ?.let { params["historyQ"] = historyQ }
        val url = NewDBAction.GET_clients_order_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderNote.serializer(), it)
        }
        res.forEach {
            it.sup_delivery_value.forEach { p ->
                p.isClient = false
            }
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getClientLastOrderNotes(ent_id: String): Respone<List<OrderNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ent_id.let { params["ent_id"] = ent_id }
        val url = NewDBAction.GET_last_clients_order_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderNote.serializer(), it)
        }
        true.let { params["full"] = "true" }
        res.forEach {
            it.sup_delivery_value.forEach { p ->
                p.isClient = false
            }
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newClientOrderNote(
        agent: String,
        ent_id: String,
        date: String,
        date_issued: String,
        delivery_value: String,
        sup_delivery_info: String,
        order_id: String?,
        active: String?,
        notes: String?,
        category: String?,
        notify: String?,
        ref_id: String?,
        agent_id: String?,
        notes2: String?,
        notes3: String?,
        notes4: String? ,
        notes5: String?,
        history: String?,
        collection:String?,
        increase_id: String?,
        noApi: String?,
        static_data: String?,
    ): Respone<OrderNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        agent?.let { params["agent"] = agent }
        ent_id?.let { params["ent_id"] = ent_id }
        date?.let { params["date"] = date }
        date_issued?.let { params["date_issued"] = date_issued }
        delivery_value?.let { params["delivery_value"] = delivery_value }
        order_id?.let { params["order_id"] = order_id }
        sup_delivery_info?.let { params["sup_delivery_info"] = sup_delivery_info }
        active?.let { params["active"] = active }
        notes?.let { params["notes"] = notes }
        category?.let { params["category"] = category }
        notify?.let { params["notify"] = notify }
        ref_id?.let { params["ref_id"] = ref_id }
        agent_id?.let { params["agent_id"] = agent_id }
        notes2?.let { params["notes2"] = notes2 }
        notes3?.let { params["notes3"] = notes3 }
        notes4?.let { params["notes4"] = notes4 }
        notes5?.let { params["notes5"] = notes5 }
        history?.let { params["history"] = history }
        collection?.let { params["collection"] = collection }
        increase_id?.let { params["increase_id"] = increase_id }
        noApi?.let { params["noApi"] = noApi }
        static_data?.let { params["static_data"] = static_data }

        val url = NewDBAction.NEW_clients_order_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderNote.serializer(), it)
        }
        res.forEach {
            it.sup_delivery_value.forEach { p ->
                p.isClient = false
            }
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun updateClientOrderNote(
        order_id: String,
        agent: String?,
        date: String?,
        delivery_value: String?,
        sup_delivery_info: String?,
        active: String?,
        notes: String?,
        category: String?,
        notify: String?,
        ref_id: String?,
        inventory: String?,
        agent_id: String?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
        history: String?,
        collection:String?,
        increase_id: String?,
        update_agent: String?,
        static_data: String?,
        e_agent: String?,
    ): Respone<OrderNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        active?.let { params["active"] = active }
        order_id.let { params["order_id"] = order_id }
        date?.let { params["date"] = date }
        delivery_value?.let { params["delivery_value"] = delivery_value }
        sup_delivery_info?.let { params["sup_delivery_info"] = sup_delivery_info }
        agent?.let { params["agent"] = agent }
        active?.let { params["active"] = active }
        notes?.let { params["notes"] = notes }
        category?.let { params["category"] = category }
        notify?.let { params["notify"] = notify }
        ref_id?.let { params["ref_id"] = ref_id }
        agent_id?.let { params["agent_id"] = agent_id }
        inventory?.let { params["inventory"] = inventory }
        notes2?.let { params["notes2"] = notes2 }
        notes3?.let { params["notes3"] = notes3 }
        notes4?.let { params["notes4"] = notes4 }
        notes5?.let { params["notes5"] = notes5 }
        history?.let { params["history"] = history }
        collection?.let { params["collection"] = collection }
        increase_id?.let { params["increase_id"] = increase_id }
        update_agent?.let { params["update_agent"] = update_agent }
        static_data?.let { params["static_data"] = static_data }
        e_agent?.let { params["e_agent"] = e_agent }

        val url = NewDBAction.UPDATE_clients_order_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)

        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderNote.serializer(), it)
        }
        res.forEach {
            it.sup_delivery_value.forEach { p ->
                p.isClient = false
            }
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getSpecialPrices(
        fromDate: String, toDate: String, ids: String
    ): Respone<List<RawPrice>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        fromDate.let { params["fromDate"] = fromDate }
        toDate.let { params["toDate"] = toDate }
        ids.let { params["ids"] = ids }
        val url = NewDBAction.GET_cost_prices.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light, timeout = 50000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<RawPrice> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPrice.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getClientPrices(
        id: String?, product_id: String?, fromDate: String, toDate: String, ids: String?, product_ids: String?,master: String?
    ): Respone<List<RawPrice>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        ids?.let { params["ids"] = ids }
        product_id?.let { params["product_id"] = product_id }
        product_ids?.let { params["product_ids"] = product_ids }
        fromDate.let { params["fromDate"] = fromDate }
        toDate.let { params["toDate"] = toDate }
        master?.let { params["master"] = master }
        val url = NewDBAction.GET_clients_prices.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light, timeout = 50000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<RawPrice> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPrice.serializer(), it)
        }
        return Respone(res, networkOutput)
    }
    override suspend fun getClientPriorityPrices(
        id: String,
        date: String,
        master: String?,
    ): Respone<List<RawPricePriority>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        date.let { params["date"] = date }
        master?.let { params["master"] = master }

        val url = NewDBAction.GET_clients_priority_prices.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light, timeout = 50000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!.replace("None","null")) as JsonArray
        val res: List<RawPricePriority> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPricePriority.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getLastClientPrices(
        id: String?, product_id: String?, ids: String?
    ): Respone<List<RawPrice>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        ids?.let { params["ids"] = ids }
        product_id?.let { params["product_id"] = product_id }
        val url = NewDBAction.GET_last_clients_prices.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light, timeout = 50000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<RawPrice> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPrice.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newClientPrice(rawPrice: RawPrice): Respone<RawPrice?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        params["id"] = rawPrice.id.toString()
        params["product_id"] = rawPrice.product_id.toString()
        params["price"] = rawPrice.price.toString()
        params["date"] = rawPrice.date
        params["discount"] = rawPrice.discount.toString()
        val url = NewDBAction.NEW_clients_prices.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<RawPrice> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPrice.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun newClientPrice(
        id: String, product_id: String, price: String, date: String?
    ): Respone<RawPrice?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        product_id?.let { params["product_id"] = product_id }
        date?.let { params["date"] = date }
        price?.let { params["price"] = price }
        val url = NewDBAction.NEW_clients_prices.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<RawPrice> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPrice.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun updateClientAllPrices(
        date: String, data: String, toDate: String?
    ): Respone<List<RawPrice>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        toDate?.let { params["toDate"] = toDate }
        data.let { params["data"] = data }
        val url = NewDBAction.UPDATE_clients_all_prices.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<RawPrice> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPrice.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun updateClientPrice(
        id: String, product_id: String, price: String, date: String?
    ): Respone<RawPrice?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        product_id?.let { params["product_id"] = product_id }
        date?.let { params["date"] = date }
        price?.let { params["price"] = price }
        val url = NewDBAction.UPDATE_clients_prices.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<RawPrice> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPrice.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getClientsMonthlyCycle(
        date: String?, id: String?
    ): Respone<List<MonthlyCycle>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date?.let { params["date"] = date }
        id?.let { params["id"] = id }
        val url = NewDBAction.GET_clients_monthly_cycle.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<MonthlyCycle> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(MonthlyCycle.serializer(), it)
        }

        return Respone(res, networkOutput)
    }

    override suspend fun getClientMonthlyCyclePdf(
        client_name: String, month: String, year: String
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        client_name.let { params["client_name"] = client_name }
        month.let { params["month"] = month }
        year.let { params["year"] = year }
        val url = NewDBAction.GET_clients_monthly_cycle_pdf.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun getClientDeliveryNotePdf(
        id: String,
        origin: String,
        force_price: String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        origin.let { params["origin"] = origin }
        force_price?.let { params["force_price"] = force_price }
        val url = NewDBAction.GET_clients_delivery_note_pdf.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun getClientTaxPdf(
        client_name: String,
        month: String,
        year: String,
        id: String,
        type: String,
        origin: String,
        client_master: String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        client_name.let { params["client_name"] = client_name }
        month.let { params["month"] = month }
        year.let { params["year"] = year }
        id.let { params["id"] = id }
        type.let { params["type"] = type }
        origin.let { params["origin"] = origin }
        client_master?.let { params["client_master"] = client_master }
        val url = NewDBAction.GET_clients_tax_pdf.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun getClientPayPdf(
        client_name: String, month: String, year: String, id: String, origin: String, client_master: String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        client_name.let { params["client_name"] = client_name }
        month.let { params["month"] = month }
        year.let { params["year"] = year }
        id.let { params["id"] = id }
        origin.let { params["origin"] = origin }
        client_master?.let { params["client_master"] = client_master }
        val url = NewDBAction.GET_clients_pay_pdf.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun newClientsMonthlyCycle(
        date: String, id: String, value: String, date_updated: String?, paid: String?, date_paid: String?
    ): Respone<MonthlyCycle?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        date?.let { params["date"] = date }
        value?.let { params["value"] = value }
        date_updated?.let { params["date_updated"] = date_updated }
        paid?.let { params["paid"] = paid }
        date_paid?.let { params["date_paid"] = date_paid }
        val url = NewDBAction.NEW_clients_monthly_cycle.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<MonthlyCycle> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(MonthlyCycle.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getClientsLastTaxNote(
        client_id: String?, type: String?, client_ids: String?,sup: Boolean
    ): Respone<List<ClientTaxNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        client_id?.let { params["client_id"] = client_id }
        client_ids?.let { params["client_ids"] = client_ids }
        type?.let { params["type"] = type }
        if(sup)
            sup.let { params["sup"] = "true" }

        true.let { params["full"] = "true" }
        val url = NewDBAction.GET_last_clients_tax_note.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientTaxNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientTaxNote.serializer(), it)
        }
        if(sup)
            res.forEach { it.setClientProduct(false) }
        return Respone(res, networkOutput)
    }

    override suspend fun getClientsLightTaxNote(
        client_id: String?,
        id: String?,
        fromDate: String?,
        toDate: String?,
        type: String?,
        withProduct: String?,
        client_ids: String?
    ): Respone<List<ClientLightTaxNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        client_id?.let { params["client_id"] = client_id }
        client_ids?.let { params["client_ids"] = client_ids }
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        type?.let { params["type"] = type }
        withProduct?.let { params["withProduct"] = withProduct }
        val url = NewDBAction.GET_clients_light_tax_note.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientLightTaxNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientLightTaxNoteImp.serializer(), it)

        }
        return Respone(res, networkOutput)
    }

    override suspend fun getClientsTaxNote(
        client_id: String?,
        id: String?,
        fromDate: String?,
        toDate: String?,
        type: String?,
        withProduct: String?,
        client_ids: String?,
        tax_ids: String?,
        open: String?,
        agent: String?,
        check_date: String?,
        order_ids: String?,
        historyQ:String?,
        sup:Boolean,
        check_date_from: String?,
        paid: String?
    ): Respone<List<ClientTaxNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        client_id?.let { params["client_id"] = client_id }
        client_ids?.let { params["client_ids"] = client_ids }
        tax_ids?.let { params["tax_ids"] = tax_ids }
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        type?.let { params["type"] = type }
        withProduct?.let { params["withProduct"] = withProduct }
        open?.let { params["open"] = open }
        agent?.let { params["agent"] = agent }
        check_date?.let { params["check_date"] = check_date }
        check_date_from?.let { params["check_date_from"] = check_date_from }
        paid?.let { params["paid"] = paid }
        order_ids?.let { params["order_ids"] = order_ids }
        historyQ?.let { params["historyQ"] = historyQ }
        true.let { params["full"] = "true" }
        if(sup)
            sup.let { params["sup"] = "true" }

        val url = NewDBAction.GET_clients_tax_note.getURL(local,web)

        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientTaxNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientTaxNote.serializer(), it)
        }
        if(sup)
            res.forEach { it.setClientProduct(false) }
        return Respone(res, networkOutput)
    }

    override suspend fun newClientsTaxNote(
        client_id: String,
        date: String,
        document_date: String,
        value: String,
        total_value: String,
        value_data_raw: String,
        details: String?, //top delete
        discount_percent: String?,
        cover_dates: String?,
        cover_date_start: String?,
        cover_date_end: String?,
        type: String?,
        payment_data_raw: String,
        delivery_notes_meta_data: String?,
        withProduct: String?,
        tax_note_meta_data: String?,
        delivery_notes_map: String?,
        active_state: String?,
        external_details: String?,
        agent: String?,
        order_id: String?,
        tax_note_id: String?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
        increase_id: String?,
        sup:Boolean
    ): Respone<ClientTaxNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        client_id?.let { params["client_id"] = client_id }
        date?.let { params["date"] = date }
        document_date?.let { params["document_date"] = document_date }
        value?.let { params["value"] = value }
        total_value?.let { params["total_value"] = total_value }
        details?.let { params["details"] = details }
        discount_percent?.let { params["discount_percent"] = discount_percent }
        cover_dates?.let { params["cover_dates"] = cover_dates }
        cover_date_start?.let { params["cover_date_start"] = cover_date_start }
        cover_date_end?.let { params["cover_date_end"] = cover_date_end }
        type?.let { params["type"] = type }
        value_data_raw.let { params["value_data_raw"] = value_data_raw }
        payment_data_raw.let { params["payment_data_raw"] = payment_data_raw }
        delivery_notes_meta_data?.let { params["delivery_notes_meta_data"] = delivery_notes_meta_data }
        withProduct?.let { params["withProduct"] = withProduct }
        tax_note_meta_data?.let { params["tax_note_meta_data"] = tax_note_meta_data }
        delivery_notes_map?.let { params["delivery_notes_map"] = delivery_notes_map }
        active_state?.let { params["active_state"] = active_state }
        external_details?.let { params["external_details"] = external_details }
        agent?.let { params["agent"] = agent }
        order_id?.let { params["order_id"] = order_id }
        tax_note_id?.let { params["tax_note_id"] = tax_note_id }
        notes2?.let { params["notes2"] = notes2 }
        notes3?.let { params["notes3"] = notes3 }
        notes4?.let { params["notes4"] = notes4 }
        notes5?.let { params["notes5"] = notes5 }
        increase_id?.let { params["increase_id"] = increase_id }
        if(sup)
            sup.let { params["sup"] = "true" }
        val url = NewDBAction.NEW_clients_tax_note.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientTaxNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientTaxNote.serializer(), it)
        }
        if(sup)
            res.forEach { it.setClientProduct(false) }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun updateAllClientTaxNote(
        data: String,  sup:Boolean
    ): Respone<List<ClientTaxNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        data.let { params["data"] = data }
        if(sup)
            sup.let { params["sup"] = "true" }
        val url = NewDBAction.UPDATE_all_clients_tax_note.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientTaxNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientTaxNote.serializer(), it)
        }
        if(sup)
            res.forEach { it.setClientProduct(false) }
        return Respone(res, networkOutput)
    }

    override suspend fun calcClientTaxNotePdf(doc_id: String, type: String): NetworkOutput {
        val params = mutableMapOf<String, String>()
        doc_id.let { params["doc_id"] = doc_id }
        type.let { params["type"] = type }
        params.putIfAbsent("company", company)
        val url = NewDBAction.CALCULATE_client_tax_pdf.getURL(local,web)
        val response = (httpWorker.post(url, params))
        return response.second
    }

    override suspend fun calcClientPayNotePdf(doc_id: String): NetworkOutput {
        val params = mutableMapOf<String, String>()
        doc_id.let { params["doc_id"] = doc_id }
        params.putIfAbsent("company", company)
        val url = NewDBAction.CALCULATE_client_pay_pdf.getURL(local,web)
        val response = (httpWorker.post(url, params))
        return response.second
    }

    override suspend fun getClientChecks(
        client_id: String?, client_ids: String?, last: String?,sup: Boolean
    ): Respone<List<PaymentData>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        client_id?.let { params["client_id"] = client_id }
        client_ids?.let { params["client_ids"] = client_ids }
        last?.let { params["last"] = last }
        if(sup)
            sup.let { params["sup"] = "true" }
        val url = NewDBAction.GET_last_clients_checks.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val x = response.first!!.replace("\\\"", "")
        val arr = getJsonWithConfig().parseToJsonElement(x) as JsonArray
        val res: List<PaymentData> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(PaymentData.serializer(), it)
        }

        return Respone(res, networkOutput)
    }

    override suspend fun getClientsLastPay(
        client_id: String?, client_ids: String?,sup: Boolean
    ): Respone<List<ClientPay>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        client_id?.let { params["client_id"] = client_id }
        client_ids?.let { params["client_ids"] = client_ids }
        true.let { params["full"] = "true" }
        if(sup)
            sup.let { params["sup"] = "true" }
        val url = NewDBAction.GET_last_clients_pay.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientPay> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientPay.serializer(), it)
        }
        if(sup)
            res.forEach { it.isClient=(false) }
        return Respone(res, networkOutput)
    }

    override suspend fun newClientsPay(
        client_id: String,
        date: String,
        document_date: String,
        value: String,
        details: String?,
        payment_data_raw: String,
        tax_note_id: String?,
        cancel_id: String?,
        reopen_tax_notes: String?,
        active: String?,
        external_details: String?,
        agent: String?,
        refund: String?,
        refund_with_tax: String?,
        type: String?,
        increase_id: String?,
        sup: Boolean,
        ref_id: String?,
    ): Respone<ClientPay?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        client_id?.let { params["client_id"] = client_id }
        date?.let { params["date"] = date }
        document_date?.let { params["document_date"] = document_date }
        value?.let { params["value"] = value }
        details?.let { params["details"] = details }
        payment_data_raw.let { params["payment_data_raw"] = payment_data_raw }
        tax_note_id?.let { params["tax_note_id"] = tax_note_id }
        cancel_id?.let { params["cancel_id"] = cancel_id }
        reopen_tax_notes?.let { params["reopen_tax_notes"] = reopen_tax_notes }
        active?.let { params["active"] = active }
        external_details?.let { params["external_details"] = external_details }
        agent?.let { params["agent"] = agent }
        refund?.let { params["refund"] = refund }
        refund_with_tax?.let { params["refund_with_tax"] = refund_with_tax }
        type?.let { params["type"] = type }
        ref_id?.let { params["ref_id"] = ref_id }
        increase_id?.let { params["increase_id"] = increase_id }
        if(sup) params["sup"] = "true"

        val url = NewDBAction.NEW_clients_pay.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientPay> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientPay.serializer(), it)
        }
        if(sup)
            res.forEach {
                it.isClient=false
            }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun updateClientsTaxNotePayment(
        id: String,
        payment_data_raw: String,
        sup:Boolean
    ): Respone<ClientTaxNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        payment_data_raw.let { params["payment_data_raw"] = payment_data_raw }
        if(sup) params["sup"] = "true"
        val url = NewDBAction.UPDATE_clients_tax_note.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientTaxNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientTaxNote.serializer(), it)
        }
        if(sup)
            res.forEach {
                it.isClient=false
            }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun updateClientsPay(
        id: String,
        payment_data_raw: String?,
        tax_note_id: String?,
        cancel_id: String?,
        reopen_tax_notes: String?,
        active: String?,
        external_details: String?,
        value: String?,
        sup: Boolean
    ): Respone<ClientPay?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        payment_data_raw?.let { params["payment_data_raw"] = payment_data_raw }
        tax_note_id?.let { params["tax_note_id"] = tax_note_id }
        cancel_id?.let { params["cancel_id"] = cancel_id }
        reopen_tax_notes?.let { params["reopen_tax_notes"] = reopen_tax_notes }
        active?.let { params["active"] = active }
        external_details?.let { params["external_details"] = external_details }
        value?.let { params["value"] = value }
        if(sup)
            params["sup"] = "true"

        val url = NewDBAction.UPDATE_clients_pay.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientPay> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientPay.serializer(), it)
        }
        if(sup)
            res.forEach {
                it.isClient=false
            }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getCartesetClients(
        id: String?, ids: String?, fromDate: String?, toDate: String?, state: String?,sup: Boolean
    ): Respone<List<ClientCarteset>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        id?.let { params["id"] = id }
        ids?.let { params["ids"] = ids }
        state?.let { params["state"] = state }
        if(sup) params["sup"] = "true"

        val url = NewDBAction.GET_clients_carteset.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientCarteset> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientCarteset.serializer(), it)
        }
        if(sup)
            res.forEach {
                it.isClient=false
            }
        return Respone(res, networkOutput)
    }

    override suspend fun newCartesetClients(
        id: String, date: String, positive: String, negative: String, date_updated: String, state: String?,notes: String?,sup: Boolean
    ): Respone<ClientCarteset?, NetworkOutput> {
        val params = mutableMapOf<String, String>()

        id.let { params["id"] = id }
        date.let { params["date"] = date }
        positive.let { params["positive"] = positive }
        negative.let { params["negative"] = negative }
        date_updated.let { params["date_updated"] = date_updated }
        state?.let { params["state"] = state }
        notes?.let { params["notes"] = notes }
        if(sup) params["sup"] = "true"

        val url = NewDBAction.NEW_clients_carteset.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientCarteset> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientCarteset.serializer(), it)
        }
        if(sup)
            res.forEach {
                it.isClient=false
            }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getEdiClients(
        date: String, id: String?, ids: String?
    ): Respone<List<EdiMember>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        id?.let { params["id"] = id }
        ids?.let { params["ids"] = ids }
        val url = NewDBAction.GET_edi_clients.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<EdiMember> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(EdiMember.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newEdiClients(
        date: String,
        id: String,
        edi_sup_id: String,
        edi_client_id: String,
        branch: String,
        internal_sup_id: String,
        net_edi: String
    ): Respone<EdiMember?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        id.let { params["client_id"] = id }
        date.let { params["date"] = date }
        edi_sup_id.let { params["edi_sup_id"] = edi_sup_id }
        edi_client_id.let { params["edi_client_id"] = edi_client_id }
        branch.let { params["branch_number"] = branch }
        internal_sup_id.let { params["internal_sup_edi"] = internal_sup_id }
        net_edi.let { params["net_edi"] = net_edi }


        val url = NewDBAction.NEW_edi_clients.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<EdiMember> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(EdiMember.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getClientsPay(
        client_id: String?,
        id: String?,
        fromDate: String?,
        toDate: String?,
        client_ids: String?,
        pay_ids: String?,
        agent: String?,
        check_date: String?,
        historyQ:String?,
        sup: Boolean,
        check_date_from: String?,
        paid:String?
    ): Respone<List<ClientPay>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        client_id?.let { params["client_id"] = client_id }
        client_ids?.let { params["client_ids"] = client_ids }
        pay_ids?.let { params["pay_ids"] = pay_ids }
        id?.let { params["id"] = id }
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        agent?.let { params["agent"] = agent }
        check_date?.let { params["check_date"] = check_date }
        true.let { params["full"] = "true" }
        historyQ?.let { params["historyQ"] = historyQ }
        if(sup) params["sup"] = "true"
        check_date_from?.let { params["check_date_from"] = check_date_from }
        paid?.let { params["paid"] = paid }


        val url = NewDBAction.GET_clients_pay.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientPay> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientPay.serializer(), it)
        }
        if(sup)
            res.forEach {
                it.isClient=false
            }
        return Respone(res, networkOutput)
    }

    override suspend fun getClientsLightPay(
        client_id: String?, id: String?, fromDate: String?, toDate: String?, client_ids: String?
    ): Respone<List<ClientLightPay>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        client_id?.let { params["client_id"] = client_id }
        client_ids?.let { params["client_ids"] = client_ids }
        id?.let { params["id"] = id }
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        val url = NewDBAction.GET_clients_light_pay.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientLightPay> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientLightPayImp.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getSuppliers(
        id: String?, active: String?, fromDate: String, toDate: String, ids: String?
    ): Respone<List<Supplier>, NetworkOutput> {

        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        ids?.let { params["ids"] = ids }
        active?.let { params["active"] = active }
        fromDate.let { params["fromDate"] = fromDate }
        toDate.let { params["toDate"] = toDate }
        val url = NewDBAction.GET_suppliers.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Supplier> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Supplier.serializer(), it)
        }
        return Respone(res, networkOutput)
    }


    override suspend fun newSupplier(
        name: String,
        date: String,
        include_tax: String?,
        business_name: String?,
        business_id: String?,
        address: String?,
        print_state: String?,
        id: String?,
        phone: String?,
        no_tax_client: String?,
        external_id: String?,
        comments: String?,
        category: String?,
        payment_notes: String?,
        category2: String?,
        phone_contact: String?
    ): Respone<Supplier?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        name.let { params["name"] = name }
        date.let { params["date"] = date }
        include_tax?.let { params["include_tax"] = include_tax }
        business_name?.let { params["business_name"] = business_name }
        business_id?.let { params["business_id"] = business_id }
        address?.let { params["address"] = address }
        print_state?.let { params["print_state"] = print_state }
        id?.let { params["id"] = id }
        phone?.let { params["phone"] = phone }
        no_tax_client?.let { params["no_tax_client"] = no_tax_client }
        external_id?.let { params["external_id"] = external_id }
        comments?.let { params["comments"] = comments }
        category?.let { params["category"] = category }
        payment_notes?.let { params["payment_notes"] = payment_notes }
        category2?.let { params["category2"] = category2 }
        phone_contact?.let { params["phone_contact"] = phone_contact }


        val url = NewDBAction.NEW_suppliers.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Supplier> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Supplier.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }


    suspend fun _updateSupplierStatic(
        id: String, print_state: String?, position: String?, active: String?
    ): Respone<List<Supplier>, NetworkOutput> {
        //static
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        print_state?.let { params["print_state"] = print_state }
//        active?.let { params["active"] = active }
        position?.let { params["position"] = position }
        val url = NewDBAction.UPDATE_suppliers_static.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Supplier> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Supplier.serializer(), it)
        }
        return Respone(res, networkOutput)
    }


    override suspend fun updateSupplier(
        id: String,
        date: String,
        name: String?,
        include_tax: String?,
        business_name: String?,
        business_id: String?,
        address: String?,
        print_state: String?,
        position: String?,
        active: String?,
        phone: String?,
        no_tax_client: String?,
        external_id: String?,
        comments: String?,
        category: String?,
        payment_notes: String?,
        category2: String?,
        phone_contact: String?
    ): Respone<List<Supplier>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        //dynamic
        id.let { params["id"] = id }
        date.let { params["date"] = date }
        name?.let { params["name"] = name }
        include_tax?.let { params["include_tax"] = include_tax }
        business_name?.let { params["business_name"] = business_name }
        business_id?.let { params["business_id"] = business_id }
        address?.let { params["address"] = address }
        phone?.let { params["phone"] = phone }
        position?.let { params["position"] = position }
        print_state?.let { params["print_state"] = print_state }
        active?.let { params["active"] = active }
        no_tax_client?.let { params["no_tax_client"] = no_tax_client }
        external_id?.let { params["external_id"] = external_id }
        comments?.let { params["comments"] = comments }
        category?.let { params["category"] = category }
        payment_notes?.let { params["payment_notes"] = payment_notes }
        category2?.let { params["category2"] = category2 }
        phone_contact?.let { params["phone_contact"] = phone_contact }

        val url = NewDBAction.UPDATE_suppliers.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Supplier> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Supplier.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getSupplierProducts(
        id: String?, active: String?, fromDate: String, toDate: String
    ): Respone<List<Product>, NetworkOutput> {

        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        active?.let { params["active"] = active }
        fromDate.let { params["fromDate"] = fromDate }
        toDate.let { params["toDate"] = toDate }
        val url = NewDBAction.GET_suppliers_products.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Product> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Product.serializer(), it)
        }
        res.forEach {
            it.isClient = false
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newSupplierProduct(
        name: String,
        date: String,
        barcode: String?,
        default_price: String?,
        id: String?,
        connected_product: String?,
        no_tax_product: String?,
        category: String?,
        external_id: String?
    ): Respone<Product?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        name.let { params["name"] = name }
        date.let { params["date"] = date }
        barcode?.let { params["barcode"] = barcode }
        default_price?.let { params["default_price"] = default_price }
        id?.let { params["id"] = id }
        connected_product?.let { params["connected_product"] = connected_product }
        no_tax_product?.let { params["no_tax_product"] = no_tax_product }
        category?.let { params["category"] = category }
        external_id?.let { params["external_id"] = external_id }
        val url = NewDBAction.NEW_suppliers_products.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Product> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Product.serializer(), it)
        }
        res.forEach {
            it.isClient = false
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun updateSupplierProduct(
        id: String,
        date: String,
        name: String?,
        barcode: String?,
        default_price: String?,
        position: String?,
        active: String?,
        connected_product: String?,
        no_tax_product: String?,
        category: String?,
        external_id: String?
    ): Respone<List<Product>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        date?.let { params["date"] = date }
        position?.let { params["position"] = position }
        name?.let { params["name"] = name }
        barcode?.let { params["barcode"] = barcode }
        default_price?.let { params["default_price"] = default_price }
        active?.let { params["active"] = active }
        connected_product?.let { params["connected_product"] = connected_product }
        no_tax_product?.let { params["no_tax_product"] = no_tax_product }
        category?.let { params["category"] = category }
        external_id?.let { params["external_id"] = external_id }
        val url = NewDBAction.UPDATE_suppliers_products.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Product> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Product.serializer(), it)
        }
        res.forEach {
            it.isClient = false
        }
        return Respone(res, networkOutput)

    }

    override suspend fun getSupplierNotes(
        ent_id: String?, delivery_id: String?, fromDate: String?, toDate: String?, delivery_ids: String?,open: String?
    ): Respone<List<DeliveryNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ent_id?.let { params["ent_id"] = ent_id }
        delivery_ids?.let { params["delivery_ids"] = delivery_ids }
        delivery_id?.let { params["delivery_id"] = delivery_id }
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        open?.let { params["open"] = open }
        val url = NewDBAction.GET_suppliers_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<DeliveryNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(DeliveryNote.serializer(), it)
        }

        res.forEach {
            it.isSupNote = true
            it.delivery_info.forEach { productDelivery -> productDelivery.isClient = false }
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getSupplierLastNotes(ent_id: String): Respone<List<DeliveryNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ent_id.let { params["ent_id"] = ent_id }
        val url = NewDBAction.GET_last_suppliers_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<DeliveryNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(DeliveryNote.serializer(), it)
        }
        res.forEach {
            it.isSupNote = true
            it.delivery_info.forEach { productDelivery -> productDelivery.isClient = false }
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newSupplierNote(
        agent: String,
        ent_id: String,
        date: String,
        date_issued: String,
        delivery_value: String,
        delivery_id: String?,
        connected_id: String?,
        notes: String?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
    ): Respone<DeliveryNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        agent?.let { params["agent"] = agent }
        ent_id?.let { params["ent_id"] = ent_id }
        date?.let { params["date"] = date }
        date_issued?.let { params["date_issued"] = date_issued }
        delivery_value?.let { params["delivery_value"] = delivery_value }
        delivery_id?.let { params["delivery_id"] = delivery_id }
        connected_id?.let { params["connected_id"] = connected_id }
        notes?.let { params["notes"] = notes }
        notes2?.let { params["notes2"] = notes2 }
        notes3?.let { params["notes3"] = notes3 }
        notes4?.let { params["notes4"] = notes4 }
        notes5?.let { params["notes5"] = notes5 }
        val url = NewDBAction.NEW_suppliers_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<DeliveryNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(DeliveryNote.serializer(), it)
        }
        res.forEach {
            it.isSupNote = true
            it.delivery_info.forEach { productDelivery -> productDelivery.isClient = false }
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun updateSupplierNote(
        delivery_id: String,
        paid: String?,
        paid_date: String?,
        active: String?,
        connected_id: String?,
        delivery_value: String?,
        notes: String?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
    ): Respone<DeliveryNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        active?.let { params["active"] = active }
        delivery_id?.let { params["delivery_id"] = delivery_id }
        paid_date?.let { params["paid_date"] = paid_date }
        paid?.let { params["paid"] = paid }
        connected_id?.let { params["connected_id"] = connected_id }
        delivery_value?.let { params["delivery_value"] = delivery_value }
        notes?.let { params["notes"] = notes }
        notes2?.let { params["notes2"] = notes2 }
        notes3?.let { params["notes3"] = notes3 }
        notes4?.let { params["notes4"] = notes4 }
        notes5?.let { params["notes5"] = notes5 }

        val url = NewDBAction.UPDATE_suppliers_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<DeliveryNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(DeliveryNote.serializer(), it)
        }
        res.forEach {
            it.isSupNote = true
            it.delivery_info.forEach { productDelivery -> productDelivery.isClient = false }
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getSupplierPrices(
        id: String?, product_id: String?, fromDate: String, toDate: String, ids: String?, product_ids: String?
    ): Respone<List<RawPrice>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        product_id?.let { params["product_id"] = product_id }
        product_ids?.let { params["product_ids"] = product_ids }
        fromDate.let { params["fromDate"] = fromDate }
        toDate.let { params["toDate"] = toDate }
        ids?.let { params["ids"] = ids }
        val url = NewDBAction.GET_suppliers_prices.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<RawPrice> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPrice.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getLastSupplierPrices(
        id: String?, product_id: String?
    ): Respone<List<RawPrice>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        product_id?.let { params["product_id"] = product_id }
        val url = NewDBAction.GET_last_supplier_prices.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<RawPrice> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPrice.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun updateSupplierAllPrices(
        date: String, data: String, toDate: String?
    ): Respone<List<RawPrice>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        toDate?.let { params["toDate"] = toDate }
        data.let { params["data"] = data }
        val url = NewDBAction.UPDATE_suppliers_all_prices.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<RawPrice> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPrice.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newSupplierPrice(rawPrice: RawPrice): Respone<RawPrice?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        params["id"] = rawPrice.id.toString()
        params["product_id"] = rawPrice.product_id.toString()
        params["price"] = rawPrice.price.toString()
        params["date"] = rawPrice.date
        params["discount"] = rawPrice.discount.toString()
        val url = NewDBAction.NEW_suppliers_prices.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<RawPrice> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPrice.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun newSupplierPrice(
        id: String, product_id: String, price: String, date: String?
    ): Respone<RawPrice?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        product_id?.let { params["product_id"] = product_id }
        date?.let { params["date"] = date }
        price?.let { params["price"] = price }
        val url = NewDBAction.NEW_suppliers_prices.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<RawPrice> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPrice.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun updateSupplierPrice(
        id: String, product_id: String, price: String, date: String?
    ): Respone<RawPrice?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        product_id?.let { params["product_id"] = product_id }
        date?.let { params["date"] = date }
        price?.let { params["price"] = price }
        val url = NewDBAction.UPDATE_suppliers_prices.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<RawPrice> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPrice.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getSuppliersMonthlyCyclePdf(
        sup_name: String, month: String, year: String
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        sup_name.let { params["sup_name"] = sup_name }
        month.let { params["month"] = month }
        year.let { params["year"] = year }
        val url = NewDBAction.GET_suppliers_monthly_cycle_pdf.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun getSuppliersMonthlyCycle(
        date: String?, id: String?
    ): Respone<List<MonthlyCycle>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date?.let { params["date"] = date }
        id?.let { params["id"] = id }
        val url = NewDBAction.GET_suppliers_debt.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<MonthlyCycle> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(MonthlyCycle.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newSuppliersMonthlyCycle(
        date: String, id: String, value: String, paid: String?, date_paid: String?, date_updated: String?
    ): Respone<MonthlyCycle?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        date?.let { params["date"] = date }
        value?.let { params["value"] = value }
        paid?.let { params["paid"] = paid }
        date_paid?.let { params["date_paid"] = date_paid }
        date_updated?.let { params["date_updated"] = date_updated }
        val url = NewDBAction.NEW_suppliers_debt.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<MonthlyCycle> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(MonthlyCycle.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun calcSupplierDeliveryNotePdf(
        sup_id: String, date_range: String, specific_notes: String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        sup_id.let { params["sup_id"] = sup_id }
        date_range.let { params["date_range"] = date_range }
        specific_notes?.let { params["specific_notes"] = specific_notes }
        val url = NewDBAction.CALCULATE_supplier_delivery.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }


    override suspend fun dataToAccountManager(
        date_range: String
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date_range.let { params["date_range"] = date_range }
        val url = NewDBAction.CALCULATE_data_account_manager.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun calcDocumentsSum(
        doc_type: String, date_range: String, pdf: String?, open_only: String?, active_only: String?, ent_id: String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        doc_type.let { params["doc_type"] = doc_type }
        date_range.let { params["date_range"] = date_range }
        pdf?.let { params["pdf"] = pdf }
        open_only?.let { params["open_only"] = open_only }
        active_only?.let { params["active_only"] = active_only }
        ent_id?.let { params["ent_id"] = ent_id }
        val url = NewDBAction.CALCULATE_document_sum.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true, timeout = 200000))
    }

    override suspend fun calcClientDebtXsl(
        date_range: String,
        agent: String,
        noZero: String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        agent.let { params["agent"] = agent }
        date_range.let { params["date_range"] = date_range }
        noZero?.let { params["noZero"] = noZero }
        val url = NewDBAction.CALCULATE_client_debt_xsl.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun login(name: String, password: String, company: String): Respone<Agent?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        name?.let { params["user_name"] = name }
        password?.let { params["password"] = password }
        company?.let { params["company"] = company }
        val url = NewDBAction.LOGIN.getURL(local,web)
        val response = (httpWorker.login(url, params, autoCompany = false, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Agent> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Agent.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getAgents(): Respone<List<Agent>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        val url = NewDBAction.GET_agents.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Agent> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Agent.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getCompanyInfo(
        id: String?, company: String?, fromDate: String, toDate: String
    ): Respone<User?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        company?.let { params["company"] = company }
        fromDate.let { params["fromDate"] = fromDate }
        toDate.let { params["toDate"] = toDate }
        val url = NewDBAction.USER_INFO.getURL(local,web)
        val response = (httpWorker.post(url, params, autoCompany = false, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<User> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(User.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getCompanysInfo(
    ): Respone<List<User>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        params["simple"] = "12" // placeholder
        val url = NewDBAction.USER_INFO.getURL(local,web)
        val response = (httpWorker.post(url, params, autoCompany = false, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<User> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(User.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getCofcConf(
        id: String?, cofc: Boolean
    ): Respone<CofcConf?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        val url =
            if (cofc) NewDBAction.USER_COFC_CONF.getURL(local,web) else NewDBAction.USER_COFC_CONF_NORMAL.getURL(local,web)
        val response = (httpWorker.post(url, params, autoCompany = true, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<CofcConf> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(CofcConf.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getAllCities(): Respone<String?, NetworkOutput> {
        val gov_api =
            "https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=100000"
        val params = hashMapOf<String, String>()
        return httpWorker.purePost(gov_api, params)
    }

    override suspend fun getGeoName(address: String, city: String?): Respone<Pair<String?, Boolean>, NetworkOutput> {
        val gov_api =
            "https://graphhopper.com/api/1/geocode?q=${address}&locale=il&key=f816fb13-e4dc-4565-965a-072f6d940a9c"


        try {
            val ret = httpWorker.pureGet(gov_api)
            val x = ret
            val array = ((getJsonWithConfig().parseToJsonElement(ret) as JsonObject)["hits"] as JsonArray)
            val r =
                (((array[0]) as JsonObject)["point"] as JsonObject)
            val rq = city?.let {
                array.firstOrNull {
                    (it as JsonObject)["city"]?.jsonPrimitive?.content == city

                }
            } as JsonObject?
            val lat = (rq?.get("point") as JsonObject? ?: r)["lat"]
            val lon = (rq?.get("point") as JsonObject? ?: r)["lng"]
            return Respone(Pair("${lat},${lon}", city == null || rq != null), NetworkOutput.SUCCESS)
        } catch (e: Exception) {

        }
        return Respone(Pair(null, false), NetworkOutput.FAILED)

    }

    override suspend fun getOptimizedRoutes(
        locations: List<Location>,
        start: Location?,
        end: Location?
    ): Respone<List<Int>?, NetworkOutput> {
        return getOptimizedRoutes2(locations, start, end)
        val gov_api =
            "https://graphhopper.com/api/1/route?key=f816fb13-e4dc-4565-965a-072f6d940a9c"

        try {
            val params = hashMapOf<String, JsonElement>()
            val locs = locations.toMutableList()
            start?.let { locs.add(0, it) }
            end?.let { locs.add(it) }
            val qlocs = locs.map {
                JsonArray(
                    listOf(
                        JsonPrimitive(it.lon!!.toDouble()),
                        JsonPrimitive(it.lat!!.toDouble())
                    )
                )
            }.take(5)
            params["points"] = JsonArray(qlocs)

            params["optimize"] = JsonPrimitive("true")
            params["instructions"] = JsonPrimitive(false)
            val result =
                httpWorker.purePostApi(gov_api, null, jsonStr = JsonObject(params).toString())
            val x = getJsonWithConfig().parseToJsonElement(result.first!!)
            val y = (((x as JsonObject)["paths"] as JsonArray)[0] as JsonObject)["points_order"] as JsonArray
            return Respone(y.map { it.jsonPrimitive.content.toInt() }, NetworkOutput.SUCCESS)
        } catch (e: Exception) {

        }
        return Respone(null, NetworkOutput.FAILED)
    }

    suspend fun getOptimizedRoutes2(
        locations: List<Location>,
        start: Location? = null,
        end: Location? = null
    ): Respone<List<Int>?, NetworkOutput> {
        val gov_api =
            "https://api.openrouteservice.org/optimization"

        try {
            val params = hashMapOf<String, JsonElement>()

            val driver1 = mutableMapOf<String, JsonElement>()
            driver1["id"] = JsonPrimitive(1)
            driver1["capacity"] = JsonArray(
                listOf(
                    JsonPrimitive(1000)
                )
            )
            driver1["skills"] = JsonArray(
                listOf(
                    JsonPrimitive(1)
                )
            )
            driver1["profile"] = JsonPrimitive("driving-car")
            start?.let {
                driver1["start"] = JsonArray(
                    listOf(
                        JsonPrimitive(start.lon!!.toDouble()),
                        JsonPrimitive(start.lat!!.toDouble())
                    )
                )
            }
            end?.let {
                driver1["end"] = JsonArray(
                    listOf(
                        JsonPrimitive(end.lon!!.toDouble()),
                        JsonPrimitive(end.lat!!.toDouble())
                    )
                )
            }

            val qlocs = locations.mapIndexed { index: Int, location: Location ->
                val m = mutableMapOf<String, JsonElement>()
                m["id"] = JsonPrimitive(index + 1)
                m["service"] = JsonPrimitive(300)
                m["amount"] = JsonArray(
                    listOf(
                        JsonPrimitive(1)
                    )
                )
                m["location"] = JsonArray(
                    listOf(
                        JsonPrimitive(location.lon!!.toDouble()),
                        JsonPrimitive(location.lat!!.toDouble())
                    )
                )
                m["skills"] = JsonArray(
                    listOf(
                        JsonPrimitive(1)
                    )
                )
                JsonObject(m)

            }
            params["jobs"] = JsonArray(qlocs)

            params["vehicles"] = JsonArray(listOf(JsonObject(driver1)))

            val result =
                httpWorker.purePostApi(
                    gov_api,
                    null,
                    token = "5b3ce3597851110001cf6248bf9e2dc8bb754ea3ba03bb0ddc5f645c",
                    jsonStr = JsonObject(params).toString(),

                )
            val x = getJsonWithConfig().parseToJsonElement(result.first!!)
            val y = (((x as JsonObject)["routes"] as JsonArray)[0] as JsonObject)["steps"] as JsonArray

            return Respone(
                y.subList(if (start != null) 1 else 0, if (end != null) y.size - 1 else y.size)
                    .map { ((it as JsonObject)["job"] as JsonPrimitive).content.toInt() },
                NetworkOutput.SUCCESS
            )
        } catch (e: Exception) {

        }
        return Respone(null, NetworkOutput.FAILED)
    }

    override suspend fun downloadApp(): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        val url = NewDBAction.UPDATE_APP.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = false, timeout = 100000))
    }

    override suspend fun getStoreNextInfo(): Respone<List<StoreNextConfig>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        val url = NewDBAction.GET_storenext_config.getURL(local,web)
        val response = (httpWorker.post(url, params, autoCompany = true, fixJsonType = FixJsonType.simple))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<StoreNextConfig> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(StoreNextConfig.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getHashavshevetInfo(): Respone<List<HashavshevetS>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        val url = NewDBAction.GET_hashavshevet_config.getURL(local,web)
        val response = (httpWorker.post(url, params, autoCompany = true, fixJsonType = FixJsonType.simple))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<HashavshevetS> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(HashavshevetS.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getWeigth(): String {
//        return httpWorker.pureGet("http://10.100.102.82:8080")
        return httpWorker.webSocket("10.100.102.82", 8080)
    }

    override suspend fun getCofcClients(
        id: String, date: String, master: String?
    ): Respone<List<Client>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        date.let { params["date"] = date }
        master?.let { params["master"] = master }
        val url = NewDBAction.GET_cofc_client.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Client> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Client.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getCofcFull(id: String): Respone<JsonObject?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }


        val url = NewDBAction.GET_cofc_full.getURL()
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val res = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonObject

        return Respone(res, networkOutput)
    }

    override suspend fun getCofcAgents(
        id: String,
        collectors:String?,
        drivers:String?,
        ): Respone<List<Agent>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        collectors?.let { params["collectors"] = collectors }
        drivers?.let { params["drivers"] = drivers }

        val url = NewDBAction.GET_cofc_agent.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Agent> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Agent.serializer(), it)
        }
        return Respone(res, networkOutput)
    }
    override suspend fun getCofcId(): Respone<String?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        val url = NewDBAction.GET_cofc_info.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        return Respone(response.first!!, networkOutput)
    }
    override suspend fun getCofcId2(pass:String): Respone<Pair<String,String>?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        params["pass"] = pass
        val url = NewDBAction.GET_cofc_info2.getURL(local,web)
        val response = (httpWorker.post(url, params, withToken = false))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS || response.first=="None") return Respone(null, NetworkOutput.FAILED)
        val x = getJsonWithConfig().parseToJsonElement(response.first!!)
        return  Respone(Pair(x.jsonObject["token"].toString().replace("\"", ""),x.jsonObject["data"].toString().replace("\"", "")), networkOutput)
    }

    override suspend fun registerCofcId(
        id: String, company: String, until: String, force_date: String?
    ): Respone<String?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["client_id"] = id }
        company.let { params["company"] = company }
        until.let { params["until_time"] = until }
        force_date?.let { params["force_date"] = force_date }

        val url = NewDBAction.register_cofc_info.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val x = getJsonWithConfig().parseToJsonElement(response.first!!)

        return Respone(x.jsonObject["access_token"].toString().replace("\"", ""), networkOutput)
    }
   override suspend fun registerCofcIdShort(
        id: String,
        company: String,
        until: String,
        force_date: String?
    ): Respone<String?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["client_id"] = id }
        company.let { params["company"] = company }
        until.let { params["until_time"] = until }
        force_date?.let { params["force_date"] = force_date }

        val url = NewDBAction.register_cofc_info2.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)

        return Respone(response.first!!, networkOutput)
    }
    override suspend fun getCofcUser(id: String): Respone<User?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        val url = NewDBAction.USER_COFC.getURL(local,web)
        val response = (httpWorker.post(url, params, autoCompany = true, fixJsonType = FixJsonType.simple))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<User> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(User.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getCofcProducts(id: String, date: String): Respone<List<Product>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        date.let { params["date"] = date }
        val url = NewDBAction.GET_cofc_product.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Product> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Product.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getCofcOrderProducts(
        id: String, op_id: String?, product_id: String?, available: String?, active: String?
    ): Respone<List<OrderProduct>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        op_id?.let { params["op_id"] = op_id }
        product_id?.let { params["product_id"] = product_id }
        available?.let { params["available"] = available }
        active?.let { params["active"] = active }
        val url = NewDBAction.GET_cofc_order_product.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderProduct> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderProduct.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getClientProductsImagesMetaCofc(
        id: String,
        company: String,
    ): Respone<JsonObject?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        params["company"] = company
        params["id"] = id
        val url = NewDBAction.GET_cofc_product_image_finder.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.NONE))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val x = getJsonWithConfig().parseToJsonElement("{" + response.first!! + "}")
        return Respone(x as JsonObject, networkOutput)
    }

    override suspend fun getCofcProductsImage(
        id: String, company: String, date: String, product_id: String
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        date.let { params["date"] = date }
        product_id.let { params["product_id"] = product_id }
        params["company_id"] = company_id
        val url = NewDBAction.GET_cofc_product_image.getURL(local,web)
        val response = (httpWorker.post_file(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        return Respone(response.first, networkOutput)
    }

    override suspend fun getClientProductsImage(
        company: String, product_id: String
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        product_id.let { params["product_id"] = product_id }
        params["company_id"] = company_id
        params["company"] = company
        val url = NewDBAction.GET_client_product_img.getURL(local,web)
        val response = (httpWorker.post_file(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        return Respone(response.first, networkOutput)
    }

    override suspend fun getClientProductsImagesMeta(
        company: String,
    ): Respone<JsonObject?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        params["company"] = company
        val url = NewDBAction.GET_client_product_imgs_meta.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.NONE))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val x = getJsonWithConfig().parseToJsonElement("{" + response.first!! + "}")
        return Respone(x as JsonObject, networkOutput)
    }

    override suspend fun getClientProductsImages(
        company: String, product_ids: String?
    ): Respone<JsonObject?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        params["company"] = company
        product_ids?.let { params["product_ids"] = product_ids }
        val url = NewDBAction.GET_client_product_imgs.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.NONE, timeout = 30000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val x = getJsonWithConfig().parseToJsonElement("{${response.first!!}}")
        return Respone(x as JsonObject, networkOutput)
    }

    override suspend fun calcDocumentZip(doc_type: String, date_range: String): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        doc_type.let { params["doc_type"] = doc_type }
        date_range.let { params["date_range"] = date_range }
        val url = NewDBAction.DOCUMENTS_ZIP.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true, timeout = 200000))
    }

    override suspend fun getCofcClientsPrices(
        id: String, date: String, master: String?
    ): Respone<List<RawPricePriority>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        date.let { params["date"] = date }
        master?.let { params["master"] = master }
        val url = NewDBAction.GET_cofc_client_price.getURL(local,web)
        val response = (httpWorker.post(url, params))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!.replace("None","null")) as JsonArray
        val res: List<RawPricePriority> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(RawPricePriority.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getCofcClientsOrder(
        id: String, dateFrom: String, dateTo: String, status: String?
    ): Respone<List<OrderNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        dateFrom.let { params["fromDate"] = dateFrom }
        dateTo.let { params["toDate"] = dateTo }
        status?.let { params["status"] = status }
        val url = NewDBAction.GET_cofc_order.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderNote.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun newCofcClientOrderNote(
        agent: String,
        ent_id: String,
        date: String,
        date_issued: String,
        delivery_value: String,
        sup_delivery_info: String,
        order_id: String?,
        active: String?,
        notes: String?,
        category: String?,
        title: String?,
        collection: String?,
        static_data: String?
    ): Respone<OrderNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        agent?.let { params["agent"] = agent }
        ent_id?.let { params["id"] = ent_id }
        date?.let { params["date"] = date }
        date_issued?.let { params["date_issued"] = date_issued }
        delivery_value?.let { params["delivery_value"] = delivery_value }
        order_id?.let { params["delivery_id"] = order_id }
        active?.let { params["active"] = active }
        sup_delivery_info?.let { params["sup_delivery_info"] = sup_delivery_info }
        notes?.let { params["notes"] = notes }
        category?.let { params["category"] = category }
        title?.let { params["title"] = title }
        collection?.let { params["collection"] = collection }
        static_data?.let { params["static_data"] = static_data }
        val url = NewDBAction.NEW_cofc_order.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderNote.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun updateCofcClientOrderNote(
        order_id: String,
        ent_id: String,
        agent: String?,
        date: String?,
        delivery_value: String?,
        sup_delivery_info: String?,
        active: String?,
        notes: String?,
        category: String?,
        title: String?,
        collection: String?,
        static_data: String?
    ): Respone<OrderNote?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        active?.let { params["active"] = active }
        order_id.let { params["order_id"] = order_id }
        ent_id?.let { params["id"] = ent_id }
        ent_id?.let { params["ent_id"] = ent_id }
        date?.let { params["date"] = date }
        delivery_value?.let { params["delivery_value"] = delivery_value }
        sup_delivery_info?.let { params["sup_delivery_info"] = sup_delivery_info }
        agent?.let { params["agent"] = agent }
        notes?.let { params["notes"] = notes }
        category?.let { params["category"] = category }
        title?.let { params["title"] = title }
        collection?.let { params["collection"] = collection }
        static_data?.let { params["static_data"] = static_data }
        val url = NewDBAction.UPDATE_cofc_order.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderNote.serializer(), it)
        }
        return Respone(res.first(), networkOutput)

    }

    override suspend fun uploadFile(f: ByteArray, img_name: String): NetworkOutput {
        val params = mutableMapOf<String, String>()
        params["company"] = company
        val url = NewDBAction.UPLOAD_IMAGE.getURL(local,web)
        val response = (httpWorker.upload_img(url, params, f, img_name, autoCompany = true))
        val networkOutput = response.second
        return networkOutput
    }

    override suspend fun uploadLogo(f: ByteArray, img_name: String): NetworkOutput {
        val params = mutableMapOf<String, String>()
        params["company"] = company
        val url = NewDBAction.UPLOAD_LOGO.getURL(local,web)
        val response = (httpWorker.upload_img(url, params, f, img_name, autoCompany = true))
        val networkOutput = response.second
        return networkOutput
    }

    override suspend fun uploadSign(
        f: ByteArray, note_id: String, date: String, signer: String, doc_type: String
    ): Respone<NoteSignHolder?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        params["company"] = company
        params["note_id"] = note_id
        params["date"] = date
        params["signer"] = signer
        params["doc_type"] = doc_type
        val url = NewDBAction.UPLOAD_IMAGE_SIGN.getURL(local,web)
        val response = (httpWorker.upload_img(url, params, f, note_id, autoCompany = true))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<NoteSignHolder> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(NoteSignHolder.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun uploadPhoto(
        f: ByteArray, note_id: String, date: String, doc_type: String
    ): Respone<NoteSignHolder?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        params["company"] = company
        params["note_id"] = note_id
        params["date"] = date
        params["doc_type"] = doc_type
        val url = NewDBAction.UPLOAD_IMAGE_PHOTO.getURL(local,web)
        val response = (httpWorker.upload_img(url, params, f, note_id, autoCompany = true))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<NoteSignHolder> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(NoteSignHolder.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun getSignImg(note_id: String, doc_type: String): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        note_id.let { params["note_id"] = note_id }
        doc_type.let { params["doc_type"] = doc_type }
        params["company_id"] = company_id
        params["company"] = company
        val url = NewDBAction.GET_IMAGE_SIGN.getURL(local,web)
        val response = (httpWorker.post_file(url, params, timeout = 10000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        return Respone(response.first, networkOutput)
    }

    override suspend fun getSignPhoto(note_id: String, doc_type: String): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        note_id.let { params["note_id"] = note_id }
        doc_type.let { params["doc_type"] = doc_type }
        params["company_id"] = company_id
        params["company"] = company
        val url = NewDBAction.GET_IMAGE_PHOTO.getURL(local,web)
        val response = (httpWorker.post_file(url, params, timeout = 10000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        return Respone(response.first, networkOutput)
    }

    override suspend fun getSign(note_id: String, doc_type: String): Respone<List<NoteSignHolder>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        note_id.let { params["ids"] = note_id }
        doc_type.let { params["doc_type"] = doc_type }
        params["company"] = company
        val url = NewDBAction.GET_SIGN.getURL(local,web)
        val response = (httpWorker.post(url, params, autoCompany = true))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<NoteSignHolder> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(NoteSignHolder.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun takanonPDF(): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        val url = NewDBAction.TAKANON_PDF.getURL(local,web)
        return (httpWorker.post_file(url, params))

    }

    override suspend fun calcApiExcelZip(
        date_range: String, xlsxType: String?, c_ids: String?,
        ids: String?,
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date_range.let { params["date_range"] = date_range }
        xlsxType?.let { params["xlsxType"] = xlsxType }
        c_ids?.let { params["c_ids"] = c_ids }
        ids?.let { params["ids"] = ids }

        val url = NewDBAction.API_EXCEL_ZIP.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true, timeout = 200000))
    }

    override suspend fun calcApiHashavshevetZip(date_range: String,ids:String?,types:String?): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date_range.let { params["date_range"] = date_range }
        ids?.let { params["ids"] = ids }
        types?.let { params["types"] = types }
        val url = NewDBAction.API_HASHAVSHEVET_ZIP.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true, timeout = 200000))
    }


    override suspend fun getClientOptimziedBADDV(
        fromDate: String?,
        toDate: String?,
        byEnt: String?,
        byAgent: String?,
    ): Respone<List<DeliveryNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        byEnt?.let { params["byEnt"] = byEnt }
        byAgent?.let { params["byAgent"] = byAgent }
        params["doc_type"] = "4"
        val url = NewDBAction.GET_OPTIMIZED_BAD_DV.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light, timeout = 30000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<DeliveryNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(DeliveryNote.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getClientOptimziedDV(
        fromDate: String?,
        toDate: String?,
        byProduct: String?,
        byEnt: String?,
        byDay: String?,
        byWeek: String?,
        byMonth: String?,
        byYear: String?,
        byAgent: String?,
        byStd: String?,
        byMoney: String?,
        doc_type: String?,
        cofc: String?,
        byDate: String?,
        byCost: String?
    ): Respone<List<DataQueryHolder>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        byProduct?.let { params["byProduct"] = byProduct }
        byEnt?.let { params["byEnt"] = byEnt }
        byDay?.let { params["byDay"] = byDay }
        byWeek?.let { params["byWeek"] = byWeek }
        byMonth?.let { params["byMonth"] = byMonth }
        byYear?.let { params["byYear"] = byYear }
        byAgent?.let { params["byAgent"] = byAgent }
        byStd?.let { params["byStd"] = byStd }
        byMoney?.let { params["byMoney"] = byMoney }
        doc_type?.let { params["doc_type"] = doc_type }
        byDate?.let { params["byDate"] = byDate }
        byCost?.let { params["byCost"] = byCost }
        val url = if (cofc != null) {
            params["company"] = company
            params["company_id"] = company_id
            params["id"] = cofc
            NewDBAction.GET_COFC_OPTIMIZED_DV.getURL(local,web)
        } else NewDBAction.GET_OPTIMIZED_DV_3.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light, timeout = 30000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!.replace("None", "0.0")) as JsonArray
        val res: List<DataQueryHolder> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(DataQueryHolder.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getClientOptimziedNotesFinal(
        fromDate: String?,
        toDate: String?,
        byProduct: String?,
        byEnt: String?,
        byDay: String?,
        byWeek: String?,
        byMonth: String?,
        byYear: String?,
        byAgent: String?,
        byStd: String?,
        byMoney: String?,
        doc_type: String?,
        cofc: String?,
        byDate: String?,
        byCost: String?,
        paid: String?,
        inventory: Boolean,
        byDocId: String?,
        byAgentId: String?,
        byCostTax:String?
    ): Respone<List<DataQueryHolder>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        byProduct?.let { params["byProduct"] = byProduct }
        byEnt?.let { params["byEnt"] = byEnt }
        byDay?.let { params["byDay"] = byDay }
        byWeek?.let { params["byWeek"] = byWeek }
        byMonth?.let { params["byMonth"] = byMonth }
        byYear?.let { params["byYear"] = byYear }
        byAgent?.let { params["byAgent"] = byAgent }
        byStd?.let { params["byStd"] = byStd }
        byMoney?.let { params["byMoney"] = byMoney }
        doc_type?.let { params["doc_type"] = doc_type }
        byDate?.let { params["byDate"] = byDate }
        byCost?.let { params["byCost"] = byCost }
        paid?.let { params["paid"] = paid }
        byDocId?.let { params["byDocId"] = byDocId }
        byAgentId?.let { params["byAgentId"] = byAgentId }
        byCostTax?.let{params["byCostTax"] = byCostTax}
        if (inventory)
            params["inventory"] = "1"

        val url = if (cofc != null) {
            params["company"] = company
            params["company_id"] = company_id
            params["id"] = cofc
            NewDBAction.GET_COFC_OPTIMIZED_DV.getURL(local,web)
        } else NewDBAction.GET_OPTIMIZED_NOTE_FINAL.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light, timeout = 30000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!.replace("None", "0.0")) as JsonArray
        val res: List<DataQueryHolder> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(DataQueryHolder.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun sendClientApi(doc_id: String, doc_type: String): NetworkOutput {
        val params = mutableMapOf<String, String>()
        doc_id.let { params["doc_id"] = doc_id }
        doc_type.let { params["doc_type"] = doc_type }

        val url = NewDBAction.API_SEND_CALL.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        return networkOutput
    }

    override suspend fun readClientApi(): NetworkOutput {
        val params = mutableMapOf<String, String>()
        val url = NewDBAction.API_READ_CALL.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        return networkOutput
    }

    override suspend fun getApiNote(
        doc_ids: String?,
        doc_type: String?,
        api_method: String?,
        connected_id: String?,
        fromDate: String?,
        toDate: String?,
        send: String?,
        retries: String?,
        needHistoryQ:String?
    ): Respone<List<ApiNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        doc_ids?.let { params["doc_ids"] = doc_ids }
        doc_type?.let { params["doc_type"] = doc_type }
        api_method?.let { params["api_method"] = api_method }
        connected_id?.let { params["connected_id"] = connected_id }
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        send?.let { params["send"] = send }
        retries?.let { params["retries"] = retries }
        needHistoryQ?.let { params["historyQ"] = needHistoryQ }

        val url = NewDBAction.API_GET_NOTE.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ApiNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ApiNote.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun setApiNote(
        doc_id: String,
        doc_type: String,
        api_method: String,
        ent_id: String?,
        connected_id: String?,
        doc_date: String?,
        send: String?,
        reason: String?,
        retries: String?
    ): Respone<List<ApiNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        doc_id.let { params["doc_id"] = doc_id }
        doc_type.let { params["doc_type"] = doc_type }
        api_method.let { params["api_method"] = api_method }
        connected_id?.let { params["connected_id"] = connected_id }
        ent_id?.let { params["ent_id"] = ent_id }
        doc_date?.let { params["doc_date"] = doc_date }
        send?.let { params["send"] = send }
        reason?.let { params["reason"] = reason }
        retries?.let { params["retries"] = retries }

        val url = NewDBAction.API_SET_NOTE.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ApiNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ApiNote.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getApiConfig(withOrders: String?, whatsapp: String?, last_counter:String?): Respone<ApiConfig?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        withOrders?.let { params["ordersApi"] = withOrders }
        whatsapp?.let { params["whatsapp"] = whatsapp }
        last_counter?.let { params["last_counter"] = last_counter }
        val url = NewDBAction.API_CONFIG.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ApiConfig> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ApiConfig.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }

    override suspend fun calcClientOrderPdf(
        ids: String, cofc: Boolean, id: String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ids.let { params["ids"] = ids }
        params.putIfAbsent("company_id", company_id)
        params.putIfAbsent("company", company)
        if (cofc) id?.let { params["id"] = id }
        val url =
            if (cofc) NewDBAction.CALCULATE_client_cofc_order_pdf.getURL(local,web) else NewDBAction.CALCULATE_client_order_pdf.getURL(
                local
            )
        return (httpWorker.post_file(url, params, autoCompany = true))
    }


    override suspend fun calcClientReportPdf(
        fromDate: String, toDate: String, report_type: String
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        fromDate.let { params["fromDate"] = fromDate }
        toDate.let { params["toDate"] = toDate }
        report_type.let { params["report_type"] = report_type }

        params.putIfAbsent("company_id", company_id)
        val url = NewDBAction.REPORTS_PDF.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun calcMASSdoc(
        ids: String,
        docs: String,
        force_price: String?,
        origin: String?,
        dates:String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ids.let { params["ids"] = ids }
        docs.let { params["docs"] = docs }
        force_price?.let { params["force_price"] = force_price }
        origin?.let { params["origin"] = origin }
        dates?.let { params["dates"] = dates }
        params.putIfAbsent("company", company)
        val url = NewDBAction.MASS_DOC_API.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun newAllClientTaxNotes(
        date: String, data: String, deliveryNotesValuesMap: String?,sup:Boolean
    ): Respone<List<ClientTaxNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        data.let { params["data"] = data }
        if(sup) params["sup"] = "true"
        deliveryNotesValuesMap?.let { params["delivery_notes_map"] = deliveryNotesValuesMap }
        val url = NewDBAction.NEW_clients_all_tax_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)

        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientTaxNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientTaxNote.serializer(), it)
        }
        if(sup)
            res.forEach { it.setClientProduct(false) }
        return Respone(res, networkOutput)
    }

    override suspend fun newAllCartesetClients(date:String,data: String,sup: Boolean): Respone<List<ClientCarteset>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        data.let { params["data"] = data }
        date.let { params["date"] = date }
        if(sup) params["sup"] = "true"


        val url = NewDBAction.NEW_clients_all_carteset_value.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)

        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientCarteset> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientCarteset.serializer(), it)
        }
        if(sup)
            res.forEach { it.isClient =(false) }
        return Respone(res, networkOutput)
    }
    override suspend fun updateAllClientNotes(
        date: String, data: String, agent: String, date_issued: String, new: String?, agent_id: String?
    ): Respone<List<DeliveryNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        agent.let { params["agent"] = agent }
        data.let { params["data"] = data }
        date_issued.let { params["date_issued"] = date_issued }
        new?.let { params["new"] = new }
        agent_id?.let { params["agent_id"] = agent_id }

        val url = NewDBAction.NEW_clients_all_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light, timeout = 40000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)

        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<DeliveryNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(DeliveryNote.serializer(), it)
        }
        return Respone(res, networkOutput)
    }    override suspend fun updateAllVisitNotes(
        date: String, data: String, agent: String, date_issued: String, new: String?
    ): Respone<List<VisitNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        agent.let { params["agent"] = agent }
        data.let { params["data"] = data }
        date_issued.let { params["date_issued"] = date_issued }
        new?.let { params["new"] = new }


        val url = NewDBAction.NEW_clients_all_visit_notes.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light, timeout = 40000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)

        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<VisitNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(VisitNote.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun updateAllClientOrders(
        date: String,
        data: String,
        agent: String,
        date_issued: String,
        new: String?,
        noApi: String?,
        agent_id: String?
    ): Respone<List<OrderNote>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        agent.let { params["agent"] = agent }
        data.let { params["data"] = data }
        date_issued.let { params["date_issued"] = date_issued }
        new?.let { params["new"] = new }
        noApi?.let { params["noApi"] = noApi }
        agent_id?.let { params["agent_id"] = agent_id }

        val url = NewDBAction.NEW_clients_all_orders.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light, timeout = 40000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)

        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<OrderNote> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderNote.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getOptimizedCartesetValue(
        ids: String?, toDate: String?,toDateTime:String?
    ): Respone<List<ClientCarteset>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ids?.let { params["ids"] = ids }
        toDate?.let { params["toDate"] = toDate }
        toDateTime?.let { params["toDateTime"] = toDateTime }
        val url = NewDBAction.GET_OPTIMIZED_CARTESET_VALUE.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientCarteset> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientCarteset.serializer(), it)
        }
        return Respone(res, networkOutput)
    }
    override suspend fun getSupOptimizedCartesetValue(
        ids: String?, toDate: String?,toDateTime:String?
    ): Respone<List<ClientCarteset>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ids?.let { params["ids"] = ids }
        toDate?.let { params["toDate"] = toDate }
        toDateTime?.let { params["toDateTime"] = toDateTime }
        val url = NewDBAction.GET_SUP_OPTIMIZED_CARTESET_VALUE.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientCarteset> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientCarteset.serializer(), it)
        }
        res.forEach {
            it.isClient = false
        }
        return Respone(res, networkOutput)
    }

    override suspend fun calcClientsXslx(clients: String?): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        clients?.let { params["clients"] = clients }
        params.putIfAbsent("company", company)
        val url = NewDBAction.CLIENTS_XSLX.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun calcProductsXslx(products: String?): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        products?.let { params["products"] = products }
        params.putIfAbsent("company", company)
        val url = NewDBAction.PRODUCTS_XSLX.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun userLogo(cofc: String?): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()

        val url = if (cofc != null) {
            params["company"] = company
            params["company_id"] = company_id
            params["id"] = cofc
            NewDBAction.LOGO_COFC.getURL(local,web)
        } else NewDBAction.LOGO.getURL(local,web)
        params.putIfAbsent("company", company)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun calcQuerySumPdf(
        ids: String,
        date_range: String,
        title: String,
        issuer: String,
        withOrders: String?,
        withNotes: String?,
        specific_products: String?,
        seperate: String?,
        byIds: String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ids.let { params["ids"] = ids }
        date_range.let { params["dates"] = date_range }
        title.let { params["title"] = title }
        issuer.let { params["issuer"] = issuer }
        withOrders?.let { params["withOrders"] = withOrders }
        withNotes?.let { params["withNotes"] = withNotes }
        specific_products?.let { params["specific_products"] = specific_products }
        seperate?.let { params["seperate"] = seperate }
        byIds?.let { params["byIds"] = byIds }
        val url = NewDBAction.QUERY_SUM_PDF.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun calcEDI100(
        tax_id: String,notes:String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        tax_id.let { params["tax_id"] = tax_id }
        notes?.let { params["notes"] = notes }
        val url = NewDBAction.EDI_100_CALC.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }
    override suspend fun calcbkmvdata(
        fromDate: String,toDate:String,no_cancel:String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        fromDate.let { params["fromDate"] = fromDate }
        toDate.let { params["toDate"] = toDate }
        no_cancel?.let { params["no_cancel"] = no_cancel }
        val url = NewDBAction.BKMVDATA_CALC.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun getOptimizeFlatNotes(
        byEnt: String?, byProduct: String?, fromDate: String, toDate: String
    ): Respone<List<ProductDeliveryFlat>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        byEnt?.let { params["byEnt"] = byEnt }
        byProduct?.let { params["byProduct"] = byProduct }
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }

        val url = NewDBAction.GET_OPTIMIZIED_FLAT_NOTE.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!.replace("None", "0.0")) as JsonArray
        val res: List<ProductDeliveryFlat> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ProductDeliveryFlat.serializer(), it)
        }
        return Respone(res, networkOutput)
    }


    override suspend fun newAgent(
        id: String?, user_name: String, password: String?, type: String
    ): Respone<Agent?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id?.let { params["id"] = id }
        user_name?.let { params["user_name"] = user_name }
        password?.let { params["password"] = password }
        type?.let { params["type"] = type }
        val url = NewDBAction.NEW_USER.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Agent> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Agent.serializer(), it)
        }
        return Respone(res.first(), networkOutput)
    }
    override suspend fun updateAgent(
        data: String
    ): Respone<List<Agent>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        params["data"] = data
        val url = NewDBAction.UPDATE_USERS.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<Agent> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Agent.serializer(), it)
        }
        return Respone(res, networkOutput)
    }
    override suspend fun getClientVisit(
        ids: String?, fromDate: String, toDate: String
    ): Respone<List<ClientVisitHolder>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ids?.let { params["ids"] = ids }
        fromDate.let { params["fromDate"] = fromDate }
        toDate.let { params["toDate"] = toDate }

        val url = NewDBAction.GET_CLIENT_VISIT.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<ClientVisitHolder> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientVisitHolder.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getClientLastVisitation(
        ids: String?, tax_visitation: String?,
        note_visitation: String?,
        dv_visitation: String?,
        last: String?
    ): Respone<List<ClientLastVisit>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ids?.let { params["ids"] = ids }
        tax_visitation?.let { params["tax_visitation"] = tax_visitation }
        note_visitation?.let { params["note_visitation"] = note_visitation }
        dv_visitation?.let { params["dv_visitation"] = dv_visitation }

        val url = NewDBAction.GET_LAST_CLIENT_VISITATION_OPTIMIZED.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!.replace("None", "")) as JsonArray
        val res: List<ClientLastVisit> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientLastVisit.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getClientVisitation(
        ids: String?, fromDate: String, toDate: String, tax_visitation: String?,
        note_visitation: String?,
        dv_visitation: String?
    ): Respone<List<ClientVisitation>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ids?.let { params["ids"] = ids }
        fromDate.let { params["fromDate"] = fromDate }
        toDate.let { params["toDate"] = toDate }
        tax_visitation?.let { params["tax_visitation"] = tax_visitation }
        note_visitation?.let { params["note_visitation"] = note_visitation }
        dv_visitation?.let { params["dv_visitation"] = dv_visitation }

        val url = NewDBAction.GET_CLIENT_VISITATION_OPTIMIZED.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.simple))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!.replace("None", "0.0")) as JsonArray
        val res: List<ClientVisitation> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientVisitation.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getClientsDebts(
        ids: String?, fromDate: String, toDate: String,sup: Boolean
    ): Respone<List<ClientDebt>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ids?.let { params["ids"] = ids }
        fromDate.let { params["fromDate"] = fromDate }
        toDate.let { params["toDate"] = toDate }

        val url = if(sup) NewDBAction.SUP_CLIENT_DEBTS.getURL(local,web) else NewDBAction.GET_CLIENT_DEBTS.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!.replace("None", "0.0")) as JsonArray
        val res: List<ClientDebt> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientDebt.serializer(), it)
        }
        if(sup)
            res.forEach {
                it.isClient = false
            }
        return Respone(res, networkOutput)
    }

    override suspend fun getClientPayDetails(
        ids: String?, fromDate: String?, toDate: String?, paid: String?
    ): Respone<List<PaymentData>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ids?.let { params["ids"] = ids }
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        paid?.let { params["paid"] = paid }

        val url = NewDBAction.GET_CLIENT_PAY_DETAILS.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!.replace("None", "null")) as JsonArray

        val res: List<PaymentData> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(PaymentData.serializer(), it)
        }
        return Respone(res, networkOutput)
    }

    override suspend fun getOptimizedClientNote(
        ent_id: String?,
        delivery_id: String?,
        fromDate: String?,
        toDate: String?,
        delivery_ids: String?,
        open: String?,
        byMoney: String?,
        order: String?
    ): Respone<List<Note>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ent_id?.let { params["ent_id"] = ent_id }
        delivery_id?.let { params["delivery_id"] = delivery_id }
        delivery_ids?.let { params["delivery_ids"] = delivery_ids }
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        open?.let { params["open"] = open }
        byMoney?.let { params["byMoney"] = byMoney }
        order?.let { params["order"] = order }

        val url = NewDBAction.GET_OPTIMIZED_NOTES.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray

        val res: List<Note> = if (order != null) arr.map {
            getJsonWithConfig().decodeFromJsonElement(OrderNote.serializer(), it)

        }
        else arr.map {
            getJsonWithConfig().decodeFromJsonElement(DeliveryNote.serializer(), it)

        }
        return Respone(res, networkOutput)
    }

    override suspend fun sendEmail(
        f: ByteArray, email: String, title: String, doc_type: String?, doc_id: String?
    ): NetworkOutput {
        val params = mutableMapOf<String, String>()
        params["company"] = company
        params["email"] = email
        params["title"] = title
        doc_type?.let { params["doc_type"] = doc_type }
        doc_id?.let { params["doc_id"] = doc_id }
        val url = NewDBAction.SEND_AUTO_MAIL.getURL(local,web)
        val response = (httpWorker.upload_file(url, params, f, autoCompany = true))
        val networkOutput = response.second
        return networkOutput
    }

    override suspend fun calcDriverOrderSumPdf(
        ids: String,
        date: String,
        groupType: String,
        withOrders: String?,
        withNotes: String?,
        specific_products: String?,
        title:String?,
        seperate:String?,
        byIds:String?,
        date_range:String?
    ): Respone<ByteArray?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ids.let { params["ids"] = ids }
        date.let { params["date"] = date }
        groupType.let { params["groupType"] = groupType }
        withOrders?.let { params["withOrders"] = withOrders }
        withNotes?.let { params["withNotes"] = withNotes }
        specific_products?.let { params["specific_products"] = specific_products }
        title?.let { params["title"] = title }
        seperate?.let { params["seperate"] = seperate }
        byIds?.let { params["byIds"] = byIds }
        date_range?.let { params["date_range"] = date_range }
        val url = NewDBAction.DRIVER_ORDER_SUM_PDF.getURL(local,web)
        return (httpWorker.post_file(url, params, autoCompany = true))
    }

    override suspend fun loadXlsx(
        f: ByteArray,
        load_type: String,
        date: String?,
        type: String?,
        load_doc_id: String?
    ): Respone<String?, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        params["company"] = company
        params["load_type"] = load_type

        date?.let { params["date"] = date }
        type?.let { params["type"] = type }
        load_doc_id?.let { params["load_doc_id"] = load_doc_id }
        val url = NewDBAction.LOAD_XLSX.getURL(local,web)
        val response = (httpWorker.upload_file(
            url,
            params,
            f,
            fixJsonType = FixJsonType.NONE,
            autoCompany = true,
            f_name = "test.xlsx",
            f_mime_type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ))
        val networkOutput = response.second
        return Respone(response.first?.replace("\\n", "\n")?.replace("\\t", "\t"), networkOutput)
    }

    override suspend fun sendReqForApi(
        doc_type: String,
        doc_id: String,
        api_method: String?,
    ): NetworkOutput {
        val params = mutableMapOf<String, String>()
        params["company"] = company
        params["doc_type"] = doc_type
        params["doc_id"] = doc_id
        api_method?.let { params["api_method"] = api_method }
        val url = NewDBAction.SEND_API_REQ.getURL(local,web)
        val response = (httpWorker.post(url, params, autoCompany = true))
        val networkOutput = response.second
        return networkOutput
    }

    override suspend fun reportError(
        data: String,
        agent: String,
        version: String,
    ): NetworkOutput {
        val params = mutableMapOf<String, String>()
        params["company"] = company
        params["data"] = data
        params["agent"] = agent
        params["version"] = version
        val url = NewDBAction.REPORT_ERROR.getURL(local,web)
        val response = (httpWorker.post(url, params, autoCompany = true))
        val networkOutput = response.second
        return networkOutput
    }


    override suspend fun getInventory(
        product_id: String?,
        agent_id: String?,
        date:String?,
        toDate: String?,
        inv_type:String?
    ): Respone<List<InventoryItem>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        product_id?.let { params["product_id"] = product_id }
        agent_id?.let { params["agent_id"] = agent_id }
        date?.let { params["date"] = date }
        toDate?.let { params["dateEnd"] = toDate }
        inv_type?.let { params["inv_type"] = inv_type }


        val url = NewDBAction.GET_INVENTORY.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray

        val res: List<InventoryItem> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(InventoryItem.serializer(), it)

        }

        return Respone(res, networkOutput)

    }
    override suspend fun insertInventory(
        date:String,
        data: String
    ): NetworkOutput {
        val params = mutableMapOf<String, String>()
        date.let { params["date"] = date }
        data.let { params["data"] = data }

        val url = NewDBAction.INSERT_INVENTORY.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
       return networkOutput

    }

    override suspend fun sendMsgAll(
        ids: String,
        msg: String,
        title: String,
        emails: String?,
        phones: String?,
        action_date: String?,
        doc_type: String?,
        special_arg: String?,
        external_var: String?,
        agent:String?
    ): NetworkOutput {
        val params = mutableMapOf<String, String>()
        params["company"] = company
        ids.let { params["ids"] = ids }
        msg.let { params["msg"] = msg }
        title.let { params["title"] = title }
        emails?.let { params["emails"] = emails }
        phones?.let { params["phones"] = phones }
        action_date?.let { params["action_date"] = action_date }
        doc_type?.let { params["doc_types"] = doc_type }
        special_arg?.let { params["special_arg"] = special_arg }
        external_var?.let { params["external_var"] = external_var }
        agent?.let { params["agent"] = agent }


        val url = NewDBAction.SEND_MSG_ALL.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light, timeout = 60000))
        return response.second
    }

    override suspend fun clearInventory(
        product_id: String?,
        agent_id: String?,
    ): NetworkOutput {
        val params = mutableMapOf<String, String>()
        product_id?.let { params["product_id"] = product_id }
        agent_id?.let { params["agent_id"] = agent_id }
        val url = NewDBAction.CLEAR_INVENTORY.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        return networkOutput
    }

    override suspend fun getProductAvailable(
        ids: String?,
        product_ids: String?,
        available: String?,
    ): Respone<List<Available>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ids?.let { params["ids"] = ids }
        product_ids?.let { params["product_ids"] = product_ids }
        available?.let { params["available"] = available }
        val url = NewDBAction.GET_PRODUCT_AVAILABLE.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray

        val res: List<Available> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Available.serializer(), it)

        }

        return Respone(res, networkOutput)

    }

    override suspend fun getCofcProductAvailable(
        id: String,

        ): Respone<List<Available>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        id.let { params["id"] = id }
        val url = NewDBAction.GET_COFC_PRODUCT_AVAILABLE.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray

        val res: List<Available> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Available.serializer(), it)

        }

        return Respone(res, networkOutput)

    }

    override suspend fun newAllAvailable(
        data: String,
    ): Respone<List<Available>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        params["data"] = data
        val url = NewDBAction.NEW_ALL_PRODUCT_AVAILABLE.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light, timeout = 50000))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray

        val res: List<Available> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(Available.serializer(), it)

        }

        return Respone(res, networkOutput)

    }

    override suspend fun getClientCPData(
        ids: String? ,
        product_ids: String? ,
    ): Respone<List<ClientProductData>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        ids?.let { params["ids"] = ids }
        product_ids?.let { params["product_ids"] = product_ids }
        val url = NewDBAction.GET_CLIENT_CP_DATA.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray

        val res: List<ClientProductData> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientProductData.serializer(), it)

        }

        return Respone(res, networkOutput)

    }
    override suspend fun setClientCPData(
        data: String
    ): Respone<List<ClientProductData>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        data.let { params["data"] = data }
        val url = NewDBAction.NEW_CLIENT_CP_DATA.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray

        val res: List<ClientProductData> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(ClientProductData.serializer(), it)

        }

        return Respone(res, networkOutput)

    }
    override suspend fun resetWhatsappConn(): NetworkOutput {
        val url = NewDBAction.RESET_WHATSAPP_CONN.getURL(local,web)
        val response = (httpWorker.post(url, mutableMapOf(), fixJsonType = FixJsonType.light))
        return response.second
    }
    override suspend fun hashIsraelAuth(token:String):Respone<String?,NetworkOutput>{
        val url = NewDBAction.HASH_IL_TOKEN.getURL(local,web)
        val params = mutableMapOf<String, String>()
        params["code"] = (token)
        params["grant_type"] = ("authorization_code")
        params["scope"] = ("scope")
//        params["redirect_uri"] = ("http://localhost:3001/reports/tax-israel")
        params["redirect_uri"] = ("https://solvitcompany.com/reports/tax-israel")


//        val response = httpWorker.purePostApi(url,params, basic ="ZTY1Njc3NDEyZWU5YmNmYzU4MmY0YjdmNmYyZDgwMWY6OTdmMTU5ZDdiYjgwMzk2MjJmYTlmZDI4YWQxYmVjZDQ=", withContentHeader = null )
        val response = httpWorker.purePostApi(url,params, basic ="MzU4MjhlNzNiOGI5ZWRiYzA1YzRhYzk0M2Y1NjM0ZjA6MjRhMzZhNTMzZjQ3NjA4NTBjODMxODhmYWFhMTI5NTQ=", withContentHeader = null )
        return response

    }

    override suspend fun updateHashTaxInvoice(token: String, refresh: String): NetworkOutput {
        val url = NewDBAction.HASH_IL_UPDATE.getURL(local,web)
        val params = mutableMapOf<String, String>()
        params["token"] = token
        params["refresh"] = refresh
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))

        return response.second
    }
    override suspend fun updateApiConfig(
        whatsapp: String?,
    ): Respone<ApiConfig?, NetworkOutput> {
        val url = NewDBAction.UPDATE_API_CONFIG.getURL(local,web)
        val params = mutableMapOf<String, String>()
        whatsapp?.let { params["whatsapp"] = whatsapp }
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(null, networkOutput)
        val res = getJsonWithConfig().decodeFromJsonElement(ApiConfig.serializer(), getJsonWithConfig().parseToJsonElement(response.first!!))

        return Respone(res, networkOutput)
    }
   override suspend fun hashIsraelSign(id:String,type:String):Respone<List<ClientTaxNote>,NetworkOutput> {
        val url = NewDBAction.HASH_IL_SIGN.getURL(local,web)
        val params = mutableMapOf<String, String>()
        params["id"] = id
        params["type"] = type
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light, timeout = 10000))

       val networkOutput = response.second
       if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
       val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
       val res: List<ClientTaxNote> = arr.map {
           getJsonWithConfig().decodeFromJsonElement(ClientTaxNote.serializer(), it)
       }
       return Respone(res, networkOutput)

    }

    override suspend fun getMsgTracker(
        msg_id: String?,
        fromDate: String?,
        toDate: String?,
        type: String?,
        send: String?,
        tries: String?,
        validate: String?
    ): Respone<List<MsgTracker>, NetworkOutput> {
        val params = mutableMapOf<String, String>()

        msg_id?.let { params["msg_id"] = msg_id }
        fromDate?.let { params["fromDate"] = fromDate }
        toDate?.let { params["toDate"] = toDate }
        type?.let { params["type"] = type }
        send?.let { params["send"] = send }
        tries?.let { params["tries"] = tries }
        validate?.let { params["validate"] = validate }
        val url = NewDBAction.get_msg_tracker.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.light))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)
        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<MsgTracker> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(MsgTracker.serializer(), it)
        }
        return Respone(res, networkOutput)



    }

    override suspend fun newAllMsgTracker(data: String): Respone<List<MsgTracker>, NetworkOutput> {
        val params = mutableMapOf<String, String>()
        data.let { params["data"] = data }

        val url = NewDBAction.NEW_all_msg_tracker.getURL(local,web)
        val response = (httpWorker.post(url, params, fixJsonType = FixJsonType.lightForInner))
        val networkOutput = response.second
        if (networkOutput != NetworkOutput.SUCCESS) return Respone(listOf(), networkOutput)

        val arr = getJsonWithConfig().parseToJsonElement(response.first!!) as JsonArray
        val res: List<MsgTracker> = arr.map {
            getJsonWithConfig().decodeFromJsonElement(MsgTracker.serializer(), it)
        }
        return Respone(res, networkOutput)
    }
}

