import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.features.*

actual typealias myName = kotlin.js.JsName

actual fun printPlat(s: Any?) = console.log(s)
actual fun getHttpClient(local:Boolean): HttpClient {

    return HttpClient(Js) {
        install(HttpTimeout) {
            requestTimeoutMillis = 15000
            connectTimeoutMillis = 5000
            socketTimeoutMillis = 10000
        }
    }

}
