package Structs

import DataBase.UINKDBInterface
import kotlinx.serialization.Serializable
import myName

@myName("ClientVisitation")
@Serializable
class ClientVisitation(
    val client_id: Int,

    val start_week: String,
    val end_week: String,
    val valueSum: Float,
    val returnSum: Float,
    val valueSumSec: Float,
    val returnSumSec: Float,
    val dv_visiting: Float,
    val dv_documents: Float,
    val tn_visiting: Float,
    val tn_documents: Float,
    val pay_visiting: Float,
    val pay_documents: Float,
    val visit_visiting: Float,
    val visit_documents: Float,
    val total_visit: Float = 0f,
    val comments: String = "",
) {

}

@myName("ClientLastVisit")
@Serializable
class ClientLastVisit(
    val client_id: Int,
    val date: String
) {

}

data class ClientVisitationHolder(
    val client_id: Int,
    val data: MutableList<ClientVisitation?> = mutableListOf(),
    var from: String,
    var to: String,
    val placeHolder: Boolean = false,
    var dv_visiting: Float = 0f,
    var dv_documents: Float = 0f,
    var tn_visiting: Float = 0f,
    var tn_documents: Float = 0f,
    var pay_visiting: Float = 0f,
    var pay_documents: Float = 0f,
    var visit_visiting: Float = 0f,
    var visit_documents: Float = 0f,
    var valueSum: Float = 0f,
    var returnSum: Float = 0f,
    var valueSumSec: Float = 0f,
    var returnSumSec: Float = 0f,
    var total_visit: Float = 0f,
    var comments: String =""
) {

}