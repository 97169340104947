package Structs

import DataBase.UINKDBInterface
import Utils.DatesManipulator
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive

@Serializable
data class InventoryItem(

    val agent_id: Int,

    var product_id: Int,
    var valueSum: Float,
    var returnSum: Float,
    var valueSumSec: Float,
    var returnSumSec: Float,
    val updated: String,
    var inventoryStart: Float? = null,
    var inventoryStart2: Float? = null,
    var amountDistribution: Float? = null,
    var amountDistribution2: Float? = null,
    val type: Int = 0,
    val inv_type: Int = 0,
    val date: String="",
    val real_product: Int = -1,
    var inventoryMatch:Float ?=null,
    var inventoryMatchSec:Float ?=null,
    var lastInventory:Float ?=null,
    var lastInventorySec:Float ?=null
) {
    init {

        if (real_product != -1) {
            product_id = real_product
        }
    }
    fun toJson(): JsonObject {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
        map["agent_id"] = JsonPrimitive(agent_id)
        map["product_id"] = JsonPrimitive(product_id)
        map["valueSum"] = JsonPrimitive(valueSum)
        map["returnSum"] = JsonPrimitive(returnSum)
        map["valueSumSec"] = JsonPrimitive(valueSumSec)
        map["returnSumSec"] = JsonPrimitive(returnSumSec)
        map["updated"] = JsonPrimitive(updated)
        map["date"] = JsonPrimitive(date)
        map["inv_type"] = JsonPrimitive(inv_type)
        map["type"] = JsonPrimitive(type)


        return JsonObject(map)
    }
    companion object {
        fun prepareAllItems(
            itemsMap: Map<Int, InventoryItem>,
            agent_id: Int,
            curItems: MutableList<InventoryItem>? = null
        ): List<InventoryItem> {
            val items = curItems ?: mutableListOf<InventoryItem>()
            val products = UINKDBInterface.activeDB.getAllClientProduct(true).first
            products.forEach {
                if (!itemsMap.containsKey(it.id)) {
                    items.add(
                        InventoryItem(
                            agent_id,
                            it.id,
                            0f,
                            0f,
                            0f,
                            0f,
                            DatesManipulator.dateNow(),
                            0f, 0f
                        )
                    )
                }
            }
            return items
        }

        fun orderNoteToInventoryItem(orderNote: OrderNote, full: Boolean): List<InventoryItem> {
            //for now agent

            val agent_id = orderNote.agent_id
            val curItems = orderNote.delivery_info.map {
                InventoryItem(
                    agent_id,
                    it.productId,
                    0f, 0f, 0f, 0f, orderNote.date_issued,
                    it.value,
                    it.wrapped_amount,
                    // switch to updated
                )

            }.toMutableList()

            if (full) {

                val itemsMap = curItems.map { it.product_id to it }.toMap()
                return prepareAllItems(itemsMap, agent_id, curItems)

            }
            return curItems
        }
        fun mergeList(list1: List<InventoryItem>, list2: List<InventoryItem>): List<InventoryItem> {
            val newList = list1.toMutableList()
            val map = list1.map { it.product_id to it }.toMap()
            list2.forEach {
                if (map.containsKey(it.product_id)) {
                    val item = map[it.product_id]!!
                    item.valueSum += it.valueSum
                    item.returnSum += it.returnSum
                    item.valueSumSec += it.valueSumSec
                    item.returnSumSec += it.returnSumSec
                    item.inventoryStart = it.inventoryStart
                    item.inventoryStart2 = it.inventoryStart2
                    item.amountDistribution = it.amountDistribution
                    item.amountDistribution2 = it.amountDistribution2
                } else {
                    newList.add(it)
                }
            }
            return list1
        }

    }
}
