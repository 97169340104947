package Structs

import myName


@myName("Named")
interface Named {
    @myName("getConnectedName")
    fun getConnectedName():String
    @myName("getConnectedSubName")
    fun getConnectedSubName():String? = null
    @myName("getConnectedId")
    fun getConnectedId():Int
    @myName("getActiveState")
    fun getActiveState():Int
}