package Structs


import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import myName

@myName("MsgTracker")
@Serializable
class MsgTracker(
    val msg_id: Int? = null,
    val company_id: Int? = null,
    val type: Int? = null,
    val title: String? = null,
    val msg: String? = null,
    val send: Int? = null,
    val err: String? = null,
    val tries: Int? = null,
    val validate: Int? = null,
    val date_send: String? = null,
    val date_updated: String? = null
) {
    fun toJson(): JsonObject? {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
        if (msg_id != null)
            map["msg_id"] = JsonPrimitive(msg_id.toString())
        if (company_id != null)
            map["company_id"] = JsonPrimitive(company_id.toString())
        if (type != null)
            map["type"] = JsonPrimitive(type.toString())
        if (title != null)
            map["title"] = JsonPrimitive(title)
        if (msg != null)
            map["msg"] = JsonPrimitive(msg)
        if (send != null)
            map["send"] = JsonPrimitive(send.toString())
        if (err != null)
            map["err"] = JsonPrimitive(err)
        if (tries != null)
            map["tries"] = JsonPrimitive(tries.toString())
        if (validate != null)
            map["validate"] = JsonPrimitive(validate.toString())
        if (date_send != null)
            map["date_send"] = JsonPrimitive(date_send)
        if (date_updated != null)
            map["date_updated"] = JsonPrimitive(date_updated)
        if(map.isEmpty()) return null
        return JsonObject(map)
    }
}