package Structs

import DataBase.UINKDBInterface
import Utils.VisitorState
import Utils.getJsonWithConfig
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.jsonPrimitive
import myName

@Serializable
data class VisitNote(
    val id: Int,
    val client_id: Int,
    val document_date: String,
    val date_issued: String,
    val lon_lat: String,
    val states: String,
    val comment: String,
    val agent: String,
    val active: Int,
    val value:Float,
    val payment: Float? = null,
    val order_id: Int? = null,
    var signer: String? = null,
    var digi_sign: Int? = null,
    var digi_photo: Int? = null,
    var date_sign: String? = null,
    val driver_id: Int? = null,
) : InformationBase() {
    val visitorState: List<VisitorState> = createFromJson(states)

    override fun getConnectedDate(): String {
        return document_date
    }

    override fun getConnectedPayment(): Float {
        return payment ?: 0f
    }
    @myName("getDriver")
    fun getDriver(): Agent? {
        return driver_id?.let {
            UINKDBInterface.activeDB.getAgentBy(it, null, driver = true).first
        }
    }
    override fun signData(): NoteSignHolder? = if (digi_sign == 1 || digi_photo == 1) NoteSignHolder(
        id,
        12,
        digi_sign ?: 0,
        digi_photo ?: 0,
        signer ?: "",
        date_sign ?: ""
    ) else UINKDBInterface.activeDB.getSignOffline(id, doc_type = 12).first

    override fun digiPhoto(): Boolean =
        if (digi_photo == 1) true else UINKDBInterface.activeDB.getSignOffline(id, doc_type = 12).first?.digi_photo == 1

    @myName("getStatesStr")
    fun getStatesStr(): List<String> {
        return visitorState.map { it.stateName() }
    }

    override fun isActive(): Boolean {
        return active >= 1
    }

    override fun isUsed(): Boolean {
        return active == 2
    }

    override fun getConnectedDocType(): Int {
        return 12
    }

    override fun getConnectedId(): Int {
        return id
    }

    override fun getConnectedEntId(): Int {
        return client_id
    }


    @myName("getConnectedProduct")
    fun getConnectedProduct(): Product? {
        return if (order_id != null)
            UINKDBInterface.activeDB.getClientOrderNotes(order_id = order_id).first.firstOrNull()?.delivery_info?.firstOrNull()?.getProduct()
        else null
    }
    override fun getConnectedValue(): Float {
        return if (order_id != null)
            UINKDBInterface.activeDB.getClientOrderNotes(order_id = order_id).first.firstOrNull()?.value ?: 0f
        else 0f
    }

    override fun getAmounts(): Float {
        return if (order_id != null)
            UINKDBInterface.activeDB.getClientOrderNotes(order_id = order_id).first.firstOrNull()?.getAmounts() ?: 0f
        else 0f
    }

    override fun getConnectedAgent(): String {
        return agent
    }

    override fun getConnectedName(): String {
        return ""
    }

    override fun toString(): String {
        return document_date.toString().split(" ").reversed().joinToString(" ")
    }

    fun setSign(NoteSignHolder: NoteSignHolder) {
        digi_sign = NoteSignHolder.digi_sign
        digi_photo = NoteSignHolder.digi_photo
        signer = NoteSignHolder.signer
        date_sign = NoteSignHolder.date
    }

    companion object {
        fun createFromJson(js: String): List<VisitorState> {

            val arr = getJsonWithConfig().parseToJsonElement((js).replace("\\'", "'")) as JsonArray
            val res: List<VisitorState> = arr.map {
                VisitorState.GENERAL.convert(it.jsonPrimitive.content.toInt())
            }
            return res
        }

        fun toJsonArrayString(data: List<VisitorState>): String {
            return "[${data.joinToString(",")}]"

        }
    }
}
