package Structs

import Utils.PayMethod

data class EmptyDoc(
    val id: Int = -1,
    val client_id: Int = -1,
    val document_date: String = "",
    val value: Float = 0f,
    val doc_type:Int = -1
) : InformationBase() {

    override fun getConnectedDate(): String {
        return document_date
    }

    override fun getConnectedDocType(): Int {
        return doc_type
    }
    override fun getConnectedId(): Int {
        return id
    }

    override fun getConnectedEntId(): Int {
        return client_id
    }

    override fun getConnectedValue(): Float {
        return value
    }

    override fun getConnectedName(): String {
        return ""
    }

    override fun toString(): String {
        return document_date.toString().split(" ").reversed().joinToString(" ")
    }


}
