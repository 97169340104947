package Utils

class BoolValueStore
/**
 * Construct a the int store.
 *
 * @param initialValue The initial value.
 */(
    /**
     * The current value.
     */
    val id:Int,
    var mValue: Boolean
) {
    /**
     * The listener (you might want turn this into an array to support many
     * listeners)
     */
    private var mListener: BoolValueStoreListener? = null

    /**
     * Sets a listener on the store. The listener will be modified when the
     * value changes.
     *
     * @param listener The [IntValueStoreListener].
     */
    fun setListener(listener: BoolValueStoreListener?) {
        mListener = listener
    }

    private var callback: ((Boolean) -> Unit)? = null
    fun setCallbackListener(callback: ((Boolean) -> Unit)) {
        this.callback = callback
    }

    /**
     * Get the current value.
     *
     * @return The current int value.
     */
    /**
     * Set a new int value.
     *
     * @param newValue The new value.
     */
    var value: Boolean
        get() = mValue
        set(newValue) {
            mValue = newValue
            if (mListener != null) {
                mListener!!.onValueChanged(mValue)
            }
            callback?.let { callback!!(mValue) }
        }

    /**
     * Callbacks by [IntValueModel].
     */
    interface BoolValueStoreListener {
        /**
         * Called when the value of the int changes.
         *
         * @param newValue The new value.
         */
        fun onValueChanged(newValue: Boolean)
    }
}