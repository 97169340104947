package Structs

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import myName

@Serializable
class Agent(
    var user_password: String? = null,
    var user_name: String,
    val manager: Int = 0,
    val protected: Int = 0,
    val company_id: String = "",
    val company: String = "",
    val price_auth: Int = 0,
    val change_auth: Int = 0,
    val all_doc_auth: Int = 0,
    val orders_auth: Int = 0,
    val debts_auth: Int = 0,
    val suppliers_auth: Int = 0,
    val amounts_sum_auth: Int = 0,
    val internal_details: Int = 0,
    val returns: Int = 0,
    val is_agent: Int = 0, // 1 = line
    val is_collector: Int = 0, // 1 = collector
    val is_driver: Int = 0, // 1 = driver
    val position: Int = 0, // 1 = priority
    val note_cancel: Int = 0,
    val can_create: Int = 1,
    val visit: Int = 0,
    val id: Int = -1,
    val print: Int = 0, // ביט 0/1 אומר אם להדפיס ישר לPDF . ביט שני אומר אם שולטים במחירים בהדפסה. ביט 3 אומר 1 יש מחירים תמיד 0 אין
    val increase_id: Int = 0,
    val version: String = "2.1",
    val car: String = "",
    val start_point: String? = null,
    val comment: String? = null,
    val active: Int = 1,
    var block_obligo: Int = 1,
    var inventory: Int = 1,
    val end_point: String? = null //"35.46375895995361,32.785039769284985",

) : Named {
    val auth: Auth = Auth(
        price_auth = price_auth >= 1,
        change_auth = change_auth >= 1,
        all_doc_auth = all_doc_auth >= 1,
        orders_auth = orders_auth >= 1,
        debts_auth = debts_auth == 1,
        suppliers_auth = suppliers_auth == 1,
        amounts_sum_auth = amounts_sum_auth >= 1,
        internal_details = internal_details == 1,
        returns = returns >= 1,
        note_cancel = note_cancel >= 1,
        visit = visit >= 1,
        create = can_create == 1
    )

    override fun getActiveState(): Int {
        return 1
    }

    fun forcePricePrint(): Boolean? {
        val controlPricePrint = (print / 2) % 2 == 1
        val forcePrice = (print / 4) % 2 == 1
        if (controlPricePrint)
            return forcePrice
        return null

    }

    override fun getConnectedName(): String {
        return user_name
    }

    override fun getConnectedId(): Int {
        return -1
    }


    override fun toString(): String {
        return user_name.toString()
    }

    fun isLineManager(): Boolean {
        return manager >= 1
    }

    fun isTopManager(): Boolean {
        return manager == 2
    }

    @myName("isAgent")
    fun isAgent(): Boolean {
        return is_agent == 1
    }
    fun toJson(): JsonObject {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()

        map["id"] = JsonPrimitive(id.toString())
        map["user_name"] = JsonPrimitive(user_name)
        map["user_password"] = JsonPrimitive(user_password ?: "")
        map["comment"] = JsonPrimitive(comment ?: "")

        return JsonObject(map)
    }
    @myName("getDescription")
    fun getDescription(): String {
        return when {
            isAgent() -> "סוכן"
            isDriver() -> "נהג"
            isCollector() -> "מלקט"
            else -> ""
        }
    }

    fun isProtected(): Boolean {
        return protected == 1
    }

    @myName("isDriver")
    fun isDriver(): Boolean {
        return is_driver >= 1
    }

    @myName("isLineDriver")
    fun isLineDriver(): Boolean {
        return is_driver >= 2
    }
    @myName("isLineCollector")
    fun isLineCollector(): Boolean {
        return is_collector == 2
    }

    @myName("isCollector")
    fun isCollector(): Boolean {
        return is_collector >= 1
    }
}