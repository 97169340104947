package Utils

import myName

@myName("ByteArrayValueStore")
class ByteArrayValueStore
/**
 * Construct a the int store.
 *
 * @param initialValue The initial value.
 */(
    /**
     * The current value.
     */
    val id: Int,
    var mValue: ByteArray
) {
    /**
     * The listener (you might want turn this into an array to support many
     * listeners)
     */
    private var mListener: ByteValueStoreListener? = null
    private var callback: ((ByteArray) -> Unit)? = null

    /**
     * Sets a listener on the store. The listener will be modified when the
     * value changes.
     *
     * @param listener The [IntValueStoreListener].
     */
    fun setListener(listener: ByteValueStoreListener?) {
        mListener = listener
    }
    /**
     * Get the current value.
     *
     * @return The current int value.
     */
    /**
     * Set a new int value.
     *
     * @param newValue The new value.
     */
    @myName("setCallbackListener")
    fun setCallbackListener(callback: ((ByteArray) -> Unit)) {
        this.callback = callback
    }

    var value: ByteArray
        get() = mValue
        set(newValue) {
            mValue = newValue
            if (mListener != null) {
                mListener!!.onValueChanged(mValue)
            }
            callback?.let { callback!!(mValue) }
        }

    /**
     * Callbacks by [IntValueModel].
     */
    interface ByteValueStoreListener {
        /**
         * Called when the value of the int changes.
         *
         * @param newValue The new value.
         */
        fun onValueChanged(newValue: ByteArray)
    }
}