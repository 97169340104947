package Structs


import Utils.InverseBanks
import Utils.PayMethod
import Utils.fixJson
import Utils.getJsonWithConfig
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import myName
import kotlin.js.JsName

@myName("PaymentData")
@Serializable
data class PaymentData(
    val value: Float,
    val pay_method: Int = 0,
    val bank_id: String = "",
    val bank_branch: String = "",
    val bank_number: String = "",
    val check_number: String = "",
    val check_date: String = "",
    val paid:Int?=null,
    val paid_date:String?=null,
    val paid_details:String?=null
) {
    var payMethod: PayMethod = pay_method.let { PayMethod.CASH.convert(it) } ?: PayMethod.CASH
    var id:Int?=null
    var client_id:Int?=null
    var document_date:String?=null
    var date:String?=null
    var agent:String?=null

    @myName("bankName")
    fun bankName():String{
        return if( bank_id.isNotEmpty())
            InverseBanks[bank_id.toInt()]?:""
        else
            ""
    }
    fun toJson(): JsonObject {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
        map["bank_branch"] = JsonPrimitive(bank_branch)
        map["value"] = JsonPrimitive(value.toString())
        map["bank_id"] = JsonPrimitive(bank_id)
        map["bank_number"] = JsonPrimitive(bank_number)
        map["check_number"] = JsonPrimitive(check_number)
        map["check_date"] = JsonPrimitive(check_date)
        map["pay_method"] = JsonPrimitive(pay_method.toString())

        paid?.let { map["paid"] = JsonPrimitive(paid.toString())}
        paid_date?.let { map["paid_date"] = JsonPrimitive(paid_date.toString())}
        paid_details?.let { map["paid_details"] = JsonPrimitive(paid_details.toString())}

        return JsonObject(map)
    }

    companion object {
        fun createFromJson(js: String): List<PaymentData> {

            val arr = getJsonWithConfig().parseToJsonElement((js).replace("\\'", "'")) as JsonArray
            val res: List<PaymentData> = arr.map {
                getJsonWithConfig().decodeFromJsonElement(PaymentData.serializer(), it)
            }
            return res
        }

        fun toJsonArrayString(payments: List<PaymentData>): String {
            return if (payments.isEmpty()) {
                "[]"
            } else {
                val payload = payments.map {
                    Json.encodeToJsonElement(JsonObject.serializer(), it.toJson()).toString()
                }.joinToString(",")
                return "[$payload]"
            }

        }

        fun toJsonArrayElement(payments: List<PaymentData>):JsonArray  {
            return if (payments.isEmpty()) {
                JsonArray(emptyList())
            } else {
                JsonArray(payments.map {
                    Json.encodeToJsonElement(JsonObject.serializer(), it.toJson())
                })
            }

        }
    }


}
