package Structs

import kotlinx.serialization.Serializable
import myName

@myName("Auth")
@Serializable
data class Auth(
    val price_auth: Boolean = false,
    val change_auth: Boolean = false,
    val all_doc_auth: Boolean = false,
    val orders_auth: Boolean = false,
    val debts_auth: Boolean = false,
    val suppliers_auth: Boolean = false,
    val amounts_sum_auth: Boolean = false,
    val internal_details:Boolean = false,
    val returns:Boolean = false,
    val note_cancel:Boolean = false,
    val visit:Boolean = false,
    val create:Boolean = false
)
