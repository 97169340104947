package Utils

import Structs.AmountsDataHolder
import Structs.EmptyEnt
import Structs.Named
import myName

@myName("oVrHolder")
data class oVrHolder(var order: Float = 0f, var real: Float = 0f) {
    companion object {
        @myName("buildOrdered")
        fun buildOrdered(
            ordered: List<Pair<Named, AmountsDataHolder>>,
            real: List<Pair<Named, AmountsDataHolder>>
        ): MutableList<Pair<Named, oVrHolder>> {
            val orderVReal: MutableMap<Int, oVrHolder> = mutableMapOf()
            val namedVId: MutableMap<Int, Named> = mutableMapOf()
            val sumAll = oVrHolder()
            val realMap = real.groupBy { it.first.getConnectedId() }

            ordered.forEach {
                val real_holder = realMap[it.first.getConnectedId()]
                real_holder?.let { h ->
                    if (h.size == 1) {
                        namedVId[it.first.getConnectedId()] = h.first().first
                    } else {
                        namedVId[it.first.getConnectedId()] = it.first
                    }
                } ?: true.let { b ->
                    namedVId[it.first.getConnectedId()] = it.first
                }
                val ins = oVrHolder(it.second.value)
                val realAmount = realMap[it.first.getConnectedId()]?.first()?.second?.value ?: 0f
                ins.real = realAmount
                orderVReal[it.first.getConnectedId()] = ins

                sumAll.order += it.second.value

            }
            real.forEach {
                val real_holder = realMap[it.first.getConnectedId()]
                if (it.first.getConnectedId() !in namedVId) {
                    real_holder?.let { h ->
                        if (h.size == 1) {
                            namedVId[it.first.getConnectedId()] = h.first().first
                        } else {
                            namedVId[it.first.getConnectedId()] = it.first
                        }
                    } ?: true.let { b ->
                        namedVId[it.first.getConnectedId()] = it.first
                    }
                }

                if (it.first.getConnectedId() !in orderVReal) {
                    val ins = oVrHolder(real = it.second.value)
                    orderVReal[it.first.getConnectedId()] = ins
                }
                sumAll.real += it.second.value
            }


            val mlist = orderVReal.toList().map { Pair(namedVId[it.first]!!, it.second) }.sortedByDescending { it.second.real }.toMutableList()
            mlist.add(0, Pair(EmptyEnt(name = "סה''כ", id = -1), sumAll))
            return mlist
        }
    }
}
