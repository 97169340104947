package DataBase

enum class DBQuery {
    INTERNET_CONNECTION,
    ID_ISSUE,
    NAME_ISSUE,
    INTERNET_GENERIC_ERROR,
    FAILED,
    FAILED_UPDATE_BRANCH,
    ERROR_DUPLICATE,
    ERROR_EMPTY_NEW,
    ARG_ISSUE,
    LOGIN_FAILED,
    SUCCESS
}