package Structs

import DataBase.UINKDBInterface
import Utils.getJsonWithConfig
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonObject
import myName

@Serializable
data class CollectionObject(
    val id: Int,
    val box: Float,

    ) {
    @myName("getColName")
    fun getColName(): String?{
        return UINKDBInterface.activeDB.getAgentBy(id,null,true).first?.user_name
    }
    companion object {
        @myName("createFromJson")
        fun createFromJson(js: String): Map<Int, CollectionObject> {
            val mapper = (getJsonWithConfig().parseToJsonElement((js).replace("\\'", "'")) as JsonObject)
            return mapper.keys.map {
                val obj = mapper[it] as JsonObject
                Pair(it.toInt(), getJsonWithConfig().decodeFromJsonElement(CollectionObject.serializer(), obj))
            }.toMap()
        }
        fun toJson(map: Map<Int, CollectionObject>): String {

            return Json.encodeToString(MapSerializer(Int.serializer(), CollectionObject.serializer()), map)

        }

    }

}
