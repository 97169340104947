package Structs

data class ProductStatistics(
    val product_id: Int,
    val amount: Float = 0f,
    val returns: Float = 0f,
    val amount_second: Float = 0f,
    val returns_second: Float = 0f,
    val instances: Int=0,
    val avg: Float=0f,
    val std: Float=-1f,
    val stdReturns: Float=-1f,
    val counter:Float=1f
) {

}
