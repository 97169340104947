package Utils

enum class PrintState(val state: Int = 1) {
    NONE(0),
    BARCODE(1),
    BARCODE_PRICE(5),
    BARCODE_RETURN(3),
    BARCODE_RETURN_PRICE(7),
    RETURN(2),
    RETURN_PRICE(6),
    PRICE(4),
    VALUE(14),
    VALUE_ONLY(8),
    VALUE_NO_PRICE_BARCODE(9),
    VALUE_NO_PRICE_RETURN(10),
    VALUE_NO_PRICE(11),
    VALUE_NO_RETURNS(12),
    VALUE_NO_RETURNS_BARCODE(13),
    VALUE_RETURNS_BARCODE(15);


    fun convert(ps: Int): PrintState {
        return when (ps) {
            1 -> {
                BARCODE
            }

            2 -> {
                RETURN
            }

            3 -> {
                BARCODE_RETURN
            }

            4 -> {
                PRICE
            }

            5 -> {
                BARCODE_PRICE
            }

            6 -> {
                RETURN_PRICE
            }

            7 -> {
                BARCODE_RETURN_PRICE
            }

            14 -> {
                VALUE
            }

            8 -> {
                VALUE_ONLY
            }

            9 -> {
                VALUE_NO_PRICE_BARCODE
            }

            10 -> {
                VALUE_NO_PRICE_RETURN
            }

            11 -> {
                VALUE_NO_PRICE
            }

            12 -> {
                VALUE_NO_RETURNS
            }

            13 -> {
                VALUE_NO_RETURNS_BARCODE
            }

            15 -> {
                VALUE_RETURNS_BARCODE
            }

            else -> {
                NONE
            }
        }
    }

    fun hasPrice(): Boolean {
        return (state / 4) % 2 == 1
    }

    fun hasBarcode(): Boolean {
        return (state % 2 == 1)
    }

    fun hasReturns(): Boolean {
        return (state / 2) % 2 == 1
    }

    fun hasValue(): Boolean {
        return (state / 8) % 2 == 1
    }

    fun dropReturns(): PrintState {
        return convert(state and (2).inv())
    }
    fun addReturns(): PrintState {
        return convert(state or (2))
    }
    fun addValue(): PrintState {
        return convert(state or (8))
    }
    fun dropPrice(): PrintState {
        return convert(state and (4).inv())
    }
    fun dropBarcode(): PrintState {
        return convert(state and (1).inv())
    }
    fun addrice(): PrintState {
        return convert(state or (4))
    }
    fun toValue(priced: Boolean? = null): PrintState {
        val hprice = priced == true || (hasPrice() && priced != false)
        return when {
            priced == false -> this.dropPrice ()
            hprice && hasBarcode() -> VALUE_NO_RETURNS_BARCODE
            hprice && hasReturns() && !hasBarcode() -> VALUE
            hprice && !hasReturns() && !hasBarcode() -> VALUE_NO_RETURNS
            else -> this
        }
    }

    fun calcSize(): Int {
        var i = 0
        var cur_state = state
        while (cur_state > 0) {
            i += cur_state % 2
            cur_state = cur_state / 2
        }
        return i
    }

    fun size(): Int {
        return when (this) {
            BARCODE -> {
                return 3
            }

            RETURN -> {
                return 3
            }

            BARCODE_RETURN -> {
                return 4
            }

            PRICE -> {
                return 3
            }

            BARCODE_PRICE -> {
                return 4
            }

            RETURN_PRICE -> {
                return 4
            }

            BARCODE_RETURN_PRICE -> {
                return 5
            }

            else -> {
                return 2
            }
        }

    }


}