package Utils


import kotlinx.datetime.LocalDate
import kotlinx.datetime.daysUntil
import printPlat
import kotlin.collections.HashMap
import kotlin.math.abs

class DatesHolder {
    val ranges: MutableList<DatesRangeHolder> = mutableListOf()

    constructor(dates: List<String>) {
        buildDates(dates)
    }

    constructor(dates: String) {
        buildDates(dates.split(","))
    }

    fun getFirst(): String {
        return ranges.first().start_date
    }

    fun getLast(): String {
        return ranges.maxByOrNull { it.end_date }!!.end_date
    }

    fun contains(date: String): Boolean {
        ranges.forEach {
            if (date >= it.start_date && date <= it.end_date)
                return true
        }
        return false
    }

    fun isOneDate(): Boolean {
        ranges.forEach {
            if (it.start_date != it.end_date)
                return false
        }
        return ranges.size == 1
    }

    fun isSameMonth(): Boolean {
        var month: String? = null
        ranges.forEach {
            val curMonth = it.start_date.split("-")[1]
            val curMonth2 = it.end_date.split("-")[1]
            if (curMonth!!.compareTo(curMonth2) != 0) {
                return false
            }
            if (month == null) {
                month = curMonth
            } else if (month!!.compareTo(curMonth) != 0) {
                return false
            }

        }
        return true
    }

    fun isSameYear(): Boolean {
        var year: String? = null
        ranges.forEach {
            val curYear = it.start_date.split("-")[0]
            if (year == null) {
                year = curYear
            } else if (year!!.compareTo(curYear) != 0) {
                return false
            }

        }
        return true
    }
    fun toStringFirstLastRange(): String {
        val f = ranges.first().start_date
        val l = ranges.last().end_date
        return if (f == l)
            (f)
        else
            "${(f)}..${(l)}"
    }
    fun toStringIsraelFirstLastRange(): String {
        val f = ranges.first().start_date
        val l = ranges.last().end_date
        return if (f == l)
            DatesManipulator.dateIsrael(f)
        else
            "${DatesManipulator.dateIsrael(f)}..${DatesManipulator.dateIsrael(l)}"
    }

    fun getYear(): String {
        var yearMap = HashMap<String, Int>()
        ranges.forEach {
            val curYear = it.start_date.split("-")[0]
            if (!yearMap.containsKey(curYear)) {
                yearMap[curYear] = 0
            }
            val value = yearMap[curYear]!!
            yearMap[curYear] = value + 1

        }
        return yearMap.maxByOrNull { it.value }!!.key
    }

    fun toStringIsrael(): String {
        return ranges.map { it.toStringIsrael() }.joinToString(",")
    }

    override fun toString(): String {
        return ranges.map { it.toString() }.joinToString(",")
    }

    fun buildDates(dates: List<String>) {
        //assuming format yyyy-mm-dd
        val ranges_given = dates.filter { it.contains("..") }
        val dates = dates.filter { !it.contains("..") && it.compareTo("") != 0 }.distinct()


//        my_data.setTimeZone(TimeZone.getTimeZone("GMT+3"))

        ranges_given.forEach {
            val d = it.split("..")
            val first = DatesManipulator.analyzeTextAsDate(d[0])
            val second = DatesManipulator.analyzeTextAsDate(d[1])
            val r = DatesRangeHolder(first, second)
            ranges.add(r)
        }
        if (dates.isNotEmpty()) {
            val sortedDates = dates.sorted().map {
                LocalDate.parse(DatesManipulator.analyzeTextAsDate(it.split(" ")[0]))
            }
            var i: Int = 1
            var start_date = sortedDates[0]
            var end_date = sortedDates[0]
            var finished: Boolean = false
            while (i < sortedDates.size) {
                var cur_date = sortedDates[i]

                if (!nextInSeq(end_date, cur_date)) {

                    val r = DatesRangeHolder(
                        DatesManipulator._localDateToString(start_date), DatesManipulator._localDateToString(end_date),
                    )
                    ranges.add(r)
                    if (i < sortedDates.size)
                        start_date = cur_date
                    else {
                        finished = true
                        break
                    }
                }
                end_date = cur_date
                i += 1
            }
            if (!finished) {
                val r = DatesRangeHolder(
                    DatesManipulator._localDateToString(start_date), DatesManipulator._localDateToString(end_date),
                )
                ranges.add(r)
            }
        }
        shrink()
        ranges.sortBy { it.start_date }

    }

    fun shrink() {
        val newRange = ranges.filter { it.end_date != it.start_date }.toMutableList()
        newRange.addAll(ranges.filter { it.end_date == it.start_date }.filter { dr ->
            !newRange.any {
                it.start_date <= dr.start_date && it.end_date >= dr.start_date
            }
        })
        ranges.clear()
        ranges.addAll(newRange)

    }

    fun nextInSeq(cal1: LocalDate, cal2: LocalDate): Boolean {
        val sameYear = cal1.year == cal2.year
        val quiteSameDay = abs(cal1.daysUntil(cal2)) <= 1
        return sameYear && quiteSameDay

    }

}