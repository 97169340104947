package Structs

import myName

class OrderStatistics(
    val dateFrom: String,
    val dateTo:String,
    val id: Int,
    var lastNotes: Map<Pair<Int,Int>,List<ProductDeliveryFlat>> = mutableMapOf(),
    var daily: Map<Pair<Int,Int>,ProductStatistics> = mutableMapOf(),
    var weekly: Map<Int,ProductStatistics> = mutableMapOf(),
    var monthly: Map<Int,ProductStatistics> = mutableMapOf(),
    var total: Map<Int,ProductStatistics> = mutableMapOf(),
) {
    // can be taxnotes or delivery notes
    @myName("getLast")
    fun getLast(id:Int,day:Int):List<ProductDeliveryFlat>?{
        return lastNotes[Pair(id,day)]
    }
    @myName("getDaily")
    fun getDaily(id:Int,day:Int):ProductStatistics?{
        return daily[Pair(id,day)]
    }
    @myName("getWeekly")
    fun getWeekly(id:Int):ProductStatistics?{
        return weekly[id]
    }
    @myName("getMonthly")
    fun getMonthly(id:Int):ProductStatistics?{
        return monthly[id]
    }
    @myName("getTotal")
    fun getTotal(id:Int):ProductStatistics?{
        return total[id]
    }
}