package Structs

import DataBase.UINKDBInterface
import Utils.DocType
import Utils.PayMethod
import Utils.fixJsonInternal
import kotlinx.serialization.Serializable
import myName
import kotlin.math.abs
import kotlin.math.min

@myName("ClientLightPay")
@Serializable
sealed class ClientLightPay(


) : InformationBase() {
    @myName("id")
    abstract val id: Int

    @myName("client_id")
    abstract val client_id: Int

    @myName("value")
    abstract val value: Float
    abstract val date: String

    @myName("document_date")
    abstract val document_date: String
    abstract val action_time: String
    abstract val ref_id: String
    var isClient = true

    override fun getConnectedDocType(): Int {
        return if (isClient) DocType.PAY_NOTE.state else DocType.SUPPLIER_PAYMENT.state
    }

    override fun getConnectedDate(): String {
        return document_date
    }

    override fun getConnectedId(): Int {
        return id
    }

    override fun getConnectedEntId(): Int {
        return client_id
    }

    override fun getConnectedValue(): Float {
        return value
    }

    override fun getConnectedName(): String {
        return UINKDBInterface.activeDB.getClient(client_id).first?.let {
            if (it.branch != -1 && it.master != -1) it.getName(
                document_date
            ) else it.getBusinessName(document_date)
        } ?: ""
    }

    override fun toString(): String {
        return document_date.toString().split(" ").reversed().joinToString(" ")
    }

    override fun getConnectedPayment(): Float {
        return value
    }

    @myName("getClient")
    fun getClient(): Client {
        return UINKDBInterface.activeDB.getClient(client_id).first!!
    }
    @myName("getSupplier")
    fun getSupplier(): Supplier {
        return UINKDBInterface.activeDB.getSupplier(client_id).first!!
    }
}

@myName("ClientLightPayImp")
@Serializable
class ClientLightPayImp(
    override val id: Int,
    override val client_id: Int,
    override val value: Float,
    override val date: String,
    override val document_date: String, override val action_time: String = "", override val ref_id: String=""
) : ClientLightPay() {

}

@myName("ClientPay")
@Serializable
data class ClientPay(
    override val id: Int,
    override val client_id: Int,
    override val value: Float,
    override val date: String,
    override val document_date: String,
    val details: String,
    val payment_data_raw: String,
    var tax_note_id: String,
    val active: Int,
    var external_details: String = "",
    val agent: String = "",
    val driver_id: Int? = null,
    override val ref_id: String="",

    override val action_time: String = "",
) : ClientLightPay() {
    val paymentsData: List<PaymentData> = PaymentData.createFromJson(payment_data_raw)
    val ClientStaticData = Structs.ClientStaticData.createFromJson(details)
    var taxNotes: MutableList<Int> = mutableListOf()
    var taxNotesAmounts: MutableList<Float?> = mutableListOf()
    var taxNotesPays: MutableList<Float?> = mutableListOf()



    init {
        external_details = fixJsonInternal(external_details)
        if (!tax_note_id.isNullOrEmpty()) {
            for (i in tax_note_id.split(",")) {
                val splitted = i.split(":")
                val first = splitted[0].toInt()
                var second: Float? = null
                var third: Float? = null
                if (splitted.size > 1) {
                    second = splitted[1].toFloat()
                }
                if (splitted.size > 2) {
                    third = splitted[2].toFloat()
                }
                taxNotes.add(first)
                taxNotesAmounts.add(second)
                taxNotesPays.add(third)
            }

        }
    }

    @myName("getDriver")
    fun getDriver(): Agent? {
        return driver_id?.let {
            UINKDBInterface.activeDB.getAgentBy(it, null, driver = true).first
        }
    }

    fun getInverseTaxNoteTriple(toMap: Map<Int, ClientTaxNote>?=null): String {
        return taxNotes.zip(taxNotesAmounts.zip(taxNotesPays)).map {
            val tn = toMap?.get(it.first)
            if (it.second.first==null || it.second.second == null) {
                "${it.first}"
            } else
                "${it.first}:${min((tn?.value_left?:it.second.first!!) + it.second.second!!,(tn?.total_value?: Float.MAX_VALUE))}:${-it.second.second!!}"

        }.joinToString(",")
    }

    override fun getConnectedAgent(): String {
        return agent
    }

    override fun isActive(): Boolean {
        return active == 1
    }

    @myName("getAgentId")
    fun getAgentId(): Int? {
        return UINKDBInterface.activeDB.getAgentBy(name = agent, id = null).first?.id
    }

    override fun getConnectedName(): String {
        return if (isClient) UINKDBInterface.activeDB.getClient(client_id).first?.let {
            if (it.branch != -1 && it.master != -1) it.getName(
                document_date
            ) else ClientStaticData.business_name
        } ?: ClientStaticData.business_name else ClientStaticData.business_name
    }

    fun getSumByType(p: PayMethod): Float {
        return paymentsData.filter { it.payMethod == p }
            .sumByDouble { it.value.toDouble() }.toFloat()
    }

    override fun getComments(): String {
        return external_details
    }

    fun getSumListByType(p: PayMethod): List<Float> {
        return paymentsData.filter { it.payMethod == p }.map {
            it.value
        }
    }

    fun getAllCheckDates(): List<String> {
        return paymentsData.filter { it.payMethod == PayMethod.CHECK }.map { it.check_date }
    }

}
