package Utils

import kotlinx.serialization.Serializable
import myName

@Serializable
@myName("StoreNextConfig")
data class StoreNextConfig(
    val host: String,
    val port: Int,
    val userName: String,
    val password: String,
    val readPath: String,
    val writePath: String,
    val returns: String="",
    var token:String=""
)
