package Structs

import DataBase.UINKDBInterface
import kotlinx.serialization.Serializable
import myName

@myName("GlulStruct")
@Serializable
class GlulStruct(


    val totalValueSum: Float,
    val taxNotes:List<ClientTaxNote>,
    val numOfOpen: Int,



    val valueSum1: Float?=null,
    val openAmount1: Int?=null,
    val valueSum2: Float?=null,
    val openAmount2: Int?=null,
    val valueSum3: Float?=null,
    val openAmount3: Int?=null,
    val valueSum4: Float?=null,
    val openAmount4: Int?=null,
    val restTotalValueSum: Float,
    val restNumOpen:Int


) {

}

