package Utils

class DatesRangeHolder {
    val start_date: String
    val end_date: String

    constructor(start_date: String, end_date: String) {
        val split1 = start_date.split("-").toMutableList()
        val split2 = end_date.split("-").toMutableList()
        if (split1[1].length == 1) {
            split1[1] = "0${split1[1]}"
        }
        if (split1[2].length == 1) {
            split1[2] = "0${split1[2]}"
        }
        if (split2[1].length == 1) {
            split2[1] = "0${split2[1]}"
        }
        if (split2[2].length == 1) {
            split2[2] = "0${split2[2]}"
        }
        this.start_date = split1.joinToString("-")
        this.end_date = split2.joinToString("-")
    }

    override fun toString(): String {
        return if (start_date == end_date) {
            start_date
        } else {
            "$start_date..$end_date"
        }
    }

    fun toStringIsrael(): String {
        return if (start_date == end_date) {
            DatesManipulator.dateIsrael(start_date)

        } else {
            "${DatesManipulator.dateIsrael(start_date)}..${DatesManipulator.dateIsrael(end_date)}"
        }
    }
}