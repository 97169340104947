package Structs

import DataBase.UINKDBInterface
import Utils.WrappedName
import Utils.fixJson
import Utils.getJsonWithConfig
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.*
import myName
import printPlat


@myName("ProductDeliveryFlat")
@Serializable
data class ProductDeliveryFlat(

    @SerialName("id")
    var id: Float,
    @SerialName("e_id")
    var e_id: Float,
    @SerialName("dt")
    var dt: Float,
    @SerialName("date")
    var date: String,
    @SerialName("v")
    var value: Float = 0f,
    @SerialName("r")
    var returns: Float = 0f,
    @SerialName("wv")
    //this is for orders, second amount
    var wrapped_amount: Float = 0f,
    @SerialName("wr")
    //this is for orders, second amount
    var wrapped_amount_return: Float = 0f,

    // conversion_ratio between the second amount to the actual value 1 value * conversion_ratio = wrapped_amount
    @SerialName("cr")
    var conversion_ratio: Float = 0f,
    // conversion_ratio between the second amount to the actual value 1 value * conversion_ratio = wrapped_amount
    @SerialName("wu")
    var wu: Float = 0f,

    @SerialName("n")
    var notes: String? = null,
) {
    var isClient: Boolean = true
    var productId: Int = id.toInt()
    var entId: Int = e_id.toInt()
    var docType: Int = dt.toInt()
    var wrapped_unit: Int = wu.toInt()
    var useWrapped: Byte = 0

    @myName("getUnit")
    fun getUnit(): WrappedName {

        return WrappedName.NONE.convert(wrapped_unit)
    }

    @myName("getProduct")
    fun getProduct(): Product {
        return if (isClient) UINKDBInterface.activeDB.getClientProduct(productId).first!! else UINKDBInterface.activeDB.getSupplierProduct(
            productId
        ).first!!
    }


    @myName("getOrderProduct")
    fun getOrderProduct(): OrderProduct? {
        return if (isClient) UINKDBInterface.activeDB.getOrderProduct(productId)?.first() else null
    }

    @myName("empty")
    fun empty(): Boolean {
        var cond = value == 0f
        var cond2 = returns == 0f
        var cond3 = conversion_ratio == 0f || wrapped_amount == 0f
        var cond4 = conversion_ratio == 0f || wrapped_amount_return == 0f
        return cond && cond2 && cond3 && cond4

    }

    @myName("doCopy")
    fun doCopy(): ProductDeliveryFlat {
        val d = copy()
        d.isClient = isClient
        return d
    }


    fun sql(): String {
        return if (!empty())
            "${productId}_s#${value}@${productId}_r#${returns}@"
        else ""
    }


    companion object {
        fun fromDataQueryHolder(l: List<DataQueryHolder?>): List<ProductDeliveryFlat>? {
            try {


                return l.map {
                    val pd = createEmpty(
                        it!!.byProduct!!.toInt(),
                        it.byEnt!!.toInt(),
                        date = it.date!!,
                        it.doc_type.toFloat()
                    )
                    pd.value = it.valueSum
                    pd.returns = it.returnSum
                    pd.wrapped_amount = it.valueSumSec ?:0f
                    pd.wrapped_amount_return= it.returnSumSec ?:0f
                    val valueSecJoin = it.valueSumSecJoin ?:0f
                    pd.conversion_ratio = if(pd.wrapped_amount>0 && valueSecJoin>0) valueSecJoin/pd.wrapped_amount else 0f
                    val returnSecJoin = it.returnSumSecJoin ?:0f
                    pd.conversion_ratio = if(pd.wrapped_amount_return>0 && valueSecJoin>0) returnSecJoin/pd.wrapped_amount_return else 0f
                    pd
                }
            } catch (e: Exception) {

            }
            return null
        }

        fun weightEstimate(pds: List<ProductDeliveryFlat>): Float {
            return pds.sumByDouble {
                (it.getOrderProduct()?.weight ?: 0f).toDouble() * (it.value + it.conversion_ratio * it.wrapped_amount)
            }.toFloat()
        }

        fun kartonEstimate(pds: List<ProductDeliveryFlat>): Float {

            return pds.sumByDouble { pd ->
                (pd.getOrderProduct()?.volume?.let { if (it == 0f) 0f else (pd.value + pd.conversion_ratio * pd.wrapped_amount) / it }
                    ?: 0f).toDouble()
            }.toFloat()
        }

        fun createEmpty(
            productId: Int = 0,
            entId: Int = 0,
            date: String = "",
            docType: Float = 0f
        ): ProductDeliveryFlat {
            return ProductDeliveryFlat(productId.toFloat(), entId.toFloat(), docType, date)

        }

        fun toWrappedAmountDescribe(pds: List<ProductDeliveryFlat>): List<ProductDeliveryFlat> {
            val dvs: MutableList<ProductDeliveryFlat> = mutableListOf()
            pds.forEach {
                if (it.value > 0 || it.returns > 0) {
                    dvs.add(it)
                }
                if ((it.wrapped_amount > 0 || it.wrapped_amount_return > 0) && it.conversion_ratio > 0) {
                    val cppd = it.doCopy()
                    cppd.value = it.wrapped_amount
                    cppd.returns = it.wrapped_amount_return
                    cppd.useWrapped = 1
                    cppd.wrapped_unit = it.wrapped_unit
                    dvs.add(
                        cppd
                    )
                }
            }
            return dvs
        }
    }
}


