package Structs

import DataBase.DBQuery
import DataBase.UINKDBInterface
import Utils.TaxNoteType
import myName
import kotlin.math.abs

val WORKER = "כל הקווים"

@myName("NotesAmountsQuery")
class NotesAmountsQuery(var isClient: Boolean = true, var withAgent: Boolean = false) {
    private val entToProducts: MutableMap<String, MutableMap<Int, MutableMap<Int, AmountsDataHolder>>> = mutableMapOf()
    private val productsToEnt: MutableMap<String, MutableMap<Int, MutableMap<Int, AmountsDataHolder>>> = mutableMapOf()
    private val agents = UINKDBInterface.activeDB.getAgents().first.filter { it.isAgent() }.map { it.user_name }
    fun getEntIds():List<Int>{
        return entToProducts.entries.map { it.value.keys.toList() }.flatten().distinct()
    }
    @myName("getAllAgents")
    fun getAllAgents(): List<String> {
        return entToProducts.keys.toList()
    }

    fun addInternalSupDelivery(d: OrderNote) {
        val tax = UINKDBInterface.activeDB.tax
        val entToProducts = if (withAgent) {
            entToProducts[d.agent] ?: true.let {
                entToProducts[d.agent] = mutableMapOf()
                entToProducts[d.agent]!!
            }
        } else {
            entToProducts[WORKER] ?: true.let {
                entToProducts[WORKER] = mutableMapOf()
                entToProducts[WORKER]!!
            }
        }
        d.sup_delivery_value.forEach { pd ->
            val c = UINKDBInterface.activeDB.getSupplier(pd.supplier_id).first!!
            val curMap = entToProducts[pd.supplier_id] ?: true.let {
                entToProducts[pd.supplier_id] = mutableMapOf()
                entToProducts[pd.supplier_id]!!
            }
            val curVal = curMap[pd.productId] ?: true.let { it1 ->
                curMap[pd.productId] = AmountsDataHolder()
                curMap[pd.productId]!!
            }
            SupDeliveryNoteUpdate(pd, curVal, c, d.date)

        }
    }

    fun SupDeliveryNoteUpdate(pd: ProductDelivery, curVal: AmountsDataHolder, c: Entity, date: String) {
        var price: Float
        var priceBefore: Float
        val rawPrice = UINKDBInterface.activeDB.pdUsePrice(pd, c, date, 0)!!
        priceBefore = rawPrice.first
        price = rawPrice.second
        val currPositiveValue = price * pd.value
        val currNegativeValue = price * pd.returns
        curVal.value += pd.value
        curVal.returns += pd.returns
        curVal.money += currPositiveValue - currNegativeValue
        curVal.taxPaid += (pd.value - pd.returns) * (price - priceBefore)
    }

    fun add(d: Note) {
        var ent_id = d.ent_id
        val c = if (ent_id != -1) {
            if (isClient)
                UINKDBInterface.activeDB.getClient(d.ent_id).first!!
            else
                UINKDBInterface.activeDB.getSupplier(d.ent_id).first!!
        } else {
            ent_id = -(abs(agents.indexOfFirst { it == d.agent }) + 1)
            null
        }
        val entToProducts = if (withAgent) {
            entToProducts[d.agent] ?: true.let {
                entToProducts[d.agent] = mutableMapOf()
                entToProducts[d.agent]!!
            }
        } else {
            entToProducts[WORKER] ?: true.let {
                entToProducts[WORKER] = mutableMapOf()
                entToProducts[WORKER]!!
            }
        }
        val curMap = entToProducts[ent_id] ?: true.let {
            entToProducts[ent_id] = mutableMapOf()
            entToProducts[ent_id]!!
        }

        val tax = UINKDBInterface.activeDB.tax
        val di = d.delivery_info
        di.forEach {
            val curVal = curMap[it.productId] ?: true.let { it1 ->
                curMap[it.productId] = AmountsDataHolder()
                curMap[it.productId]!!
            }
            noteUpdate(it, curVal, c, d)
        }
    }

    fun noteUpdate(it: ProductDelivery, curVal: AmountsDataHolder, c: Entity?, d: Note) {
        curVal.value += it.value
        curVal.returns += it.returns
        curVal.wrappedValue += it.wrapped_amount
        curVal.wrappedReturn += it.wrapped_amount_return
        val note_used: Byte = if (d.isUsed()) 1 else 0
        if (c != null) {
            var price: Float
            var priceBefore: Float
            val rawPrice = UINKDBInterface.activeDB.pdUsePrice(it, c, d.date, note_used)!!
            priceBefore = rawPrice.first
            price = rawPrice.second
            val currPositiveValue = price * it.value
            val currNegativeValue = price * it.returns
            curVal.money += currPositiveValue - currNegativeValue
            curVal.taxPaid += (it.value - it.returns) * (price - priceBefore)
            if (it.wrapped_amount > 0) {
                val wrapped_amount_value = it.wrapped_amount * it.conversion_ratio
                curVal.wrappedMoney += wrapped_amount_value * price
                curVal.wrappedTaxPaid += wrapped_amount_value * (price - priceBefore)
                curVal.wrappedUnit = it.getUnit()
            }
            if (it.wrapped_amount_return > 0) {
                val wrapped_amount_return = it.wrapped_amount_return * it.conversion_ratio
                curVal.wrappedMoney -= wrapped_amount_return * price
                curVal.wrappedTaxPaid -= wrapped_amount_return * (price - priceBefore)
                curVal.wrappedUnit = it.getUnit()
            }

        }
    }

    fun add(note: ClientTaxNote) {
        val entToProducts = if (withAgent) {
            entToProducts[note.agent] ?: true.let {
                entToProducts[note.agent] = mutableMapOf()
                entToProducts[note.agent]!!
            }
        } else {
            entToProducts[WORKER] ?: true.let {
                entToProducts[WORKER] = mutableMapOf()
                entToProducts[WORKER]!!
            }
        }
        val curMap = entToProducts[note.client_id] ?: true.let {
            entToProducts[note.client_id] = mutableMapOf()
            entToProducts[note.client_id]!!
        }
        val c =
            UINKDBInterface.activeDB.getClient(note.client_id).first!!
        val di = note.productDeliveries
        val valueSign = if (note.taxNoteType == TaxNoteType.CANCEL) -1 else 1
        di.forEach {
            val curVal = curMap[it.productId] ?: true.let { it1 ->
                curMap[it.productId] = AmountsDataHolder()
                curMap[it.productId]!!
            }
            taxNoteUpdate(it, curVal, c, note, valueSign)
        }
    }

    fun taxNoteUpdate(it: ProductDelivery, curVal: AmountsDataHolder, c: Client, note: ClientTaxNote, valueSign: Int) {
        val prices = UINKDBInterface.activeDB.pdPriceFromTaxNote(it, c, note.document_date)
        val discount = prices.third
        val priceBefore = prices.first * (1 - discount / 100)
        val priceAfter = prices.second * (1 - discount / 100)

        val value = it.value * valueSign
        val returns = it.returns * valueSign
        val price = priceAfter
        val currPositiveValue = price * value
        val currNegativeValue = price * returns
        curVal.value += value
        curVal.returns += returns
        curVal.money += currPositiveValue - currNegativeValue
        curVal.taxPaid += (value - returns) * (price - priceBefore)
        curVal.wrappedValue += it.wrapped_amount
        curVal.wrappedReturn += it.wrapped_amount_return
        if (it.wrapped_amount > 0) {
            val wrapped_amount_value = it.wrapped_amount * it.conversion_ratio
            curVal.wrappedMoney += wrapped_amount_value * price
            curVal.wrappedTaxPaid += wrapped_amount_value * (price - priceBefore)
            curVal.wrappedUnit = it.getUnit()
        }
        if (it.wrapped_amount_return > 0) {
            val wrapped_amount_return = it.wrapped_amount_return * it.conversion_ratio
            curVal.wrappedMoney -= wrapped_amount_return * price
            curVal.wrappedTaxPaid -= wrapped_amount_return * (price - priceBefore)
            curVal.wrappedUnit = it.getUnit()
        }
    }

    fun buildInverseMap() {
        entToProducts.entries.forEach { m ->
            val agent = m.key
            val productsToEntFiltered = productsToEnt[agent] ?: true.let {
                productsToEnt[agent] = mutableMapOf()
                productsToEnt[agent]!!
            }
            m.value.forEach {
                val client = it.key
                it.value.entries.forEach { entry ->
                    val curMap = productsToEntFiltered[entry.key] ?: true.let {
                        productsToEntFiltered[entry.key] = mutableMapOf()
                        productsToEntFiltered[entry.key]!!
                    }
                    val curProductClientIdAmount = curMap[client] ?: true.let {
                        curMap[client] = AmountsDataHolder()
                        curMap[client]!!
                    }
                    curProductClientIdAmount.value += entry.value.value
                    curProductClientIdAmount.returns += entry.value.returns
                    curProductClientIdAmount.money = +entry.value.money
                    curProductClientIdAmount.taxPaid = +entry.value.taxPaid
                    if (entry.value.wrappedValue > 0) {
                        curProductClientIdAmount.wrappedValue += entry.value.wrappedValue
                        curProductClientIdAmount.wrappedTaxPaid += entry.value.wrappedTaxPaid
                        curProductClientIdAmount.wrappedMoney += entry.value.wrappedMoney
                        curProductClientIdAmount.wrappedReturn += entry.value.wrappedReturn
                        curProductClientIdAmount.wrappedUnit = entry.value.wrappedUnit
                    }

                }
            }
        }
    }

    fun add(notes: List<Note>, use_order_alt: Boolean = false) {
        notes.forEach {
            if (use_order_alt)
                addInternalSupDelivery(it as OrderNote)
            else
                add(it)
        }

    }

    fun add(taxNotes: List<ClientTaxNote>) {
        taxNotes.forEach { add(it) }
    }

    fun add(id: Int, pId: Int, value: Int) {

    }

    fun add(agent: String, map: MutableMap<Int, MutableMap<Int, AmountsDataHolder>>) {
        entToProducts[agent] = map
    }

    fun add(agent: String, id: Int, map: Map<Int, AmountsDataHolder>) {
        (entToProducts[agent] ?: true.let {
            entToProducts[agent] = mutableMapOf()
            entToProducts[agent]!!
        }).let {
            it[id] = map.toMutableMap()
        }
    }


    @myName("getProductsToTotalCount")
    fun getProductsToTotalCount(agent: String? = null,id:Int?=null): List<Pair<Named, AmountsDataHolder>> {
        var pMap: MutableMap<Int, AmountsDataHolder> = mutableMapOf()

        entToProducts.entries.forEach { m ->
            val agentInside = m.key
            if (agent != null && withAgent) {
                if (agent != agentInside && agent != WORKER)
                    return@forEach
            }

            var productsToEntFiltered = entToProducts[agentInside] ?: true.let {
                entToProducts[agentInside] = mutableMapOf()
                entToProducts[agentInside]!!
            }
            id?.let {
                productsToEntFiltered = productsToEntFiltered.filter { it.key == id }.toMutableMap()
            }
            productsToEntFiltered.values.forEach {
                it.entries.forEach { entry ->

                    val curVal = pMap[entry.key] ?: true.let {
                        pMap[entry.key] = AmountsDataHolder()
                        pMap[entry.key]!!
                    }
                    curVal.value += entry.value.value
                    curVal.returns += entry.value.returns
                    curVal.money += entry.value.money
                    curVal.taxPaid += entry.value.taxPaid
                    if (entry.value.wrappedValue > 0) {
                        curVal.wrappedValue += entry.value.wrappedValue
                        curVal.wrappedReturn += entry.value.wrappedReturn
                        curVal.wrappedMoney += entry.value.wrappedMoney
                        curVal.wrappedTaxPaid += entry.value.wrappedValue
                        curVal.wrappedUnit = entry.value.wrappedUnit
                    }
                }

            }
        }
        return if (isClient)
            pMap.map {

                Pair(UINKDBInterface.activeDB.getClientProduct(it.key).first!!, it.value)
            }
        else
            pMap.map { Pair(UINKDBInterface.activeDB.getSupplierProduct(it.key).first!!, it.value) }
    }

    @myName("getClientsToTotalCount")
    fun getClientsToTotalCount(agent: String? = null): List<Pair<Named, AmountsDataHolder>> {
        var pMap: MutableMap<Int, AmountsDataHolder> = mutableMapOf()

        entToProducts.entries.forEach { m ->
            val agentInside = m.key
            if (agent != null && withAgent) {
                if (agent != agentInside && agent != WORKER)
                    return@forEach
            }

            val entToProductFiltered = productsToEnt[agentInside] ?: true.let {
                entToProducts[agentInside] = mutableMapOf()
                entToProducts[agentInside]!!
            }
            entToProductFiltered.values.forEach {
                it.entries.forEach { entry ->

                    val curVal = pMap[entry.key] ?: true.let {
                        pMap[entry.key] = AmountsDataHolder()
                        pMap[entry.key]!!
                    }
                    curVal.value += entry.value.value
                    curVal.returns += entry.value.returns
                    curVal.money += entry.value.money
                    curVal.taxPaid += entry.value.taxPaid
                    if (entry.value.wrappedValue > 0) {
                        curVal.wrappedValue += entry.value.wrappedValue
                        curVal.wrappedReturn += entry.value.wrappedReturn
                        curVal.wrappedMoney += entry.value.wrappedMoney
                        curVal.wrappedTaxPaid += entry.value.wrappedValue
                        curVal.wrappedUnit = entry.value.wrappedUnit
                    }
                }

            }
        }
        return if (isClient)
            pMap.map {

                Pair(UINKDBInterface.activeDB.getClient(it.key).first!!, it.value)
            }
        else
            pMap.map { Pair(UINKDBInterface.activeDB.getSupplier(it.key).first!!, it.value) }
    }

    @myName("getSumExplain")
    fun getSumExplain(): AmountsDataHolder {
        val sumAll = AmountsDataHolder()
        entToProducts.entries.forEach { m ->
            val agentInside = m.key
            val entToProductsFiltered = entToProducts[agentInside] ?: true.let {
                entToProducts[agentInside] = mutableMapOf()
                entToProducts[agentInside]!!
            }
            entToProductsFiltered.values.forEach { hold ->
                hold.values.forEach {
                    sumAll.money += it.money
                    sumAll.value += it.value
                    sumAll.returns += it.returns
                    sumAll.taxPaid += it.taxPaid
                    if (it.wrappedValue > 0) {
                        sumAll.wrappedValue += it.wrappedValue
                        sumAll.wrappedReturn += it.wrappedReturn
                        sumAll.wrappedMoney += it.wrappedMoney
                        sumAll.wrappedTaxPaid += it.wrappedTaxPaid
                        sumAll.wrappedUnit = it.wrappedUnit
                    }
                }
            }
        }
        return sumAll
    }

    @myName("getProductToClient")
    fun getProductToClient(pId: Int, agent: String? = null): List<Pair<Named, AmountsDataHolder>> {
        if (withAgent) {
            var pMap: MutableMap<Int, AmountsDataHolder> = mutableMapOf()
            entToProducts.entries.forEach { m ->
                val agentInside = m.key
                if (agent != null && withAgent) {
                    if (agent != agentInside && agent != WORKER)
                        return@forEach
                }

                val productToEntFiltered = productsToEnt[agentInside] ?: true.let {
                    productsToEnt[agentInside] = mutableMapOf()
                    productsToEnt[agentInside]!!
                }
                productToEntFiltered[pId]?.forEach {
                    val k = it.key
                    val entry = it

                    val curVal = pMap[k] ?: true.let {
                        pMap[k] = AmountsDataHolder()
                        pMap[k]!!
                    }
                    curVal.value += entry.value.value
                    curVal.returns += entry.value.returns
                    curVal.money += entry.value.money
                    curVal.taxPaid += entry.value.taxPaid
                    if (entry.value.wrappedValue > 0) {
                        curVal.wrappedValue += entry.value.wrappedValue
                        curVal.wrappedReturn += entry.value.wrappedReturn
                        curVal.wrappedMoney += entry.value.wrappedMoney
                        curVal.wrappedTaxPaid += entry.value.wrappedValue
                        curVal.wrappedUnit = entry.value.wrappedUnit
                    }

                }
            }
            return pMap.map {
                if (isClient)
                    Pair(UINKDBInterface.activeDB.getClient(it.key).first!!, it.value)
                else
                    Pair(UINKDBInterface.activeDB.getSupplier(it.key).first!!, it.value)
            }

        } else {
            return productsToEnt.values.first()[pId]?.map {
                if (isClient)
                    Pair(UINKDBInterface.activeDB.getClient(it.key).first!!, it.value)
                else
                    Pair(UINKDBInterface.activeDB.getSupplier(it.key).first!!, it.value)
            }
                ?: listOf()
        }

    }

    @myName("filterById")
    fun filterById(id: Int): NotesAmountsQuery {
        if (id == -1 || withAgent) {
            return this
        }
        val map = entToProducts[WORKER]!!

        val ret = NotesAmountsQuery()
        if (!isClient)
            ret.isClient = false
        if (id !in map)
            return ret
        ret.add(WORKER, id, map[id]!!)
        ret.buildInverseMap()
        return ret

    }

    @myName("filterByAgent")
    fun filterByAgent(agent: String): NotesAmountsQuery {
        if (agent.compareTo("כל הקווים") == 0) {
            return this
        }
        val ret = NotesAmountsQuery(withAgent = withAgent)
        if (!isClient)
            ret.isClient = false


        entToProducts.entries.forEach { m ->
            val agentInside = m.key
            val entToProductFiltered = entToProducts[agentInside] ?: true.let {
                entToProducts[agentInside] = mutableMapOf()
                entToProducts[agentInside]!!
            }
            if (withAgent) {
                if (agent != agentInside)
                    return@forEach
                ret.add(agentInside, entToProductFiltered)
            } else {
                entToProductFiltered.entries.forEach {
                    val agentInternal = if (it.key >= 0) {
                        UINKDBInterface.activeDB.getClient(it.key).first!!.agent
                    } else {
                        agents[abs(it.key) - 1]
                    }
                    if (agentInternal.compareTo(agent) == 0) {
                        ret.add(agentInternal, it.key, it.value)
                    }

                }
            }


        }
        ret.buildInverseMap()
        return ret


    }

    @myName("getProductsOfEntityAsProductDelivery")
    fun getProductsOfEntityAsProductDelivery(id: Int): List<ProductDelivery> {

        return entToProducts[WORKER]!![id].let {
            it?.map { entry ->
                ProductDelivery(entry.key, entry.value.value, entry.value.returns, 0f)
            }
        } ?: listOf()
    }

    companion object {
        fun toWrappedDescribe(data: List<Pair<Named, AmountsDataHolder>>): List<Pair<Named, AmountsDataHolder>> {
            return data.map {
                if (it.second.wrappedValue > 0) {
                    val p = Pair(
                        EmptyEnt(
                            id = it.first.getConnectedId(),
                            name = it.first.getConnectedName() + " " + it.second.wrappedUnit.MethodName()
                        ),
                        AmountsDataHolder(
                            value = it.second.wrappedValue,
                            returns = it.second.wrappedReturn,
                            money = it.second.wrappedMoney,
                            taxPaid = it.second.wrappedTaxPaid
                        )
                    )
                    if (it.second.value == 0f && it.second.returns == 0f) {
                        listOf(
                            p
                        )
                    } else {
                        listOf(
                            it,
                            p
                        )
                    }
                } else {
                    listOf(
                        it,
                    )
                }
            }.flatten()
        }
    }

}