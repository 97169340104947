package Structs

import DataBase.UINKDBInterface
import Utils.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import myName

@myName("ClientDailyData")
@Serializable
data class ClientDailyData(
    val date: String,
    val id: Int,
    val driver_id: Int,
    val collector_id: Int,
    val position: Int,
    val position_col:Int,
    val group_id:Int,
    val car:String,
    val active: Int,

) {
    fun toJson(): JsonObject {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
        map["date"] = JsonPrimitive(date.toString())
        map["id"] = JsonPrimitive(id.toString())
        map["driver_id"] = JsonPrimitive(driver_id.toString())
        map["collector_id"] = JsonPrimitive(collector_id.toString())
        map["position"] = JsonPrimitive(position.toString())
        map["position_col"] = JsonPrimitive(position_col.toString())
        map["group_id"] = JsonPrimitive(group_id.toString())
        map["car"] = JsonPrimitive(car.toString())
        map["active"] = JsonPrimitive(active.toString())
        return JsonObject(map)
    }
    fun toDriverJson(): JsonObject {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
        map["date"] = JsonPrimitive(date.toString())
        map["id"] = JsonPrimitive(id.toString())
        map["driver_id"] = JsonPrimitive(driver_id.toString())
        return JsonObject(map)
    }

    companion object {
        fun toJsonArrayString(pd: List<ClientDailyData>,onlyDriver:Boolean=false): String {
            return if (pd.isEmpty()) {
                "[]"
            } else {
                val payload = pd.map {
                    Json.encodeToJsonElement(JsonObject.serializer(), if(onlyDriver) it.toDriverJson() else it.toJson()).toString()
                }.joinToString(",")
                return "[$payload]"
            }

        }
    }
}


