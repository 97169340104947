package DataBase.Interface

import DataBase.NewDBAction
import Network.NetworkOutput
import Network.httpWorker
import Structs.*
import Structs.Api.ApiConfig
import Structs.Api.ApiNote
import Structs.Respone
import Utils.ReportType
import Utils.StoreNextConfig
import Utils.TaxHolder
import Utils.getJsonWithConfig
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonObject


interface AdapterInterface {
    //clients
    suspend fun isLocal(): Boolean
    suspend fun getCompanyId(): String
    fun getToken(): String
    suspend fun setCompanyId(company_id: String?)
    fun setConcreteCompanyId(company_id: String)

    suspend fun getTaxData(): Respone<List<TaxHolder>, NetworkOutput>
    suspend fun getClients(
        id: String? = null,
        active: String? = null,
        fromDate: String,
        toDate: String,
        ids: String? = null,
        branch: String? = null,
        branches: String? = null
    ): Respone<List<Client>, NetworkOutput>

    suspend fun newClient(
        agent: String,
        name: String,
        date: String,
        include_tax: String? = null,
        business_name: String? = null,
        business_id: String? = null,
        address: String? = null,
        print_state: String? = null,
        id: String? = null,
        phone: String? = null,
        branch: String? = null,
        master: String? = null,
        phone_contact: String? = null,
        discount: String? = null,
        email: String? = null,
        external_id: String? = null,
        day: String? = null,
        notes: String? = null,
        no_tax_client: String? = null,
        position: String? = null,
        comments: String? = null,
        min_order: String? = null,
        location: String? = null,
        category: String? = null,
        obligo: String? = null,
        price_control: String? = null,
        payment_notes: String? = null,
        category2: String? = null,
        min_order_sum: String? = null,
        tax_note_type: String? = null,
        net_split: String? = null,
        days_to_pay: String? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        date_contact: String? = null,
    ): Respone<List<Client>, NetworkOutput>

    suspend fun updateClient(
        id: String,
        date: String,
        agent: String? = null,
        name: String? = null,
        include_tax: String? = null,
        business_name: String? = null,
        business_id: String? = null,
        address: String? = null,
        print_state: String? = null,
        position: String? = null,
        active: String? = null,
        phone: String? = null,
        branch: String? = null,
        master: String? = null,
        phone_contact: String? = null,
        discount: String? = null,
        email: String? = null,
        external_id: String? = null,
        day: String? = null,
        notes: String? = null,
        no_tax_client: String? = null,
        comments: String? = null,
        min_order: String? = null,
        location: String? = null,
        category: String? = null,
        obligo: String? = null,
        price_control: String? = null,
        payment_notes: String? = null,
        category2: String? = null,
        min_order_sum: String? = null,
        tax_note_type: String? = null,
        net_split: String? = null,
        days_to_pay: String? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        date_contact: String? = null,
    ): Respone<List<Client>, NetworkOutput>

    suspend fun updateClientsAll(
        date: String,
        data: String
    ): Respone<List<Client>, NetworkOutput>

    suspend fun getClientsCreationData(

    ): Respone<List<ClientCreation>, NetworkOutput>

    suspend fun getClientsDailyData(
        id: String? = null,
        date: String,
        driver_id: String? = null,
        collector_id: String? = null,
        ids: String? = null
    ): Respone<List<ClientDailyData>, NetworkOutput>

    suspend fun newClientDailyData(
        id: String,
        date: String,
        driver_id: String,
        collector_id: String,
        position: String,
        car: String
    ): Respone<List<ClientDailyData>, NetworkOutput>

    suspend fun newClientAllDailyData(
        date: String,
        data: String
    ): Respone<List<ClientDailyData>, NetworkOutput>

    suspend fun getClientProducts(
        id: String? = null,
        active: String? = null,
        fromDate: String,
        toDate: String,
        ids: String? = null
    ): Respone<List<Product>, NetworkOutput>

    suspend fun newClientProduct(
        name: String,
        date: String,
        barcode: String? = null,
        default_price: String? = null,
        id: String? = null,
        no_tax_product: String? = null,
        external_id: String? = null,
        unit: String? = null,
        unit_amount: String? = null,
        category: String? = null,
        cost_price: String? = null,
        split_category: String? = null,
        position: String? = null,
        base_price: String? = null,
        cost_include_returns: String? = null,
        category2: String? = null,
        taklit_id: String? = null,
        taklit_type: String? = null,
    ): Respone<Product?, NetworkOutput>

    suspend fun updateClientProduct(
        id: String,
        date: String,
        name: String? = null,
        barcode: String? = null,
        default_price: String? = null,
        position: String? = null,
        active: String? = null,
        no_tax_product: String? = null,
        taklit_id: String? = null,
        taklit_type: String? = null,
        external_id: String? = null,
        unit: String? = null,
        unit_amount: String? = null,
        category: String? = null,
        cost_price: String? = null,
        split_category: String? = null,
        base_price: String? = null,
        cost_include_returns: String? = null,
        category2: String? = null
    ): Respone<List<Product>, NetworkOutput>

    suspend fun updateClientAllProduct(
        date: String,
        data: String,
    ): Respone<List<Product>, NetworkOutput>

    suspend fun updateClientAllProductFull(

        date: String, data: String
    ): Respone<List<Product>, NetworkOutput>

    suspend fun updateSupplierAllProduct(
        date: String,
        data: String,
    ): Respone<List<Product>, NetworkOutput>

    suspend fun getClientCPData(
        ids: String? = null,
        product_ids: String? = null,
    ): Respone<List<ClientProductData>, NetworkOutput>
    suspend fun setClientCPData(
        data: String
    ): Respone<List<ClientProductData>, NetworkOutput>
    suspend fun getClientVisitNote(
        fromDate: String? = null,
        toDate: String? = null,
        ids: String? = null,
        ent_ids: String? = null,
        states: String? = null,
        has_order: String? = null,
    ): Respone<List<VisitNote>, NetworkOutput>

    suspend fun newClientVisitNote(
        client_id: String,
        agent: String,
        document_date: String,
        date_issued: String,
        lon_lat: String,
        states: String,
        comment: String,
        f: ByteArray? = null,
        order_id: String? = null,
        payment: String? = null,
        valueForDebt: String? = null,
    ): Respone<VisitNote?, NetworkOutput>

    suspend fun updateClientVisitNote(
        id: String? = null,
        states: String? = null,
        comment: String? = null,
        active: String? = null,
        payment: String? = null,
        valueForDebt: String? = null,
        order_id: String? = null,
        date: String? = null,
    ): Respone<VisitNote?, NetworkOutput>


    suspend fun getClientNotes(
        ent_id: String? = null,
        delivery_id: String? = null,
        fromDate: String? = null,
        toDate: String? = null,
        delivery_ids: String? = null,
        open: String? = null,
        order_ids: String? = null,
        historyQ:String? = null
    ): Respone<List<DeliveryNote>, NetworkOutput>

    suspend fun calcClientDeliveryNotePdf(
        client_id: String,
        date_range: String? = null,
        specific_notes: String? = null,
        tax_id_merge: String? = null,
        order: String? = null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun calcClientCartesetPdf(
        client_id: String,
        date_range: String? = null,
        onlyTaxSide: String? = null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun calcClientPricesPdf(
        client_id: String,
        date_range: String? = null,
        p_ids: String? = null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun getClientLastNotes(
        ent_id: String
    ): Respone<List<DeliveryNote>, NetworkOutput>


    suspend fun newClientNote(
        agent: String,
        ent_id: String,
        date: String,
        date_issued: String,
        delivery_value: String,
        delivery_id: String? = null,
        order_id: String? = null,
        connected_id: String? = null,
        notes: String? = null,
        increase_id: String? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,

        agent_id: String? = null,
        active: String? = null,
    ): Respone<DeliveryNote?, NetworkOutput>

    suspend fun updateClientNote(
        delivery_id: String,
        paid: String? = null,
        active: String? = null,
        connected_id: String? = null,
        delivery_value: String? = null,
        order_id: String? = null,
        notes: String? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        agent_id: String? = null,
    ): Respone<DeliveryNote?, NetworkOutput>

    suspend fun getClientBaseOrder(
        ent_id: String? = null,
    ): Respone<List<OrderNote>, NetworkOutput>

    suspend fun newClientBaseOrderNote(
        order_id: String? = null,
        agent: String,
        ent_id: String,
        date_issued: String,
        delivery_value: String,
        sup_delivery_info: String,
    ): Respone<OrderNote?, NetworkOutput>

    suspend fun getClientOrderNotes(
        ent_id: String? = null,
        order_id: String? = null,
        fromDate: String? = null,
        toDate: String? = null,
        ids: String? = null,
        order_ids: String? = null,
        status: String? = null,
        inverse_status: String? = null,
        ref_id: String? = null,
        agent_id: String? = null,
        historyQ:String? = null
    ): Respone<List<OrderNote>, NetworkOutput>

    suspend fun getLastUpdateOrders(
        date: String,
        action_time: String,
    ): Respone<List<OrderNote>, NetworkOutput>

    suspend fun getLastUpdateEnt(
        action_time: String,
    ): Respone<List<Int>, NetworkOutput>

    suspend fun getClientLastOrderNotes(
        ent_id: String
    ): Respone<List<OrderNote>, NetworkOutput>

    suspend fun newClientOrderNote(
        agent: String,
        ent_id: String,
        date: String,
        date_issued: String,
        delivery_value: String,
        sup_delivery_info: String,
        order_id: String? = null,
        active: String? = null,
        notes: String? = null,
        category: String? = null,
        notify: String? = null,
        ref_id: String? = null,
        agent_id: String? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        history: String? = null,
        collection: String? = null,
        increase_id: String? = null,
        noApi: String? = null,
        static_data: String? = null,
    ): Respone<OrderNote?, NetworkOutput>

    suspend fun updateClientOrderNote(
        order_id: String,
        agent: String? = null,
        date: String? = null,
        delivery_value: String?,
        sup_delivery_info: String?,
        active: String? = null,
        notes: String? = null,
        category: String? = null,
        notify: String? = null,
        ref_id: String? = null,
        inventory: String? = null,
        agent_id: String? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        history: String? = null,
        collection: String? = null,
        increase_id: String? = null,
        update_agent: String? = null,
        static_data: String? = null,
    ): Respone<OrderNote?, NetworkOutput>

    suspend fun getProductAvailable(
        ids: String? = null,
        product_ids: String? = null,
        available: String? = null,
    ): Respone<List<Available>, NetworkOutput>

    suspend fun newAllAvailable(
        data: String,
    ): Respone<List<Available>, NetworkOutput>

    suspend fun getSpecialPrices(
        fromDate: String,
        toDate: String,
        ids: String
    ): Respone<List<RawPrice>, NetworkOutput>

    suspend fun getClientPrices(
        id: String? = null,
        product_id: String? = null,
        fromDate: String,
        toDate: String,
        ids: String? = null,
        product_ids: String? = null,
        master: String? = null,
    ): Respone<List<RawPrice>, NetworkOutput>

    suspend fun getClientPriorityPrices(
        id: String,
        date: String,
        master: String? = null,
    ): Respone<List<RawPricePriority>, NetworkOutput>

    suspend fun getLastClientPrices(
        id: String? = null,
        product_id: String? = null,
        ids: String? = null,
    ): Respone<List<RawPrice>, NetworkOutput>

    suspend fun newClientPrice(
        rawPrice: RawPrice
    ): Respone<RawPrice?, NetworkOutput>

    suspend fun updateClientAllPrices(
        date: String,
        data: String,
        toDate: String? = null
    ): Respone<List<RawPrice>, NetworkOutput>

    suspend fun newClientPrice(
        id: String,
        product_id: String,
        price: String,
        date: String? = null
    ): Respone<RawPrice?, NetworkOutput>

    suspend fun updateClientPrice(
        id: String,
        product_id: String,
        price: String,
        date: String? = null
    ): Respone<RawPrice?, NetworkOutput>

    suspend fun getClientsMonthlyCycle(
        date: String? = null,
        id: String? = null
    ): Respone<List<MonthlyCycle>, NetworkOutput>

    suspend fun getClientMonthlyCyclePdf(
        client_name: String,
        month: String,
        year: String
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun getClientDeliveryNotePdf(
        id: String,
        origin: String,
        force_price: String? = null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun getClientTaxPdf(
        client_name: String,
        month: String,
        year: String,
        id: String,
        type: String,
        origin: String,
        client_master: String? = null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun getClientPayPdf(
        client_name: String,
        month: String,
        year: String,
        id: String,
        origin: String,
        client_master: String? = null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun newClientsMonthlyCycle(
        date: String,
        id: String,
        value: String,
        date_updated: String? = null,
        paid: String? = null,
        date_paid: String? = null
    ): Respone<MonthlyCycle?, NetworkOutput>

    suspend fun getClientsLastTaxNote(
        client_id: String?,
        type: String? = null,
        client_ids: String? = null,
        sup:Boolean=false
    ): Respone<List<ClientTaxNote>, NetworkOutput>

    suspend fun getClientsLightTaxNote(
        client_id: String? = null,
        id: String? = null,
        fromDate: String? = null,
        toDate: String? = null,
        type: String? = null,
        withProduct: String? = null,
        client_ids: String? = null
    ): Respone<List<ClientLightTaxNote>, NetworkOutput>

    suspend fun getClientsTaxNote(
        client_id: String? = null,
        id: String? = null,
        fromDate: String? = null,
        toDate: String? = null,
        type: String? = null,
        withProduct: String? = null,
        client_ids: String? = null,
        tax_ids: String? = null,
        open: String? = null,
        agent: String? = null,
        check_date: String? = null,
        order_ids: String? = null,
        historyQ:String? = null,
        sup:Boolean=false
    ): Respone<List<ClientTaxNote>, NetworkOutput>

    suspend fun newClientsTaxNote(
        client_id: String,
        date: String,
        document_date: String,
        value: String,
        total_value: String,
        value_data_raw: String,

        details: String? = null, //top delete
        discount_percent: String? = null,
        cover_dates: String? = null,
        cover_date_start: String? = null,
        cover_date_end: String? = null,
        type: String? = null,
        payment_data_raw: String,
        delivery_notes_meta_data: String? = null,
        withProduct: String? = null,
        tax_note_meta_data: String? = null,
        delivery_notes_map: String? = null,
        active_state: String? = null,
        external_details: String? = null,
        agent: String? = null,
        order_id: String? = null,
        tax_note_id: String? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        increase_id: String? = null,
        sup:Boolean=false
    ): Respone<ClientTaxNote?, NetworkOutput>

    suspend fun updateAllClientTaxNote(
        data: String,sup:Boolean=false
    ): Respone<List<ClientTaxNote>, NetworkOutput>

    suspend fun newAllClientTaxNotes(
        date: String,
        data: String,
        deliveryNotesValuesMap: String? = null,
        sup:Boolean=false
    ): Respone<List<ClientTaxNote>, NetworkOutput>

    suspend fun calcClientTaxNotePdf(doc_id: String, type: String): NetworkOutput
    suspend fun calcClientPayNotePdf(doc_id: String): NetworkOutput
    suspend fun getClientsLastPay(
        client_id: String?,
        client_ids: String? = null,
        sup:Boolean=false
    ): Respone<List<ClientPay>, NetworkOutput>

    suspend fun getClientChecks(
        client_id: String? = null,
        client_ids: String? = null,
        last: String? = null,
        sup:Boolean=false
    ): Respone<List<PaymentData>, NetworkOutput>

    suspend fun getClientsPay(
        client_id: String? = null,
        id: String? = null,
        fromDate: String? = null,
        toDate: String? = null,
        client_ids: String? = null,
        pay_ids: String? = null,
        agent: String? = null,
        check_date: String? = null,
        historyQ:String? = null,
        sup:Boolean=false
        ): Respone<List<ClientPay>, NetworkOutput>

    suspend fun getClientsLightPay(
        client_id: String? = null,
        id: String? = null,
        fromDate: String? = null,
        toDate: String? = null,
        client_ids: String? = null,
    ): Respone<List<ClientLightPay>, NetworkOutput>

    suspend fun newClientsPay(
        client_id: String,
        date: String,
        document_date: String,
        value: String,
        details: String? = null,
        payment_data_raw: String,
        tax_note_id: String? = null,
        cancel_id: String? = null,
        reopen_tax_notes: String? = null,
        active: String? = null,
        external_details: String? = null,
        agent: String? = null,
        refund: String? = null,
        refund_with_tax: String? = null,
        type: String? = null,
        increase_id: String? = null,
        sup:Boolean=false
    ): Respone<ClientPay?, NetworkOutput>

    suspend fun updateClientsTaxNotePayment(
        id: String,
        payment_data_raw: String,
        sup:Boolean=false
    ): Respone<ClientTaxNote?, NetworkOutput>

    suspend fun updateClientsPay(
        id: String,
        payment_data_raw: String? = null,
        tax_note_id: String? = null,
        cancel_id: String? = null,
        reopen_tax_notes: String? = null,
        active: String? = null,
        external_details: String? = null,
        value: String? = null,
        sup:Boolean=false
        ): Respone<ClientPay?, NetworkOutput>

    suspend fun getCartesetClients(
        id: String? = null,
        ids: String? = null,
        fromDate: String? = null,
        toDate: String? = null,
        state: String? = null,
        sup: Boolean = false
    ): Respone<List<ClientCarteset>, NetworkOutput>

    suspend fun newCartesetClients(
        id: String,
        date: String,
        positive: String,
        negative: String,
        date_updated: String,
        state: String? = null,
        notes: String? = null,
        sup: Boolean = false
    ): Respone<ClientCarteset?, NetworkOutput>

    suspend fun newAllCartesetClients(
        date: String,
        data: String,
        sup: Boolean = false
    ): Respone<List<ClientCarteset>, NetworkOutput>

    suspend fun getEdiClients(
        date: String,
        id: String? = null,
        ids: String? = null
    ): Respone<List<EdiMember>, NetworkOutput>

    suspend fun newEdiClients(
        date: String,
        id: String,
        edi_sup_id: String,
        edi_client_id: String,
        branch: String,
        internal_sup_edi: String,
        net_edi: String
    ): Respone<EdiMember?, NetworkOutput>
    //Suppliers

    suspend fun getSuppliers(
        id: String? = null,
        active: String? = null,
        fromDate: String,
        toDate: String,
        ids: String? = null
    ): Respone<List<Supplier>, NetworkOutput>

    suspend fun newSupplier(
        name: String,
        date: String,
        include_tax: String? = null,
        business_name: String? = null,
        business_id: String? = null,
        address: String? = null,
        print_state: String? = null,
        id: String? = null,
        phone: String? = null,
        no_tax_client: String? = null,
        external_id: String? = null,
        comments: String? = null,
        category: String? = null,
        payment_notes: String? = null,
        category2: String? = null,
        phone_contact: String? = null,
        ): Respone<Supplier?, NetworkOutput>

    suspend fun updateSupplier(
        id: String,
        date: String,
        name: String? = null,
        include_tax: String? = null,
        business_name: String? = null,
        business_id: String? = null,
        address: String? = null,
        print_state: String? = null,
        position: String? = null,
        active: String? = null,
        phone: String? = null,
        no_tax_client: String? = null,
        external_id: String? = null,
        comments: String? = null,
        category: String? = null,
        payment_notes: String? = null,
        category2: String? = null,
        phone_contact: String? = null,
    ): Respone<List<Supplier>, NetworkOutput>

    suspend fun getSupplierProducts(
        id: String? = null,
        active: String? = null,
        fromDate: String,
        toDate: String
    ): Respone<List<Product>, NetworkOutput>

    suspend fun newSupplierProduct(
        name: String,
        date: String,
        barcode: String? = null,
        default_price: String? = null,
        id: String? = null,
        connected_product: String? = null,
        no_tax_product: String? = null,
        category: String? = null,
        external_id: String? = null
    ): Respone<Product?, NetworkOutput>

    suspend fun updateSupplierProduct(
        id: String,
        date: String,
        name: String? = null,
        barcode: String? = null,
        default_price: String? = null,
        position: String? = null,
        active: String? = null,
        connected_product: String? = null,
        no_tax_product: String? = null,
        category: String? = null,
        external_id: String? = null
    ): Respone<List<Product>, NetworkOutput>


    suspend fun getSupplierNotes(
        ent_id: String? = null,
        delivery_id: String? = null,
        fromDate: String? = null,
        toDate: String? = null,
        delivery_ids: String? = null
    ): Respone<List<DeliveryNote>, NetworkOutput>

    suspend fun getSupplierLastNotes(
        ent_id: String
    ): Respone<List<DeliveryNote>, NetworkOutput>

    suspend fun newSupplierNote(
        agent: String,
        ent_id: String,
        date: String,
        date_issued: String,
        delivery_value: String,
        delivery_id: String? = null,
        connected_id: String? = null,
        notes: String? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        ): Respone<DeliveryNote?, NetworkOutput>

    suspend fun updateSupplierNote(
        delivery_id: String,
        paid: String? = null,
        paid_date: String? = null,
        active: String? = null,
        connected_id: String? = null,
        delivery_value: String? = null,
        notes: String? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
    ): Respone<DeliveryNote?, NetworkOutput>


    suspend fun getSupplierPrices(
        id: String? = null,
        product_id: String? = null,
        fromDate: String,
        toDate: String,
        ids: String? = null,
        product_ids: String? = null
    ): Respone<List<RawPrice>, NetworkOutput>

    suspend fun getLastSupplierPrices(
        id: String? = null,
        product_id: String? = null
    ): Respone<List<RawPrice>, NetworkOutput>

    suspend fun newSupplierPrice(
        rawPrice: RawPrice
    ): Respone<RawPrice?, NetworkOutput>

    suspend fun newSupplierPrice(
        id: String,
        product_id: String,
        price: String,
        date: String? = null
    ): Respone<RawPrice?, NetworkOutput>

    suspend fun updateSupplierAllPrices(
        date: String,
        data: String,
        toDate: String? = null
    ): Respone<List<RawPrice>, NetworkOutput>

    suspend fun updateSupplierPrice(
        id: String,
        product_id: String,
        price: String,
        date: String? = null
    ): Respone<RawPrice?, NetworkOutput>

    suspend fun getSuppliersMonthlyCyclePdf(
        sup_name: String,
        month: String,
        year: String
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun getSuppliersMonthlyCycle(
        date: String? = null,
        id: String? = null
    ): Respone<List<MonthlyCycle>, NetworkOutput>

    suspend fun newSuppliersMonthlyCycle(
        date: String,
        id: String,
        value: String,
        paid: String? = null,
        date_paid: String? = null,
        date_updated: String? = null
    ): Respone<MonthlyCycle?, NetworkOutput>

    suspend fun calcSupplierDeliveryNotePdf(
        sup_id: String,
        date_range: String,
        specific_notes: String? = null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun dataToAccountManager(date_range: String): Respone<ByteArray?, NetworkOutput>
    suspend fun calcDocumentsSum(
        doc_type: String,
        date_range: String,
        pdf: String? = null,
        open_only: String? = null,
        active_only: String? = null,
        ent_id: String? = null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun calcClientDebtXsl(
        date_range: String,
        agent: String,
        noZero: String? = null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun login(name: String, password: String, company: String): Respone<Agent?, NetworkOutput>
    suspend fun getAgents(): Respone<List<Agent>, NetworkOutput>

    suspend fun getCompanyInfo(
        id: String? = null,
        company: String? = null,
        fromDate: String,
        toDate: String
    ): Respone<User?, NetworkOutput>

    suspend fun getCompanysInfo(
    ): Respone<List<User>, NetworkOutput>

    suspend fun getCofcConf(
        id: String? = null, cofc: Boolean
    ): Respone<CofcConf?, NetworkOutput>

    suspend fun getAllCities(): Respone<String?, NetworkOutput>
    suspend fun getOptimizedRoutes(
        locations: List<Location>,
        start: Location? = null,
        end: Location? = null
    ): Respone<List<Int>?, NetworkOutput>

    suspend fun getGeoName(address: String, city: String? = null): Respone<Pair<String?, Boolean>, NetworkOutput>
    suspend fun downloadApp(): Respone<ByteArray?, NetworkOutput>
    suspend fun getStoreNextInfo(
    ): Respone<List<StoreNextConfig>, NetworkOutput>

    suspend fun getHashavshevetInfo(
    ): Respone<List<HashavshevetS>, NetworkOutput>

    suspend fun calcClientEdiNote(
        note_id: String,
    ): NetworkOutput

    suspend fun getEdiNote(
        note_id: String? = null,
        note_ids: String? = null,
        connected_id: String? = null,
        fromDate: String? = null,
        toDate: String? = null,
        send: String? = null
    ): Respone<List<EdiNote>, NetworkOutput>

    suspend fun newEdiNote(
        note_id: String,
        date: String,
        info: String,
        reason: String? = null,
        connected_id: String? = null,
        send: String? = null
    ): Respone<EdiNote?, NetworkOutput>

    suspend fun updateEdiNote(
        note_id: String,
        date: String,
        info: String? = null,
        reason: String? = null,
        connected_id: String? = null,
        send: String? = null
    ): Respone<EdiNote?, NetworkOutput>

    suspend fun getTaklitProducts(
        id: String? = null,
        date: String? = null
    ): Respone<List<TaklitProduct>, NetworkOutput>

    suspend fun getOrderProducts(
        id: String? = null,
        product_id: String? = null,
        available: String? = null,
        active: String? = null,
        product_ids: String? = null,
    ): Respone<List<OrderProduct>, NetworkOutput>

    suspend fun newOrderProducts(
        id: String,
        product_id: String,
        extra_unit: String,
        conversion_rate: String,
        available: String,
        active: String,
        weight: String? = null,
        englishName: String? = null,
        orderName: String? = null,
        position: String? = null,
        volume: String? = null,
        sales: String? = null,
        forceLock: String? = null,
        hourLock: String? = null,
        dayLock: String? = null,
        step: String? = null,
        collector: String? = null,
        min_order: String? = null,
        unit_preferece: String? = null,

        ): Respone<OrderProduct?, NetworkOutput>

    suspend fun updateAllOrderProduct(
        date: String,
        data: String,
    ): Respone<List<OrderProduct>, NetworkOutput>

    suspend fun calcTaklit(

    ): NetworkOutput

    suspend fun getWeigth(): String


    suspend fun getCofcClients(
        id: String,
        date: String,
        master: String? = null
    ): Respone<List<Client>, NetworkOutput>

    suspend fun getCofcFull(
        id: String,
    ): Respone<JsonObject?, NetworkOutput>

    suspend fun getCofcProductAvailable(
        id: String,

        ): Respone<List<Available>, NetworkOutput>

    suspend fun getCofcId(
    ): Respone<String?, NetworkOutput>
    suspend fun getCofcId2(pass:String
    ): Respone<Pair<String,String>?, NetworkOutput>

    suspend fun registerCofcId(
        id: String,
        company: String,
        until: String,
        force_date: String? = null
    ): Respone<String?, NetworkOutput>
    suspend fun registerCofcIdShort(
        id: String,
        company: String,
        until: String,
        force_date: String? = null
    ): Respone<String?, NetworkOutput>

    suspend fun getCofcUser(
        id: String
    ): Respone<User?, NetworkOutput>

    suspend fun getCofcAgents(
        id: String,
        collectors: String? = null,
        drivers: String? = null,
    ): Respone<List<Agent>, NetworkOutput>

    suspend fun getCofcProducts(
        id: String,
        date: String
    ): Respone<List<Product>, NetworkOutput>

    suspend fun getCofcOrderProducts(
        id: String,
        op_id: String? = null,
        product_id: String? = null,
        available: String? = null,
        active: String? = null
    ): Respone<List<OrderProduct>, NetworkOutput>

    suspend fun getClientProductsImagesMetaCofc(
        id: String,
        company: String,
    ): Respone<JsonObject?, NetworkOutput>

    suspend fun getCofcProductsImage(
        id: String,
        company: String,
        date: String,
        product_id: String
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun getCofcClientsPrices(
        id: String,
        date: String,
        master: String? = null
    ): Respone<List<RawPricePriority>, NetworkOutput>

    suspend fun getCofcClientsOrder(
        id: String,
        dateFrom: String,
        dateTo: String,
        status: String?
    ): Respone<List<OrderNote>, NetworkOutput>

    suspend fun newCofcClientOrderNote(
        agent: String,
        ent_id: String,
        date: String,
        date_issued: String,
        delivery_value: String,
        sup_delivery_info: String,
        order_id: String? = null,
        active: String? = null,
        notes: String? = null,
        category: String? = null,
        title: String? = null,
        collection: String? = null,
                static_data: String? = null,
    ): Respone<OrderNote?, NetworkOutput>

    suspend fun updateCofcClientOrderNote(
        order_id: String,
        ent_id: String,
        agent: String? = null,
        date: String? = null,
        delivery_value: String?,
        sup_delivery_info: String?,
        active: String? = null,
        notes: String? = null,
        category: String? = null,
        title: String? = null,
        collection: String? = null,
        static_data: String? = null,
    ): Respone<OrderNote?, NetworkOutput>

    suspend fun uploadFile(
        f: ByteArray,
        img_name: String
    ): NetworkOutput

    suspend fun uploadLogo(
        f: ByteArray,
        img_name: String
    ): NetworkOutput

    suspend fun uploadSign(
        f: ByteArray,
        note_id: String,
        date: String,
        signer: String,
        doc_type: String
    ): Respone<NoteSignHolder?, NetworkOutput>

    suspend fun uploadPhoto(
        f: ByteArray,
        note_id: String,
        date: String,
        doc_type: String
    ): Respone<NoteSignHolder?, NetworkOutput>

    suspend fun getSignImg(
        note_id: String,
        doc_type: String
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun getSignPhoto(
        note_id: String,
        doc_type: String
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun getSign(
        note_id: String,
        doc_type: String
    ): Respone<List<NoteSignHolder>, NetworkOutput>

    suspend fun getClientProductsImage(
        company: String,
        product_id: String
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun getClientProductsImagesMeta(
        company: String,
    ): Respone<JsonObject?, NetworkOutput>

    suspend fun getClientProductsImages(
        company: String,
        product_ids: String?
    ): Respone<JsonObject?, NetworkOutput>

    suspend fun calcDocumentZip(
        doc_type: String,
        date_range: String
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun takanonPDF(
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun calcApiExcelZip(
        date_range: String, xlsxType: String? = null, c_ids: String?,
        ids: String?,
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun calcApiHashavshevetZip(
        date_range: String, ids: String? = null, types: String? = null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun getClientOptimziedDV(
        fromDate: String? = null,
        toDate: String? = null,
        byProduct: String? = null,
        byEnt: String? = null,
        byDay: String? = null,
        byWeek: String? = null,
        byMonth: String? = null,
        byYear: String? = null,
        byAgent: String? = null,
        byStd: String? = null,
        byMoney: String? = null,
        doc_type: String? = null,
        cofc: String? = null,
        byDate: String? = null,
        byCost: String? = null,
    ): Respone<List<DataQueryHolder>, NetworkOutput>

    suspend fun getClientOptimziedBADDV(
        fromDate: String?,
        toDate: String?,
        byEnt: String?,
        byAgent: String?,

        ): Respone<List<DeliveryNote>, NetworkOutput>

    // api
    suspend fun sendClientApi(
        doc_id: String,
        doc_type: String
    ): NetworkOutput

    // api
    suspend fun readClientApi(

    ): NetworkOutput

    suspend fun getApiNote(
        doc_ids: String? = null,
        doc_type: String? = null,
        api_method: String? = null,
        connected_id: String? = null,
        fromDate: String? = null,
        toDate: String? = null,
        send: String? = null,
        retries: String? = null,
        needHistoryQ: String? = null,
    ): Respone<List<ApiNote>, NetworkOutput>

    suspend fun setApiNote(
        doc_id: String,
        doc_type: String,
        api_method: String,
        ent_id: String? = null,
        connected_id: String? = null,
        doc_date: String? = null,
        send: String? = null,
        reason: String? = null,
        retries: String? = null
    ): Respone<List<ApiNote>, NetworkOutput>

    suspend fun getApiConfig(
        withOrders: String? = null,
        whatsapp: String? = null,
        last_counter:String? = null
    ): Respone<ApiConfig?, NetworkOutput>

    suspend fun calcClientOrderPdf(
        ids: String,
        cofc: Boolean = false,
        id: String? = null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun calcMASSdoc(
        ids: String,
        docs: String,
        force_price: String? = null,
        origin: String? = null,
        dates:String?=null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun updateAllClientOrders(
        date: String,
        data: String,
        agent: String,
        date_issued: String,
        new: String? = null,
        noApi: String? = null,
        agent_id: String? = null
    ): Respone<List<OrderNote>, NetworkOutput>

    suspend fun updateAllClientNotes(
        date: String,
        data: String,
        agent: String,
        date_issued: String,
        new: String? = null,
        agent_id: String? = null
    ): Respone<List<DeliveryNote>, NetworkOutput>

    suspend fun getOptimizedCartesetValue(
        ids: String? = null,
        toDate: String? = null,
        toDateTime: String? = null,
    ): Respone<List<ClientCarteset>, NetworkOutput>

    suspend fun calcClientsXslx(clients: String? = null): Respone<ByteArray?, NetworkOutput>
    suspend fun calcProductsXslx(products: String? = null): Respone<ByteArray?, NetworkOutput>
    suspend fun userLogo(cofc: String? = null): Respone<ByteArray?, NetworkOutput>
    suspend fun calcQuerySumPdf(
        ids: String,
        date_range: String,
        title: String,
        issuer: String,
        withOrders: String? = null,
        withNotes: String? = null,
        specific_products: String? = null,
        seperate: String? = null,
        byIds: String? = null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun calcDriverOrderSumPdf(
        ids: String,
        date: String,
        groupType: String,
        withOrders: String? = null,
        withNotes: String? = null,
        specific_products: String? = null,
        title: String? = null,
        seperate: String? = null,
        byIds: String? = null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun calcEDI100(
        tax_id: String,
        notes: String? = null
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun getOptimizeFlatNotes(
        byEnt: String? = null,
        byProduct: String? = null,
        fromDate: String,
        toDate: String
    ): Respone<List<ProductDeliveryFlat>, NetworkOutput>

    suspend fun newAgent(
        id: String? = null,
        user_name: String,
        password: String? = null,
        type: String,
    ): Respone<Agent?, NetworkOutput>
    suspend fun updateAgent(
       data: String
    ): Respone<List<Agent>, NetworkOutput>

    suspend fun getClientVisit(
        ids: String? = null,
        fromDate: String,
        toDate: String
    ): Respone<List<ClientVisitHolder>, NetworkOutput>

    suspend fun getClientsDebts(
        ids: String? = null,
        fromDate: String,
        toDate: String
    ): Respone<List<ClientDebt>, NetworkOutput>


    suspend fun getClientLastVisitation(
        ids: String?, tax_visitation: String? = null,
        note_visitation: String? = null,
        dv_visitation: String? = null,
        last: String? = null
    ): Respone<List<ClientLastVisit>, NetworkOutput>

    suspend fun getClientVisitation(
        ids: String?, fromDate: String, toDate: String, tax_visitation: String? = null,
        note_visitation: String? = null,
        dv_visitation: String? = null
    ): Respone<List<ClientVisitation>, NetworkOutput>

    suspend fun getClientPayDetails(
        ids: String? = null,
        fromDate: String? = null,
        toDate: String? = null,
        paid: String? = null
    ): Respone<List<PaymentData>, NetworkOutput>

    suspend fun getOptimizedClientNote(
        ent_id: String? = null,
        delivery_id: String? = null,
        fromDate: String? = null,
        toDate: String? = null,
        delivery_ids: String? = null,
        open: String? = null,
        byMoney: String? = null,
        order: String? = null
    ): Respone<List<Note>, NetworkOutput>

    suspend fun sendEmail(
        f: ByteArray,
        email: String,
        title: String,
        doc_type: String? = null,
        doc_id: String? = null
    ): NetworkOutput

    suspend fun sendReqForApi(
        doc_type: String,
        doc_id: String,
        api_method: String? = null,
    ): NetworkOutput

    suspend fun getClientOptimziedNotesFinal(
        fromDate: String?,
        toDate: String?,
        byProduct: String?,
        byEnt: String?,
        byDay: String?,
        byWeek: String?,
        byMonth: String?,
        byYear: String?,
        byAgent: String?,
        byStd: String?,
        byMoney: String?,
        doc_type: String?,
        cofc: String?,
        byDate: String?,
        byCost: String?,
        paid: String?,
        inventory: Boolean,
        byDocId: String?,
        byAgentId: String?,
        byCostTax:String?
    ): Respone<List<DataQueryHolder>, NetworkOutput>

    suspend fun loadXlsx(
        f: ByteArray,
        load_type: String,
        date: String? = null,
        type: String? = null,
        load_doc_id: String? = null
    ): Respone<String?, NetworkOutput>

    suspend fun reportError(
        data: String,
        agent: String,
        version: String
    ): NetworkOutput

    suspend fun calcClientReportPdf(
        fromDate: String, toDate: String, report_type: String
    ): Respone<ByteArray?, NetworkOutput>


    suspend fun getInventory(
        product_id: String? = null,
        agent_id: String? = null,
        date: String? = null,
        toDate: String? = null,
        inv_type: String? = null,
    ): Respone<List<InventoryItem>, NetworkOutput>
    suspend fun insertInventory(
        date:String,
        data: String
    ): NetworkOutput
    suspend fun clearInventory(
        product_id: String? = null,
        agent_id: String? = null,
    ): NetworkOutput

    suspend fun sendMsgAll(
        ids: String,
        msg: String,
        title: String,
        emails: String? = null,
        phones: String? = null,
        action_date: String? = null,
        doc_type: String? = null,
        special_arg: String? = null,
        external_var: String? = null,
        agent: String? = null,
        ): NetworkOutput

    suspend fun updateApiConfig(
        whatsapp: String?=null,
    ): Respone<ApiConfig?, NetworkOutput>
    suspend fun resetWhatsappConn():NetworkOutput
    suspend fun hashIsraelAuth(token:String):Respone<String?,NetworkOutput>
    suspend fun updateHashTaxInvoice(token:String,refresh:String):NetworkOutput
    suspend fun  hashIsraelSign(id:String,type:String):Respone<List<ClientTaxNote>,NetworkOutput>
    suspend fun getMsgTracker(
        msg_id: String? = null,
        fromDate: String?=null,
        toDate: String?=null,
        type:String?=null,
        send: String?=null,
        tries: String?=null,
        validate: String?=null,
    ): Respone<List<MsgTracker>, NetworkOutput>

    suspend fun newAllMsgTracker(
        data: String,
    ): Respone<List<MsgTracker>, NetworkOutput>


}

