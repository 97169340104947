package Structs

import DataBase.UINKDBInterface
import kotlinx.serialization.Serializable

@Serializable
class EdiMember(
    val date: String,
    val client_id: Int,
    val edi_sup_id: String,
    val edi_client_id: String,
    val net_edi: String,
    val internal_sup_edi: String,
    val branch_number:String

) : Named {

    override fun getActiveState(): Int {
        return 1
    }
    override fun getConnectedName(): String {
        return UINKDBInterface.activeDB.getClient(client_id).first?.getName() ?: ""
    }

    override fun getConnectedId(): Int {
        return client_id
    }

    fun getNetEdi(): String {
        if(net_edi.startsWith("729"))
            return net_edi
        return "729$net_edi"
    }
    fun getEdiClientId(): String {
        if(edi_client_id.startsWith("729"))
            return edi_client_id
        return "729$edi_client_id"
    }
    override fun toString(): String {
        return getConnectedName()
    }

}