package Structs

import Utils.DatesManipulator
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import myName

@myName("RawPrice")
@Serializable
data class RawPrice(val id: Int, val product_id: Int, val price: Float, val date: String, val discount: Float,val master:Int?=null) :
    Comparable<RawPrice
            > {
    override fun compareTo(other: RawPrice): Int {
        return date.compareTo(other.date)
    }

    fun toJson(): JsonObject {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
        map["id"] = JsonPrimitive(id.toString())
        map["product_id"] = JsonPrimitive(product_id.toString())
        map["price"] = JsonPrimitive(price.toString())
        map["date"] = JsonPrimitive(date)
        map["discount"] = JsonPrimitive(discount.toString())
        if(master!=null) map["master"] = JsonPrimitive(master.toString())
        return JsonObject(map)
    }

    companion object {
        fun newPriceDate(id: Int, product_id: Int, price: Float, date: String?, discount: Float): RawPrice {
            var finalDate: String? = date
            if (date == null) {

                finalDate = DatesManipulator.dateNowNoHour()
            }
            return RawPrice(id, product_id, price, finalDate!!, discount)

        }
    }
}