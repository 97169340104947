package Utils

val STATE_NAME = listOf<String>(
    "ביקור כללי",
    "ביצעתי הזמנה",
    "ביצעתי מכירה",
    "לא צריך סחורה",
    "לקחתי החזרות",
    "לקחתי תשלום",
    "הוספתי לקוח"

)



enum class VisitorState(val state: Int) {
    GENERAL(0),
    ORDER(1),
    SALE(2),
    NO_NEED_PRODUCT(3),
    RETURNS(4),
    PAYMENT(5),
    ADD_CLIENT(6);

    fun convert(s: Int): VisitorState {
        return when (s) {
            0 -> GENERAL
            1 -> ORDER
            2 -> SALE
            3 -> NO_NEED_PRODUCT
            4 -> RETURNS
            5 -> PAYMENT
            6 -> ADD_CLIENT

            else -> GENERAL
        }
    }


    fun stateName(): String {
        return STATE_NAME[this.state]

    }

}
