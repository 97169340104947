package Structs

import DataBase.UINKDBInterface
import kotlinx.serialization.Serializable
import myName

@myName("Location")
@Serializable
class Location(
    val lon:String?=null,
    val lat:String?=null,
    val city:String,
    val street:String,
    val streetNumber: String

) {
    fun geo(): String? {
        if(lon!=null && lat!=null){
            return "${lat.trim()},${lon.trim()}"
        }
        return null
    }
    fun geoInverse(): String? {
        if(lon!=null && lat!=null){
            return "${lon.trim()},${lat.trim()}"
        }
        return null
    }
    fun getAddress(): String? {

        return listOf<String?>(city,street,streetNumber).mapNotNull {

            if(it=="")
                return@mapNotNull null
            else it

        }.joinToString(" ")

    }
}
