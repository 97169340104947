package Utils

import kotlinx.serialization.Serializable
import myName

@Serializable
@myName("StoreNextConfig")
data class StoreNextConfig(
    val host: String,
    val port: Int,
    val userName: String,
    val password: String,
    val readPath: String,
    val writePath: String,
    val returns: String="",
    var token:String="",
    val hesh_host: String="",
    val hesh_port: Int=0,
    val hesh_user:String="",
    val hesh_pass:String="",
    val path: String="",
)
