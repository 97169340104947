import DataBase.UINKDBInterface
import Network.NetworkOutput
import Network.Tasks
import Structs.Respone
import com.example.nihulkavapp.Backend.Network.JsCoroutinesAsyncTask

@JsName("ApiCallWrapper")
class ApiCallWrapper(
    val taskName: String,
    var callback: (result: Any?) -> Unit
) :
    JsCoroutinesAsyncTask<Any?>() {

    val params: HashMap<String, Any> = HashMap()
    var descriptionData = listOf("מוריד", "מקשר מסמכים", "מחשב")

    @JsName("putParam")
    fun putParam(name: String, value: Any): ApiCallWrapper {
        params[name] = value
        return this
    }
    @JsName("replaceCallback")
    fun replaceCallback(callback: (result: Any?) -> Unit): ApiCallWrapper {
        this.callback = callback
        return this
    }


    init {

    }

    override fun onPreExecute() {
        console.log("on pre")
        super.onPreExecute()
    }


    override suspend fun doInBackground(): Any? {
        try {
//            var a: List<Agent>
//            a = SharedApi().getAgents()
            var result: Any?
            val taskId = Tasks.valueOf(taskName)
            console.log(taskId.name)
            result = taskId.applyTask(params)
            when (true) {
                result is Respone<*, *> -> {
                    val msg = (result as Respone<*, *>).second
                    if (msg is NetworkOutput)
                        if (msg != NetworkOutput.SUCCESS && msg != NetworkOutput.ERROR_EMPTY_QUERY) {

                            return null
                        }
                }
                result is NetworkOutput -> {
                    if (result != NetworkOutput.SUCCESS && result != NetworkOutput.ERROR_EMPTY_QUERY) {
                        return null
                    }
                }
            }
            return result

        } catch (e: Exception) {
            console.log(e.toString())
            console.log(e.stackTraceToString())
        }
        return null
    }


    override fun onPostExecute(result: Any?) {
        super.onPostExecute(result)
        console.log("on post")
        callback(result)

    }

}