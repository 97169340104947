package Structs

import Utils.PayMethod
import kotlinx.serialization.Serializable

val reasonMap: Map<Int, String> = mapOf(
    27 to "דחייה",
    29 to "אישור",
    34 to "הסתייגות",
    28 to "דחייה עקב כפילות תעודה",
    26 to "דחייה טכנית",
    7 to "דחייה על כפילות התעודה הוקלדה ידנית",
    24 to "כמות שהתקבלה בפועל",
    45 to "כמות שהתקבלה בפועל לא סופי",
    12 to "ביטול תמ נדחה",
    40 to "קליטה ידנית"
)

@Serializable
data class EdiNote(
    val note_id: Int = -1,
    val connected_id: Long = -1,
    val reason: Int = -1,
    val send: Int = 0,
    val info: String = "",
    val date: String = "",
) : InformationBase() {

    override fun getConnectedDate(): String {
        return date
    }

    override fun getConnectedId(): Int {
        return note_id
    }

    override fun getConnectedEntId(): Int {
        return -1
    }

    fun isSend(): Boolean {
        return send > 0
    }

    fun getReason(): String? {
        return reasonMap[reason]
    }

    override fun getConnectedValue(): Float {
        return 0f
    }

    override fun getConnectedName(): String {
        return ""
    }

    override fun toString(): String {
        return date
    }


}
