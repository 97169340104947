package Structs

import DataBase.UINKDBInterface
import Utils.DatesManipulator
import Utils.PrintState
import Utils.fixJsonInternal
import Utils.isNotEmpty
import kotlinx.serialization.Serializable
import myName

@myName("Entity")
@Serializable
sealed class Entity(

) : Named {
    abstract val id: Int
    abstract val agent: String
    abstract val name: String
    abstract val include_tax: Int
    abstract val business_name: String?
    abstract val business_id: String?
    abstract val address: String?
    abstract val print_state: Int
    abstract var position: Int?
    abstract var location: String?
    abstract var active: Int
    abstract var phone: String?
    abstract val date: String
    abstract val phone_contact: String
    abstract val category: String
    abstract val discount: Float
    abstract val external_id: String
    abstract val email: String
    abstract val no_tax_client: Int
    abstract val obligo: Float
    abstract val price_control: Int // starts from -10 and forward
    abstract val payment_notes: String // starts from -10 and forward
    abstract val category2: String // starts from -10 and forward
    abstract val tax_note_type: Int
    abstract var branch: Int
    abstract var master: Int

    abstract val days_to_pay: Int
    @myName("comments")
    abstract val comments: String
    @myName("notes2")
    abstract val notes2: String
    @myName("notes3")
    abstract val notes3: String
    @myName("notes4")
    abstract val notes4: String
    @myName("date_contact")
    abstract var date_contact: String?

    internal val prices: HashMap<Int, Price>
        get() {
            return if (this is Client) UINKDBInterface.activeDB.getClientPrices(if (price_control < -9) price_control else id) else UINKDBInterface.activeDB.getSupplierPrices(
                id
            )
        }
    var lastVisit: String? = null
        get() {
            return UINKDBInterface.activeDB.getLastVisit(id)
        }
    abstract val ps: PrintState
    var city: String = ""
    var street: String = ""
    var streetNumber: String = ""
    var entityDated: EntitySequence = EntitySequence()
    @myName("debtWall")
    var debtWall: DebtWall? = null
        get() {
            val d = UINKDBInterface.activeDB.getClientDebtWalls(id)
            return if (d.isEmpty())
                null
            else
                d.first()
        }
    var masterBranch: Client? = null
        get() {
            return if (master != -1) {
                UINKDBInterface.activeDB.getClient(master).first
            } else {
                null
            }
        }

    @myName("getAgent")
    fun getAgent(curDate: String = DatesManipulator.dateNow()): String {
        return (entityDated.get(curDate)).agent
    }
    override fun getActiveState(): Int {
        return active
    }

    fun addRawPrice(rp: RawPrice, p: Product, runOver: Boolean = false) {
        val prices =
            if (this is Client) UINKDBInterface.activeDB.getClientPrices(id) else UINKDBInterface.activeDB.getSupplierPrices(
                id
            )
        if (!prices.containsKey(p.id)) {
            val ent = if (this is Client) "client" else "supplier"
            prices[p.id] = Price(p.id, ent)
        }
        prices[p.id]!!.add(rp, runOver)

    }
    @myName("hasBranch")
    open fun hasBranch(): Boolean {
        return false
    }

    fun fillProduct(p: Product) {
        val prices =
            if (this is Client) UINKDBInterface.activeDB.getClientPrices(id) else UINKDBInterface.activeDB.getSupplierPrices(
                id
            )
        if (!prices.containsKey(p.id)) {
            val ent = if (this is Client) "client" else "supplier"
            prices[p.id] = Price(p.id, ent)
        }
    }

    @myName("getActiveProducts")
    open fun getActiveProducts(curDate: String = DatesManipulator.dateNow()): List<Product> {
        return prices.values.filter { it.isActive(curDate) && it.product.getActive(curDate) }.map { it.product }
    }

    @myName("getActiveProductsPrices")
    open fun getActiveProductsPrices(curDate: String = DatesManipulator.dateNow()): List<Price> {
        return prices.values.filter { it.product.getActive(curDate) }
    }

    @myName("getActivePrices")
    open fun getActivePrices(curDate: String = DatesManipulator.dateNow()): List<Price> {
        return prices.values.filter { it.isActive(curDate) && it.product.getActive(curDate) }
    }

    @myName("getActivePricesSize")
    open fun getActivePricesSize(curDate: String = DatesManipulator.dateNow()): Int {
        return prices.values.filter { it.isActive(curDate) && it.product.getActive(curDate) }.size
    }

    @myName("getPrices")
    open fun getPrices(): List<Price> {
        return prices.values.toList()
    }

    @myName("getPricesMap")
    open fun getPricesMap(): HashMap<Int, Price> {
        return prices
    }

    @myName("getPrice")
    open fun getPrice(id: Int): Price? {
        return prices[id]
    }

    @myName("getIncludePrice")
    open fun getIncludePrice(id: Int, date: String = DatesManipulator.dateNow()): Triple<Float, Float, Float>? {
        val priceMenu = prices[id] ?: return null
        val prePrice = priceMenu.get(date)
        var price: Float
        var priceBefore: Float
        val detailedPrice = UINKDBInterface.activeDB.getPriceDetailed(prePrice.first, this, priceMenu.product, date)
        priceBefore = detailedPrice.first
        price = detailedPrice.second
        return Triple(priceBefore, price, prePrice.second)
    }

    open fun setPrice(id: Int, price: Price) {
        prices[id] = price
    }

    override fun toString(): String {
        return name
    }

    @myName("getExternalId")
    open fun getExternalId(curDate: String = DatesManipulator.dateNow()): String {
        return entityDated.get(curDate).external_id
    }

    @myName("getNoTaxClient")
    open fun getNoTaxClient(curDate: String = DatesManipulator.dateNow()): Int {
        return entityDated.get(curDate).no_tax_client
    }

    @myName("getActive")
    open fun getActive(curDate: String = DatesManipulator.dateNow()): Boolean {
        return entityDated.get(curDate).active == 1
    }

    @myName("getName")
    open fun getName(curDate: String = DatesManipulator.dateNow()): String {
        return entityDated.get(curDate).name
    }

    @myName("getPhoneContact")
    open fun getPhoneContact(curDate: String = DatesManipulator.dateNow()): String {
        return entityDated.get(curDate).phone_contact.split(",").first()
    }

    @myName("getSecondPhoneContact")
    open fun getSecondPhoneContact(curDate: String = DatesManipulator.dateNow()): String {
        val contacts = entityDated.get(curDate).phone_contact.split(",")
        return if (contacts.size >= 2) contacts[1] else ""
    }

    @myName("getThirdPhoneContact")
    open fun getThirdPhoneContact(curDate: String = DatesManipulator.dateNow()): String {
        val contacts = entityDated.get(curDate).phone_contact.split(",")
        return if (contacts.size >= 3) contacts.last() else ""
    }


    @myName("getIncludeTax")
    open fun getIncludeTax(curDate: String = DatesManipulator.dateNow()): Int {
        return entityDated.get(curDate).include_tax
    }

    @myName("getBusinessName")
    open fun getBusinessName(curDate: String = DatesManipulator.dateNow()): String? {
        return entityDated.get(curDate).business_name
    }

    @myName("getBusinessId")
    open fun getBusinessId(curDate: String = DatesManipulator.dateNow()): String? {
        return entityDated.get(curDate).business_id
    }

    @myName("getCity")
    open fun getCity(curDate: String = DatesManipulator.dateNow()): String {
        return entityDated.get(curDate).city
    }

    @myName("getAddress")
    open fun getAddress(curDate: String = DatesManipulator.dateNow()): String? {
        return entityDated.get(curDate).address
    }

    @myName("getStreet")
    open fun getStreet(curDate: String = DatesManipulator.dateNow()): String {
        return entityDated.get(curDate).street
    }

    @myName("getCategory")
    open fun getCategory(curDate: String = DatesManipulator.dateNow()): String {
        return entityDated.get(curDate).category
    }

    @myName("getStreetNumber")
    open fun getStreetNumber(curDate: String = DatesManipulator.dateNow()): String {
        return entityDated.get(curDate).streetNumber
    }

    @myName("getPhone")
    open fun getPhone(curDate: String = DatesManipulator.dateNow()): String? {
        return entityDated.get(curDate).phone?.split(",")?.firstOrNull()
    }

    @myName("getPhoneComplex")
    open fun getPhoneComplex(curDate: String = DatesManipulator.dateNow(), type: Int = 0): String? {
        val userCommens =
            UINKDBInterface.activeDB.getUser().first?.defaultNotesJson?.filterKeys { it.contains("phone") }?.values?.firstOrNull { it.func == type }?.param
        return when (userCommens) {
            "cphone" -> getPhone(curDate)
            "cphone2" -> getSecondPhone(curDate).isNotEmpty(true,getPhone(curDate)?:"")
            "cphone3" -> getThirdPhone(curDate).isNotEmpty(true,getPhone(curDate)?:"")
            else -> getPhone(curDate)
        }
    }

    @myName("getSecondPhone")
    open fun getSecondPhone(curDate: String = DatesManipulator.dateNow()): String? {
        val phones = entityDated.get(curDate).phone?.split(",")
        return if (phones?.size ?: 0 >= 2) phones?.get(1) else ""
    }

    @myName("getThirdPhone")
    open fun getThirdPhone(curDate: String = DatesManipulator.dateNow()): String? {
        val phones = entityDated.get(curDate).phone?.split(",")
        return if (phones?.size ?: 0 >= 3) phones?.lastOrNull() else ""
    }

    @myName("getEmail")
    open fun getEmail(curDate: String = DatesManipulator.dateNow()): String {
        return entityDated.get(curDate).email
    }

    @myName("getDiscount")
    open fun getDiscount(curDate: String = DatesManipulator.dateNow()): Float {
        return entityDated.get(curDate).discount
    }

    @myName("getObligo")
    open fun getObligo(curDate: String = DatesManipulator.dateNow()): Float {
        return entityDated.get(curDate).obligo
    }

    @myName("getAddressStr")
    open fun getAddressStr(curDate: String = DatesManipulator.dateNow()): String {
        val x = listOf<String?>(getStreet(curDate), getStreetNumber(curDate), getCity(curDate)).mapNotNull {
            if (it == "")
                return@mapNotNull null
            else it

        }.joinToString(" ")
        return x
    }

}

@myName("ClientCreation")
@Serializable
data class ClientCreation(
    val id: Int,
    val creation_time: String
)

@myName("Client")
@Serializable
class Client(
    override val agent: String,
    override val id: Int,
    override var name: String,
    override val include_tax: Int,
    override var business_name: String,
    override val business_id: String,
    override var address: String?,
    override val print_state: Int,
    override var position: Int?,
    override var location: String? = null,
    override var active: Int,
    override var phone: String?,
    override var category: String = "",
    override var branch: Int=-1,
    override var master: Int=-1,
    override val date: String,
    override var phone_contact: String,
    override val discount: Float = 0f,
    override val email: String = "",
    override val external_id: String = "-1",
    val days: Int = 63,
    val notes: Int = 1,

    override val no_tax_client: Int = 0,
    override val comments: String = "",
    override val obligo: Float = 0f,
    val min_order: Int = 0,
    val min_order_sum: Float = 0f,
    val action_time: String? = null,
    val net_split: Int = 0,
    override val price_control: Int = 0,
    override val payment_notes: String = "",
    override val category2: String = "", override val tax_note_type: Int = 0,
    override val days_to_pay: Int = 0,
    override val notes2: String = "",
    override val notes3: String = "",
    override val notes4: String = "",
    override var date_contact: String? = "",


    ) : Entity() {
    override val ps: PrintState = PrintState.NONE.convert(print_state)
    var creation_time: String? = null
        get() {
            return UINKDBInterface.activeDB.getClientCreation(id)
        }
    val edi: EdiMember?
        get() {
            return UINKDBInterface.activeDB.getClientEdi(id)
        }

    init {
        name = fixJsonInternal(name)
        phone_contact = fixJsonInternal(phone_contact)
        business_name = fixJsonInternal(business_name ?: "")
        address = fixJsonInternal(address ?: "")
        val addressAr = if (address != null && address != "") address!!.split(",") else listOf("", "", "")
        city = addressAr[0]
        street = addressAr[1]
        streetNumber = addressAr[2]
        entityDated.add(this)
        date_contact = if(date_contact=="None") "" else date_contact
    }





    @myName("getCollector")
    fun getCollector(date: String? = null): Agent? {
        return UINKDBInterface.activeDB.getDateDailyData(date)?.get(id)?.collector_id?.let {
            UINKDBInterface.activeDB.getAgentBy(id = it, null, collector = true).first
        }
    }

    @myName("getDriver")
    fun getDriver(date: String? = null): Agent? {
        return UINKDBInterface.activeDB.getDateDailyData(date)?.get(id)?.driver_id?.let {
            UINKDBInterface.activeDB.getAgentBy(id = it, null, driver = true).first
        }
    }

    @myName("getDriverPosition")
    fun getDriverPosition(date: String? = null): Int? {
        return UINKDBInterface.activeDB.getDateDailyData(date)?.get(id)?.position
    }

    @myName("getCollectorPosition")
    fun getCollectorPosition(date: String? = null): Int? {
        return UINKDBInterface.activeDB.getDateDailyData(date)?.get(id)?.position_col
    }

    @myName("getDriverFinder")
    fun getDriverFinder(date: String): Agent? {
        return UINKDBInterface.activeDB.getDateDailyDataFinder(date)?.get(id)?.driver_id?.let {
            UINKDBInterface.activeDB.getAgentBy(id = it, null, driver = true).first
        }
    }

    @myName("getCollectorFinder")
    fun getCollectorFinder(date: String): Agent? {
        return UINKDBInterface.activeDB.getDateDailyDataFinder(date)?.get(id)?.collector_id?.let {
            UINKDBInterface.activeDB.getAgentBy(id = it, null, collector = true).first
        }
    }


    override fun hasBranch(): Boolean {
        return branch != -1
    }

    override fun getActiveProducts(curDate: String): List<Product> {
        return masterBranch?.getActiveProducts(curDate) ?: super.getActiveProducts(curDate)
    }

    override fun getActivePrices(curDate: String): List<Price> {
        return masterBranch?.getActivePrices(curDate) ?: super.getActivePrices(curDate)
    }

    override fun getObligo(curDate: String): Float {
        return masterBranch?.getObligo(curDate) ?: super.getObligo(curDate)
    }

    override fun getPricesMap(): HashMap<Int, Price> {
        return masterBranch?.getPricesMap() ?: super.getPricesMap()
    }

    override fun getActiveProductsPrices(curDate: String): List<Price> {
        return masterBranch?.getActiveProductsPrices(curDate) ?: super.getActiveProductsPrices(curDate)
    }

    override fun getPrices(): List<Price> {
        return masterBranch?.getPrices() ?: super.getPrices()
    }

    override fun getPrice(id: Int): Price? {
        return masterBranch?.prices?.get(id) ?: prices[id]
    }

    override fun toString(): String {
        return getName()
    }

    override fun getName(curDate: String): String {
        return entityDated.get(curDate).name
    }


    override fun getDiscount(curDate: String): Float {
        return masterBranch?.getDiscount(curDate) ?: entityDated.get(curDate).discount
    }



    fun getTaxNoteType(curDate: String = DatesManipulator.dateNow()): Int {
        return (entityDated.get(curDate) as Client).tax_note_type
    }

    override fun getIncludeTax(curDate: String): Int {
        return masterBranch?.getIncludeTax(curDate) ?: entityDated.get(curDate).include_tax
    }

    override fun getBusinessName(curDate: String): String? {
        return masterBranch?.getBusinessName(curDate) ?: entityDated.get(curDate).business_name
    }

    override fun getBusinessId(curDate: String): String? {
        return masterBranch?.getBusinessId(curDate) ?: entityDated.get(curDate).business_id
    }

    override fun getCity(curDate: String): String {
        return entityDated.get(curDate).city
    }

    override fun getStreet(curDate: String): String {
        return entityDated.get(curDate).street
    }

    override fun getStreetNumber(curDate: String): String {
        return entityDated.get(curDate).streetNumber
    }

    override fun getPhoneContact(curDate: String): String {
        return super.getPhoneContact(curDate)
    }

    override fun getSecondPhoneContact(curDate: String): String {
        return super.getSecondPhoneContact(curDate)
    }


    override fun getPhone(curDate: String): String? {
        return super.getPhone(curDate)
    }

    override fun getSecondPhone(curDate: String): String? {
        return super.getSecondPhone(curDate)
    }

    override fun getEmail(curDate: String): String {
        return masterBranch?.getEmail(curDate) ?: super.getEmail(curDate)
    }

    override fun getConnectedName(): String {
        return getName()
    }

    override fun getConnectedId(): Int {
        return id
    }

    @myName("getNotes")
    fun getNotes(curDate: String = DatesManipulator.dateNow()): Int {
        return (entityDated.get(curDate) as Client).notes
    }

    @myName("getMinOrder")
    fun getMinOrder(curDate: String = DatesManipulator.dateNow()): Int {
        return (entityDated.get(curDate) as Client).min_order
    }

    @myName("getDays")
    fun getDays(curDate: String = DatesManipulator.dateNow()): Int {
        return (entityDated.get(curDate) as Client).days
    }

    @myName("isSunday")
    fun isSunday(curDate: String = DatesManipulator.dateNow()): Boolean {
        return getDays(curDate) % 2 == 1
    }

    @myName("isMonday")
    fun isMonday(curDate: String = DatesManipulator.dateNow()): Boolean {
        return (getDays(curDate) / 2) % 2 == 1
    }

    @myName("isThusday")
    fun isThusday(curDate: String = DatesManipulator.dateNow()): Boolean {
        return (getDays(curDate) / 4) % 2 == 1
    }

    @myName("isWendesday")
    fun isWendesday(curDate: String = DatesManipulator.dateNow()): Boolean {
        return (getDays(curDate) / 8) % 2 == 1
    }

    @myName("isThursday")
    fun isThursday(curDate: String = DatesManipulator.dateNow()): Boolean {
        return (getDays(curDate) / 16) % 2 == 1
    }

    @myName("isFriday")
    fun isFriday(curDate: String = DatesManipulator.dateNow()): Boolean {
        return (getDays(curDate) / 32) % 2 == 1
    }

    @myName("getLon")
    fun getLon(curDate: String = DatesManipulator.dateNow()): String {
        return entityDated.get(curDate).location?.split(",")?.getOrNull(0) ?: ""
    }

    @myName("getLat")
    fun getLat(curDate: String = DatesManipulator.dateNow()): String {
        return entityDated.get(curDate).location?.split(",")?.getOrNull(1) ?: ""
    }

    @myName("getOutId")
    fun getOutId(curDate: String = DatesManipulator.dateNow()): String {
        return if (getExternalId(curDate).isNotEmpty() && (getExternalId(curDate) == "-1")) getExternalId(curDate) else id.toString()
    }

    @myName("daysString")
    fun daysString(curDate: String = DatesManipulator.dateNow()): List<String> {
        val daysStr = mutableListOf<String>()
        if (isSunday(curDate))
            daysStr.add("א")
        if (isMonday(curDate))
            daysStr.add("ב")
        if (isThusday(curDate))
            daysStr.add("ג")
        if (isWendesday(curDate))
            daysStr.add("ד")
        if (isThursday(curDate))
            daysStr.add("ה")
        if (isFriday(curDate))
            daysStr.add("ו")
        return daysStr
    }

    @myName("daysInt")
    fun daysInt(curDate: String = DatesManipulator.dateNow()): List<Int> {
        val daysStr = mutableListOf<Int>()
        if (isSunday(curDate))
            daysStr.add(0)
        if (isMonday(curDate))
            daysStr.add(1)
        if (isThusday(curDate))
            daysStr.add(2)
        if (isWendesday(curDate))
            daysStr.add(3)
        if (isThursday(curDate))
            daysStr.add(4)
        if (isFriday(curDate))
            daysStr.add(5)
        return daysStr
    }

}

@myName("Supplier")
@Serializable
class Supplier(
    override val id: Int,
    override var name: String,
    override val include_tax: Int,
    override var business_name: String? = null,
    override val business_id: String? = null,
    override var address: String? = null,
    override val print_state: Int,
    override var position: Int? = null,
    override var location: String? = null,
    override var active: Int,
    override var phone: String? = null,
    override val date: String,
    override var phone_contact: String = "",
    override val discount: Float = 0f,
    override val email: String = "",
    override val external_id: String = "-1",
    override val no_tax_client: Int = 0,
    override val comments: String = "",
    override val category: String = "",
    override val obligo: Float = 0f,
    override val price_control: Int = 0,
    override val payment_notes: String = "",
    override val category2: String = "", override val tax_note_type: Int = 0,
    override val days_to_pay: Int = 0,
    override val notes2: String = "",
    override val notes3: String = "",
    override val notes4: String = "",
    override var date_contact: String? = "", override val agent: String = "כללי",
    override var branch: Int=-1,
    override var master: Int=-1,

    ) : Entity() {
    override val ps: PrintState = PrintState.NONE.convert(print_state)

    init {
        name = fixJsonInternal(name)
        phone_contact = fixJsonInternal(phone_contact)
        business_name = fixJsonInternal(business_name ?: "")
        address = fixJsonInternal(address ?: "")

        val addressAr = if (address != null && address != "") address!!.split(",") else listOf("", "", "")
        city = addressAr[0]
        street = addressAr[1]
        streetNumber = addressAr[2]
        entityDated.add(this)
    }

    override fun getConnectedName(): String {
        return getName()
    }

    override fun getConnectedId(): Int {
        return id
    }
}

class EmptyEnt(
    override val id: Int = -1,
    override val name: String = "",
    override val include_tax: Int = 0,
    override val business_name: String? = null,
    override val business_id: String? = null,
    override val address: String? = null,
    override val print_state: Int = 0,
    override var position: Int? = null,
    override var location: String? = null,
    override var active: Int = 0,
    override var phone: String? = null,
    override val date: String = "",
    override val phone_contact: String = "",
    override val discount: Float = 0f,
    override val email: String = "",
    override val external_id: String = "-1",
    override val no_tax_client: Int = 0,
    override val comments: String = "",
    override val category: String = "",
    override val obligo: Float = 0f,
    override val price_control: Int = 0, override val payment_notes: String = "", override val category2: String = "",
    override val tax_note_type: Int = 0,
    override val days_to_pay: Int = 0,
    override val notes2: String = "",
    override val notes3: String = "",
    override val notes4: String     = "",
    override var date_contact: String? = "",
    override val agent: String = "כללי",
    override var branch: Int=-1,
    override var master: Int=-1,
) : Entity(
) {
    override val ps: PrintState = PrintState.NONE

    init {
        entityDated.add(this)
    }

    override fun toString(): String {
        return name
    }
    override fun getActiveProductsPrices(curDate: String): List<Price> {
        return UINKDBInterface.activeDB.getAllProductCost().filter { it.product.getActive() }
    }    override fun getActivePrices(curDate: String): List<Price> {
        return UINKDBInterface.activeDB.getAllProductCost().filter { it.isActive(curDate) && it.product.getActive() }
    }

    override fun getConnectedName(): String {
        return getName()
    }

    override fun getConnectedId(): Int {
        return id
    }
}
