package Structs

import DataBase.UINKDBInterface
import Utils.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import myName

@myName("ClientStaticData")
@Serializable
data class ClientStaticData(
    val id: Int,
    var business_name: String,
    val business_id: String="",
    var address: String = "",
    val phone: String = ""
) {
    var city: String = ""
    var street: String = ""
    var streetNumber: String = ""

    init {
        business_name = fixJsonInternal(business_name ?: "")
        address = fixJsonInternal(address ?: "")
        val addressAr = if (address.isNotEmpty()) address!!.split(",") else listOf("", "", "")
        city = addressAr[0]
        street = addressAr[1]
        streetNumber = addressAr[2]
    }

    fun toJson(): JsonObject {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
        map["id"] = JsonPrimitive(id.toString())
        map["business_name"] = JsonPrimitive(prepareStrToJson(business_name))
        map["business_id"] = JsonPrimitive(business_id)
        map["address"] = JsonPrimitive(prepareStrToJson(address))
        map["phone"] = JsonPrimitive(prepareStrToJson(phone))
        return JsonObject(map)
    }
    @myName("getClient")
    fun getClient(): Client {
        return UINKDBInterface.activeDB.getClient(id).first!!
    }
    companion object {
        fun createFromJson(js: String): ClientStaticData {

            val arr = getJsonWithConfig().parseToJsonElement((js).replace("\\'", "'")) as JsonArray
            val res: List<ClientStaticData> = arr.map {
                getJsonWithConfig().decodeFromJsonElement(ClientStaticData.serializer(), it)
            }
            return res.first()
        }

        fun toJsonArrayString(data: ClientStaticData): String {
            return "[${getJsonWithConfig().encodeToString(JsonObject.serializer(), data.toJson())}]"

        }
    }

}