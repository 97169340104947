package Structs

import DataBase.UINKDBInterface
import kotlinx.serialization.Serializable
import myName

@myName("ClientCompare")
@Serializable
class ClientCompare(
    val client_id: Int,
    val month: Int? = null,
    val year: Int? = null,
    val week: String? = null,


    val valueSum: Float,
    val returnSum: Float,
    val valueSumSec: Float,
    val returnSumSec: Float,
    val totalMoney: Float,
    val cost: Float? = null,


//    val dv_visiting: Float,
//    val dv_documents: Float,
//    val tn_visiting: Float,
//    val tn_documents: Float,
//    val pay_visiting: Float,
//    val pay_documents: Float,
//    val visit_visiting: Float,
//    val visit_documents: Float,
//    val total_visit: Float = 0f,
//    val comments: String = "",
) {

}


data class ClientCompareHolder(
    val client_id: Int,
    val data: MutableList<ClientCompare> = mutableListOf(),
    val method: Int,
    var to: String,
) {

}