package Structs

import DataBase.UINKDBInterface
import kotlinx.serialization.Serializable

@Serializable
data class NoteSignHolder(
    val note_id: Int,
    val note_type:Int,
    val digi_sign:Int,
    val digi_photo:Int,
    val signer: String,
    val date: String,
) {
}