package Network

import DataBase.Interface.BaseAdapterInterface
import DataBase.Interface.FixJsonType
import DataBase.NewDBAction
import Structs.Respone
import Utils.fixJson
import Utils.getJsonWithConfig
import Utils.putIfAbsent
import getHttpClient
import io.ktor.client.*
import io.ktor.client.features.*
import io.ktor.client.features.logging.*
import io.ktor.client.features.websocket.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.http.cio.websocket.*
import kotlinx.serialization.json.JsonObject

object httpWorker : BaseAdapterInterface {


    var companyName: String = ""
    var companyId: String = ""
    var local: Boolean = false
    var curToken: String = ""
    var curRefToken: String = ""
    private var httpClient = getHttpClient(local)
    fun setHTTPClient() {
        httpClient = getHttpClient(local)
    }

    class CustomHttpLogger() : Logger {
        override fun log(message: String) {
            println(message) // Or whatever logging system you want here
        }
    }
    suspend fun pureGetApiBytes(
        url: String,

        token: String? = null,
    ): Respone<ByteArray?, NetworkOutput> {
        try {
            val http = HttpClient() {
                install(HttpTimeout) {
                    requestTimeoutMillis = 30000
                    connectTimeoutMillis = 10000
                    socketTimeoutMillis = 35000
                }

                //           install(Logging) {
                //               logger = CustomHttpLogger()
                //              level = LogLevel.ALL
                //          }
            }

            val response = http.get<HttpResponse>(url) {
                headers {
                    append("Accept", "application/json")

                    token?.let { append(HttpHeaders.Authorization, " Bearer $token") }
                }
            }

            return if (response.status == HttpStatusCode.OK || response.status == HttpStatusCode.Created) {
                Respone(response.readBytes(), NetworkOutput.SUCCESS)
            } else {
                Respone(null, NetworkOutput.ERROR_GENERIC)
            }
        } catch (e: Exception) {
//            if(e.message?.contains("The record has a key field containing a duplicate value"))
            return Respone(null, NetworkOutput.ERROR_GENERIC)
        }
    }
    suspend fun pureGetApi(
        url: String,
        token: String? = null,
    ): Respone<String?, NetworkOutput> {
        try {
            val http = HttpClient() {
                install(HttpTimeout) {
                    requestTimeoutMillis = 30000
                    connectTimeoutMillis = 10000
                    socketTimeoutMillis = 35000
                }
                //           install(Logging) {
                //               logger = CustomHttpLogger()
                //              level = LogLevel.ALL
                //          }
            }

            val response = http.get<HttpResponse>(url) {
                headers {
                    append("Accept", "application/json")

                    token?.let { append(HttpHeaders.Authorization, " Bearer $token") }
                }
            }

            return if (response.status == HttpStatusCode.OK || response.status == HttpStatusCode.Created) {
                Respone(response.readText(), NetworkOutput.SUCCESS)
            } else {
                Respone(null, NetworkOutput.ERROR_GENERIC)
            }
        } catch (e: Exception) {
//            if(e.message?.contains("The record has a key field containing a duplicate value"))
            return Respone(null, NetworkOutput.ERROR_GENERIC)
        }
    }
    suspend fun getWithEncodedUrl(
        url: String,
        params: MutableMap<String, String>,
        token: String? = null,
        doPost: Boolean = false,
    ): Respone<String?, NetworkOutput> {
        try {
            val http = HttpClient() {
                install(HttpTimeout) {
                    requestTimeoutMillis = 30000
                    connectTimeoutMillis = 10000
                    socketTimeoutMillis = 35000
                }
                //           install(Logging) {
                //               logger = CustomHttpLogger()
                //              level = LogLevel.ALL
                //          }
            }

            val response = if(doPost) http.post<HttpResponse>(url) {
                headers {
                    append("Accept", "application/json")

                    token?.let { append(HttpHeaders.Authorization, " Bearer $token") }
                }
                url {
                    params.forEach { p ->
                        parameters.append(p.key, p.value)
                    }
                }
            }  else http.get<HttpResponse>(url) {
                headers {
                    append("Accept", "application/json")

                    token?.let { append(HttpHeaders.Authorization, " Bearer $token") }
                }
                url {
                    params.forEach { p ->
                        parameters.append(p.key, p.value)
                    }
                }
            }

            return if (response.status == HttpStatusCode.OK || response.status == HttpStatusCode.Created) {
                Respone(response.readText(), NetworkOutput.SUCCESS)
            } else {
                Respone(null, NetworkOutput.ERROR_GENERIC)
            }
        } catch (e: Exception) {
//            if(e.message?.contains("The record has a key field containing a duplicate value"))
            val x=e
            return Respone(null, NetworkOutput.ERROR_GENERIC)
        }
    }
    suspend fun purePostApi(
        url: String,
        params: MutableMap<String, String>?,
        token: String? = null,
        headerStr: String? = null,
        jsonStr: String = "{}",
        withContentHeader: String? = "application/json",
        basic: String? = null,
        requestTimeOut: Long = 60000,
        connectTimeOut : Long = 10000,
    ): Respone<String?, NetworkOutput> {
        try {
            val http = HttpClient() {
                install(HttpTimeout) {
                    requestTimeoutMillis = requestTimeOut
                    connectTimeoutMillis = connectTimeOut
                    socketTimeoutMillis = requestTimeOut
                }
                //           install(Logging) {
                //               logger = CustomHttpLogger()
                //              level = LogLevel.ALL
                //          }
            }

            val response = http.post<HttpResponse>(url) {
                headers {
                    append("Accept", "application/json")
                    if (withContentHeader!=null)
                        append("Content-Type", withContentHeader)

                    headerStr?.let { append("Document-Type", " ${it}") }
                    token?.let { append(HttpHeaders.Authorization, " Bearer $token") }
                    basic?.let { append(HttpHeaders.Authorization, " Basic $basic") }
                }
                body = params?.let {
                    FormDataContent(Parameters.build {
                        params.forEach { p ->
                            append(p.key, p.value)
                        }
                    })
                } ?: jsonStr
            }

            return if (response.status == HttpStatusCode.OK || response.status == HttpStatusCode.Created) {
                Respone(response.readText(), NetworkOutput.SUCCESS)
            } else {
                Respone(null, NetworkOutput.ERROR_GENERIC)
            }
        } catch (e: Exception) {
//            if(e.message?.contains("The record has a key field containing a duplicate value"))
            return Respone(e.message, NetworkOutput.ERROR_GENERIC)
        }
    }

    override suspend fun purePost(
        url: String,
        params: MutableMap<String, String>,
        token: String?,
        urlEnc: Boolean
    ): Respone<String?, NetworkOutput> {
        try {
            val response = httpClient.post<HttpResponse>(url) {
                headers {
                    append("Accept", "application/json")
                    token?.let { append(HttpHeaders.Authorization, " Bearer $token") }
                }

                if (urlEnc) {
                    body = FormDataContent(
                        (Parameters.build {
                            params.forEach { p ->
                                append(p.key, p.value)
                            }

                        }
                                ))
                } else {
                    body = MultiPartFormDataContent(
                        formData {
                            params.forEach { p ->
                                append(p.key, p.value)
                            }

                        }
                    )
                }
            }
            return if (response.status == HttpStatusCode.OK) {
                Respone(response.readText(), NetworkOutput.SUCCESS)
            } else {
                Respone(null, NetworkOutput.ERROR_GENERIC)
            }
        } catch (e: Exception) {
            println(e.stackTraceToString())
            return Respone(null, NetworkOutput.ERROR_GENERIC)
        }
    }


    private fun analyzeToken(response: JsonObject) {
        if ("token" in response) {
            val tokstr = response["token"]!!.toString()
            val tok = simpleFixJson(tokstr.substring(1, tokstr.length - 1))
            val tokObj = getJsonWithConfig().parseToJsonElement(tok) as JsonObject
            curToken = tokObj["access_token"].toString().drop(1).dropLast(1)
            if ("refresh_token" in tokObj) {
                curRefToken = tokObj["refresh_token"].toString().drop(1).dropLast(1)
            }
        }

    }

    private fun analyzeResponse(response: JsonObject): NetworkOutput {
        if (response["status"].toString().subSequence(1, response["status"].toString().length - 1) == "ERROR") {
            var msg = response["status_msg"].toString()
            when {
                msg.contains("id is not found") -> return NetworkOutput.ERROR_ID_NOT_EXIST
                msg.contains("Internal Error") -> return NetworkOutput.ERROR_GENERIC
                msg.contains("not found in args") -> return NetworkOutput.ERROR_ARG_MISSING
                msg.contains("Duplicate") -> return NetworkOutput.ERROR_DUPLICATE
                msg.contains("Empty Query") -> return NetworkOutput.ERROR_EMPTY_QUERY
                msg.contains("CONNECTION_ERROR") -> return NetworkOutput.CONNECTION_ERROR
                msg.contains("Token ERROR") -> return NetworkOutput.TOKEN_ERROR
                msg.contains("Token EXPIRED") -> return NetworkOutput.TOKEN_ERROR
                msg.contains("Token FAIL") -> return NetworkOutput.TOKEN_FAIL
            }
            return NetworkOutput.ERROR_GENERIC
        }
        return NetworkOutput.SUCCESS
    }

    fun fixJsonStr(s: String): String {
        var regexp = ""
// replace it with "- and -"
// note that $1 is now ""example"" and $2 is example (without double quote)

        val regex = Regex("(\'[^':{}]+\')")
        return regex.replace(s) { m ->

            "\"${m.value.substring(1, m.value.length - 1).replace("\"", "\'")}\""
        }
    }

    fun fixJsonStr2(s: String): String {
        var regexp = ""
// replace it with "- and -"
// note that $1 is now ""example"" and $2 is example (without double quote)

        val regex = Regex("(\"[^\\[\\]:{}]+\")")
        return regex.replace(s) { m ->

            m.value.replace("\\", "")
        }
    }

    fun fixInternalJsonStr(s: String): String {
        var regexp = ""
// replace it with "- and -"
// note that $1 is now ""example"" and $2 is example (without double quote)

        val regex = Regex("(\"\\[[^\\[]+\\]\")")
        return regex.replace(s) { m ->
            "\"${m.value.substring(1, m.value.length - 1).replace("\\", "\\\\")}\""
        }
    }

    //    fun fixJsonStr(s: String): String {
//        var regexp = ""
//// replace it with "- and -"
//// note that $1 is now ""example"" and $2 is example (without double quote)
//
//        val regex = Regex("(\'[^':{}]*\')")
//        return regex.replace(s) { m ->
//
//            "\"${m.value.substring(1, m.value.length - 1).replace("\"","\'")}\""
//        }
//    }
    fun badResult(): Respone<String?, NetworkOutput> {
        return Respone(null, NetworkOutput.CONNECTION_ERROR)
    }

    fun ligthFixJson(s: String): String {
        return s.replace("\'", "\"")
    }

    fun lightForInnerFixJson(s: String): String {
        return s.replace("\\\'", "#^").replace("\'", "\"").replace("#^", "\\\'")

    }

    fun simpleFixJson(s: String): String {
        var s = fixJson(s)
        val regex = Regex("(\'[^':{}]+\')")
        s = s.replace("\\\"", "#^")
        s = regex.replace(s) { m ->

            "\"${m.value.substring(1, m.value.length - 1).replace("\"", "\'")}\""
        }.replace("\\", "")
        s = s.replace("#^", "\\\"")
        return s
    }

    fun ultimateFixJson(s: String): String {
        var s =
            s
        var regex = Regex("(\'\\[\\{[^\\[\\]]+\\}\\]\')")
        s = regex.replace(s) { m ->

            m.value.replace("\"", "#^").replace("\'", "#$").replace("\\", "#&")
        }
        regex = Regex("(\'\\[\\{[^\\]]+\\[[^\\[]+\\][^\\[\\]]+\\}\\]\')")
        s = regex.replace(s) { m ->

            m.value.replace("\"", "#^").replace("\'", "#$").replace("\\", "#&")
        }
        s = fixJson(s)
        regex = Regex("(\'[^':{}]+\')")
        s = s.replace("\\\"", "#^")
        s = regex.replace(s) { m ->

            "\"${m.value.substring(1, m.value.length - 1).replace("\"", "\'")}\""
        }.replace("\\", "")
        s = (s.replace("#&", "\\").replace("#$", "\"").replace("#^", "\'"))

        regex = Regex("(\"\\[\\{[^\\[\\]]+\\}\\]\")")
        s = regex.replace(s) { m ->
            "\"${fixJson(m.value).substring(1, m.value.length - 1)}\""
        }
        regex = Regex("(\"\\[\\{[^\\]]+\\[[^\\[]+\\][^\\[\\]]+\\}\\]\")")
        s = regex.replace(s) { m ->
            "\"${fixJson(m.value).substring(1, m.value.length - 1)}\""
        }
        s = s.replace("#^", "\\\"")
        return s
    }

    suspend fun refreshToken(): Boolean {
        try {

            val response = httpClient.post<HttpResponse>(NewDBAction.REFRESH_TOKEN.getURL()) {
                headers {
                    append("Accept", "application/json")
                    append(HttpHeaders.Authorization, " Bearer $curRefToken")
                }
                body = MultiPartFormDataContent(
                    formData {
                    }
                )
            }
            return if (response.status == HttpStatusCode.OK) {
                val text = response.readText()
                val elem = getJsonWithConfig().parseToJsonElement(text) as JsonObject
                val networkOutput = analyzeResponse(elem)
                analyzeToken(elem)
                networkOutput == NetworkOutput.SUCCESS
            } else {
                false
            }
        } catch (e: Exception) {
            return false
        }
    }

    suspend fun login(
        url: String,
        params: MutableMap<String, String>,
        autoCompany: Boolean,
        fixJsonType: FixJsonType = FixJsonType.simple
    ): Respone<String?, NetworkOutput> {
        try {
            if (autoCompany)
                params.putIfAbsent("company_id", companyId)
            val response = httpClient.post<HttpResponse>(url) {
                headers {
                    append("Accept", "application/json")

                }
                body = MultiPartFormDataContent(
                    formData {
                        params.forEach { p ->
                            append(p.key, p.value)
                        }

                    }
                )
            }
            if (response.status == HttpStatusCode.OK) {
                val text = response.readText()
                val elem = getJsonWithConfig().parseToJsonElement(text) as JsonObject
                val networkOutput = analyzeResponse(elem)
                analyzeToken(elem)
                if (networkOutput == NetworkOutput.SUCCESS) {
                    var NKResponse = elem["status_msg"].toString()
                    NKResponse = NKResponse.substring(1, NKResponse.length - 1)
                    NKResponse = when (fixJsonType) {
                        FixJsonType.complex ->
                            ultimateFixJson(NKResponse)

                        FixJsonType.simple -> simpleFixJson(NKResponse)
                        FixJsonType.light -> ligthFixJson(NKResponse)
                        FixJsonType.lightForInner -> lightForInnerFixJson(NKResponse)
                        FixJsonType.NONE -> NKResponse
                    }


                    return Respone(NKResponse, NetworkOutput.SUCCESS)
                } else {
                    return Respone(null, networkOutput)
                }
            } else {
                return badResult()
            }
        } catch (e: Exception) {
            return badResult()
        }
    }

    override suspend fun post(
        url: String,
        params: MutableMap<String, String>,
        autoCompany: Boolean,
        fixJsonType: FixJsonType,
        token_refresh_counter: Int,
        timeout: Long,
        withToken: Boolean
    ): Respone<String?, NetworkOutput> {
        if (token_refresh_counter == 0) {
            return Respone(null, NetworkOutput.TOKEN_ERROR)
        }
        try {
            if (autoCompany)
                params.putIfAbsent("company_id", companyId)
            val response = httpClient.post<HttpResponse>(url) {
                timeout {
                    requestTimeoutMillis = timeout
                    connectTimeoutMillis = 10000
                    socketTimeoutMillis = timeout
                }
                headers {
                    append("Accept", "application/json")
                    if(withToken)
                    append(HttpHeaders.Authorization, " Bearer $curToken")
                }
                body = MultiPartFormDataContent(
                    formData {
                        params.forEach { p ->
                            append(p.key, p.value)
                        }

                    }
                )
            }
            if (response.status == HttpStatusCode.OK) {
                val text = response.readText()
                val elem = getJsonWithConfig().parseToJsonElement(text) as JsonObject
                val networkOutput = analyzeResponse(elem)
                when (networkOutput) {
                    NetworkOutput.SUCCESS -> {
                        var NKResponse = elem["status_msg"].toString()
                        NKResponse = NKResponse.substring(1, NKResponse.length - 1)
                        NKResponse = when (fixJsonType) {
                            FixJsonType.complex ->
                                ultimateFixJson(NKResponse)

                            FixJsonType.simple -> simpleFixJson(NKResponse)
                            FixJsonType.light -> ligthFixJson(NKResponse)
                            FixJsonType.lightForInner -> lightForInnerFixJson(NKResponse)
                            FixJsonType.NONE -> NKResponse
                        }


                        return Respone(NKResponse, NetworkOutput.SUCCESS)
                    }

                    NetworkOutput.TOKEN_ERROR -> {
                        refreshToken()

                        return post(url, params, autoCompany, fixJsonType, token_refresh_counter - 1)
                    }

                    else -> {
                        return Respone(null, networkOutput)
                    }
                }
            } else {
                return badResult()
            }
        } catch (e: Exception) {
            if (e.toString().contains("Token has expired")) {
                refreshToken()
                return post(url, params, autoCompany, fixJsonType, token_refresh_counter - 1)
            }
            return badResult()
        }
    }

    override suspend fun post_file(
        url: String,
        params: MutableMap<String, String>,
        autoCompany: Boolean,
        token_refresh_counter: Int,
        timeout: Long
    ): Respone<ByteArray?, NetworkOutput> {

        try {
            if (autoCompany)
                params.putIfAbsent("company", companyName)
            val response = httpClient.post<HttpResponse>(url) {
                timeout {
                    requestTimeoutMillis = timeout
                    connectTimeoutMillis = 10000
                    socketTimeoutMillis = timeout
                }
                headers {
                    append("Accept", "application/json")
                    append(HttpHeaders.Authorization, " Bearer $curToken")
                }
                body = MultiPartFormDataContent(
                    formData {
                        params.forEach { p ->
                            append(p.key, p.value)
                        }

                    }
                )
            }
            return if (response.status == HttpStatusCode.OK) {
                Respone(response.readBytes(), NetworkOutput.SUCCESS)
            } else {
                Respone(null, NetworkOutput.ERROR_GENERIC)
            }
        } catch (e: Exception) {
            return Respone(null, NetworkOutput.ERROR_GENERIC)
        }
    }

    override suspend fun upload_img(
        url: String,
        params: MutableMap<String, String>,
        f: ByteArray,
        image_name: String,
        autoCompany: Boolean,
        token_refresh_counter: Int
    ): Respone<String?, NetworkOutput> {

        try {
            if (autoCompany)
                params.putIfAbsent("company_id", companyId)
            val response: HttpResponse = httpClient.submitFormWithBinaryData(
                url = url,
                formData = formData {
                    params.forEach { p ->
                        append(p.key, p.value)
                    }
                    append("description", image_name)
                    append("image", f, Headers.build {
                        append(HttpHeaders.ContentType, "image/png")
                        append(HttpHeaders.ContentDisposition, "filename=$image_name.png")
                    })
                }
            ) {
                timeout {
                    requestTimeoutMillis = 40000
                    connectTimeoutMillis = 10000
                    socketTimeoutMillis = 40000
                }
                headers {
                    append(HttpHeaders.Authorization, " Bearer $curToken")
                }
            }

            return if (response.status == HttpStatusCode.OK) {
                val text = response.readText()
                val elem = getJsonWithConfig().parseToJsonElement(text) as JsonObject
                val networkOutput = analyzeResponse(elem)
                analyzeToken(elem)
                if (networkOutput == NetworkOutput.SUCCESS) {
                    var NKResponse = elem["status_msg"].toString()
                    NKResponse = NKResponse.substring(1, NKResponse.length - 1)
                    NKResponse = simpleFixJson(NKResponse)
                    return Respone(NKResponse, NetworkOutput.SUCCESS)
                } else {
                    Respone(null, networkOutput)
                }

            } else {
                Respone(null, NetworkOutput.ERROR_GENERIC)
            }
        } catch (e: Exception) {
            return Respone(null, NetworkOutput.ERROR_GENERIC)
        }
    }

    override suspend fun upload_file(
        url: String,
        params: MutableMap<String, String>,
        f: ByteArray?,
        fixJsonType: FixJsonType,
        autoCompany: Boolean,
        token_refresh_counter: Int,
        f_name: String?,
        f_mime_type: String?
    ): Respone<String?, NetworkOutput> {

        try {
            if (autoCompany)
                params.putIfAbsent("company_id", companyId)
            val response: HttpResponse = httpClient.submitFormWithBinaryData(
                url = url,
                formData = formData {
                    params.forEach { p ->
                        append(p.key, p.value)
                    }
                    f?.let {
                        append("file", f, Headers.build {
                            append(HttpHeaders.ContentType, f_mime_type ?: "application/pdf")
                            append(HttpHeaders.ContentDisposition, "filename=${f_name ?: "test.pdf"}")
                        })
                    }
                }
            ) {
                timeout {
                    requestTimeoutMillis = 45000
                    connectTimeoutMillis = 10000
                    socketTimeoutMillis = 45000
                }
                headers {
                    append(HttpHeaders.Authorization, " Bearer $curToken")
                }
            }

            return if (response.status == HttpStatusCode.OK) {
                val text = response.readText()
                val elem = getJsonWithConfig().parseToJsonElement(text) as JsonObject
                val networkOutput = analyzeResponse(elem)
                analyzeToken(elem)
                if (networkOutput == NetworkOutput.SUCCESS) {
                    var NKResponse = elem["status_msg"].toString()
                    NKResponse = NKResponse.substring(1, NKResponse.length - 1)
                    NKResponse = when (fixJsonType) {
                        FixJsonType.complex ->
                            ultimateFixJson(NKResponse)

                        FixJsonType.simple -> simpleFixJson(NKResponse)
                        FixJsonType.light -> ligthFixJson(NKResponse)
                        FixJsonType.lightForInner -> lightForInnerFixJson(NKResponse)
                        FixJsonType.NONE -> NKResponse
                    }
                    return Respone(NKResponse, NetworkOutput.SUCCESS)
                } else {
                    Respone(null, networkOutput)
                }

            } else {
                Respone(null, NetworkOutput.ERROR_GENERIC)
            }
        } catch (e: Exception) {
            return Respone(null, NetworkOutput.ERROR_GENERIC)
        }
    }

    suspend fun pureGet(url: String): String {
        try {
            val response = httpClient.get<HttpResponse>(url) {
                headers {
                    append("Connection", "close")
                }
            }
            return if (response.status == HttpStatusCode.OK) {
                return response.readText()
            } else {
                return ""

            }
        } catch (e: Exception) {
            e.message?.let {
                if (it.contains("Unexpected status line: \r0")) {
                    return it.split("Unexpected status line: \r0")[1]
                }
            }
            return ""
        }
    }

    val webSocketClient = HttpClient {
        install(WebSockets)
    }

    suspend fun webSocket(url: String, port: Int): String {
        var weight: String = ""
        try {
            webSocketClient.ws(
                method = HttpMethod.Get,
                host = url,
                port = port
            ) { // this: DefaultClientWebSocketSession
                val hey = 2
                // Send text frame.
                send("W")

                // Receive frame.
                val frame = incoming.receive()
                when (frame) {
                    is Frame.Text -> weight = (frame.readText())
                }
                return@ws
            }
        } catch (e: Exception) {
            println(e.message)
            return ""
        }
        return ""
    }
}