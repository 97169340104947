package Structs

import DataBase.UINKDBInterface
import kotlinx.serialization.Serializable

@Serializable
data class HashavshevetS(
    val movein: Int,
    val imovein: Int,
    val maam: String,
    val income_with: String,
    val income_without: String,
    val cash_payment: String,
    val bank_payment: String,
    val check_payment: String,
    val other_payments: String,
    val mass_bamacor:String,
    val other_clients: Int,
    val clientsIndex:Int,
    val productsIndex:Int,
    val tax_move:String,
    val tax_cancel_move:String,
    val pay_move:String,
    val hand_sum_with:String,
    val hand_sum_without:String,
    val note_sum_with:String,
    val note_sum_without:String,
    val taxnote_sum_with:String,
    val taxnote_sum_without:String,
    val rivhit:Int = 0 // 1 if rivhit ,2,3    10- bkmvdata

) {
    val to_rivhit = rivhit ==1
}