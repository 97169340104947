package Structs

import Utils.DatesManipulator
import Utils.number_to_month
import kotlinx.serialization.Serializable

@Serializable
class MonthlyCycle(
    val date: String,
    val id: Int,
    var value: Float,
    var paid: Float,
    var date_updated: String? = null,
    var date_paid: String? = null,
    var openValue:Float?=null,
) : InformationBase() {
    override fun getConnectedDate(): String {
        return date
    }

    override fun getConnectedId(): Int {
        return id
    }

    override fun getConnectedEntId(): Int {
        return id
    }

    override fun getConnectedValue(): Float {
        return value
    }

    override fun getConnectedName(): String {
        return ""
    }

    override fun toString(): String {
        return date.toString().split(" ").reversed().joinToString(" ")
    }


    fun toStringHebrewMonth(): String {
        val d = DatesManipulator
            .toStringWithoutime(date)
        val month = d.split("-")[1]
        val hebrew_month = number_to_month[month.toInt()]
        val year = d.split("-")[0]
        return "$year-$hebrew_month"
    }

}
