package Utils

import DataBase.DB
import DataBase.DBQuery
import DataBase.UINKDBInterface
import Structs.DeliveryNote
import Structs.EdiMember
import Structs.Note
import Structs.ProductDelivery
import printPlat

class storeNextLoader(  val data: List<List<String>>,
                        val map: Map<String, Int>,
                        val date: String,
                        ) {
    val SUPPLIER_NUMBER = "מספר ספק"
    val SUPPLIER_NAME = "שם ספק"
    val NETWORK_NUMBER = "מספר רשת"
    val NETWORK_NAME = "שם רשת"
    val BRANCH_NUMBER = "מספר סניף"
    val BRANCH_NAME = "שם סניף"
    val BRANCH_NUMBER_IN_NETWORK = "מספר סניף ברשת"
    val ENTRY_DOCUMENT_NUMBER = "מספר תעודת כניסה"
    val BILL_NUMBER = "מספר בול"
    val BARCODE = "barcode"
    val ITEM_NAME = "item name"
    val ENTRY_DOCUMENT_DATE = "תאריך תעודת כניסה"
    val DELIVERY_DOCUMENT_NUMBER = "מספר תעודת משלוח"
    val ENTRY_DOCUMENT_TYPE = "סוג תעודת הכניסה"
    val QUANTITY = "Quantity"

    enum class IssueType {
        DOCUMENT_NOT_FOUND,
        DOCUMENT_NOT_MATCH,
        DOCUMENT_NOT_MATCH_QUANTITY,
        DOCUMENT_NOT_MATCH_QUANTITY_EMPTY,
        DOCUMENT_NOT_MATCH_ITEM,
        DOCUMENT_NOT_MATCH_CLIENT,
        DOCUMENT_MISSING_BULL,

        DOCUMENT_WRONG_BULL,

        NK_NO_EDI_MEMBER,
        EDI_NO_MATCH_DOC,
        EDI_NO_MATCH_DOC_BUT_MATCH_ITEM;

        fun translate(issue: IssueType): String {
            return when (issue) {
                DOCUMENT_NOT_FOUND -> "תעודת כניסה לא נמצאה במערכת"
                DOCUMENT_NOT_MATCH -> "תעודת כניסה לא תואמת למספר ספק ומספר סניף"
                DOCUMENT_NOT_MATCH_QUANTITY -> "תעודת כניסה לא תואמת לכמות"
                DOCUMENT_NOT_MATCH_QUANTITY_EMPTY -> "כמות ריקה בסטורנקסט למוצר"
                DOCUMENT_NOT_MATCH_ITEM -> "תעודת כניסה לא תואמת לפריט"
                DOCUMENT_NOT_MATCH_CLIENT -> "תעודת כניסה לא תואמת ללקוח"
                DOCUMENT_MISSING_BULL -> "תעודת כניסה לא מכילה מספר בול"
                DOCUMENT_WRONG_BULL -> "תעודת כניסה מכילה מספר בול שגוי"
                NK_NO_EDI_MEMBER -> "NK לא חבר בEDI"
                EDI_NO_MATCH_DOC -> "EDI לא תואם לתעודת כניסה"
                EDI_NO_MATCH_DOC_BUT_MATCH_ITEM -> "EDI לא תואם לתעודת כניסה אבל תואם לפריט"

            }

        }

        fun allowed(issue: IssueType): Boolean {
            return when (issue) {
                DOCUMENT_NOT_FOUND -> true
                DOCUMENT_NOT_MATCH -> true
                DOCUMENT_NOT_MATCH_QUANTITY -> true
                DOCUMENT_NOT_MATCH_QUANTITY_EMPTY -> false
                DOCUMENT_NOT_MATCH_ITEM -> true
                DOCUMENT_NOT_MATCH_CLIENT -> true
                DOCUMENT_MISSING_BULL -> false
                DOCUMENT_WRONG_BULL -> false
                NK_NO_EDI_MEMBER -> true
                EDI_NO_MATCH_DOC -> true
                EDI_NO_MATCH_DOC_BUT_MATCH_ITEM -> true

            }

        }

        override fun toString(): String {
            return translate(this)
        }
    }

        data class OrderIssue(val issueType: IssueType, val issue: String, val data: String) {
            override fun toString(): String {
                return "${prepareStrToJson( issue)} ${prepareStrToJson(data)}"
            }
        }

        data class Order(
            val supplierNumber: String,
            val supplierName: String,
            val networkNumber: String,
            val networkName: String,
            val branchNumber: String,
            val branchName: String,
            val branchNumberInNetwork: Int,
            val entryDocumentNumber: String,
            val billNumber: String,
            val barcode: String,
            val itemName: String,
            val entryDocumentDate: String,
            val deliveryDocumentNumber: Int,
            val entryDocumentType: String,
            val quantity: Float,
            val index: Int,
            val issues: MutableList<OrderIssue> = mutableListOf()
        )
        lateinit var document: List<DeliveryNote>
        var rows: MutableList<Order> = mutableListOf()
        lateinit var ediClients: List<EdiMember>
        lateinit var edtClientMapId: Map<Int, EdiMember>
        lateinit var edtClientMapBranch: Map<String, EdiMember>
        val noteIssues: MutableMap<Int, MutableList<OrderIssue>> = mutableMapOf()
        val seen = mutableSetOf<Int>()

    suspend fun  init(){
        printPlat("yotam1")
        val q = UINKDBInterface.activeDB.buildClientNotes(fromDate = date, toDate = date)
        printPlat("yotam2")
        ediClients = UINKDBInterface.activeDB.buildClientEdiMap(date).first
        printPlat("yotam3")
        edtClientMapId = ediClients.associate { it.client_id to it }
        edtClientMapBranch = ediClients.associate { it.edi_client_id to it }
        document = q.first
        printPlat("yotam4")
    }
    fun load(date: String?=null){
        data.forEachIndexed { index, list->
            val supplierNumber = list[map[SUPPLIER_NUMBER]!!].trim()
            val supplierName = list[map[SUPPLIER_NAME]!!].trim()
            val networkNumber = list[map[NETWORK_NUMBER]!!].trim()
            val networkName = list[map[NETWORK_NAME]!!].trim()
            val branchNumber = list[map[BRANCH_NUMBER]!!].trim()
            val branchName = list[map[BRANCH_NAME]!!].trim()
            val branchNumberInNetwork = (list[map[BRANCH_NUMBER_IN_NETWORK]!!].trim()).toIntOrNull() ?: 0
            val entryDocumentNumber = list[map[ENTRY_DOCUMENT_NUMBER]!!].trim()
            val billNumber = list[map[BILL_NUMBER]!!].trim()
            val barcode = list[map[BARCODE]!!].trim()
            val itemName = list[map[ITEM_NAME]!!].trim()
            val entryDocumentDate = date?:DatesManipulator.dateIsrael((list[map[ENTRY_DOCUMENT_DATE]!!].trim()).split(" ")[0].replace("/", "-"))
            val deliveryDocumentNumber =(list[map[DELIVERY_DOCUMENT_NUMBER]!!].trim()).toIntOrNull() ?: 0
            val entryDocumentType = list[map[ENTRY_DOCUMENT_TYPE]!!].trim()
            val quantity = (list[map[QUANTITY]!!].trim()).toFloatOrNull() ?: 0.0f

            rows.add(
                storeNextLoader.Order(
                    supplierNumber,
                    supplierName,
                    networkNumber,
                    networkName,
                    branchNumber,
                    branchName,
                    branchNumberInNetwork,
                    entryDocumentNumber,
                    billNumber,
                    barcode,
                    itemName,
                    entryDocumentDate,
                    deliveryDocumentNumber,
                    entryDocumentType,
                    quantity,
                    index
                )
            )
        }

    }

    fun compareProductQuantity(order: List<Order>, pds: List<ProductDelivery>, dv: Note): Boolean {
        val pdsMap = pds.associateBy { it.getProduct().barcode }
        val orderMap = order.associateBy { it.barcode }
        var ok = true
        pdsMap.forEach {
            val pd = it.value
            val o = orderMap[it.key]
            if (o == null) {
                // no order for this product
                order.forEach {
                    it.issues.add(
                        OrderIssue(
                            IssueType.DOCUMENT_NOT_MATCH_ITEM,
                            "מוצר לא נמצא בתעודת כניסה",
                            pd.getProduct().barcode
                        )
                    )

                }
                noteIssues.getOrPut(dv.id, { mutableListOf() }).add(
                    OrderIssue(
                        IssueType.DOCUMENT_NOT_MATCH_ITEM,
                        "מוצר לא נמצא בתעודת כניסה",
                        pd.getProduct().barcode
                    )
                )
                ok = false
            } else {
                if (o.quantity == 0f) {
                    o.issues.add(
                        OrderIssue(
                            IssueType.DOCUMENT_NOT_MATCH_QUANTITY_EMPTY,
                            "כמות ריקה בתעודת כניסה",
                            pd.getProduct().barcode
                        )
                    )
                    noteIssues.getOrPut(dv.id, { mutableListOf() }).add(
                        OrderIssue(
                            IssueType.DOCUMENT_NOT_MATCH_QUANTITY_EMPTY,
                            "כמות ריקה בתעודת כניסה",
                            pd.getProduct().barcode
                        )
                    )
                    ok = false
                    return@forEach
                }
                val multFactor: Float =
                    if (pd.getProduct().unit_amount > 0) pd.getProduct().unit_amount else 1f

                if (pd.value > 0) {
                    val value = pd.value * multFactor
                    if (value != o.quantity) {
                        o.issues.add(
                            OrderIssue(
                                IssueType.DOCUMENT_NOT_MATCH_QUANTITY,
                                "כמות לא תואמת",
                                pd.getProduct().barcode
                            )
                        )
                        noteIssues.getOrPut(dv.id, { mutableListOf() }).add(
                            OrderIssue(
                                IssueType.DOCUMENT_NOT_MATCH_QUANTITY,
                                "כמות לא תואמת",
                                pd.getProduct().barcode
                            )
                        )
                        ok = false
                    }

                }
                if (pd.returns > 0) {
                    val value = -pd.returns
                    if (value != o.quantity) {
                        o.issues.add(
                            OrderIssue(
                                IssueType.DOCUMENT_NOT_MATCH_QUANTITY,
                                "כמות לא תואמת",
                                pd.getProduct().barcode
                            )
                        )
                        noteIssues.getOrPut(dv.id, { mutableListOf() }).add(
                            OrderIssue(
                                IssueType.DOCUMENT_NOT_MATCH_QUANTITY,
                                "כמות לא תואמת",
                                pd.getProduct().barcode
                            )
                        )
                        ok = false
                    }
                }
            }
        }




        return ok

    }

    fun finish() {
        //do work with rows
        val orders = rows.groupBy { it.deliveryDocumentNumber }
        val storeNextByBull = rows.groupBy { it.billNumber }
        val notes = document.map { it.id to it }.toMap()
        val notesByBull = document.groupBy { it.connected_id.toString() }
        printPlat("hey1")
        document.filter { it.ent_id in edtClientMapId }.forEach { dv ->
            val o = orders[dv.delivery_id]
            seen.add(dv.delivery_id)  // mark as seen
            if (o == null) {
                // weird situation where the note is not found in storenext can happen if the supermarket typed wrong
                noteIssues.getOrPut(dv.delivery_id, { mutableListOf() })
                    .add(OrderIssue(IssueType.DOCUMENT_NOT_FOUND, "מסמך לא נמצא בסטורנקסט", ""))
            } else {
                val ediMember = edtClientMapId[dv.ent_id]
                if (ediMember == null) {
//                    o.forEach { it.issues.add(OrderIssue(IssueType.NK_NO_EDI_MEMBER, "לקוח ללא EDI", "")) }
//                    noteIssues.getOrPut(dv.delivery_id, { mutableListOf() }).add(OrderIssue(IssueType.NK_NO_EDI_MEMBER, "לקוח ללא EDI", ""))
                } else {
//                    val netCompare = ediMember.net_edi == o[0].networkNumber
                    val branchCompare = o[0].branchNumber.contains(ediMember.edi_client_id)
//                    val ediClientCompare = ediMember.branch_number == o[0].branchNumberInNetwork.toString()

                    if (!branchCompare) {
                        ediClients.firstOrNull { o[0].branchNumber.contains(it.edi_client_id) }
                            ?.let {
                                printPlat("hey2")
                                val curClient = UINKDBInterface.activeDB.getClient(dv.ent_id).first!!
                                printPlat("hey3")
                                o.forEach {
                                    it.issues.add(
                                        OrderIssue(
                                            IssueType.DOCUMENT_NOT_MATCH_CLIENT,
                                            "נהג התבלבל בין חנויות  ",
                                            "${curClient.getName()} במקום ${it.branchName}"
                                        )
                                    )
                                }
                                noteIssues.getOrPut(dv.delivery_id, { mutableListOf() }).add(
                                    OrderIssue(
                                        IssueType.EDI_NO_MATCH_DOC,
                                        "אחד מפרטי EDI של הלקוח אינו תואם",
                                        "נהג התבלבל בין חנויות  ${curClient.getName()} במקום ${o[0].branchName}"
                                    )
                                )

                            } ?: let {
                            o.forEach {
                                it.issues.add(
                                    OrderIssue(
                                        IssueType.DOCUMENT_NOT_MATCH_CLIENT,
                                        "סניף ברשת לא תואם ואין לקוח בניהול קו עם EDI כזה",
                                        ""
                                    )
                                )
                            }
                        }

                    }
                }
                val connectedId = dv.connected_id
                if (connectedId == (-1).toLong()) {
                    o.forEach { it.issues.add(OrderIssue(IssueType.DOCUMENT_MISSING_BULL, "חסר בול למסמך", "")) }
                    noteIssues.getOrPut(dv.delivery_id, { mutableListOf() })
                        .add(OrderIssue(IssueType.DOCUMENT_MISSING_BULL, "חסר בול למסמך", ""))

                } else if (o[0].billNumber != connectedId.toString()) {
                    o.forEach { it.issues.add(OrderIssue(IssueType.DOCUMENT_WRONG_BULL, "בול לא תואם", "")) }
                    noteIssues.getOrPut(dv.delivery_id, { mutableListOf() })
                        .add(OrderIssue(IssueType.DOCUMENT_WRONG_BULL, "בול לא תואם", ""))
                }
                if (!compareProductQuantity(o, dv.delivery_info, dv)) {
//                    noteIssues.getOrPut(dv.delivery_id, { mutableListOf() }).add(OrderIssue(IssueType.DOCUMENT_NOT_MATCH_QUANTITY, "כמויות לא תואמות", ""))
                }
            }
        }
        printPlat("hey12")
        orders.entries.forEach { (t, o) ->
            if (t !in seen) {
                val dv = notes[t]
                if (dv == null) {
                    o.forEach {
                        it.issues.add(
                            OrderIssue(
                                IssueType.DOCUMENT_NOT_FOUND,
                                "תעודה לא נמצאה",
                                it.deliveryDocumentNumber.toString()
                            )
                        )
                    }
                } else {

                    val ediMember = edtClientMapId[dv.ent_id]
                    if (ediMember == null) {
                        o.forEach { it.issues.add(OrderIssue(IssueType.NK_NO_EDI_MEMBER, "לקוח ללא EDI", "")) }
                        noteIssues.getOrPut(dv.delivery_id, { mutableListOf() })
                            .add(OrderIssue(IssueType.NK_NO_EDI_MEMBER, "לקוח ללא EDI", ""))

                    } else {

                        val branchCompare = ediMember.edi_client_id == o[0].branchNumber

                        if (!branchCompare) {
                            ediClients.firstOrNull { it.branch_number == o[0].branchNumberInNetwork.toString() || it.edi_client_id == o[0].branchNumber }
                                ?.let {
                                    printPlat("hey4")
                                    val curClient = UINKDBInterface.activeDB.getClient(dv.ent_id).first!!
                                    printPlat("hey5")
                                    o.forEach {
                                        it.issues.add(
                                            OrderIssue(
                                                IssueType.DOCUMENT_NOT_MATCH_CLIENT,
                                                "נהג התבלבל בין חנויות  ",
                                                "${curClient.getName()} במקום ${it.branchName}"
                                            )
                                        )
                                    }
                                    noteIssues.getOrPut(dv.delivery_id, { mutableListOf() }).add(
                                        OrderIssue(
                                            IssueType.EDI_NO_MATCH_DOC,
                                            "אחד מפרטי EDI של הלקוח אינו תואם",
                                            "נהג התבלבל בין חנויות  ${curClient.getName()} במקום ${o[0].branchName}"
                                        )
                                    )

                                } ?: let {
                                o.forEach {
                                    it.issues.add(
                                        OrderIssue(
                                            IssueType.DOCUMENT_NOT_MATCH_CLIENT,
                                            "סניף ברשת לא תואם ואין לקוח בניהול קו עם EDI כזה",
                                            ""
                                        )
                                    )
                                }
                            }

                        }
                    }
                    val connectedId = dv.connected_id
                    if (connectedId == (-1).toLong()) {
                        o.forEach { it.issues.add(OrderIssue(IssueType.DOCUMENT_MISSING_BULL, "חסר בול למסמך", "")) }
                        noteIssues.getOrPut(dv.delivery_id, { mutableListOf() })
                            .add(OrderIssue(IssueType.DOCUMENT_MISSING_BULL, "חסר בול למסמך", ""))

                    } else if (o[0].billNumber != connectedId.toString()) {
                        o.forEach { it.issues.add(OrderIssue(IssueType.DOCUMENT_WRONG_BULL, "בול לא תואם", "")) }
                        var addText = ""
                        noteIssues.getOrPut(dv.delivery_id, { mutableListOf() })
                            .add(OrderIssue(IssueType.DOCUMENT_WRONG_BULL, "בול לא תואם", ""))
                    }
                    if (!compareProductQuantity(o, dv.delivery_info, dv)) {
//                        noteIssues.getOrPut(dv.delivery_id, { mutableListOf() }).add(OrderIssue(IssueType.DOCUMENT_NOT_MATCH_QUANTITY, "כמויות לא תואמות", ""))
                    }
                }

            }

        }

    }

    open fun writeTofile():String {
        val f = StringBuilder()

        noteIssues.forEach {
            val problems = it.value.filter { it.issueType.allowed(it.issueType) }
            if (problems.isNotEmpty())
                f.append("${it.key}\n\t${problems.joinToString("\n\t")}\n")
        }
    return f.toString()
    }
    fun writeToJsonStr():String {
        val f = mutableListOf<String>()

        var i=0
        noteIssues.forEach {
            val problems = it.value.filter { it.issueType.allowed(it.issueType) }
            if (problems.isNotEmpty()){
                problems.forEach {oI->
                    f.add("{" +
                            "\"line\": ${it.key}," +
                            "\"status\": 1," +
                            "\"msg\": \"${oI}\"" +"}")
                }

            }

        }
        return "[${f.joinToString(",")}]"
    }
}