package Structs.Api

val METHOD_NAME = listOf<String>(
    "סטורנקסט", "מנגר", "וואן", "חשבשבת","אימייל","ווטסאפ","חישובית","פריוריטי"

,"לסר פריוריטי"
)

enum class Api(val state: Int = 0) {
    STORENEXT(0),
    MANAGER(1),
    ONE(2),
    HASHAVSHEVET(3),
    EMAIL(4),
    WHATSAPP(5),
    HISHUVIT(6),
    PRIORITY(7),
    LESSER(8)
    ;

    fun convert(ps: Int): Api {
        return when (ps) {
            0 -> {
                STORENEXT
            }
            1 -> {
                MANAGER
            }
            2 -> {
                ONE
            }
            3 -> {
                HASHAVSHEVET
            }
            4 -> {
                EMAIL
            }
            5 -> {
                WHATSAPP
            }
            6 -> {
                HISHUVIT
            }
            7 -> {
                PRIORITY
            }
            8 -> {
                LESSER
            }

            else -> {
                throw NotImplementedError()
            }
        }
    }

    fun MethodName(): String {
        return METHOD_NAME[this.state]

    }

}
