package Structs

import DataBase.UINKDBInterface
import kotlinx.serialization.Serializable
import myName
import kotlin.js.JsExport


@myName("CofcConf")
@Serializable
data class CofcConf(
    val state: Int,
    val date_control: Int,
    val with_price: Int,
    val conversion_rate_blocker: Int, // 0 = on first not blocking, 1 second blocking,2 second no blocking, 3 first blocking
    val filterOnlyUse: Int,
    val sortByCategory: Int,
    val without_images: Int,
    val product_id: Int,
    val time_close: String? = null,
    val daily_link: Int,
    val daily_close: Int,
    val auto_open_without_cart: Int,
    val text: String? = null,
    val stock:Int=0,
    val credit:Int=0,
    val multi_order:Int=0,
    val no_need:Int=0,
    val need_english:Int=0,
    val need_notes:Int=0,
    val base_order:Int=0,
) {
    fun second(): Boolean {
        return conversion_rate_blocker == 1 || conversion_rate_blocker == 2
    }

    fun first(): Boolean {
        return conversion_rate_blocker == 0 || conversion_rate_blocker == 3
    }

    fun block(): Boolean {
        return conversion_rate_blocker == 1 || conversion_rate_blocker == 3
    }

    fun url(client: Client, token: String, date: String): String? {
        val tex = if (text.isNullOrEmpty())
            "שלום ${client.getName()} ${client.getOutId()}אנא בצע הזמנה עבור ${UINKDBInterface.activeDB.getUser().first?.user_company_print_name}"
        else {
            var t = text.replace("#name", client.getName())
            t = t.replace("#id", client.id.toString())
            t = t.replace("#external_id", client.getExternalId())
            t = t.replace("#date", date)
            t = t.replace("\\n", "%0a")
            t
        }

        return "`${tex} %0a %0a https://nihulkav.shop?token=${token}&date=${date} %0a";
    }
}

