package Structs

import Utils.DatesManipulator
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import myName

@myName("Available")
@Serializable
data class Available(val id: Int, val product_id: Int, val available: Int,val priority:Float=0.0f,val inventory:Float=0.0f,val master:Int?=null) {

    fun toJson(): JsonObject {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
        map["id"] = JsonPrimitive(id.toString())
        map["product_id"] = JsonPrimitive(product_id.toString())
        map["available"] = JsonPrimitive(available.toString())
        return JsonObject(map)
    }

    companion object {
        fun newAvailable(id: Int, product_id: Int, available: Int): Available {

            return Available(id, product_id, available)

        }
    }
}