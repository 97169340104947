package Structs


import DataBase.UINKDBInterface
import Utils.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import myName

@myName("ClientTaxNoteData")
@Serializable
data class ClientTaxNoteData(
    val value: Float,
    val fill_type: Int = 0,
    var details: String = "",
    val taxPaid: Float? = null,
    val amount: Float? = null
) {
    val fillTaxNoteType: FillTaxNoteType = fill_type.let { FillTaxNoteType.HAND.convert(it) }
    fun getValueBefore(date: String = DatesManipulator.dateNowNoHour()): Float {
        if (taxPaid != null)
            return value - taxPaid

        val tax = UINKDBInterface.activeDB.tax.get(date)
        return value / tax
    }

    fun toJson(): JsonObject {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
        map["value"] = JsonPrimitive(roundToDecimals( value,2).toString())
        map["fill_type"] = JsonPrimitive(fill_type.toString())
        map["details"] = JsonPrimitive(
            if (fillTaxNoteType == FillTaxNoteType.PRODUCTS) {
                details.replace("\"", "@") //issue with inserting json inside json as string
            } else
                details

        )
        taxPaid?.let {
            map["taxPaid"] = JsonPrimitive(roundToDecimals( it,2).toString())
        }
        amount?.let {
            map["amount"] = JsonPrimitive(roundToDecimals( it,2).toString())
        }
        return JsonObject(map)
    }

    companion object {
        fun createFromJson(js: String): List<ClientTaxNoteData> {
            return (getJsonWithConfig().parseToJsonElement((js).replace("\\'", "'")) as JsonArray).map {
                getJsonWithConfig().decodeFromJsonElement(ClientTaxNoteData.serializer(), it)
            }
        }

        fun toJsonArrayString(noteData: List<ClientTaxNoteData>): String {
            return if (noteData.isEmpty()) {
                "[]"
            } else {
                val payload = noteData.map {
                    getJsonWithConfig().encodeToJsonElement(JsonObject.serializer(), it.toJson()).toString()
                }.joinToString(",")
                return "[$payload]"
            }

        }
    }
}
