package Structs
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import myName

@myName("ClientVisitHolder")
@Serializable
class ClientVisitHolder(
    val ent_id: Int,
    @SerialName("dt")
    val doc_type:Int,
    val counter:Int,
) {
}