package com.example.nihulkavapp.Backend.Network

import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import myName

abstract class JsCoroutinesAsyncTask<Result>{

    var status: Status = Status.PENDING
    abstract suspend fun doInBackground(): Result
    open fun onPostExecute(result: Result?) {}
    open fun onPreExecute() {}
    open fun onCancelled(result: Result?) {}
    protected var isCancelled = false
    @myName("execute")
    fun execute(){

        if (status != Status.PENDING) {
            when (status) {
                Status.RUNNING -> throw IllegalStateException("Cannot execute task:" + " the task is already running.")
                Status.FINISHED -> throw IllegalStateException("Cannot execute task:"
                        + " the task has already been executed "
                        + "(a task can be executed only once)")
            }
        }

        status = Status.RUNNING

        // it can be used to setup UI - it should have access to Main Thread
        GlobalScope.launch(Dispatchers.Main){
            onPreExecute()
        }
        console.log("befoore do in back")
        // doInBackground works on background thread(default)
        GlobalScope.launch(Dispatchers.Default){
            console.log("in do in back")
            val result = doInBackground()
            status = Status.FINISHED
            withContext(Dispatchers.Main){
                // onPostExecute works on main thread to show output
                if (!isCancelled){onPostExecute(result)}
            }
        }
    }

    fun cancel(mayInterruptIfRunning : Boolean){
        isCancelled = true
        status = Status.FINISHED
        GlobalScope.launch(Dispatchers.Main){
            // onPostExecute works on main thread to show output
            onPostExecute(null)
        }
    }
}
enum class Status {
    PENDING,
    RUNNING,
    FINISHED
}