package Structs

import DataBase.UINKDBInterface
import Utils.DatesManipulator
import kotlinx.serialization.Serializable
import myName

@myName("TaklitProduct")
@Serializable
data class TaklitProduct(
    val name: String,
    val id: Int,
    val pricea: Float,
    val priceb: Float,
    val date: String
) : Named {
    override fun getConnectedName(): String {
        return name
    }

    override fun getConnectedId(): Int {
        return id
    }

    override fun getActiveState(): Int {
        return 1
    }

    override fun toString(): String {
        return name.toString()
    }

    fun clone(): TaklitProduct {
        return this.copy()
    }

}


