package Structs

import DataBase.UINKDBInterface
import Utils.DatesManipulator
import Utils.WrappedName
import Utils.getJsonWithConfig
import Utils.timeLock
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import myName
import printPlat

@myName("OrderProduct")
@Serializable
data class OrderProduct(
    val id: Int,
    val product_id: Int,
    val extra_unit: Int,
    val conversion_rate: Float,
    val weight: Float,
    val volume:Float,
    val englishName: String = "",
    var available: Byte,
    var active: Byte,
    val orderName: String = "",
    val position:Int=0,
    val sales:Int=0,
    val forceLock:Int=0,
    val hourLock:String="",
    val dayLock:Int=63, // 6 bits
    val step:Float=0f,
    val collector:Int=-1,
    val min_order:Float=0f,
    val unit_preferece:Int=0, // 0 - default , 1 - first unit , 2 - second unit


) : Named {
    override fun getConnectedName(): String {
        return getName()
    }

    override fun getActiveState(): Int {
        return active.toInt()
    }

    override fun getConnectedId(): Int {
        return product_id
    }
    @myName("isSunday")
    fun isSunday(curDate: String = DatesManipulator.dateNow()): Boolean {
        return dayLock % 2 == 1
    }

    @myName("isMonday")
    fun isMonday(curDate: String = DatesManipulator.dateNow()): Boolean {
        return (dayLock / 2) % 2 == 1
    }

    @myName("isThusday")
    fun isThusday(curDate: String = DatesManipulator.dateNow()): Boolean {
        return (dayLock / 4) % 2 == 1
    }

    @myName("isWendesday")
    fun isWendesday(curDate: String = DatesManipulator.dateNow()): Boolean {
        return (dayLock / 8) % 2 == 1
    }

    @myName("isThursday")
    fun isThursday(curDate: String = DatesManipulator.dateNow()): Boolean {
        return (dayLock / 16) % 2 == 1
    }

    @myName("isFriday")
    fun isFriday(curDate: String = DatesManipulator.dateNow()): Boolean {
        return (dayLock / 32) % 2 == 1
    }
    @myName("isSaturday")
    fun isSaturday(curDate: String = DatesManipulator.dateNow()): Boolean {
        return (dayLock / 64) % 2 == 1
    }
    @myName("daysString")
    fun daysString(curDate: String = DatesManipulator.dateNow()): List<String> {
        val daysStr = mutableListOf<String>()
        if (isSunday(curDate))
            daysStr.add("א")
        if (isMonday(curDate))
            daysStr.add("ב")
        if (isThusday(curDate))
            daysStr.add("ג")
        if (isWendesday(curDate))
            daysStr.add("ד")
        if (isThursday(curDate))
            daysStr.add("ה")
        if (isFriday(curDate))
            daysStr.add("ו")
        if (isSaturday(curDate))
            daysStr.add("ש")
        return daysStr
    }
    @myName("daysInt")
    fun daysInt(curDate: String = DatesManipulator.dateNow()): List<Int> {
        val daysStr = mutableListOf<Int>()
        if (isSunday(curDate))
            daysStr.add(1)
        if (isMonday(curDate))
            daysStr.add(2)
        if (isThusday(curDate))
            daysStr.add(3)
        if (isWendesday(curDate))
            daysStr.add(4)
        if (isThursday(curDate))
            daysStr.add(5)
        if (isFriday(curDate))
            daysStr.add(6)
        if (isSaturday(curDate))
            daysStr.add(7)
        return daysStr
    }
    override fun toString(): String {
        return getName()
    }

    fun clone(): OrderProduct {
        return this.copy()
    }

    @myName("getProduct")
    open fun getProdoct(): Product {
        return UINKDBInterface.activeDB.getClientProduct(product_id).first!!

    }

    @myName("getName")
    open fun getName(): String {
        return UINKDBInterface.activeDB.getClientProduct(product_id).first!!.getName()

    }

    @myName("getExtraUnitName")
    open fun getExtraUnitName(): String? {

        return if (extra_unit > 0) WrappedName.NONE.convert(extra_unit).MethodName() else null

    }

    @myName("extraUnitOn")
    open fun extraUnitOn(): Boolean {
        return (extra_unit > 0)
    }

    @myName("isAvailable")
    open fun isAvailable(): Boolean {
        return (isActive() && available == (1).toByte())

    }

    @myName("isActive")
    open fun isActive(): Boolean {
        return (active == (1).toByte())
    }

    fun isLocked(date:String?=null,applyTime:Boolean=true):Boolean{
        val day = date !=null &&  !daysInt().contains((DatesManipulator.getDayOfWeekIsrael(date) %7 +1))
        val lock_time = applyTime&&timeLock(hourLock)
        return (forceLock==1) || lock_time ||  day
    }

    fun toJson(): JsonObject {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
        map["id"] = JsonPrimitive(id.toString())
        map["product_id"] = JsonPrimitive(product_id.toString())
        map["extra_unit"] = JsonPrimitive(extra_unit.toString())
        map["conversion_rate"] = JsonPrimitive(conversion_rate.toString())
        map["weight"] = JsonPrimitive(weight.toString())
        map["englishName"] = JsonPrimitive(englishName.toString())
        map["available"] = JsonPrimitive(available.toString())
        map["active"] = JsonPrimitive(active.toString())

        return JsonObject(map)
    }

    companion object {
        fun toJsonArrayString(pd: List<OrderProduct>): String {
            return if (pd.isEmpty()) {
                "[]"
            } else {
                val payload = pd.map {
                    it.toJson()
                }.joinToString(",")
                return "[$payload]"
            }

        }
    }
}


