package Structs

import DataBase.UINKDBInterface
import kotlinx.serialization.Serializable
import myName

@myName("TaxNoteMassHolder")
@Serializable
class TaxNoteMassHolder(
    val client_id: Int,
    val notes: List<DeliveryNote>,
    val taxNotes: List<ClientTaxNote>,
    val openSum: Float,
    val openTax: Float,
    val totalSum: Float,
    val date: String,
    val totalTaxTn: Float,
    val totalNotes: Float,
    val totalTax: Float,
) {
    @myName("openNotesNumber")
    fun openNotesNumber(): Int {
        return notes.filter { !it.isUsed() }.size
    }

    @myName("totalNotesNumber")
    fun totalNotesNumber(): Int {
        return notes.size
    }

    @myName("getClients")
    fun getClients(): List<Client> {
        return notes.map { it.ent_id }.distinct().sorted().map { UINKDBInterface.activeDB.getClient(it).first!! }
    }

    @myName("canceledNotesNumber")
    fun canceledNotesNumber(): Int {
        return notes.filter { !it.isActive() }.size
    }

    @myName("clientName")
    fun clientName(): String {
        val c = UINKDBInterface.activeDB.getClient(client_id).first!!
        return if (c.hasBranch()) {
            c.getBusinessName(date) ?: c.getName(date)
        } else {
            c.getName(date)
        }
    }
}