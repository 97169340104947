package DataBase.Interface

import DataBase.DBQuery
import Network.NetworkOutput
import Network.Tasks
import Structs.*
import Structs.Api.ApiConfig
import Structs.Api.ApiNote
import Utils.*
import myName
import kotlin.collections.HashMap

interface DBInterface {
    suspend fun fetch(agent: Agent? = null, loadPrices: Boolean = true,forceAttach:Boolean=false): DBQuery
    suspend fun fetchAll(fromDate: String, toDate: String): DBQuery

    @myName("clearDB")
    fun clearDB()

    @myName("fetchClients")
    suspend fun fetchClients(
        fromDate: String = DatesManipulator.dateNowNoHour(),
        toDate: String = DatesManipulator.dateNowNoHour(),
        withDaily: Boolean = false
    ): DBQuery

    suspend fun fetchSuppliers(
        fromDate: String = DatesManipulator.dateNowNoHour(),
        toDate: String = DatesManipulator.dateNowNoHour()
    ): DBQuery

    suspend fun fetchSpecificClientData(params: HashMap<String, Any>): DBQuery
    suspend fun fetchSpecificClientData(
        ids: List<Int>,
        fromDate: String = DatesManipulator.dateNowNoHour(),
        toDate: String = DatesManipulator.dateNowNoHour(),
        optimized: Boolean = false,
        fromApp: Boolean = false,
        product_ids: List<Int>? = null,
        withLatestProducts: Boolean = false
    ): DBQuery

    suspend fun fetchSpecificSupplierData(
        ids: List<Int>,
        fromDate: String = DatesManipulator.dateNowNoHour(),
        toDate: String = DatesManipulator.dateNowNoHour()
    ): DBQuery

    suspend fun fetchTodayNotes(
        date: String? = null,
        withSuppliers: Boolean = false,
        visit: Int? = null
    ): DBQuery

    fun getCompany(): String

    @myName("setConcreteCompanyId")
    fun setConcreteCompanyId(company_id: String)

    suspend fun buildClientVisitNote(
        fromDate: String? = null,
        toDate: String? = null,
        ids: List<Int>? = null,
        ent_ids: List<Int>? = null,
        states: List<Int>? = null,
        full: Boolean = false,
        has_order: Boolean = false,
    ): Respone<List<VisitNote>, DBQuery>

    suspend fun newClientVisitNote(
        client_id: Int,
        agent: String,
        document_date: String,
        date_issued: String,
        lon_lat: String,
        states: List<Int>,
        comment: String,
        f: ByteArray? = null,
        order_id: Int? = null,
        payment: Float? = null,
        pd: ProductDelivery? = null,
    ): Respone<VisitNote?, DBQuery>

    suspend fun updateClientVisitNote(
        id: Int,
        states: List<Int>? = null,
        payment: Float? = null,
        pd: ProductDelivery? = null,
        comment: String? = null,

        active: Boolean? = null,
        order_id: Int? = null,
        date: String? = null
    ): Respone<VisitNote?, DBQuery>

    @myName("buildClients")
    suspend fun buildClients(
        ids: List<Int>? = null,
        active: Boolean? = null,
        fromDate: String = DatesManipulator.dateNowNoHour(),
        toDate: String = DatesManipulator.dateNowNoHour(),
        branch: Int? = null,
        branches: List<Int>? = null,
        withDaily: Boolean = false
    ): Respone<List<Client>, DBQuery>

    @myName("getClient")
    fun getClient(id: Int): Respone<Client?, DBQuery>


    fun getClient(name: String): Respone<Client?, DBQuery>
    fun getClient(ids: List<Int>): Respone<List<Client>, DBQuery>

    @myName("getBranchMembers")
    fun getBranchMembers(branch: Int, active: Boolean = true): Respone<List<Client>, DBQuery>

    @myName("getAllClient")
    fun getAllClient(active: Boolean): Respone<List<Client>, DBQuery>
    suspend fun newClient(
        agent: String,
        name: String,
        date: String,
        include_tax: Int? = null,
        business_name: String? = null,
        business_id: String? = null,
        address: String? = null,
        print_state: Int? = null,
        phone: String? = null,
        branch: Int? = null,
        master: Int? = null,
        phone_contact: String? = null,
        discount: Float? = null,
        email: String? = null,
        external_id: String? = null,
        day: Int? = null,
        notes: Int? = null,
        no_tax_client: Int? = null,
        position: Int? = null,
        comments: String? = null,
        min_order: Int? = null,
        id: Int? = null,
        location: String? = null,
        category: String? = null,
        obligo: Float? = null,
        price_control: Int? = null,
        payment_notes: String? = null,
        category2: String? = null,
        driver: String? = null,
        min_order_sum: Float? = null,
        tax_note_type: Int? = null,
        net_split: Int? = null,
        days_to_pay: Int? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        date_contact: String? = null,
    ): Respone<Client?, DBQuery>

    suspend fun updateClient(
        id: Int,
        date: String,
        agent: String? = null,
        name: String? = null,
        include_tax: Int? = null,
        business_name: String? = null,
        business_id: String? = null,
        address: String? = null,
        print_state: Int? = null,
        position: Int? = null,
        active: Int? = null,
        phone: String? = null,
        branch: Int? = null,
        master: Int? = null,
        phone_contact: String? = null,
        discount: Float? = null,
        email: String? = null,
        external_id: String? = null,
        day: Int? = null,
        notes: Int? = null,
        no_tax_client: Int? = null,
        comments: String? = null,
        min_order: Int? = null,
        location: String? = null,
        category: String? = null,
        obligo: Float? = null,
        price_control: Int? = null,
        payment_notes: String? = null,
        category2: String? = null,
        driver: String? = null,
        min_order_sum: Float? = null,
        tax_note_type: Int? = null,
        net_split: Int? = null,
        days_to_pay: Int? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        date_contact: String? = null,
    ): Respone<Client?, DBQuery>

    suspend fun updateClientAllData(
        mapper: List<Map<String, Any>>, ids: List<Int>? = null, mimshak: Boolean = false
    ): Respone<List<Client>, DBQuery>

    suspend fun newClientAllDailyData(
        date: String = DatesManipulator.dateNowNoHour(),
        daily: List<ClientDailyData>,
        dailyFinder: Boolean = false,
        onlyDriver: Boolean = false
    ): Respone<List<ClientDailyData>, DBQuery>

    suspend fun buildClientsDailyData(
        id: Int? = null,
        date: String = DatesManipulator.dateNowNoHour(),
        driver_id: List<Int>? = null,
        collector_id: List<Int>? = null,
        day: Int? = null,
        ids: List<Int>? = null,
        dailyFinder: Boolean = false
    ): Respone<List<ClientDailyData>, DBQuery>

    suspend fun newClientDailyData(
        id: Int,
        date: String,
        driver_id: Int,
        collector_id: Int,
        position: Int,
        car: String
    ): Respone<List<ClientDailyData>, DBQuery>

    suspend fun buildClientProducts(
        id: Int? = null,
        active: Int? = null,
        fromDate: String = DatesManipulator.dateNowNoHour(),
        toDate: String = DatesManipulator.dateNowNoHour(),
        ids: List<Int>? = null
    ): Respone<List<Product>, DBQuery>

    @myName("getClientProduct")
    fun getClientProduct(id: Int): Respone<Product?, DBQuery>
    fun getClientProduct(name: String): Respone<Product?, DBQuery>

    @myName("getClientPrices")
    fun getClientPrices(id: Int): HashMap<Int, Price>

    @myName("getAllClientProduct")
    fun getAllClientProduct(active: Boolean = false): Respone<List<Product>, DBQuery>
    suspend fun newClientProduct(
        name: String,
        date: String,
        barcode: String? = null,
        default_price: Float? = null,
        no_tax_product: Int? = null,
        external_id: String? = null,
        unit: Int? = null,
        unit_amount: Float? = null,
        category: String? = null,
        cost_price: Float? = null,
        split_category: Int? = null,
        position: Int? = null,
        base_price: Float? = null,
        cost_include_returns: Int? = null,
        category2: String? = null,
        id: Int? = null,
        taklit_id: Int? = null,
        taklit_type: Int? = null
    ): Respone<Product?, DBQuery>

    suspend fun updateClientProduct(
        id: Int,
        date: String,
        name: String? = null,
        barcode: String? = null,
        default_price: Float? = null,
        position: Int? = null,
        active: Int? = null,
        no_tax_product: Int? = null,
        taklit_id: Int? = null,
        taklit_type: Int? = null,
        external_id: String? = null,
        unit: Int? = null,
        unit_amount: Float? = null,
        category: String? = null,
        cost_price: Float? = null,
        split_category: Int? = null,
        base_price: Float? = null,
        cost_include_returns: Int? = null,
        category2: String? = null
    ): Respone<Product?, DBQuery>

    suspend fun updateClientAllProduct(
        mapper: List<Map<String, Any>>, full: Boolean, mimshak: Boolean = false
    ): Respone<List<Product>, DBQuery>

    suspend fun updateAllSpecialProductPrice(
        ids: List<Int>,
        product_id: List<Int>,
        date: String,
        price: Float? = null,
        default_price: Float? = null,
        percent: Float? = null,
        tax_mutate: Boolean = true,
        percent_price: Float? = null,
        toDate: String? = null,
        byPrice: Float? = null,
        unique: Boolean? = null
    ): DBQuery

    suspend fun updateAllClientProductPrice(
        ids: List<Int>? = null,
        product_id: List<Int>,
        date: String,
        price: Float? = null,
        default_price: Float? = null,
        percent: Float? = null,
        tax_mutate: Boolean = true,
        percent_price: Float? = null,
        toDate: String? = null,
        byPrice: Float? = null,
        unique: Boolean? = null,
        baseChange: Boolean = false,
        alreadyPrice: Boolean = false
    ): DBQuery

    fun attachClientProductsToClients(ids: List<Int>? = null): DBQuery
    suspend fun buildClientPrices(
        id: List<Int>? = null,
        product_id: List<Int>? = null,
        fromDate: String,
        toDate: String,
        available: Boolean? = null,
        master: Boolean? = null,
    ): Respone<List<RawPrice>, DBQuery>

    suspend fun buildClientPriorityPrices(
        id: List<Int>,
        date: String,
    ): Respone<List<RawPrice>, DBQuery>

    suspend fun buildLatestClientPrices(
        ids: List<Int>? = null,
        product_id: Int? = null,
        clear: Boolean = false,
        fetchControlAndMasters: Boolean = false
    ): Respone<List<RawPrice>, DBQuery>

    suspend fun newClientPrice(
        id: Int,
        product_id: Int,
        price: Float,
        date: String? = null,
        discount: Float = 0f,
        toDate: String? = null
    ): Respone<RawPrice?, DBQuery>

    suspend fun buildClientOrderNotes(
        client_id: Int? = null,
        order_id: Int? = null,
        fromDate: String? = null,
        toDate: String? = null,
        full: Boolean = false,
        withoutInventory: Boolean = false,
        order_ids: List<Int>? = null,
        status: List<Int>? = null,
        client_ids: List<Int>? = null,
        inverse_status: List<Int>? = null,
        ref_id: List<Int>? = null,
        agent_id: List<Int>? = null
    ): Respone<List<OrderNote>, DBQuery>

    suspend fun buildClientLastOrderNotes(
        client_id: Int,
        full: Boolean = false
    ): Respone<List<OrderNote>, DBQuery>

    fun getAllClientOrderNotes(
    ): Respone<Map<Int, List<OrderNote>>, DBQuery>

    @myName("getClientOrderNotes")
    fun getClientOrderNotes(
        order_id: Int? = null, client_id: Int? = null, date: String? = null, order_ids: List<Int>? = null
    ): Respone<List<OrderNote>, DBQuery>


    fun getClientOrderNotesOpt(
        order_id: Int? = null,
        client_id: Int? = null,
        fromDate: String? = null,
        toDate: String? = null,
        order_ids: List<Int>? = null,
        withoutInventory: Boolean = false,
        states: List<Int>? = null,
        inverse_status: List<Int>? = null,
        ref_ids: List<Int>? = null,
        agent_ids: List<Int>? = null,
                client_ids: List<Int>? = null,
    ): Respone<List<OrderNote>, DBQuery>

    suspend fun getLastUpdateOrders(
        date: String,
        action_time: String,
        full: Boolean = false
    ): Respone<List<OrderNote>, DBQuery>

    suspend fun getLastUpdateEnt(
        action_time: String,
    ): Respone<List<Int>, DBQuery>

    @myName("getClientBaseOrderNotes")
    suspend fun getClientBaseOrderNotes(
        client_ids: List<Int>? = null, day: Int, full: Boolean = false
    ): Respone<List<OrderNote>, DBQuery>


    suspend fun newClientOrderNote(
        agent: String,
        ent_id: Int,
        date: String,
        date_issued: String,
        delivery_value: String,
        sup_delivery_info: String,
        active: Int = 1,
        notes: String? = null,
        category: String? = null,
        order_id: Int? = null,
        notify: Boolean = false,
        ref_id: String? = null,
        agent_id: Int? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        history: String? = null,
        collection: String? = null,
        increase_id: Int? = null,
        noApi: Boolean? = null,
        static_data: ClientStaticData? = null,
    ): Respone<OrderNote?, DBQuery>

    suspend fun updateClientOrderNote(
        order_id: Int,
        agent: String? = null,
        active: Int? = null,
        date: String? = null,
        delivery_value: List<ProductDelivery>? = null,
        sup_delivery_value: List<ProductDelivery>? = null,
        notes: String? = null,
        category: String? = null,
        updatePrice: Boolean = false,
        notify: Boolean = false,
        ref_id: String? = null,
        inventory: String? = null,
        agent_id: Int? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        history: List<ProductDelivery>? = null,
        collection: Map<Int, CollectionObject>? = null,
        collector_id: Int? = null,
        karton: Float? = null,
        increase_id: Int? = null,
        update_agent: String? = null,
        static_data: ClientStaticData? = null,
    ): Respone<OrderNote?, DBQuery>

    suspend fun newClientOrderNote(
        agent: String,
        ent_id: Int,
        date: String,
        date_issued: String,
        delivery_value: List<ProductDelivery>,
        sup_delivery_value: List<ProductDelivery>,
        active: Int = 1,
        notes: String? = null,
        category: String? = null,
        order_id: Int? = null,
        notify: Boolean = false,
        ref_id: String? = null,
        agent_id: Int? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        history: List<ProductDelivery>? = null,
        with_price: Boolean = false,
        collection: Map<Int, CollectionObject>? = null,
        checkUpdate: Boolean? = null,
        increase_id: Int? = null,
        noApi: Boolean? = null,
        static_data: ClientStaticData? = null,
    ): Respone<OrderNote?, DBQuery>

    suspend fun buildClientDeliveryNotePdf(
        id: Int,
        origin: Int = 0,
        force_price: Boolean? = null
    ): Respone<ByteArray?, DBQuery>

    suspend fun buildClientCartesetPdf(
        id: Int,
        dates: String,
        onlyTaxSide: Boolean? = null,
        open: Boolean? = null,
    ): Respone<ByteArray?, DBQuery>

    suspend fun buildClientPricesPdf(
        id: Int,
        date: String,
        product_ids: List<Int>? = null
    ): Respone<ByteArray?, DBQuery>


    suspend fun buildClientNotes(
        client_id: Int? = null,
        delivery_id: Int? = null,
        fromDate: String? = null,
        toDate: String? = null,
        full: Boolean = false,
        open: Boolean? = null,
        client_ids: List<Int>? = null,
        withDaily: Boolean = false,
        api: Boolean = false,
        orders_ids: List<Int>? = null,
        delivery_ids: List<Int>? = null,
    ): Respone<List<DeliveryNote>, DBQuery>

    suspend fun buildClientNotes(
        client_id: Int? = null,
        datesHolder: DatesHolder,
        full: Boolean = false,
        api: Boolean = false,
        client_ids: List<Int>? = null,
        open: Boolean? = null,
    ): Respone<List<DeliveryNote>, DBQuery>

    suspend fun buildClientLastNotes(
        client_id: Int,
        full: Boolean = false
    ): Respone<List<DeliveryNote>, DBQuery>

    fun getAllClientDeliveryNotes(
    ): Respone<Map<Int, List<DeliveryNote>>, DBQuery>

    fun getClientDeliveryNotes(
        delivery_id: Int? = null, client_id: Int? = null, delivery_ids: List<Int>? = null
    ): Respone<List<DeliveryNote>, DBQuery>


    suspend fun newClientDeliveryNote(
        agent: String,
        ent_id: Int,
        date: String,
        date_issued: String,
        delivery_value: String,
        delivery_id: Int? = null,
        connected_id: Long? = null,
        notes: String? = null,
        order_id: Int? = null,
        increase_id: Int? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        agent_id: Int? = null,
        active: Int? = null,
    ): Respone<DeliveryNote?, DBQuery>

    suspend fun newClientDeliveryNote(
        agent: String,
        ent_id: Int,
        date: String,
        date_issued: String,
        delivery_value: List<ProductDelivery>,
        with_price: Boolean = false,
        delivery_id: Int? = null,
        connected_id: Long? = null,
        notes: String? = null,
        order_id: Int? = null,
        increase_id: Int? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        agent_id: Int? = null,
    ): Respone<List<DeliveryNote>, DBQuery>


    suspend fun updateClientNote(
        delivery_id: Int,
        paid: Int? = null,
        active: Int? = null,
        connected_id: Long? = null,
        delivery_value: List<ProductDelivery>? = null,
        order_id: Int? = null,
        notes: String? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        agent_id: Int? = null,
        updatePrice: Boolean? = null,
        agentEdit: String? = null,
    ): Respone<DeliveryNote?, DBQuery>

    @myName("changePriceFromProductDelivery")
    suspend fun changePriceFromProductDelivery(
        dv: List<ProductDelivery>,
        ent_id: Int,
        date: String,
        ent_type: String
    ): DBQuery

    @myName("calcDeliveryNoteSumMap")
    fun calcDeliveryNoteSumMap(
        notes: List<Note>,
        ent_type: String = "client",
        priceDistinct: Boolean = false,
        forceTaxDate:String? = null
    ): Respone<Map<Int, CalculationHolder>, DBQuery>

    suspend fun calcClientDeliveryNotePdf(
        client_id: Int,
        date_range: String? = null,
        specific_notes: String? = null,
        tax_id_merge: List<Int>? = null,
        order: Boolean? = null,
        client_ids: List<Int>? = null,
        force_sum_type: Int? = null,
    ): Respone<ByteArray?, DBQuery>

    suspend fun buildClientMonthlyCycle(
        date: String? = null,
        id: Int? = null,
        full: Boolean = false
    ): Respone<List<MonthlyCycle>, DBQuery>

    fun getClientMonthlyCycle(): Respone<Map<Int, List<MonthlyCycle>>, DBQuery>
    fun getClientMonthlyCycle(
        date: String? = null,
        id: Int? = null
    ): Respone<List<MonthlyCycle>, DBQuery>


    suspend fun clientMonthlyCycleReCalculate(
        id: Int,
        date: String
    ): DBQuery

    suspend fun setClientMonthlyCycle(
        date: String,
        id: Int,
        value: Float,
        date_updated: String? = null,
        paid: Float? = null,
        date_paid: String? = null
    ): Respone<MonthlyCycle?, DBQuery>


    suspend fun buildClientMonthlyCyclePdf(
        client_name: String,
        month: String,
        year: String
    ): Respone<ByteArray?, DBQuery>

    suspend fun buildClientTaxNoteCyclePdf(
        client_name: String,
        month: String,
        year: String,
        id: Int,
        type: Int,
        origin: Int = 0
    ): Respone<ByteArray?, DBQuery>

    suspend fun buildClientPayCyclePdf(
        client_name: String,
        month: String,
        year: String,
        id: Int,
        origin: Int = 0
    ): Respone<ByteArray?, DBQuery>

    fun calcClientTaxNote(id: Int, type: Int): DBQuery
    suspend fun calcClientPayNote(id: Int): DBQuery
    suspend fun buildClientLightTaxNote(
        client_id: Int? = null,
        id: Int? = null,
        fromDate: String? = null,
        toDate: String? = null,
        full: Boolean = false,
        type: Int? = null,
        withProduct: Int? = null,
        client_ids: List<Int>? = null
    ): Respone<List<ClientLightTaxNote>, DBQuery>

    suspend fun buildClientTaxNote(
        client_id: Int? = null,
        id: Int? = null,
        fromDate: String? = null,
        toDate: String? = null,
        full: Boolean = false,
        type: Int? = null,
        withProduct: Int? = null,
        client_ids: List<Int>? = null,
        ids: List<Int>? = null,
        open: Boolean? = null,
        agents: List<String>? = null,
        check_date: String? = null,
        order_ids: List<Int>? = null,
        withDaily: Boolean = false,
        sup:Boolean= false
    ): Respone<List<ClientTaxNote>, DBQuery>

    suspend fun buildClientLastTaxNote(
        client_id: Int?,
        full: Boolean = false,
        type: Int? = null,
        client_ids: List<Int>? = null,
        sup:Boolean= false

    ): Respone<List<ClientTaxNote>, DBQuery>

    @myName("getClientLightTaxNotes")
    fun getClientLightTaxNotes(
        fromDate: String,
        toDate: String,
        client_id: Int? = null,
    ): Respone<List<ClientLightTaxNote>, DBQuery>

    @myName("getClientTaxNotes")
    fun getClientTaxNotes(
        fromDate: String,
        toDate: String,
        client_id: Int? = null,
        sup:Boolean= false
    ): Respone<List<ClientTaxNote>, DBQuery>

    fun getClientTaxNotes(
        fromDate: String,
        toDate: String,
        client_id: Int? = null,
        type: Int? = null,
        client_ids: List<Int>? = null,
        ids: List<Int>? = null,
        open: Boolean? = null,
        agents: List<String>? = null,
        order_ids: List<Int>? = null,
        withProduct: Int? = null,
        sup:Boolean= false
    ): Respone<List<ClientTaxNote>, DBQuery>

    fun getAllClientTaxNote(sup:Boolean= false): Respone<Map<Int, List<ClientLightTaxNote>>, DBQuery>
    fun getClientTaxNote(

        client_id: Int? = null,
        id: Int? = null,
        type: Int? = null,
        ids: List<Int>? = null,
        sup:Boolean= false
    ): Respone<List<ClientTaxNote>, DBQuery>

    suspend fun newClientTaxNote(
        client_id: Int,
        date: String,
        document_date: String,
        value: Float,
        total_value: Float,
        dataValues: List<ClientTaxNoteData>,
        details: ClientStaticData,
        discount_percent: Float? = null,
        cover_dates: String? = null,
        type: Int? = null,
        paymentsData: List<PaymentData> = listOf(),
        extra_delivery_notes_meta: List<Int>? = null,
        with_price: Boolean = false,
        delivery_notes: List<DeliveryNote>? = null,
        active_state: Int? = null,
        external_details: String? = null,
        agent: String? = null,
        order_id: Int? = null,
        calculate_pdf: Boolean = true,
        tax_note_id: Int? = null,
        agent_id: Int? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        increase_id: Int? = null,
        sup:Boolean= false
    ): Respone<List<ClientTaxNote>, DBQuery>

    suspend fun newClientTaxNoteWithSplit(
        client_id: Int,
        date: String,
        document_date: String,
        delivery_value: List<ProductDelivery>,
        details: ClientStaticData,
        discount_percent: Float?,
        type: Int,
        with_price: Boolean,
        external_details: String?,
        agent: String,
        order_id: Int?,
        round: Boolean = false,
        fastPay: PaymentData? = null,
        agent_id: Int? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        increase_id: Int? = null,
    ): Respone<List<InformationBase>, DBQuery>

    suspend fun cancelClientTaxNote(
        id: Int,
        type: Int,
        date: String,
        agent: String,
        increase_id: Int? = null,
        sup:Boolean= false
    ): Respone<ClientTaxNote?, DBQuery>

    fun calcClientTaxNoteSumMap(notes: List<ClientTaxNote>,sup:Boolean= false): Respone<Float?, DBQuery>

    suspend fun buildClientPay(
        client_id: Int? = null,
        id: Int? = null,
        fromDate: String? = null,
        toDate: String? = null,
        full: Boolean = false,
        client_ids: List<Int>? = null,
        ids: List<Int>? = null,
        agents: List<String>? = null,
        check_date: String? = null,
        withTaxIds: Boolean = false,
        sup:Boolean= false
    ): Respone<List<ClientPay>, DBQuery>

    suspend fun buildClientChecks(
        client_id: Int? = null,
        client_ids: List<Int>? = null,
        last: Int? = null,
        sup:Boolean= false

    ): Respone<List<PaymentData>, DBQuery>

    suspend fun buildClientLightPay(
        client_id: Int? = null,
        id: Int? = null,
        fromDate: String? = null,
        toDate: String? = null,
        full: Boolean = false,
        client_ids: List<Int>? = null
    ): Respone<List<ClientLightPay>, DBQuery>

    suspend fun buildClientLastPay(
        client_id: Int? = null,
        full: Boolean = false,
        client_ids: List<Int>? = null,
        sup:Boolean= false
    ): Respone<List<ClientPay>, DBQuery>

    fun getAllClientPay(sup:Boolean= false): Respone<Map<Int, List<ClientLightPay>>, DBQuery>

    @myName("getClientLightPay")
    fun getClientLightPay(
        fromDate: String,
        toDate: String,
        client_id: Int? = null
    ): Respone<List<ClientLightPay>, DBQuery>

    @myName("getClientPay")
    fun getClientPay(
        fromDate: String,
        toDate: String,
        client_id: Int? = null,
        client_ids: List<Int>? = null,
        agents: List<String>?=null,
        check_date: String? = null,
        ids: List<Int>? = null,
        sup:Boolean= false
    ): Respone<List<ClientPay>, DBQuery>

    fun getClientPay(
        client_id: Int? = null,
        id: Int? = null,
        ids: List<Int>? = null,
        sup:Boolean= false
    ): Respone<List<ClientPay>, DBQuery>

    suspend fun newClientPay(
        client_id: Int,
        date: String,
        document_date: String,
        value: Float,
        details: ClientStaticData,
        paymentsData: List<PaymentData> = listOf(),
        tax_note_id: String? = null,
        cancel_id: Int? = null,
        reopen_tax_notes: String? = null,
        active: Int? = null,
        external_details: String? = null,
        agent: String? = null,
        refundRest: Boolean? = null,
        type: Int? = null,
        increase_id: Int? = null,
        sup:Int?=null,
        ref_id:String?=null
    ): Respone<ClientPay?, DBQuery>

    suspend fun updateClientPay(
        client_id: Int,
        paymentsData: List<PaymentData>? = null,
        tax_note_id: String? = null,
        cancel_id: Int? = null,
        reopen_tax_notes: String? = null,
        active: Int? = null,
        external_details: String? = null,
        sup:Boolean= false
    ): Respone<ClientPay?, DBQuery>

    suspend fun cancelClientPay(
        id: Int,
        date: String,
        agent: String,
        increase_id: Int? = null,
      value: Float?=null,
        checkNumber: String?=null,
        bank_id: String?=null,
        method:Int?=null,
        sup:Boolean= false
    ): Respone<ClientPay?, DBQuery>

    suspend fun buildClientCartesetMap(
        id: Int? = null,
        fromDate: String? = null,
        toDate: String? = null,
        ids: List<Int>? = null,
        state: Int? = null,
        sup:Boolean= false
    ): Respone<List<ClientCarteset>, DBQuery>

    suspend fun newClientCarteset(
        client_id: Int,
        date: String,
        positive: Float,
        negative: Float,
        state: Int = 0,
        notes: String? = null,
        sup:Boolean= false
    ): Respone<ClientCarteset?, DBQuery>

    suspend fun buildClientEdiMap(date: String, ids: List<Int>? = null): Respone<List<EdiMember>, DBQuery>
    fun getClientEdiMap(id: Int): Respone<EdiMember?, DBQuery>
    suspend fun newEdiClient(
        client_id: Int,
        date: String,
        edi_sup_id: String,
        edi_client_id: String,
        branch: String,
        internal_sup_edi: String, net_edi: String

    ): Respone<EdiMember?, DBQuery>

    suspend fun deActivate(ent: String, deliveryNote: DeliveryNote, state: Int = 1): Respone<DeliveryNote?, DBQuery>
    suspend fun deActivate(client: Client): DBQuery
    suspend fun deActivate(ent: String, p: Product): DBQuery
    suspend fun deActivate(supplier: Supplier): DBQuery
    suspend fun activate(ent: String, deliveryNote: DeliveryNote): DBQuery
    suspend fun activate(client: Client): DBQuery
    suspend fun activate(ent: String, p: Product): DBQuery
    suspend fun activate(supplier: Supplier): DBQuery

    suspend fun buildSuppliers(
        ids: List<Int>?,
        fromDate: String = DatesManipulator.dateNowNoHour(),
        toDate: String = DatesManipulator.dateNowNoHour()
    ): Respone<List<Supplier>, DBQuery>

    suspend fun buildSuppliers(
        id: Int,
        fromDate: String = DatesManipulator.dateNowNoHour(),
        toDate: String = DatesManipulator.dateNowNoHour()
    ): Respone<List<Supplier>, DBQuery>

    suspend fun buildSuppliers(
        active: Int? = null, fromDate: String = DatesManipulator.dateNowNoHour(),
        toDate: String = DatesManipulator.dateNowNoHour()
    ): Respone<List<Supplier>, DBQuery>

    fun getSupplier(ids: List<Int>): Respone<List<Supplier>, DBQuery>

    @myName("getSupplier")
    fun getSupplier(id: Int): Respone<Supplier?, DBQuery>
    fun getSupplier(name: String): Respone<Supplier?, DBQuery>

    @myName("getAllSupplier")
    fun getAllSupplier(active: Boolean): Respone<List<Supplier>, DBQuery>
    suspend fun newSupplier(
        name: String,
        date: String,
        include_tax: Int? = null,
        business_name: String? = null,
        business_id: String? = null,
        address: String? = null,
        print_state: Int? = null,
        phone: String? = null,
        no_tax_client: Int? = null,
        external_id: String? = null,
        comments: String? = null,
        category: String? = null,
        payment_notes: String? = null,
        category2: String? = null,
        phone_contact: String? = null,
    ): Respone<Supplier?, DBQuery>

    suspend fun updateSupplier(
        id: Int,
        date: String,
        name: String? = null,
        include_tax: Int? = null,
        business_name: String? = null,
        business_id: String? = null,
        address: String? = null,
        print_state: Int? = null,
        position: Int? = null,
        active: Int? = null,
        phone: String? = null,
        no_tax_client: Int? = null,
        external_id: String? = null,
        comments: String? = null,
        category: String? = null,
        payment_notes: String? = null,
        category2: String? = null,
        phone_contact: String? = null,
    ): Respone<Supplier?, DBQuery>

    suspend fun buildSupplierProducts(
        active: Int? = null, fromDate: String = DatesManipulator.dateNowNoHour(),
        toDate: String = DatesManipulator.dateNowNoHour(),
        attach: Boolean? = null
    ): Respone<List<Product>, DBQuery>

    suspend fun buildSupplierProducts(
        id: Int, fromDate: String = DatesManipulator.dateNowNoHour(),
        toDate: String = DatesManipulator.dateNowNoHour()
    ): Respone<List<Product>, DBQuery>

    @myName("getSupplierProduct")
    fun getSupplierProduct(id: Int): Respone<Product?, DBQuery>
    fun getSupplierProduct(name: String): Respone<Product?, DBQuery>
    fun getSupplierPrices(id: Int): HashMap<Int, Price>
    suspend fun updateAllSuppliersProductPrice(
        ids: List<Int>? = null,
        product_id: List<Int>,
        date: String,
        price: Float? = null,
        default_price: Float? = null,
        percent: Float? = null,
        tax_mutate: Boolean = true,
        percent_price: Float? = null,
        toDate: String? = null,
        byPrice: Float? = null,
        unique: Boolean? = null
    ): DBQuery

    @myName("getAllSupplierProduct")
    fun getAllSupplierProduct(active: Boolean): Respone<List<Product>, DBQuery>
    suspend fun newSupplierProduct(
        name: String,
        date: String,
        barcode: String? = null,
        default_price: Float? = null,
        connected_product: Int? = null,
        no_tax_product: Int? = null,
        category: String? = null,
        external_id: String? = null,
    ): Respone<Product?, DBQuery>

    suspend fun updateSupplierProduct(
        id: Int,
        date: String,
        name: String? = null,
        barcode: String? = null,
        default_price: Float? = null,
        position: Int? = null,
        active: Int? = null,
        connected_product: Int? = null,
        no_tax_product: Int? = null,
        category: String? = null,
        external_id: String? = null,
    ): Respone<Product?, DBQuery>

    fun attachSupplierProductsToSuppliers(ids: List<Int>? = null): DBQuery
    suspend fun buildSupplierPrices(
        ids: List<Int>? = null,
        product_ids: List<Int>? = null,
        fromDate: String,
        toDate: String
    ): Respone<List<RawPrice>, DBQuery>

    suspend fun buildSupplierPrices(
        id: Int, fromDate: String = DatesManipulator.dateNowNoHour(),
        toDate: String = DatesManipulator.dateNowNoHour()
    ): Respone<List<RawPrice>, DBQuery>

    suspend fun buildSupplierPrices(
        id: Int? = null,
        product_id: Int? = null,
        fromDate: String,
        toDate: String
    ): Respone<List<RawPrice>, DBQuery>

    suspend fun buildLatestSupplierPrices(
        id: Int? = null,
        product_id: Int? = null
    ): Respone<List<RawPrice>, DBQuery>

    suspend fun newSupplierPrice(
        id: Int,
        product_id: Int,
        price: Float,
        date: String? = null,
        discount: Float = 0f,
        toDate: String? = null
    ): Respone<RawPrice?, DBQuery>

    suspend fun buildSupplierNotes(
        supplier_id: Int? = null,
        datesHolder: DatesHolder,
        full: Boolean,

    ): Respone<List<DeliveryNote>, DBQuery>

    suspend fun buildSupplierNotes(
        supplier_id: Int? = null,
        delivery_id: Int? = null,
        fromDate: String? = null,
        toDate: String? = null,
        full: Boolean = false,
        delivery_ids: List<Int>? = null,
        open: Boolean?=null
    ): Respone<List<DeliveryNote>, DBQuery>

    suspend fun buildSupplierLastNotes(
        supplier_id: Int,
        full: Boolean = false
    ): Respone<List<DeliveryNote>, DBQuery>

    fun getAllSupplierDeliveryNotes(
    ): Respone<Map<Int, List<DeliveryNote>>, DBQuery>

    fun getSupplierDeliveryNotes(
        fromDate: String, toDate: String, active: Boolean
    ): Respone<List<DeliveryNote>, DBQuery>

    fun getSupplierDeliveryNotes(
        delivery_id: Int? = null, supplier_id: Int? = null, delivery_ids: List<Int>? = null
    ): Respone<List<DeliveryNote>, DBQuery>


    suspend fun newSupplierDeliveryNote(
        agent: String,
        ent_id: Int,
        date: String,
        date_issued: String,
        delivery_value: String,
        connected_id: Long?,
        notes: String? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
    ): Respone<DeliveryNote?, DBQuery>

    suspend fun newSupplierDeliveryNote(
        agent: String,
        ent_id: Int,
        date: String,
        date_issued: String,
        delivery_value: List<ProductDelivery>,
        connected_id: Long?,
        with_price: Boolean = false,
        notes: String? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
    ): Respone<List<DeliveryNote>, DBQuery>

    suspend fun updateSupplierDeliveryNote(
        delivery_id: Int,
        paid: Int? = null,
        active: Int? = null,
        connected_id: Long? = null,
        delivery_value: List<ProductDelivery>? = null,
        notes: String? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
    ): Respone<DeliveryNote?, DBQuery>

    suspend
    fun closeSupplierDeliveryNote(
        delivery_ids: List<Int>
    ): Respone<List<DeliveryNote>, DBQuery>

    suspend fun supplierMonthlyCycleReCalculate(id: Int, date: String): DBQuery
    suspend fun buildSupplierMonthlyCyclePdf(
        sup_name: String,
        month: String,
        year: String
    ): Respone<ByteArray?, DBQuery>

    suspend fun buildSupplierMonthlyCycle(
        date: String? = null,
        id: Int? = null,
        full: Boolean = false
    ): Respone<List<MonthlyCycle>, DBQuery>

    suspend fun calcSupplierDeliveryNotePdf(
        sup_id: Int,
        date_range: String,
        specific_notes: String? = null
    ): Respone<ByteArray?, DBQuery>

    fun getSupplierMonthlyCycle(): Respone<Map<Int, List<MonthlyCycle>>, DBQuery>
    fun getSupplierMonthlyCycle(
        date: String? = null,
        id: Int? = null
    ): Respone<List<MonthlyCycle>, DBQuery>


    suspend fun setSupplierMonthlyCycle(
        date: String,
        id: Int,
        value: Float,
        paid: Float? = null,
        date_paid: String? = null,
        date_updated: String? = null
    ): Respone<MonthlyCycle?, DBQuery>

    suspend
    fun dataToAccountManager(date_range: String): Respone<ByteArray?, DBQuery>
    suspend fun calcDocumentSum(
        doc_type: Int,
        date_range: String,
        pdf: Boolean,
        open_only: Boolean? = null,
        active_only: Boolean? = null,
        ent_id: List<Int>? = null
    ): Respone<ByteArray?, DBQuery>

    suspend fun buildAgents(): Respone<List<Agent>, DBQuery>

    @myName("getAgents")
    fun getAgents(hasLine: Boolean = false): Respone<List<Agent>, DBQuery>

    suspend fun updateAgent(
        ids: List<Int>,
        user_name: List<String>? = null,
        password: List<String>? = null,
        comments: List<String>? = null,
    ): Respone<List<Agent>, DBQuery>

    suspend fun buildUserInfo(
        id: String? = null,
        company: String? = null,
        fromDate: String = DatesManipulator.dateNowNoHour(),
        toDate: String = DatesManipulator.dateNowNoHour()
    ): DBQuery

    suspend fun buildCofcUserConf(
        id: Int? = null, cofc: Boolean = false
    ): DBQuery

    @myName("getUser")
    fun getUser(): Respone<User?, DBQuery>

    suspend fun downloadApp(): Respone<ByteArray?, DBQuery>
    suspend fun calcClientDebtXSL(
        date_range: String,
        agent: Int = -1,
        noZero: Int? = null
    ): Respone<ByteArray?, DBQuery>

    suspend fun calcClientDebtTillNow(
        fromDate: String,
        toDate: String,
        client_id: Int? = null,
        withDebtYearly: Boolean = false
    ): DBQuery

    suspend fun calcAmountData(
        fromDate: String, toDate: String,
        sn: StateNotifier? = null,
        id: Int? = null,
        withAgent: Boolean = false
    ): Respone<Triple<NotesAmountsQuery?, NotesAmountsQuery?, NotesAmountsQuery?>, DBQuery>

    suspend fun calcOrdersAmountData(
        fromDate: String,
        toDate: String,
        sn: StateNotifier?,
        inventory: Boolean = false,
        withAgent: Boolean = false,
        id: Int? = null
    ): Respone<Pair<NotesAmountsQuery?, NotesAmountsQuery?>, DBQuery>

    suspend fun duplicateTomorowsOrder(date: String, toDate: String? = null): DBQuery
    suspend fun newInventoryNote(
        agents: List<String>,
        date: String,
        delivery_value: List<List<ProductDelivery>>,
        sup_delivery_value: List<List<ProductDelivery>>,
        orders: List<Int>,
        agent_ids: List<Int>? = null,
        increase_id: Int? = null,
    ): Respone<List<OrderNote>, DBQuery>

    suspend fun buildIsraelCities(new: Boolean = false): DBQuery

    @myName("getPricesOfProduct")
    fun getPricesOfProduct(product_id: Int, client: Boolean = true): Respone<List<EntityPriceHolder>, DBQuery>

    @myName("helloJs")
    fun helloJs(): User

    fun musi(): Tasks

    suspend fun buildClientEdiNotes(
        note_id: List<Int>? = null,
        connected_id: Long? = null,
        fromDate: String? = null,
        toDate: String? = null,
        send: Boolean? = null,
        full: Boolean = false
    ): Respone<List<EdiNote>, DBQuery>

    suspend fun newClientEdiNote(
        note_id: Int,
        date: String,
        info: String,
        reason: Int? = null,
        connected_id: Long? = null,
        send: Boolean? = null
    ): Respone<EdiNote?, DBQuery>


    suspend fun updateClientEdiNote(
        note_id: Int,
        date: String,
        info: String? = null,
        reason: Int? = null,
        connected_id: Long? = null,
        send: Boolean? = null
    ): Respone<EdiNote?, DBQuery>

    suspend fun buildTaklitProducts(
        id: Int? = null,
        date: String = DatesManipulator.dateNowNoHour()
    ): Respone<List<TaklitProduct>, DBQuery>

    suspend fun calcTaklit(

    ): DBQuery

    suspend fun buildOrderProducts(
        id: Int? = null,
        product_id: Int? = null,
        available: Boolean? = null,
        active: Boolean? = null,
        product_ids: List<Int>? = null
    ): Respone<List<OrderProduct>, DBQuery>

    suspend fun newOrderProducts(
        id: Int,
        product_id: Int,
        extra_unit: Int,
        conversion_rate: Float,
        available: Boolean,
        active: Boolean,
        weight: Float? = null,
        englishName: String? = null,
        orderName: String? = null,
        position: Int? = null,
        volume: Float? = null,
        sales: Int? = null,
        forceLock: Int? = null,
        hourLock: String? = null,
        dayLock: Int? = null,
        step: Float? = null,
        collector: Int? = null,
        min_order: Float? = null,
        unit_preferece: Int? = null,
    ): Respone<OrderProduct?, DBQuery>

    suspend fun updateAllOrderProduct(
        mapper: List<Map<String, Any>>
    ): Respone<List<OrderProduct>, DBQuery>

    suspend fun buildCofcClients(
        id: Int,
        date: String
    ): Respone<List<Client>, DBQuery>

    suspend fun getCofcId(
    ): Respone<String?, DBQuery>

    suspend fun registerCofcId(
        id: Int,
        until: String,
        open_date: String? = null,
        short:Boolean?= null
    ): Respone<String?, DBQuery>

    suspend fun getCofcProducts(
        id: Int,
        date: String
    ): Respone<List<Product>, DBQuery>

    suspend fun getCofcAgents(
        id: Int,
        collectors: Boolean? = null,
        drivers: Boolean? = null,

        ): Respone<List<Agent>, DBQuery>

    suspend fun getCofcOrderProducts(
        id: Int,
        op_id: Int? = null,
        product_id: Int? = null,
        available: Boolean? = null,
        active: Boolean? = null
    ): Respone<List<OrderProduct>, DBQuery>

    suspend fun getCofcProductsImage(
        id: Int,
        date: String,
        product_id: Int
    ): Respone<ByteArray?, DBQuery>

    suspend fun getCofcClientsPrices(
        id: Int,
        date: String
    ): Respone<List<RawPrice>, DBQuery>

    suspend fun getCofcClientsOrder(
        id: Int,
        dateFrom: String,
        dateTo: String,
        status: Int? = null
    ): Respone<List<OrderNote>, DBQuery>

    suspend fun newCofcClientOrderNote(
        agent: String,
        ent_id: Int,
        date: String,
        date_issued: String,
        delivery_value: List<ProductDelivery>,
        sup_delivery_info: List<ProductDelivery>,
        order_id: Int? = null,
        active: Int? = null,
        notes: String? = null,
        category: String? = null,
        static_data: ClientStaticData? = null
    ): Respone<OrderNote?, DBQuery>

    suspend fun updateCofcClientOrderNote(
        order_id: Int,
        ent_id: Int,
        agent: String? = null,
        date: String? = null,
        delivery_value: List<ProductDelivery>,
        sup_delivery_info: List<ProductDelivery>,
        active: Int? = null,
        notes: String? = null,
        category: String? = null,
        static_data: ClientStaticData? = null
    ): Respone<OrderNote?, DBQuery>

    suspend fun uploadImg(f: ByteArray, product_id: Int, offline: Boolean = false): DBQuery

    suspend fun uploadSign(
        f: ByteArray,
        note_id: Int,
        date: String,
        signer: String,
        doc_type: Int,
        offline: Boolean = false
    ): Respone<NoteSignHolder?, DBQuery>

    suspend fun uploadPhoto(
        f: ByteArray,
        note_id: Int,
        date: String,
        doc_type: Int,
        offline: Boolean = false
    ): Respone<NoteSignHolder?, DBQuery>

    suspend fun getSignImg(
        note_id: Int,
        doc_type: Int = 4,
        strict: Boolean = false
    ): Respone<Triple<ByteArray?, Int, Int>, DBQuery>

    suspend fun getSignPhoto(
        note_id: Int,
        doc_type: Int = 4,
        strict: Boolean = false
    ): Respone<Triple<ByteArray?, Int, Int>, DBQuery>

    suspend fun getSign(
        ids: List<Int>,
        doc_type: Int = 4,

        ): Respone<List<NoteSignHolder>, DBQuery>

    suspend fun getClientProductsImage(product_id: Int?): DBQuery
    suspend fun prepareMassTaxNote(
        date: String,
        client_ids: List<Int>? = null,
        fromDate: String? = null,
        allOpen: Boolean = false
    ): Respone<List<TaxNoteMassHolder>, DBQuery>

    suspend fun calcDocumentZip(
        doc_type: Int,
        date_range: String
    ): Respone<ByteArray?, DBQuery>

    suspend fun takanonPDF(
    ): Respone<ByteArray?, DBQuery>

    suspend fun calcApiExcelZip(
        date_start: String,
        date_end: String,
        xlsxType: String? = null,
        c_ids: List<Int>? = null,
        ids: List<Int>? = null,
    ): Respone<ByteArray?, DBQuery>

    suspend fun calcApiHashavshevetZip(
        date_start: String,
        date_end: String,
        ids: List<Int>? = null,
        types: List<Int>? = null
    ): Respone<ByteArray?, DBQuery>

    suspend fun getClientCPData(
        ids: List<Int>? = null,
        product_ids: List<Int>? = null,
    ): Respone<List<ClientProductData>, DBQuery>

    suspend fun setClientCPData(
        ids: List<Int>,
        product_ids: List<Int>,
        barcode: List<String?>? = null,
    ): Respone<List<ClientProductData>, DBQuery>

    //api
    suspend fun getApiNote(
        doc_ids: List<Int>? = null,
        doc_type: Int? = null,
        api_method: Int? = null,
        connected_id: Long? = null,
        fromDate: String? = null,
        toDate: String? = null,
        send: Int? = null,
        full: Boolean = false,
        retries: Int? = null,
        doc_types: List<Int>? = null
    ): Respone<List<ApiNote>, DBQuery>

    suspend fun setApiNote(
        doc_id: Int,
        doc_type: Int,
        api_method: Int,
        ent_id: Int? = null,
        connected_id: Long? = null,
        doc_date: String? = null,
        send: Int? = null,
        reason: String? = null,
        retries: Int? = null
    ): Respone<List<ApiNote>, DBQuery>

    suspend fun getApiConfig(
        withOrders: Boolean? = null,
        whatsapp: Boolean? = null,
        last_counter: Boolean? = null,

        ): Respone<ApiConfig?, DBQuery>

    suspend fun calcClientOrderPdf(ids: List<Int>, cofc: Boolean = false, id: Int? = null): Respone<ByteArray?, DBQuery>
    suspend fun calcMASSdocPdf(
        ids: List<Int>,
        docs: List<Int>,
        force_price: Boolean? = null,
        origin: Boolean? = null,
        originForce: Int? = null,
        dates: String? = null
    ): Respone<ByteArray?, DBQuery>

    suspend fun calcClientsXslx(clients: List<Int>? = null): Respone<ByteArray?, DBQuery>
    suspend fun calcProductsXslx(products: List<Int>? = null): Respone<ByteArray?, DBQuery>
    suspend fun getClientsLastVisitationOptimized(
        ids: List<Int>? = null, allClients: Boolean = false, tax_visitation: Boolean = true,
        note_visitation: Boolean = true, dv_visitation: Boolean = true
    ): Respone<List<ClientLastVisit>, DBQuery>

    suspend fun getClientsVisitationOptimized(
        ids: List<Int>? = null,
        fromDate: String,
        toDate: String,
        allClients: Boolean = false,
        tax_visitation: Boolean = true,
        note_visitation: Boolean = true,
        dv_visitation: Boolean = true,
        last: Boolean = false
    ): Respone<Pair<List<ClientVisitationHolder>, List<String>>?, DBQuery>

    suspend fun getClientsDebts(
        ids: List<Int>? = null,
        fromDate: String,
        toDate: String
    ): Respone<List<ClientDebt>, DBQuery>

    suspend fun getClientPayDetails(
        ids: List<Int>? = null,
        fromDate: String? = null,
        toDate: String? = null,
        paid: Boolean? = null
    ): Respone<List<PaymentData>, DBQuery>

    suspend fun getOptimizedClientNote(
        ent_id: List<Int>? = null,
        delivery_id: Int? = null,
        fromDate: String? = null,
        toDate: String? = null,
        delivery_ids: List<Int>? = null,
        open: Boolean? = null,
        full: Boolean = false,
        order: Boolean = false
    ): Respone<List<Note>, DBQuery>

    suspend fun sendEmail(
        f: ByteArray,
        email: String,
        title: String,
        doc_type: Int? = null,
        doc_id: Int? = null
    ): DBQuery

    suspend fun sendReqForApi(
        doc_type: Int,
        doc_id: Int,
        api_method: Int? = null,
    ): DBQuery

    suspend fun sendClientApi(doc_id: List<Int>, doc_type: List<Int>): DBQuery
    suspend fun loadXlsx(
        f: ByteArray,
        load_type: String,
        date: String? = null,
        type: String? = null,
        load_doc_id: Boolean?
    ): Respone<String?, DBQuery>

    suspend fun calcClientReportPdf(
        fromDate: String, toDate: String, report_type: ReportType
    ): Respone<ByteArray?, DBQuery>

    suspend fun getInventory(
        product_id: List<Int>? = null,
        agent_id: List<Int>? = null,
        fromDate: String? = null,
        toDate: String? = null,
        inv_type: List<Int>? = null,
        last: Boolean = false,
        byEnt:Int=-1

        ): Respone<List<InventoryItem>, DBQuery>

    suspend fun insertInventory(
        date: String,
        inventoryItems: List<InventoryItem>
    ): DBQuery

    suspend fun clearInventory(
        product_id: List<Int>? = null,
        agent_id: List<Int>? = null,
    ): DBQuery

    suspend fun sendMsgAll(
        ids: List<Int>,
        msg: String,
        title: String,
        emails: List<String>? = null,
        phones: List<String>? = null,
        action_date: String? = null,
        doc_type: List<Int>? = null,
        special_arg: String? = null,
        external_var:List<String>? = null,
        agent:String?=null

        ): DBQuery

    suspend fun getProductAvailable(
        ids: List<Int>? = null,
        product_ids: List<Int>? = null,
        available: Int? = null,
    ): Respone<List<Available>, DBQuery>

    suspend fun newAllAvailable(
        data: List<Available>,
    ): Respone<List<Available>, DBQuery>


    suspend fun getCofcFull(
        id: Int
    ): DBQuery

    suspend fun resetWhatsappConn(): DBQuery
}