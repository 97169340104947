package Network


enum class NetworkOutput {
    SUCCESS,
    ERROR_ID_NOT_EXIST,
    ERROR_ARG_MISSING,
    ERROR_GENERIC,
    ERROR_EMPTY_QUERY,
    CONNECTION_ERROR,
    TOKEN_ERROR,
    ERROR_DUPLICATE,
    FAILED,
    TOKEN_FAIL;

}
