package Structs

import kotlinx.serialization.Serializable

@Serializable
open class ProductSequence() : DatedObject<Product>() {
    override fun isActive(curDate:String): Boolean {
        return get(curDate).active==1
    }


    fun add(p: Product) {
        add(p, p.date)
    }

    override fun copy(): ProductSequence {
        val new_p: ProductSequence = ProductSequence()
        new_p.objects = objects.toMutableList()
        return new_p
    }

    override fun add(obj: Product, date: String,runOver:Boolean) {
        super.add(obj, date,runOver)
        obj.productDated = this
    }

    override fun toString(): String {
        return get().toString()
    }
    override fun getDefault(curDate: String): Product {
        return get(default = true)
    }

}