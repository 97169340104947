package Structs

import Utils.DatesManipulator
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import myName

@myName("RawPricePriority")
@Serializable
data class RawPricePriority(val id: Int, val product_id: Int, val price: Float?, val date: String? ,val discount: Float?,val available:Int,val priority:Float,val master:Int?=null)
             {
    fun toRawPrice(idd:Int?=null):RawPrice?{
        if(price == null || date == null || discount == null) return null
        return RawPrice(idd?:id,product_id,price,date,discount,master)
    }
    fun toAvailable():Available{
        return Available(id,product_id,available,priority, master = if(master!=null && master!=-1) master else null)
    }
}