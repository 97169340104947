package Utils

import Structs.Entity
import Structs.Price
import Structs.Product
import myName

@myName("EntityPriceHolder")
data class EntityPriceHolder(val entity: Entity, val product_id: Int) {
    @myName("getPrice")
    fun getPrice(date: String): Float {
        return entity.getPrice(product_id)?.get(date)?.first ?: 0f
    }
}
