package Structs

import kotlinx.serialization.Serializable

@Serializable
data class CollectionHolder(
    val id: String,
    val box: Float,
    ) {


}
